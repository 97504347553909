import RequestHelper from "./requesthelper";

export default class ArriveQueue {
  constructor(apply_function, app) {
    this.app = app;
    this.apply_function = apply_function;
    this.queue = [];
  }

  arrive(response, myself) {
    var req = new RequestHelper(response.target.request_info);
    var queue_item = this.new_queue_item(req, response, myself);
    if (this.ready_to_apply(req)) {
      this.apply(queue_item);
    } else {
      this.add_to_queue(queue_item);
    }
  }

  ready_to_apply(req) {
    if (req.depend_on_project()) {
      if (!this.required_project_exists(req)) return false;

      if (req.depend_on_zone()) {
        if (!this.required_zone_exists(req)) return false;

        if (req.depend_on_node()) {
          if (!this.required_node_exists(req)) return false;
        }
      }
    }

    // Some request can't be applied until the
    // current_user is properly initalized
    if (req.depend_on_user_being_initalized()) {
      if (!this.app.$appm.current_user_initialized()) return false;
    }

    return true;
  }

  add_to_queue(item) {
    this.queue.push(item);
  }

  apply(queue_item) {
    queue_item.applied = true;
    this.apply_function(queue_item.response, queue_item.context);
  }

  apply_queue() {
    var items_which_can_be_applied = this.items_which_can_be_applied();
    while (items_which_can_be_applied.length > 0) {
      this.apply(items_which_can_be_applied[0]);
      this.remove_applied_from_queue();
      items_which_can_be_applied = this.items_which_can_be_applied();
    }
  }

  items_which_can_be_applied() {
    return this.queue.filter(item => this.ready_to_apply(item.request_info));
  }

  remove_applied_from_queue() {
    this.queue = this.queue.filter(item => !item.applied);
  }

  required_project_exists(req) {
    return this.app.$appm.get_project(req.data.identification.project_id) != null;
  }

  required_zone_exists(req) {
    return (
      this.app.$appm.get_zone(
        req.data.identification.project_id,
        req.data.identification.zone_id
      ) != null
    );
  }

  required_node_exists(req) {
    return (
      this.app.$appm.get_node(
        req.data.identification.project_id,
        req.data.identification.zone_id,
        req.data.identification.node_id
      ) != null
    );
  }

  new_queue_item(request_info, response, context) {
    if (request_info == null || response == null || context == null) {
      throw "Arrive queue items cannot have null";
    }

    return {
      request_info: request_info,
      response: response,
      context: context,
      applied: false
    };
  }
}
