<template>
  <span>
    <!--
<Btn title="A" @click="$emit('input', !value)"/>
-->
    <IconBtn
      size="18"
      :icon="icon"
      color="gray"
      @clicked="$emit('input', !value)"
    />
  </span>
</template>

<script>
export default {
  name: "OpenCloseTreeBtn",
  data() {
    return {
      t: true
    };
  },

  props: {
    value: Boolean
  },

  methods: {
    toggle() {}
  },

  computed: {
    icon() {
      return this.value ? this.$icon.close_tree : this.$icon.open_tree;
    }
  },

  created: function() {}
};
</script>
