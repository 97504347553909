<template lang="html">
  <div
    class="file_export_box"
    :class="{
      file_export_submitted: export_action.state == 0,
      file_export_working: export_action.state == 1,
      file_export_finished: export_action.state == 2,
      file_export_failed: export_action.state == 3
    }"
  >
    <div class="heading">{{ progress_text }}</div>
    <div class="description">{{ description }}</div>

    <Progressbar
      style="margin-bottom: 10px"
      v-if="show_progress_bar"
      :max="100"
      :now="export_action.progress"
      :show="true"
      :height="6"
    />
    <div v-show="show_download_btn">
      <hr />
      <Btn class="hol_btna" :title="$txt.download"> </Btn>
    </div>
  </div>
</template>

<script>
export default {
  name: "FileExportIndicator",
  data() {
    return {};
  },

  props: {
    export_action: {
      type: Object
    }
  },

  computed: {
    progress_text() {
      switch (this.export_action.state) {
        case 0: {
          return this.$txt.submitting_export_request;
        }

        case 1: {
          return this.$txt.generating_export;
        }

        case 2: {
          return this.$txt.export_finished;
        }

        case 3: {
          return this.$txt.export_failed;
        }

        default: {
          return this.$txt.working;
        }
      }
    },

    show_progress_bar() {
      return this.export_action.state == 1;
    },

    show_download_btn() {
      return this.export_action.state == 2;
    },

    description() {
      return this.$txt.filename + ": " + this.export_action.filename;
    }
  },

  methods: {
    download_to_file() {
      // TODO: download the file
    }
  }
};
</script>

<style lang="less" scoped>
.file_export_box {
  max-width: 600px;
  border-radius: 4px;
  padding: 10px;
  padding-bottom: 10px;
  margin-top: 3px;
}

hr {
  margin: 5px;
  margin-left: 0px;
  margin-right: 0px;
}

.heading {
  text-transform: uppercase;
  padding-top: 0px;
  font-size: 14px;
  font-weight: bold;
}

.description {
  font-style: italic;
  padding-top: 0px;
  margin-bottom: 5px;
}
</style>
