import { APIBase } from "./apibase";
import Formula from "@/items/formula";

export class FormulaAPI extends APIBase {
  list(
    project_id,
    custom_callback,
    refresh_interval = global.default_refresh_interval
  ) {
    return this.send({
      command: "projects/" + project_id + "/formulas",
      method: "GET",
      identification: {
        project_id: project_id
      },
      on_receive: this.receive_array,
      refresh_interval: refresh_interval,
      custom_callback: custom_callback
    });
  }

  fetch(
    project_id,
    formula_id,
    custom_callback,
    refresh_interval = global.default_refresh_interval
  ) {
    return this.send({
      command: "projects/" + project_id + "/formulas/" + formula_id,
      method: "GET",
      identification: {
        project_id: project_id,
        formula_id: formula_id
      },
      on_receive: this.receive,
      refresh_interval: refresh_interval,
      custom_callback: custom_callback
    });
  }

  create(project_id, data, custom_callback) {
    return this.send({
      command: "projects/" + project_id + "/formulas",
      method: "POST",
      identification: {
        project_id: project_id,
        channel_id: data.channelId
      },
      post_json: data,
      on_receive: this.receive,
      custom_callback: custom_callback
    });
  }

  update(project_id, formula_id, changes, custom_callback) {
    return this.send({
      command: "projects/" + project_id + "/formulas/" + formula_id,
      method: "PUT",
      identification: {
        project_id: project_id,
        formula_id: formula_id
      },
      post_json: changes,
      on_receive: this.receive,
      custom_callback: custom_callback
    });
  }

  del(project_id, formula_id, custom_callback) {
    return this.send({
      command: "projects/" + project_id + "/formulas/" + formula_id,
      method: "DELETE",
      identification: {
        project_id: project_id,
        formula_id: formula_id
      },
      on_receive: this.receive_delete,
      custom_callback: custom_callback
    });
  }

  receive(response, identification, myself) {
    Formula.update_or_create_from_dto(response, myself.app, identification);
  }

  receive_array(response, identification, myself) {
    response.forEach(function(el) {
      Formula.update_or_create_from_dto(el, myself.app, identification);
    });
  }
  // eslint-disable-next-line
  receive_delete(response, identification, myself) {
    Formula.delete_clientside(
      myself.app,
      identification,
      identification.formula_id
    );
  }
}
