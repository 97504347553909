<template lang="html">
  <transition name="noteanim">
    <div
      v-if="!too_old"
      :class="{ note_panel: job.succesful, error_panel: !job.succesful }"
    >
      <span>
        <span v-if="job.active">
          <TinyIcon :icon="$icon.locked" color="white" class="right_space" />
          <TinyIcon
            :icon="$icon.loading"
            color="white"
            class="right_space rotating"
          />
        </span>
        <span v-else>
          <TinyIcon
            v-if="job.succesful"
            :icon="$icon.yes"
            color="white"
            class="right_space"
          />
          <TinyIcon v-else :icon="$icon.no" color="black" class="right_space" />
        </span>
        <span>
          {{ text }}
        </span>
        <span class="note_error_time_label">
          <TimeLabel
            :time="time_stamp"
            :show_date="false"
            :show_seconds="true"
          />
        </span>
      </span>
    </div>
  </transition>
</template>

<script>
export default {
  props: {
    job: {
      type: Object
    }
  },

  computed: {
    job_status() {
      return this.$appg.langutil.get_job_status(this.job, this.$camur.job_type);
    },

    text() {
      if (this.job.active) {
        return `${this.job_status}`;
      } else {
        return `${this.job_status} (${this.job.full_time} ${
          this.$txt.unit_ms
        })`;
      }
    },

    time_stamp() {
      if (this.job.active) {
        return this.job.create_date;
      } else {
        return this.job.finish_date;
      }
    },

    too_old() {
      if (this.job.active) return false;
      let time_since_finish = Date.now() - this.job.finish_date;
      return time_since_finish > 5000;
    }
  }
};
</script>
