<template>
  <span class="wm_status_lamp" :title="hint">
    <svg height="16" width="14">
      <circle
        cx="6"
        cy="10"
        r="4.5"
        fill="rgba(0,0,0,0)"
        :stroke="stroke"
        stroke-width="1.5"
      />
      <circle cx="6" cy="10" r="3" :fill="fill" />
    </svg>
  </span>
</template>

<script>
export default {
  name: "DeviceAssignedIndicator",
  data() {
    return {};
  },

  props: {
    item: {
      type: Object,
      default: null
    }
  },

  computed: {
    last_seen() {
      return this.item.get_last_seen_string();
    },

    assigned() {
      return (
        this.item.locals.serialNumber != null &&
        this.item.locals.serialNumber != 0
      );
    },

    recording_mode() {
      if (!this.item.connected) return -1;
      if (this.$util.is_cntrl(this.item)) {
        return this.item.dto.recordingMode;
      } else {
        let cntrl = this.item.get_cntrl();
        if (cntrl == null) return -1;
        return cntrl.dto.recordingMode;
      }
    },

    fill() {
      if (this.assigned) {
        return this.$camur.color_code_status_of_rectype(this.recording_mode);
      }
      return "rgba(0,0,0,0)";
    },

    stroke() {
      return "rgba(0, 0, 0, 0.5)";
    },

    hint() {
      let hint = "";
      hint += `${this.$txt.state}: ${this.$appg.langutil.get_controller_state(this.item.dto.state)}`;
      hint += `\n${this.$txt.rec_status}: ${this.$camur.get_rectype_text(this.recording_mode)}`;
      if(this.assigned) hint += `\n${this.$txt.serial_short}: ${this.item.serial}`;

      if (this.last_seen != null) {
        hint += `\n${this.$txt.last_seen}: ${this.last_seen}`;
      }

      return hint;
    }
  }
};
</script>
