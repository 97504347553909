import { APIBase } from "./apibase";
import RequestData from "./requestdata";
import { ServiceLogItem } from "@/items/servicelogitem";

export class ServiceLogAPI extends APIBase {
  create(project_id, data) {
    let req = new RequestData(`projects/${project_id}/log/service`);
    req.post_json = data;
    req.identification = { project_id: project_id };
    req.method = "POST";
    req.on_receive = this.receive;
    return this.send(req);
  }

  list(project_id, options) {
    let req = new RequestData(
      `projects/${project_id}/log/service?Limit=100`,
      options
    );
    req.identification = { project_id: project_id };
    req.method = "GET";
    req.on_receive = this.receive_array;
    return this.send(req);
  }
  // eslint-disable-next-line
  receive(response, identification, myself) {}

  receive_array(response, identification, myself) {
    window.console.log("ServiceLog received, length:" + response.length);
    for (var i = 0; i < response.length; i++) {
      ServiceLogItem.update_or_create_from_dto(
        response[i],
        myself.app,
        identification
      );
    }
  }
}
