export default {
  data() {
    return {
      selected_tab: "",
      tab_menu_name: ""
    };
  },

  methods: {
    select_tab: function(tab) {
      console.log("select_tab:"+tab);
      this.selected_tab = tab;
      if (this.tab_menu_name != null) {
        this.$app.settings.last_tab[this.tab_menu_name] = tab;
      }
    }
  },

  mounted() {
/*      if (this.tab_menu_name != null) {
      this.select_tab(this.$app.settings.last_tab[this.tab_menu_name]);
    } */
  }
};
