import { APIBase } from "./apibase";
import { Node } from "@/items/node";
const Utils = require("@/utils").default;
import RequestData from "./requestdata";

export class NodeAPI extends APIBase {
  list(
    project_id,
    zone_id,
    callback,
    refresh_interval = global.default_refresh_interval
  ) {
    let req = new RequestData(
      `projects/${project_id}/zones/${zone_id}/nodes`,
      callback
    );
    req.on_receive = this.receive_array;
    req.identification = {
      project_id: project_id,
      zone_id: zone_id
    };
    req.set_interval(refresh_interval);
    return this.send(req);
  }

  fetch(
    project_id,
    zone_id,
    node_id,
    callback,
    refresh_interval = global.default_refresh_interval
  ) {
    let req = new RequestData(
      `projects/${project_id}/zones/${zone_id}/nodes/${node_id}`,
      callback
    );
    req.on_receive = this.receive;
    req.identification = {
      project_id: project_id,
      zone_id: zone_id,
      node_id: node_id
    };
    req.set_interval(refresh_interval);
    return this.send(req);
  }

  create(project_id, zone_id, node, callback) {
    let req = new RequestData(
      `projects/${project_id}/zones/${zone_id}/nodes`,
      callback
    );
    req.method = "POST";
    req.on_receive = this.receive;
    req.identification = {
      project_id: project_id,
      zone_id: zone_id
    };
    req.post_json = node;
    return this.send(req);
  }

  update(project_id, zone_id, node_id, changes, callback, refresh_interval) {
    let req = new RequestData(
      `projects/${project_id}/zones/${zone_id}/nodes/${node_id}`,
      callback
    );
    req.method = "PUT";
    req.on_receive = this.receive;
    req.identification = {
      project_id: project_id,
      zone_id: zone_id,
      node_id: node_id
    };
    req.post_json = changes;
    req.set_interval(refresh_interval);
    return this.send(req);
  }

  update_node_channel(
    project_id,
    zone_id,
    node_id,
    channel_id,
    changes,
    callback,
    refresh_interval
  ) {
    let req = new RequestData(
      `projects/${project_id}/zones/${zone_id}/nodes/${node_id}/channels/${channel_id}`,
      callback
    );
    req.method = "PUT";
    req.on_receive = this.receive_channel;
    req.identification = {
      project_id: project_id,
      zone_id: zone_id,
      node_id: node_id,
      channel_id: channel_id
    };
    req.post_json = changes;
    req.set_interval(refresh_interval);
    return this.send(req);
  }

  update_node_channel_alarms_settings(
    project_id,
    zone_id,
    node_id,
    channel_id,
    changes,
    callback,
    refresh_interval
  ) {
    let req = new RequestData(
      `projects/${project_id}/zones/${zone_id}/nodes/${node_id}/channels/${channel_id}/alarms/settings`,
      callback
    );
    req.method = "PUT";
    req.on_receive = this.receive_channel;
    req.identification = {
      project_id: project_id,
      zone_id: zone_id,
      node_id: node_id,
      channel_id: channel_id
    };
    req.post_json = changes;
    req.set_interval(refresh_interval);
    return this.send(req);
  }

  ack_node_channel_alarm(
    project_id,
    zone_id,
    node_id,
    channel_id,
    changes,
    callback,
    refresh_interval
  ) {
    let req = new RequestData(
      `projects/${project_id}/zones/${zone_id}/nodes/${node_id}/channels/${channel_id}/alarms/ack`,
      callback
    );
    req.method = "PUT";
    req.on_receive = this.receive_channel;
    req.identification = {
      project_id: project_id,
      zone_id: zone_id,
      node_id: node_id,
      channel_id: channel_id
    };
    req.post_json = changes;
    req.set_interval(refresh_interval);
    return this.send(req);
  }

  reset_node_channel_alarm(
    project_id,
    zone_id,
    node_id,
    channel_id,
    changes,
    callback,
    refresh_interval
  ) {
    let req = new RequestData(
      `projects/${project_id}/zones/${zone_id}/nodes/${node_id}/channels/${channel_id}/alarms/reset`,
      callback
    );
    req.method = "PUT";
    req.on_receive = this.receive_channel;
    req.identification = {
      project_id: project_id,
      zone_id: zone_id,
      node_id: node_id,
      channel_id: channel_id
    };
    req.post_json = changes;
    req.set_interval(refresh_interval);
    return this.send(req);
  }

  del(project_id, zone_id, node_id, callback) {
    let req = new RequestData(
      `projects/${project_id}/zones/${zone_id}/nodes/${node_id}`,
      callback
    );
    req.method = "DELETE";
    req.on_receive = this.receive_deleted;
    req.identification = {
      project_id: project_id,
      zone_id: zone_id,
      node_id: node_id
    };
    req.set_interval(0);
    return this.send(req);
  }

  receive_array(response, identification, myself) {
    // TODO: Can't do this because it deletes local data! We must remove all instances
    // who's ID isnt represented in the new array instead
    var existing_elements = myself.app.$appm.get_zone(
      identification.project_id,
      identification.zone_id
    ).nodes;
    Utils.remove_elements_not_present_in_new_array(existing_elements, response);

    response.forEach(function(el) {
      //      el = myself.node.add_channel_name_as_field_in_dto(el);
      Node.update_or_create_from_dto(el, myself.app, identification);
    });
  }

  receive(response, identification, myself) {
    // response = myself.node.add_channel_name_as_field_in_dto(response);
    Node.update_or_create_from_dto(response, myself.app, identification);
  }

  add_channel_name_as_field_in_dto(dto) {
    for (let channel in dto.channels) {
      dto.channels[channel].name = channel;
    }
    return dto;
  }

  receive_deleted(response, identification, myself) {
    Node.delete_clientside(myself.app, identification, identification.node_id);
  }
  receive_channel(response, identification, myself) {
    Node.update_channel(response, myself.app, identification);
  }
}
