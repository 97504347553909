<template>
  <StatusLamp :status="col" :tiny="tiny" />
</template>

<script>
export default {
  name: "AlarmLamp",
  data() {
    return {};
  },

  props: {
    status: Number,
    tiny: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    col() {
      if (this.status == 1) return "red";
      if (this.status == 0) return "green";
      if (this.status == 2) return "blue";
      return "yellow";
    }
  }
};
</script>
