<template>
  <transition>
    <a
      class="TreeOpenCloseBtn"
      :title="$txt.hint_press_to_show_hide_items"
      @click="clicked(!modelValue)"
    > 
      <svg-icon
        class="{ closed_btn: modelValue, open: !modelValue }"
        size="12" 
        viewbox="0 0 24 24" 
        :path="expandMoreIcon" 
        :rotate="modelValue? 0 : -90"
        color="gray"
      />
<!--       <TinyIcon
        class="abcde"
        :class="{ closed_btn: !modelValue, open: modelValue }"
        icon="expand_more"
      /> -->
    </a>
  </transition>
</template>

<script>
  const expandMoreIcon ="M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6z";
  export default {
  emits: ['update:modelValue'],
  setup() {
		return {
        expandMoreIcon
	  	}
  	},
  name: "TreeOpenCloseBtn",
  data() {
    return {};
  },
  methods: {
    clicked(value) {
      this.$emit('update:modelValue', value);
    }
  },

  props: {
    modelValue: Boolean
  }
};
</script>

<style>
.closed_btn {
  transition: 0.15s;
  transform: rotate(-90deg);
}
.open {
  transition: 0.15s;
  transform: rotate(0deg);
}
</style>
