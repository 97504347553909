<template>
  <a @click="toggle" style="margin: 3px;" :title="padlock_hint" id="toggle">
    <TinyIcon v-if="modelValue" :size="size" :color="color" icon="lock-black" />
    <TinyIcon v-else :size="size" :color="color" icon="lock_open-black" />
  </a>
</template>

<script>
export default {
  emits:['lock'],
  name: "PadlockToggle",
  data() {
    return {};
  },

  props: {
    modelValue: {
      type: Boolean,
      default: false
    },
    color: {
      type: String,
      default: "black"
    },
    size: {
      type: String,
      default: "14"
    },
    editable: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    padlocked() {
      return this.$app.sltd.project.edit_locked;
    },
    padlock_hint() {
      if (this.padlocked) return this.$txt.enable_prj_edits;
      else return this.$txt.disable_prj_edits;
    }
  },
  methods: {
    toggle() {
      console.log("toggle");
      if (this.editable) {
        this.$emit("lock");
      }
    }
  }
};
</script>
