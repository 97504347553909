const Camur = require("@/utils/camurutils").default;
const Enum = require('@/utils/enums').default;
const RecordingCache = require("@/recordings/recordingcache").default;
import * as RecordingBlocks from "@/recordings/recordingblocks";

const CACHE_INVALIDATION_DAYS = 30;
const MAX_RESOLUTION = 500;

export default class GraphBuffer {
  constructor(project, graph_data_api) {
    this.state = 0;
    this.project = project;
    this.graph_data_api = graph_data_api;
    this.from_date = null;
    this.to_date = null;
    this.series = [];
    this.hidden_series = [];
    this.zone_ids = [];
    this.recording_cache = new RecordingCache(
      project.dto.id,
      CACHE_INVALIDATION_DAYS
    );
    this.resolution = "?";
    this.max_resolution = MAX_RESOLUTION;
    this.fetched_blocks = [];
    this.expected_blocks = new Set();
    this.fetch_batch_size = 0;
    this.fetched_blocks_in_batch = 0;
    this.report_type = 0;
  }

  is_empty() {
    return this.series.length === 0;
  }

  update(
    from_date,
    to_date,
    display_from,
    display_to,
    recording_id,
    report_type,
    recording_type,
    zone_ids,
    enable_caching = true
  ) {

    if (recording_type == 0) {
      // No specific recording id selected
      if (report_type === Camur.report_type.reptype_monitor) {
        recording_type = Camur.recording_type.rectype_monitor;
      } else if (report_type === Camur.report_type.reptype_decay) {
        recording_type = Camur.recording_type.rectype_decay;
      } else if (report_type === Camur.report_type.reptype_lpr_measurements) {
        recording_type = Camur.recording_type.rectype_lpr;
      } else if (report_type === Camur.report_type.reptype_lpr_results) {
        recording_type = Camur.recording_type.rectype_lpr_results;
      }  else if (report_type === Camur.report_type.reptype_zra) {
        recording_type = Camur.recording_type.rectype_zra;
      }  else if (report_type === Camur.report_type.reptype_resmes) {
        recording_type = Camur.recording_type.rectype_resmes;
      }  else if (report_type === Camur.report_type.reptype_decay_results) {
        recording_type = Camur.recording_type.rectype_decay_results;
        display_from = from_date;
        display_to = to_date;   
      }
    }

    this.from_date = from_date;
    this.to_date = to_date;
    this.zone_ids = zone_ids;

    this.report_type = report_type;

    let filters = {
      recordingId: recording_id,
      recordingType: recording_type
    };

    // Only rebuild the buffer when the resolution changes
    let resolution_needed = RecordingBlocks.get_resolution_for_timespan(
      display_from,
      display_to
    ).name;
    if (resolution_needed != this.resolution) {
      this.resolution = resolution_needed;
      this.reset();
    } else if (this.is_to_big()) {
      // Reset the buffer when we have to much data to
      // improve the performance of the graph
      this.reset();
    }

    let all_flag = false;
    let value_tag = 0;
    if (recording_type == Camur.recording_type.rectype_decay_results) {
      all_flag = true;
      if (recording_id ==-1) {
        // We are requesting several decay results in an interval
        // fetch only the depolarization result
        // value_tag == hour value to use as depolarization result
        // to be a project setting
        value_tag = 12;
      }
    }

    let required_blocks = [];
    if (enable_caching) {
      required_blocks = RecordingBlocks.get_recording_blocks_for_timespan(
        from_date,
        to_date,
        display_from,
        display_to
      );

      // Ignore any block that we already have fetched
      required_blocks = required_blocks.filter(
        b => !this.fetched_blocks.includes(b.hash)
      );

      // Ignore any blocks from the future!
      let now = new Date(Date.now());
      required_blocks = required_blocks.filter(b => b.from <= now);
    } else {
      // If we are not caching, just load all data as one big request
      required_blocks = [
        {
          from: from_date,
          to: to_date,
          max_values: this.max_resolution
        }
      ];
    }

    for (let block of required_blocks) {
      this.fetched_blocks.push(block.hash);
      this.load_block(block, filters, zone_ids, enable_caching, all_flag, value_tag);
    }
  }

  load_block(block_info, filters, zone_ids, enable_caching, all_flag, value_tag) {
    let hash_params = {
      f: block_info.from,
      t: block_info.to,
      z: zone_ids,
      i: [filters.recordingId],
      type: [filters.recordingType],
      r: block_info.max_values
    };

    let channel_ids = [];
    for (let zone of this.project.zones.filter(z => zone_ids.includes(z.id))) {
       for  (let node of zone.nodes) {
        if (this.selected_node(zone, node)) {
          for (let channelkey in node.channels) {
            let channel = node.channels[channelkey];
            if (channel.userVisible) {
              if (channel.lastValueUpdated > "2020-01-01") {
                if (this.relevantChannel(channel, filters.recordingType))
                  channel_ids.push(channel.id);
              }  
            }
          }
        }
      }
    }

    if (channel_ids.length == 0)
        // Do not request if no channels
        // Otherwise values for all channels will be fetched
      return;


    // If this block is cached, use that instead!
    if (this.recording_cache.has_data_for_dates(hash_params) && (filters.recordingType != Camur.recording_type.rectype_decay_results)) {
      let cached_block = this.recording_cache.load(hash_params);
      let converted_block = this.convert_block_for_graph(cached_block, null);
      this.add_block_to_buffer(converted_block);
      return;
    }

    // Dont cache a block which is in the future
    let enable_cache_flag = enable_caching && block_info.to < Date.now();
    this.expected_blocks.add(block_info.hash);
 
    this.fetch_batch_size++;
    this.graph_data_api.fetch(
      block_info.from,
      block_info.to,
      block_info.max_values,
      this.project.id,
      //zone_ids[0],
      null,
      channel_ids,
      filters,
      enable_cache_flag,
      all_flag,
      value_tag,
      () => {
        this.on_expected_block_loaded(block_info);
      }
    );
  }

  selected_node(zone, node) {
    if (window.appm.store.sltd.zone_is_selected()) {
      if (zone.id == window.appm.store.sltd.zone.id)
        return true;
      else
        return false;
    } else {
      return node.app_prop.include_select;
    }
  }

  on_expected_block_loaded(block_info) {
    this.expected_blocks.delete(block_info.hash);
    this.fetched_blocks_in_batch++;
    if(this.expected_blocks.size === 0) {
      this.fetch_batch_size = 0;
      this.fetched_blocks_in_batch = 0;
    }
  }

  add_downloaded_block(block, metadata) {
    if (metadata != null && metadata.cache_data) {
      this.cache_block(block, metadata);
    }
    let converted_block = this.convert_block_for_graph(block, metadata);
    this.add_block_to_buffer(converted_block);
  }

  convert_block_for_graph(block, metadata) {
    // Reformat the buffer to work with the graph
    let color_index = 0;
    let color_scheme = Camur.get_series_color_scheme();
    let resulting_series = [];

    //console.log(JSON.stringify(metadata));

    for (let series of block.series) {
      let channel = this.project.find_channel(series.channelId);
      if (channel === null) continue;
      if (channel.io === Camur.io_type.io_type_output) continue; // Ignore outputs here
      let node = this.project.find_node(channel.nodeId);

      let graph_series = {};
      graph_series.name = node.name + "-" + channel.name;
      graph_series.id = series.channelId;
      graph_series.unit = channel.formula.unit;
      graph_series.decimals = channel.formula.decimals;
      if (metadata == null) {
        graph_series.graph_min = channel.formula.graphScaleMin;
      } else {
        graph_series.graph_min = (metadata.filters.recordingType == Camur.recording_type.rectype_decay_results) ? 0 : channel.formula.graphScaleMin;
      }
      graph_series.graph_max = channel.formula.graphScaleMax;
      graph_series.color = color_scheme[color_index % color_scheme.length];
      graph_series.override_y_axis_scale = false;
      graph_series.override_y_axis_scale_min = 0;
      graph_series.override_y_axis_scale_max = 200;

      graph_series.grid = false;
      graph_series.zero_line = false;
      graph_series.draw_line_at = false;
      graph_series.draw_line_at_value = 100;
      graph_series.yLabel = "";
      graph_series.yPos = Enum.graph_pos.graph_pos_bottom;
      graph_series.graph_type = Enum.graph_type.graph_type_line;
      graph_series.settings_updated = false;
      color_index++;
  
      graph_series.data = series.data.map(sample => {
        return {
          minDate: new Date(sample[0]),
          maxDate: new Date(sample[1]),
          minVal: sample[2],
          maxVal: sample[3]
        };
      });
      resulting_series.push(graph_series);
      //console.log("Push serie:"+JSON.stringify(graph_series.data));
    }

    return {
      ...block,
      series: resulting_series
    };
  }

  cache_block(block, metadata) {
    if (metadata != null && metadata.cache_data) {
      let hash_params = {
        f: metadata.from,
        t: metadata.to,
        z: metadata.zone_ids,
        i: [metadata.filters.recordingId],
        type: [metadata.filters.recordingType],
        r: metadata.max_values
      };

      this.recording_cache.store(hash_params, block);
    }
  }

  is_to_big() {
    return this.fetched_blocks.length >= 50;
  }

  is_waiting_for_blocks() {
    return this.expected_blocks.size > 0;
  }

  reset() {
    this.state += 1;
    this.series = [];
    this.fetched_blocks = [];
  }

  add_block_to_buffer(block) {
    for (let block_series of block.series) {
      // Ignore empty series
      // No, keep and show empty instead, 2024-04-23
      //  if (block_series.data.length === 0) continue;

      // Add any channels that does not yet exist on the buffer
      let channel_exist = this.series.some(s => s.id === block_series.id);
      if (!channel_exist) {
        this.series.push({
          ...block_series,
          data: []
        });
      }

      // Find the same matching series in the buffer and add the block to that
      let buffer_series = this.series.find(s => s.id === block_series.id);
      this.add_block_series_to_buffer_series(block_series, buffer_series);
      this.state++;
    }
  }

  add_block_series_to_buffer_series(block_series, series) {
    if (block_series.data.length == 0) {
      // Nothing to add
      return;
    }

    // If empty, just copy
    if (series.data.length == 0) {
      series.data = block_series.data;
      return;
    }

    let first_date_of_block = block_series.data[0].minDate;
    let last_date_of_block =
      block_series.data[block_series.data.length - 1].minDate;
    let first_date_of_buffer = series.data[0].minDate;
    let last_date_of_buffer = series.data[series.data.length - 1].minDate;

    if (last_date_of_block <= first_date_of_buffer) {
      // Add to the beginning of the array
      this.insert_to_array(series.data, block_series.data, 0);
    } else if (first_date_of_block >= last_date_of_buffer) {
      // Add to the end of the array
      this.insert_to_array(series.data, block_series.data, series.data.length);
    } else {
      // Add to the middle of the array
      let index_to_add_block_at = series.data.findIndex(
        d => d.minDate >= last_date_of_block
      );
      this.insert_to_array(
        series.data,
        block_series.data,
        index_to_add_block_at
      );
    }
  }

  relevantChannel(channel, recordingType)
  {
    if (recordingType > 0) {
      switch (recordingType) {
        case Camur.recording_type.rectype_lpr_results:
          let node = this.project.find_node(channel.nodeId);
          // Only include iCorr and iSigma
          if ((node.capability == Camur.node_type.ntype_corrowatch) || (node.capability == Camur.node_type.ntype_ladder)) {
            if (channel.no >= 24)
              return true;
            else
              return false;  
          } else if (node.capability == Camur.node_type.ntype_lpr) {
            if (channel.no >= 3)
              return true;
            else
              return false;  
          } else {
            return false;  
          }
        default:
          return true;
      }
    }
    else
      return true;
  }

  insert_to_array(array, elements, index) {
    let el_length = elements.length;
    let arr_length = el_length + array.length;
    array.length = arr_length;
    let limit = arr_length - index;

    for (let i = 1; i <= limit; i++) {
      let index_to_copy_from = arr_length - i;
      array[index_to_copy_from] = array[index_to_copy_from - el_length];
    }

    for (let i = 0; i < el_length; i++) {
      array[index + i] = elements[i];
    }
  }
}
