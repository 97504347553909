import { APIBase } from "./apibase";
import { Cntrl } from "@/items/cntrl";

const Utils = require("@/utils").default;

export class CntrlAPI extends APIBase {
  fetch(
    project_id,
    cntrl_id,
    custom_callback,
    refresh_interval = global.default_refresh_interval
  ) {
    return this.send({
      command: "projects/" + project_id + "/controllers/" + cntrl_id,
      method: "GET",
      identification: {
        project_id: project_id,
        serial: cntrl_id
      },
      on_receive: this.receive,
      refresh_interval: refresh_interval,
      custom_callback: custom_callback
    });
  }

  create(data) {
    return this.send({
      command: "controllers/serials",
      method: "POST",
      identification: {
        serialNumber: data.serialNumber
      },
      post_json: data,
      on_receive: this.receive_create
    });
  }


  claim(data) {
    return this.send({
      command: "controllers/serials/claim",
      method: "POST",
      identification: {
        serial: data.serial
      },
      post_json: data,
      on_receive: this.receive_claim
    });
  }

  revoke(data, custom_callback) {
    return this.send({
      command: "controllers/serials/revoke",
      method: "POST",
      identification: {
        projectId : data.projectId,
        serialNumber: data.serialNumber,
        checksum: data.checksum
      },
      post_json: data,
      on_receive: this.receive_revoke,
      custom_callback: custom_callback
    });
  }

  post(project_id, data, custom_callback) {
    return this.send({
      command: "projects/" + project_id + "/controllers",
      method: "POST",
      identification: {
        project_id: project_id,
        serialNumber: data.serialNumber,
        onlineStatus: 0
      },
      post_json: data,
      on_receive: this.receive,
      custom_callback: custom_callback
    });
  }

  del(project_id, cntrl_id, custom_callback) {
    return this.send({
      command: "projects/" + project_id + "/controllers/" + cntrl_id,
      method: "DELETE",
      identification: {
        project_id: project_id,
        cntrl_id: cntrl_id
      },
      on_receive: this.receive_delete,
      custom_callback: custom_callback
    });
  }

  update(project_id, cntrl_id, data, custom_callback) {
    return this.send({
      command: "projects/" + project_id + "/controllers/" + cntrl_id,
      method: "PUT",
      identification: {
        project_id: project_id,
        cntrl_id: cntrl_id,
        onlineStatus: 0
      },
      post_json: data,
      on_receive: this.receive,
      custom_callback: custom_callback
    });
  }

  update_decay_settings(project_id, cntrl_id, settings, custom_callback) {
    return this.update_settings(
      project_id,
      cntrl_id,
      settings,
      "decay",
      custom_callback
    );
  }

  update_lpr_settings(project_id, cntrl_id, settings, custom_callback) {
    return this.update_settings(
      project_id,
      cntrl_id,
      settings,
      "lpr",
      custom_callback
    );
  }

  update_zra_settings(project_id, cntrl_id, settings, custom_callback) {
    return this.update_settings(
      project_id,
      cntrl_id,
      settings,
      "zra",
      custom_callback
    );
  }

  update_resmes_settings(project_id, cntrl_id, settings, custom_callback) {
    return this.update_settings(
      project_id,
      cntrl_id,
      settings,
      "resMes",
      custom_callback
    );
  }

  update_monitor_settings(project_id, cntrl_id, settings, custom_callback) {
    return this.update_settings(
      project_id,
      cntrl_id,
      settings,
      "monitor",
      custom_callback
    );
  }

  update_schedule(project_id, cntrl_id, settings, custom_callback) {
    return this.update_settings(
      project_id,
      cntrl_id,
      settings,
      "schedule",
      custom_callback
    );
  }

  update_settings(project_id, cntrl_id, data, which, custom_callback) {
    return this.send({
      command: `projects/${project_id}/controllers/${cntrl_id}/${which}`,
      method: "PUT",
      identification: {
        project_id: project_id,
        cntrl_id: cntrl_id
      },
      post_json: data,
      on_receive: this.receive_settings_change_posted,
      custom_callback: custom_callback
    });
  }

  list(
    project_id,
    custom_callback,
    refresh_interval = global.default_refresh_interval
  ) {
    return this.send({
      command: "projects/" + project_id + "/controllers",
      method: "GET",
      identification: {
        project_id: project_id
      },
      on_receive: this.receive_array,
      refresh_interval: refresh_interval,
      custom_callback: custom_callback
    });
  }

  set_recording_mode(project_id, cntrl_id, tag, mode) {
    return this.send({
      command: `projects/${project_id}/controllers/${cntrl_id}/record`,
      method: "PUT",
      post_json: {
        tag: tag,
        mode: mode
      },
      identification: {
        project_id,
        cntrl_id
      },
      on_receive: this.receive_record_mode_change
    });
  }

  diagnose(project_id, cntrl_id) {
    return this.send({
      command: "projects/" + project_id + "/state/id/" + cntrl_id,
      method: "GET",
      identification: {
        project_id: project_id,
        cntrl_id: cntrl_id,
      },
      on_receive: this.receive_diagnostics
    });
  }

  cancelGetRecordingData(project_id, cntrl_id) {
    return this.send({
      command: "projects/" + project_id + "/devices/" + cntrl_id + "/cancel_get_recording_data",
      method: "POST",
      identification: {
        project_id: project_id,
        cntrl_id: cntrl_id,
      },
      post_json: {
        tag : "maint",
        skipAck : true
      },
      on_receive: this.receive_maintenance
    });
  }

  prepareBoot(project_id, cntrl_id) {
    return this.send({
      command: "projects/" + project_id + "/devices/" + cntrl_id + "/prepare_boot",
      method: "POST",
      identification: {
        project_id: project_id,
        cntrl_id: cntrl_id,
      },
      post_json: {
        tag : "maint",
        skipAck : true
      },
      on_receive: this.receive_maintenance
    });
  }

  reBoot(project_id, cntrl_id) {
    return this.send({
      command: "projects/" + project_id + "/devices/" + cntrl_id + "/reboot_now",
      method: "POST",
      identification: {
        project_id: project_id,
        cntrl_id: cntrl_id,
      },
      post_json: {
        tag : "maint",
        skipAck : true
      },
      on_receive: this.receive_maintenance
    });
  }

  resetCAN(project_id, cntrl_id) {
    return this.send({
      command: "projects/" + project_id + "/devices/" + cntrl_id + "/reset_can",
      method: "POST",
      identification: {
        project_id: project_id,
        cntrl_id: cntrl_id,
      },
      post_json: {
        tag : "maint",
        skipAck : true
      },
      on_receive: this.receive_maintenance
    });
  }

  pingPeriodReset(project_id, cntrl_id) {
    return this.send({
      command: "projects/" + project_id + "/devices/" + cntrl_id + "/ping_period_reset",
      method: "POST",
      identification: {
        project_id: project_id,
        cntrl_id: cntrl_id,
      },
      post_json: {
        tag : "maint",
        skipAck : true
      },
      on_receive: this.receive_maintenance
    });
  }

  update_firmware(project_id, cntrl_id, firmware_id) {
    return this.send({
      command: "projects/" + project_id + "/controllers/" + cntrl_id + "/firmware/" + firmware_id + "/upgrade",
      method: "POST",
      post_json:  {
        tag: "tag1",
        ignoreWarning: true
      },
      identification: {
        project_id: project_id,
        cntrl_id: cntrl_id,
      },
      on_receive: this.receive_update_firmware
    });
  }

  switch_off_on_alarm(project_id, cntrl_id, switchOffOnAlarm) {
    return this.send({
      command: "projects/" + project_id + "/controllers/" + cntrl_id + "/switch_off_on_alarm",
      method: "PUT",
      post_json:  {
        tag: "tag1",
        switchOffOnAlarm: switchOffOnAlarm
      },
      identification: {
        project_id: project_id,
        cntrl_id: cntrl_id,
      },
      on_receive: this.receive_switch_off_on_alarm
    });
  }

  refresh_html(project_id, cntrl_id) {
    return this.send({
      command: "projects/" + project_id + "/controllers/" + cntrl_id +"/status",
      method: "PUT",
      identification: {
        project_id: project_id,
        cntrl_id: cntrl_id,
      },
      on_receive: this.receive_refresh_html
    });
  }

  refresh_log(project_id, cntrl_id) {
    return this.send({
      command: "projects/" + project_id + "/controllers/" + cntrl_id +"/log",
      method: "PUT",
      identification: {
        project_id: project_id,
        cntrl_id: cntrl_id,
      },
      on_receive: this.receive_refresh_log
    });
  }

  diagnose_node(project_id, cntrl_id, node_id) {
    return this.send({
      command: "projects/" + project_id + "/state/id/" + cntrl_id + "/" + node_id,
      method: "GET",
      identification: {
        project_id: project_id,
        cntrl_id: cntrl_id,
        node_id: node_id
      },
      on_receive: this.receive_diagnostics
    });
  }


  receive(response, identification, myself) {
    Cntrl.update_or_create_from_dto(response, myself.app, identification);
  }

  // eslint-disable-next-line
  receive_create(response, identification, myself) {
    ;
  }

  // eslint-disable-next-line
  receive_revoke(response, identification, myself) {
    myself.app.$appm.delete_cntrl_device(identification.projectId, identification.serialNumber);
  }

  receive_claim(response, identification, myself) {
    // Updated when UpdateControllerHardware arrives from message hub
  }


  receive_array(response, identification, myself) {
    response.forEach(function(el) {
      Cntrl.update_or_create_from_dto(el, myself.app, identification);
    });
  }

  receive_settings_change_posted(response, identification, myself) {
    myself.app.$appm
      .get_cntrl(identification.project_id, identification.cntrl_id)
      .app_prop.job_queue.on_job_with_id_posted(response.jobId);
  }

  receive_record_mode_change(response, identification, myself) {
    myself.app.$appm
      .get_cntrl(identification.project_id, identification.cntrl_id)
      .app_prop.job_queue.on_job_with_id_posted(response.jobId);
  }
  receive_diagnostics(response, identification, myself) {
  }
  receive_maintenance(response, identification, myself) {
  }
  receive_update_firmware(response, identification, myself) {
  }
  receive_refresh_html(response, identification, myself) {
  }
  receive_refresh_log(response, identification, myself) {
  }
  receive_switch_off_on_alarm(response, identification, myself) {
  }
}
