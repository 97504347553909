import * as d3 from "d3";
import { options } from "less";
const Enum = require("@/utils/enums").default;

let TG_SERIES_CONTAINER = null;
let axesDistance = 60;
let yLabelDistance = 48;
let xLegendDistance = 30;
let xLegendOffset = 0;
let paddingSplit=50;
let maxAxis = 20;

const HALF_HOUR = 1 / 24 / 2;

export function get_min_value(series) {
  let candidates = [];
  for (let s of series) {
    if (s.data[0].hasOwnProperty("value")) {
      candidates.push(
        s.data.reduce((lowest, samp) => {
          return samp.value < lowest ? samp.value : lowest;
        }, Infinity)
      );
    } else {
      candidates.push(
        s.data.reduce((lowest, samp) => {
          return samp.minVal < lowest ? samp.minVal : lowest;
        }, Infinity)
      );
    }
  }

  return Math.min.apply(null, candidates);
}

function get_max_value(series) {
  let candidates = [];
  for (let s of series) {
    if (s.data[0].hasOwnProperty("value")) {
      candidates.push(
        s.data.reduce((highest, samp) => {
          return samp.value > highest ? samp.value : highest;
        }, 0)
      );
    } else {
      candidates.push(
        s.data.reduce((highest, samp) => {
          return samp.maxVal > highest ? samp.maxVal : highest;
        }, 0)
      );
    }
  }
  return Math.max.apply(null, candidates);
}

export function init_graph(canvas_id, height, width) {
  let canvas = d3.select("#" + canvas_id);
  canvas.select(".tg_focus_circle").style("display", "none");
}

export function clearXAxisSelectionColor(canvasId)
{
  let canvas = d3.select("#" + canvasId);
  let axis_group = canvas.select(".tg_axis");
  axis_group.style('fill','white');  
}

function clear_graph(canvas) {
    // Hide old charts etc
    TG_SERIES_CONTAINER = canvas.select(".tg_series_container");
    if (TG_SERIES_CONTAINER != null) {
      TG_SERIES_CONTAINER.selectAll('*').remove(); 
    }
     
    canvas.selectAll(".clipPath").remove();
    canvas.selectAll(".tg_focus_circles").style("display", "none");  
    canvas.selectAll("circle").remove();
    canvas.selectAll(".legend_label").remove();
    canvas.selectAll(".barrect").remove();
}

export function number_of_stacked_graphs(visible_series) {
  let graph_N = 1;
  for (let i=0; i < visible_series.length ; i++) {
    switch (graph_N) {
      case 1: 
        if (visible_series[i].yPos == Enum.graph_pos.graph_pos_high) {
          graph_N = 2;
        }
      case 2:
        if (visible_series[i].yPos == Enum.graph_pos.graph_pos_top) {
          graph_N = 3;
        }
      break;
      case 3: // Max already, do nothing
      break;
    }
  }
  return graph_N;
}


export function render_graph(
  options,
  axesData,
  series,
  hidden_series,
  alarmLineHigh,
  alarmLineLow,
  showHoldValues
) 
{
  let canvas = d3.select("#" + options.canvasId);


  // Define extra margins
  let extraMarginX = 35; // Adjust as needed
  let extraMarginY = 0; // Adjust as needed

  // Update the options to include extra margins
  options.width += extraMarginX * 2;
  options.height += extraMarginY * 2;

  // Increase padding values
  options.paddingXLeft += extraMarginX;
  options.paddingXRight += extraMarginX;
  options.paddingY += extraMarginY;

  // Update the SVG dimensions
  canvas
    .attr("width", options.width)
    .attr("height", options.height)
    .attr("viewBox", `0 0 ${options.width} ${options.height}`);

  clear_graph(canvas);
  
  window.points_drawn = 0;

  let visible_series = series.filter(s => !hidden_series.includes(s.id));
  const is_visible = d => {
    return !visible_series.filter(s => d.id === s.id).length < 1;
  };

  // Number of stacked graphs with same X-axis
  let graph_N = number_of_stacked_graphs(visible_series);

//  console.log("graph_N=", graph_N);

  let displayed_interval_end = options.endDate;
  let displayed_interval_start = options.startDate;
  let time_interval = options.endDate - options.startDate;

  if (!options.disable_zoom) {
    displayed_interval_start =
      options.position -
      (time_interval / 2) * options.interval_scaling_factor +
      options.offset_ms;
    displayed_interval_end =
      options.position +
      (time_interval / 2) * options.interval_scaling_factor +
      options.offset_ms;
  }

  const x = d3
    .scaleTime()
    .domain([displayed_interval_start, displayed_interval_end])
    .range([options.paddingXLeft, options.width - options.paddingXRight]);

  const xCoord = d => {
    return d.hasOwnProperty("date") ? x(d.date) : x(d.minDate);
  };

  // Choose format
  let format = null;
  let daysBetween = (displayed_interval_end - displayed_interval_start) / (1000 * 60 * 60 * 24);
  if (daysBetween >= 8) {
    format = t =>
      window.app.langutil.print_date_time(t, {
        show_time: false,
        show_date: true
      });
  } else if (daysBetween > HALF_HOUR) {
    format = t =>
      window.app.langutil.print_date_time(t, {
        show_time: true,
        show_date: true,
        show_seconds: false
      });
  } else {
    // If period is less than a half-hour, show seconds
    format = t =>
      window.app.langutil.print_date_time(t, {
        show_time: true,
        show_date: true,
        show_seconds: true
      });
  }

  // Axis
  let bottomAxis = d3
    .axisBottom(x)
    .tickFormat(format)
    .ticks((options.width - options.paddingXLeft - options.paddingXRight) / 140);

    // Adjust positions that depend on options.width and options.height
    // For example, when positioning the bottom axis:
    let axis_x = canvas
      .select(".tg_axis")
      .attr(
        "transform",
        `translate(0, ${options.height - options.paddingY})`
      )
      .call(bottomAxis);

    // Adjust description position
    let descriptionX = options.width - options.paddingXRight - xLegendOffset - extraMarginX;
    let descriptionY = options.height - 5 - extraMarginY;
    axis_x
      .style("cursor", "pointer") // Add cursor pointer style to indicate clickability
      .on("click", function(d) {
        onClickXAxis(canvas, bottomAxis, axesData, options);
      });

  let anyGrid = false;
 
  let available_value_categories = new Set();
  for (let s of series) {
    let b = JSON.stringify({
      unit: s.unit,
      graph_min: s.graph_min,
      graph_max: s.graph_max,
      override_y_axis_scale: s.override_y_axis_scale,
      override_y_axis_scale_min: s.override_y_axis_scale_min,
      override_y_axis_scale_max: s.override_y_axis_scale_max,
      grid: s.grid,
      zero_line : s.zero_line,
      draw_line_at : s.draw_line_at,
      draw_line_at_value : s.draw_line_at_value,
      visible : is_visible(s),
      yLabel : s.yLabel,
      yPos : s.yPos,
      count : 1
    });
    if (s.grid && is_visible(s))
      anyGrid = true;
    
    var reUseCategory = false;
    for (let catStr of available_value_categories) {
//      console.log("Available category:"+catStr);
      let cat = JSON.parse(catStr);
      if ((cat.unit == s.unit) && (cat.yPos == s.yPos)){
        reUseCategory = true;
        if (is_visible(s)) {

          if (cat.visible) {
            if (s.graph_min < cat.graph_min)
              cat.graph_min = s.graph_min;
            if (s.graph_max > cat.graph_max)
              cat.graph_max = s.graph_max;
            if (cat.override_y_axis_scale == false) {
              cat.override_y_axis_scale = s.override_y_axis_scale;
              cat.override_y_axis_scale_min = s.override_y_axis_scale_min;
              cat.override_y_axis_scale_max = s.override_y_axis_scale_max;
            }  
            if (cat.grid == false)
              cat.grid = s.grid;
            if (cat.zero_line == false)
              cat.zero_line = s.zero_line;
            if (cat.draw_line_at == false) {
              cat.draw_line_at = s.draw_line_at;
              cat.draw_line_at_value = s.draw_line_at_value;
            }
            if (cat.yLabel == "")
              cat.yLabel = s.yLabel;
            cat.yPos = s.yPos;
            cat.count++;
          }
          else {
            cat.graph_min = s.graph_min;
            cat.graph_max = s.graph_max;
            cat.override_y_axis_scale = s.override_y_axis_scale;
            cat.override_y_axis_scale_min = s.override_y_axis_scale_min;
            cat.override_y_axis_scale_max = s.override_y_axis_scale_max;
            cat.grid = s.grid;
            cat.zero_line = s.zero_line;
            cat.draw_line_at = s.draw_line_at;
            cat.draw_line_at_value = s.draw_line_at_value;
            cat.yLabel = s.yLabel;
            cat.visible = true;
            cat.yPos = s.yPos;
          }
        }
      }
      if (reUseCategory) {
        available_value_categories.delete(catStr);
        b = JSON.stringify({
          unit: s.unit,
          graph_min: cat.graph_min,
          graph_max: cat.graph_max,
          override_y_axis_scale: cat.override_y_axis_scale = s.override_y_axis_scale,
          override_y_axis_scale_min: cat.override_y_axis_scale_min,
          override_y_axis_scale_max: cat.override_y_axis_scale_max,
          grid: cat.grid,
          zero_line: cat.zero_line,
          draw_line_at: cat.draw_line_at,
          draw_line_at_value: cat.draw_line_at_value,
          visible: cat.visible,
          yLabel : cat.yLabel,
          yPos : cat.yPos,
          count : cat.count
        });
//        console.log("Reuse category:"+b);
        break;
      }
      else {
//        console.log("New category:"+b);  
      }
    }
    available_value_categories.add(b);
  }

    // Add x-axis grid
  let bottomGridAxis = d3
  .axisBottom(x)
  .tickFormat('')
  .ticks((options.width - options.paddingXLeft - options.paddingXRight) / 120)
  .tickSizeInner(-(options.height - options.paddingY * 2));

  let axis_grid_id = "tg_axis_grid";
  let axis_group_grid = canvas.select("#" + axis_grid_id);
  
      // Remove any existing grid
  axis_group_grid.remove(); 

  if (anyGrid) {
    // X-axis grid
    axis_group_grid = canvas.append("g").attr("id", axis_grid_id);  

    axis_group_grid.attr('class', 'tg_axis_grid');
    axis_group_grid.attr("stroke-dasharray", "4,4,4,4,4,4");
    axis_group_grid.attr("transform", "translate(" + `${0},${options.height - options.paddingY}` + ")").call(bottomGridAxis);  
  }

  //let axes = [];
  if (axesData.array != null) {
    for (let initIndex = 0; initIndex < axesData.array.length;initIndex++) {
      let index = axesData.array[initIndex].index;
      let axis_id = "tg_axis_" + index;
      let axis_group = canvas.select("#" + axis_id);
      if (!axis_group.empty()) {
        axis_group.remove();
      }
      let y_axis_value_label_id = "tg_y_axis_value_label_" + index;
      let y_axis_value_label_group = canvas.select("#" + y_axis_value_label_id);
    
          // Remove any existing label
      y_axis_value_label_group.remove();   
      
      let y_axis_label_id = "tg_y_axis_label_" + index;
      let y_axis_label_group = canvas.select("#" + y_axis_label_id);
      
        // Remove any existing label
      y_axis_label_group.remove(); 

      let axis_grid_id = "tg_axis_grid_" + index;
      let axis_group_grid = canvas.select("#" + axis_grid_id);
    
        // Remove any existing grid
      axis_group_grid.remove();  
    }
  }

  axesData.array = [];

  let axisHeight = (options.height - 2*options.paddingY - (graph_N-1)*paddingSplit) / graph_N;
  let index = 0;

  if (axesData.transpose == null) {
    // Init zoom and offset to default
    axesData.transpose = [];
    let initIndex=0;
    for (initIndex=0;initIndex < maxAxis;initIndex++) {
      let transpose = {zoomFactor:1.0, offset:0.0, unit:"", gi:0};
      axesData.transpose.push(transpose);
    }
  }

    // Stacked graph index
  for (let gi=Enum.graph_pos.graph_pos_bottom;gi < graph_N;gi++) {
    let rangeMin = options.height - options.paddingY - (gi * (axisHeight + paddingSplit));
    let rangeMax = rangeMin - axisHeight;
    let yLegend = rangeMax;

    let right = options.firstYAxisLeft;
    let right_offset = -axesDistance;
    let left_offset = -axesDistance;
    for (let categoryJSON of available_value_categories) {
      let category = JSON.parse(categoryJSON);
      if (category.yPos == gi) {
        let graphMin = category.override_y_axis_scale ? category.override_y_axis_scale_min : category.graph_min;
        let graphMax = category.override_y_axis_scale ? category.override_y_axis_scale_max : category.graph_max;
        let zoom_min = zoomMin(graphMin, graphMax, axesData.transpose[index].zoomFactor, axesData.transpose[index].offset/axisHeight);
        let zoom_max = zoomMax(graphMin, graphMax, axesData.transpose[index].zoomFactor, axesData.transpose[index].offset/axisHeight);
          
        if (category.visible) {
          // toggle right
          right = !right;
          if (right)
            right_offset +=axesDistance;
          else
            left_offset +=axesDistance;
        }
              
        axesData.array.push(
          new_value_axis(
            series,
            false, // Disable auto scaling
            category,
            right,
            right ? right_offset : left_offset,
            zoom_min,
            zoom_max,
            rangeMin,
            rangeMax,
            index,
            gi,
            yLegend,
            options
          )
        );
        if (axesData.transpose != null) {
          // Check if existing zoom, offset is valid
          // else reset
          if (axesData.transpose[index].unit != "") {
            if ((axesData.transpose[index].unit != category.unit)
                      ||
                (axesData.transpose[index].gi != gi)) {
              axesData.transpose[index].unit = "";
              axesData.transpose[index].gi = 0;
              axesData.transpose[index].zoomFactor = 1.0;
              axesData.transpose[index].offset = 0.0;
            }
          }
        }
        index += 1;
        if (category.visible)
          yLegend += (category.count * 20);
      }
    }
  }

  TG_SERIES_CONTAINER = canvas.select(".tg_series_container");

  let overshoot_ms = (displayed_interval_end - displayed_interval_start) * options.area_overshoot;
  let min_date_to_draw = displayed_interval_start - overshoot_ms;
  let max_date_to_draw = displayed_interval_end + overshoot_ms;

  // Clip date limits to specified interval
  if(min_date_to_draw < options.startDate) min_date_to_draw = options.startDate;
  if(max_date_to_draw > options.endDate) max_date_to_draw = options.endDate;

  xLegendOffset = 0;
  for (let value_axis of axesData.array) {
    if (value_axis.visible && value_axis.rightSide) {
      xLegendOffset += axesDistance;
    }
  }

  for (let value_axis of axesData.array) {
    draw_value_axis(
      value_axis,
      canvas,
      options,
      axesData,
      xCoord,
      min_date_to_draw,
      max_date_to_draw,
      visible_series,
      showHoldValues
    );
  }

  // Set up clip path
   canvas
    .selectAll("#tg_clipping_rect, .tg_overlay")
    .attr("width", options.width - options.paddingXLeft - options.paddingXRight)
    .attr("height", options.height - options.paddingY*2)
    .attr("x", options.paddingXLeft)
    .attr("y", options.paddingY);

  // Alarm levels
/*   if (alarmLineLow != null && series.length === 1) {
    let alarmLowY = axesData.array[0].getYforPoint({ value: alarmLineLow }) + 1;
    canvas
      .select("#tg_alarm_low_line")
      .style("display", null)
      .attr("stroke-dasharray", "12,12")
      .attr("x1", "0")
      .attr("y1", alarmLowY)
      .attr("x2", options.width)
      .attr("y2", alarmLowY);
  } else {
    canvas.select("#tg_alarm_low_line").style("display", "none");
  }
  if (alarmLineHigh != null && series.length === 1) {
    let alarmHighY = axesData.array[0].getYforPoint({ value: alarmLineHigh }) + 1;
    canvas
      .style("display", null)
      .select("#tg_alarm_high_line")
      .attr("x1", "0")
      .attr("y1", alarmHighY)
      .attr("x2", options.width)
      .attr("y2", alarmHighY);
  } else {
    canvas.select("#tg_alarm_high_line").style("display", "none");
  }
 */
  //axesData.array = axes;

  function zoomMin(category_graph_min,
    category_graph_max, axesZoomFactor, axesOffset) {
      let originalRange = category_graph_max - category_graph_min;
      let offset = axesOffset * originalRange;
      if (axesZoomFactor <= 1.0)
        return category_graph_min + offset;
      else {
        
        let newRange = originalRange / axesZoomFactor;
        let newGraphMin = category_graph_min + (originalRange - newRange)/2;
        return newGraphMin + offset;
      }
  }

  function zoomMax(category_graph_min,
    category_graph_max, axesZoomFactor, axesOffset) {
      let originalRange = category_graph_max - category_graph_min;
      let offset = axesOffset * originalRange;
      if (axesZoomFactor <= 1.0)
        return category_graph_max + offset;
      else {
        let newRange = originalRange / axesZoomFactor;
        let newGraphMax = category_graph_max - (originalRange - newRange)/2;
        return newGraphMax + offset;
      }
  }

  function onMouseUp(event) {
    var date_clicked = x.invert(d3.pointer(event)[0]).getTime();
    options.click_callback(date_clicked);
  }

  function onMouseMove(event) {

    var mouseX = d3.pointer(event)[0];
    var mouseY = d3.pointer(event)[1];

    // MAgnify rectangle
    let magnify_rect = canvas.selectAll("#tg_magnify_rect");

    if (options.magnify_start_position != null) {
      var last_click_pos = x(options.magnify_start_position);

      if (mouseX < last_click_pos) {
        magnify_rect.attr("x", mouseX).attr("width", last_click_pos - mouseX);
      } else {
        magnify_rect
          .attr("x", last_click_pos)
          .attr("width", mouseX - last_click_pos);
      }

      magnify_rect
        .attr("visibility", "visible")
        .attr("fill", "rgba(0,0,0,0.1)")
        .attr("y", options.paddingY)
        .attr("height", options.height - options.paddingY * 2);
    } else {
      magnify_rect.attr("visibility", "hidden");
    }

    // Labels
    var date_at_mouse_pos = x.invert(mouseX).getTime();
    let highlighted_sample_time = date_at_mouse_pos;

    var bisectDate = d3.bisector(function(d) {
      if (d.hasOwnProperty("date")) {
        return d.date;
      }
      return d.minDate;
    }).left;

    // Highlighted samples
    if (!window.app.ui.mousedown &&
        highlighted_sample_time >= options.startDate &&
        highlighted_sample_time <= options.endDate ) {
      let highlighted_sample_position = 0;
      let highlighted_samples = {};

      for (let a of axesData.array) {
        for (let series of a.series) {
          if (series.data.length == 0)
            continue;
          series.hidden =
            visible_series.filter(v => v.id === series.id).length === 0;

          if (series.data != null) {
            if (series.data.length > 0) {
              var closestIndex = bisectDate(series.data, date_at_mouse_pos, 1);
              var d0 = series.data[closestIndex - 1];
              var d1 = series.data[closestIndex];
              if (series.data.length === closestIndex) d1 = d0;
    
              var closest_sample =
                date_at_mouse_pos - d0.date > d1.date - date_at_mouse_pos
                  ? d1
                  : d0;
    
              if(closest_sample.maxDate < options.startDate ||
                 closest_sample.minDate > options.endDate ) {
                continue;
              }
    
              highlighted_sample_position = xCoord(closest_sample);
              highlighted_sample_time = x
                .invert(highlighted_sample_position)
                .getTime();
    
              if (options.thick) {
                create_focus_circles_category(
                  "top",
                  canvas,
                  series,
                  highlighted_sample_position,
                  a.getYforPoint({ value: closest_sample.maxVal })
                );
                create_focus_circles_category(
                  "bottom",
                  canvas,
                  series,
                  highlighted_sample_position,
                  a.getYforPoint({ value: closest_sample.minVal })
                );
              } else {
                create_focus_circles_category(
                  "bottom",
                  canvas,
                  series,
                  highlighted_sample_position,
                  a.getYforPoint(closest_sample)
                );
              }
    
              if (!series.hidden) {
                // console.log("highlighted_samples:"+series.id+" pos:"+highlighted_sample_position+" y:"+mouseY + " time:" + highlighted_sample_time);
                highlighted_samples[series.id] = closest_sample;
              }
  
            }

          }  
        }
      }
      // Return via callback
      options.highlighted_samples_callback(highlighted_samples);
      // Vertical line
      canvas
        .select("#tg_vertical_line")
        .attr("x1", highlighted_sample_position)
        .attr("x2", highlighted_sample_position)
        .attr("y1", 0)
        .attr("y2", options.height);

      canvas
        .select("#tg_date_display")
        .attr("x", highlighted_sample_position + 10)
        .attr("y", mouseY - 20)
        .text(format(highlighted_sample_time));

      canvas.selectAll(".tg_vertical_line").style("display", null);
      canvas.selectAll(".tg_focus_circles").style("display", null);
      canvas.selectAll("#tg_date_display").style("display", null);
    } else {
      options.highlighted_samples_callback({});
      canvas.selectAll(".tg_vertical_line").style("display", "none");
      canvas.selectAll(".tg_focus_circles").style("display", "none");
      canvas.selectAll("#tg_date_display").style("display", "none");
    }
  }

  function showHoverVisibility() {
    canvas.selectAll(".tg_only_on_hover").style("display", null);
  }

  function hideHoverVisibility() {
    canvas.selectAll(".tg_only_on_hover").style("display", "none");
    options.highlighted_samples_callback({});
  }

  canvas
    .select("rect.tg_overlay")
    .on("mouseover", showHoverVisibility)
    .on("mouseout", hideHoverVisibility)
    .on("mousemove", (event) => onMouseMove(event))
    .on("mouseup", (event) => onMouseUp(event));

  return { minDate: displayed_interval_start, maxDate: displayed_interval_end };
}

function clearAxisSelection(canvas, axesData, options) {
  // Clear selection of any Y-Axis
  if (axesData.selectedIndex !== -1) {
    let selectedAxisId = axesData.selectedId;
    if (selectedAxisId) {
      let selectedAxisGroup = canvas.select("#" + selectedAxisId);
      selectedAxisGroup.style("fill", "none"); // Reset y-axis to default style

      axesData.selectedId = "";
      axesData.selectedIndex = -1;
    }
  }

  // Clear selection of X-Axis if it was selected
  if (axesData.selectedX) {
    let xAxisGroup = canvas.select(".tg_axis");
    xAxisGroup.style("fill", "none"); // Reset fill of the x-axis to default

    axesData.selectedX = false;
  }

  // Optional: trigger any visual refresh if necessary
  options.resize_callback();
}



function draw_value_axis(
  value_axis,
  canvas,
  options,
  axesData,
  getXforPoint,
  lowest_date_to_draw,
  highest_date_to_draw,
  visible_series,
  showHoldValues
) 
{
  const is_visible = d => {
    return !visible_series.filter(s => d.id === s.id).length < 1;
  };

  let axisVisible = false;

  let yLegendOffset = 0;
  for (let s of value_axis.series) {
    draw_series(
      s,
      getXforPoint,
      canvas,
      options,
      lowest_date_to_draw,
      highest_date_to_draw,
      value_axis,
      !is_visible(s),
      value_axis.yLegend + yLegendOffset
    );

    if (is_visible(s)) {
      yLegendOffset += 20;
      axisVisible = true;
    }
  }

  // Hold values
  var hold_lines = canvas
    .selectAll("line.tg_hold_line_" + value_axis.index)
    .data(value_axis.series);

  const hold_value_y = d => {
    return value_axis.getYforPoint({ value: d.hold_value });
  };

  hold_lines
    .enter()
    .append("line")
    .attr("class", "tg_hold_line")
    .attr("class", "tg_hold_line_" + value_axis.index)
    .attr("stroke", d => d.color)
    .attr("stroke-dasharray", "3,3");

  hold_lines
    .attr("x1", options.paddingXLeft)
    .attr("y1", hold_value_y)
    .attr("x2", options.width - options.paddingXRight)
    .attr("y2", hold_value_y)
    .attr("visibility", d =>
      !showHoldValues || !is_visible(d) ? "hidden" : "visible"
    );

  hold_lines.exit().remove();

  let axis_id = "tg_axis_" + value_axis.index;
  let axis_group = canvas.select("#" + axis_id);
  if (!axis_group.empty()) {
    axis_group.remove();
  }

  let y_axis_value_label_id = "tg_y_axis_value_label_" + value_axis.index;
  let y_axis_value_label_group = canvas.select("#" + y_axis_value_label_id);

      // Remove any existing label
  y_axis_value_label_group.remove(); 

  let axis_grid_id = "tg_axis_grid_" + value_axis.index;
  let axis_group_grid = canvas.select("#" + axis_grid_id);

    // Remove any existing grid
  axis_group_grid.remove();  

  let axis_zero_id = "tg_axis_zero_" + value_axis.index;
  let axis_group_zero = canvas.select("#" + axis_zero_id);

    // Remove any existing zero line
  axis_group_zero.remove();  

  let axis_value_line_id = "tg_axis_value_Line_" + value_axis.index;
  let axis_group_value_line = canvas.select("#" + axis_value_line_id);

    // Remove any existing zero line
  axis_group_value_line.remove();  

  let y_axis_label_id = "tg_y_axis_label_" + value_axis.index;
  let y_axis_label_group = canvas.select("#" + y_axis_label_id);
  
    // Remove any existing label
  y_axis_label_group.remove(); 

  let axis = null;
  let axisGrid = null;
  let axisOffset = null;
  if (value_axis.visible) {
    if (value_axis.rightSide) {
      axis = d3.axisRight(value_axis.yFunction);
      if (value_axis.grid) {
        axisOffset = value_axis.offset;
        axisGrid = d3.axisRight(value_axis.yFunction);
        axisGrid.tickFormat('').ticks((value_axis.rangeMin - value_axis.rangeMax) / 30)
        .tickSizeInner(-(options.width - options.paddingXLeft - options.paddingXRight + axisOffset));  
      }
    } else {
      axis = d3.axisLeft(value_axis.yFunction);
      if (value_axis.grid) {
        axisOffset = value_axis.offset;
        axisGrid = d3.axisLeft(value_axis.yFunction);
        axisGrid.tickFormat('').ticks((value_axis.rangeMin - value_axis.rangeMax) / 30)
        .tickSizeInner(-(options.width - options.paddingXLeft - options.paddingXRight + axisOffset));  
      }
    }
  
    axis.ticks((value_axis.rangeMin - value_axis.rangeMax) / 30);//.tickSizeInner(-5);  

    axis_group = canvas.append("g").attr("id", axis_id);

    // Add title for the entire y-axis
    axis_group.append("title")
      .text("Click to activate scroll functionality for this axis");
  
    axis_group.attr("visibility", axisVisible ? "visible" : "hidden");
    axis_group.attr("transform", `translate(${value_axis.x},${0})`).call(axis);
    axis_group.on("click", function(d) {onClickYAxis(canvas, axis_id, axis, value_axis.index, axesData, options)});
    axis_group.on("mousedown", function(d) {onMouseDownYAxis(canvas, this, axis_id, axis, value_axis.index)});
    axis_group.on("mousemove", function(d) {onMouseMoveYAxis(canvas, this, axis_id, axis, value_axis.index)});
    axis_group.on("mouseup", function(d) {onMouseUpYAxis(canvas, this, axis_id, axis, value_axis.index, options)});
    axis_group.style("cursor", "pointer") // Add cursor pointer style to indicate clickability
   
    if (value_axis.grid) {
      // Append grid if not exists
      axis_group_grid = canvas.append("g").attr("id", axis_grid_id);  
    }  
    
    if (value_axis.grid) {
      axis_group_grid.attr('class', 'tg_axis_grid');
      axis_group_grid.attr("stroke-dasharray", "4,4,4,4,4,4");
      axis_group_grid.attr("transform", `translate(${value_axis.x},${0})`).call(axisGrid);  
    }


  
    if (value_axis.index == axesData.selectedIndex) {
      //console.log("Selection, value_axis.index:selectedAxisIndex"+value_axis.index+axesData.selectedIndex);
      axis_group.style('fill','lightgreen').call(axis);   
    }
    else {
      //console.log("Clear selection, value_axis.index:selectedAxisIndex"+value_axis.index+axesData.selectedIndex);
      axis_group.style('fill','white').call(axis);   
    }
  
    let axisLabelX;
    let labelOffset = 5; // Adjust this value as needed

    if (value_axis.rightSide) {
      axisLabelX = value_axis.x + labelOffset;
    } else {
      axisLabelX = value_axis.x - labelOffset;
    }
  
    // Append a group element to wrap the text and its background rect
    let labelGroup = canvas.append("g")
      .attr("id", y_axis_value_label_id)
      .style("cursor", "pointer"); // Add cursor pointer style to the entire group

    // Append the text element
    let textElement = labelGroup.append("text")
    .attr("x", axisLabelX)
    .attr("y", value_axis.rangeMin + 30)
    .text(value_axis.name)
    .style("fill", "black")
    .style("font-size", "12px")
    .style("stroke", "none");

    textElement.append("title")
      .text("Click to configure axis settings");

    // Use the text bounding box to determine the size of the background rect
    let bbox = textElement.node().getBBox();

    // Append the rectangle element as a background
    labelGroup.insert("rect", "text")
    .attr("x", bbox.x - 5)
    .attr("y", bbox.y - 2)
    .attr("width", bbox.width + 10)
    .attr("height", bbox.height + 4)
    .style("fill", "white")
    .style("stroke", "none");

    // Add interaction events to both the text and background rect
    labelGroup.on("click", function() {
      onClickYAxisLabel(canvas, options, value_axis);
    })
    .on("mouseover", function() {
      textElement.style("fill", "white");
      labelGroup.select("rect").style("fill", "#007bff");
    })
    .on("mouseout", function() {
      textElement.style("fill", "black");
      labelGroup.select("rect").style("fill", "white");
    });
    let axisLabelY = (value_axis.rangeMin + value_axis.rangeMax)/2;
  
    if (value_axis.yLabel != "") {
      let offset = value_axis.rightSide ? 45 : -35; // Adjust as needed 

      canvas.append("g")
      .attr("id", y_axis_label_id)
      .attr("transform", "translate(" + (axisLabelX + offset) + "," + axisLabelY + ")")
      .append("text")
      .attr("text-anchor", "middle")
      .attr("transform", "rotate(-90)")
      .text(value_axis.yLabel)
      .on("click", function(d) {onClickYAxisLabel(canvas, options, value_axis)});
    }

      // Zero-lines
    if (value_axis.zero_line) {
      let zero_for_axis = value_axis.getYforPoint({ value: 0 }) + 1;
      console.log("Zero line for axis:" + value_axis.unit + " at" + zero_for_axis);
      axis_group_zero = canvas.append("g").attr("id", axis_zero_id);  
      axis_group_zero  
        .append("line")
        .attr("stroke", "lightgray")
        .attr("stroke-width", "1px") // Set stroke width explicitly
        .attr("stroke-dasharray", "20,10,5,5,5,10")
        .attr("x1", options.paddingXLeft)
        .attr("y1", zero_for_axis)
        .attr("x2", options.width - options.paddingXRight)
        .attr("y2", zero_for_axis);  
    }

          // Draw line at
    if (value_axis.draw_line_at) {
      let value_for_axis = value_axis.getYforPoint({ value: value_axis.draw_line_at_value }) + 1;
//      console.log("Value line for axis:" + value_axis.unit + " at" + value_for_axis);
      axis_group_value_line = canvas.append("g").attr("id", axis_value_line_id);  
      axis_group_value_line  
        .append("line")
        .attr("stroke", "red")
        .attr("stroke-dasharray", "20,10,5,5,5,10")
        .attr("x1", options.paddingXLeft)
        .attr("y1", value_for_axis)
        .attr("x2", options.width - options.paddingXRight)
        .attr("y2", value_for_axis);  
    }

    axesData.array[value_axis.index].yAxis=axis; 
  
  }

  // Remove any existing diagram description
  let diagram_description_id = "tg_diagram_description";
  let diagram_description = canvas.select("#" + diagram_description_id);
  diagram_description.remove();

  let descriptionX = options.width - options.paddingXRight - xLegendOffset;
  let descriptionY = options.height - 5;
  let diagramDescription = options.graph_description;

  if (diagramDescription != "") {
    // Append a group element to wrap the text and its background rect
    let group = canvas.append("g")
      .attr("id", diagram_description_id)
      .attr("transform", `translate(${descriptionX}, ${descriptionY})`)
      .style("cursor", "pointer"); // Add cursor pointer style to the entire group

    // Append the text element
    let textElement = group.append("text")
      .attr("text-anchor", "end")
      .text(diagramDescription)
      .style("fill", "black") // Set text color explicitly
      .style("font-size", "14px"); // Set font size as needed

    textElement.append("title")
      .text("Click to change graph description");

    // Use the text bounding box to determine the size of the background rect
    let bbox = textElement.node().getBBox();

    // Append the rectangle element as a background
    let rectElement = group.insert("rect", "text")
      .attr("x", bbox.x - 5)
      .attr("y", bbox.y - 2)
      .attr("width", bbox.width + 10)
      .attr("height", bbox.height + 4)
      .style("fill", "white") // Set background color explicitly
      .style("stroke", "none");

    // Add interaction events with direct style changes
    group.on("click", function() {
        onClickGraphDescription(options);
      })
      .on("mouseover", function() {
        textElement.style("fill", "white");
        rectElement.style("fill", "#007bff");
      })
      .on("mouseout", function() {
        textElement.style("fill", "black");
        rectElement.style("fill", "white");
      }
    );
  }
}
function onMouseDownYAxis(canvas, el, axisId, axis, index)
{
/*   console.log("Mouse down");
  if (index == selectedAxisIndex) {
    var mouse = d3.mouse(el);
    axesYDragStart = mouse[1];
  }
 */}
function onMouseUpYAxis(canvas, el, axisId, axis, index, options)
{
 /*  console.log("Mouse up");
  if (index == selectedAxisIndex) {
    var mouse = d3.mouse(el);
    let axesYDragEnd = mouse[1];
    let diffY = axesYDragEnd - axesYDragStart;
    console.log("Drag:", diffY);
    offsetYAxis(diffY);
    options.resize_callback();
  } */
}

function onMouseMoveYAxis(canvas, el, axisId, axis, index, options)
{
 /*  console.log("Mouse move");
  if (index == selectedAxisIndex) {
    var mouse = d3.mouse(el);
    let axesYDragPos = mouse[1];
    let diffY = axesYDragPos - axesYDragStart;
    axesYDragStart = axesYDragPos;
    console.log("Drag(move):", diffY);
    offsetYAxis(diffY);
    options.resize_callback();
  } */
}
function onClickXAxis(canvas, axis, axesData, options) {
  console.log("clickX!");
  let axis_group = canvas.select(".tg_axis");

  // If the X-axis is already selected, deselect it
  if (axesData.selectedX) {
    axis_group.style("fill", "white").call(axis);

    // Clear the selection state
    axesData.selectedX = false;

    // Trigger a redraw if necessary
    options.resize_callback();
  } else {
    // Highlight the selected axis line by changing its color to green
    axis_group.style("fill", "lightgreen");

    // Call axis again to refresh immediately
    axis_group.call(axis);

    // Bring the selected axis to the front
    axis_group.raise();

    // Update the selection state
    axesData.selectedId = "";
    axesData.selectedIndex = -1;
    axesData.selectedX = true;

    // Explicitly trigger the resize callback to ensure everything is redrawn
    options.resize_callback();
  }
}


function onClickYAxisLabel(canvas, options, value_axis)
{
  console.log("Clicked:"+ value_axis.name + ", index:"+ value_axis.index);
  options.axis_options_callback(value_axis.index, value_axis.name, 
    value_axis.override_y_axis_scale,
    value_axis.override_y_axis_scale_min,
    value_axis.override_y_axis_scale_max,
    value_axis.grid, value_axis.zero_line, value_axis.draw_line_at, value_axis.draw_line_at_value, value_axis.yLabel);
}

function onClickGraphDescription(options)
{
  console.log("Clicked graph description");
  options.graph_description_callback();
}

function onClickYAxis(canvas, axisId, axis, index, axesData, options) {
  console.log("click!" + axisId);
  let axis_group = canvas.select("#" + axisId);

  // If the clicked axis is already selected, deselect it
  if (axesData.selectedIndex === index) {
    // Deselect the axis by resetting its color and other properties
    axis_group.style("fill", "white").call(axis);

    // Clear selection state
    axesData.selectedId = "";
    axesData.selectedIndex = -1;
    axesData.selectedX = false;

    // Trigger a redraw if necessary
    options.resize_callback();
  } else {
    // If it's not already selected, select this axis
    axis_group.selectAll("line")
      .attr("stroke", "green") // Change the line to green to indicate selection
      .attr("stroke-width", 2) // Make the line thicker for emphasis if desired
      .style("stroke-opacity", "1") // Ensure full opacity
      .style("shape-rendering", "crispEdges"); // Helps with rendering sharp lines

    // Call axis again to ensure it refreshes properly
    axis_group.call(axis);

    // Bring the selected axis to the front to force rendering order
    axis_group.raise();

    // Update the selection state
    axesData.selectedId = axisId;
    axesData.selectedIndex = index;
    axesData.selectedX = false;

    // Deselect the X-axis if selected
    clearXAxisSelectionColor(options.canvasId);

    // Deselect other Y-axes
    for (let axisIndex = 0; axisIndex < axesData.array.length; axisIndex++) {
      if (axisIndex !== axesData.selectedIndex) {
        let other_axis_group = canvas.select("#tg_axis_" + axisIndex);
        other_axis_group.selectAll("line")
          .attr("stroke", "black") // Reset color to black or original color
          .attr("stroke-width", 1) // Reset line thickness to default
          .style("stroke-opacity", "1") // Ensure full opacity
          .style("shape-rendering", "crispEdges"); // Helps with rendering sharp lines
      }
    }

    // Explicitly trigger the resize callback to ensure everything is redrawn
    options.resize_callback();
  }
}


function draw_series(
  s,
  xCoord,
  canvas,
  options,
  min_tstamp,
  max_tstamp,
  value_axis,
  hidden,
  yLegend
) 
{
  let series_id = "series_" + s.id;
  let series_group = TG_SERIES_CONTAINER.select("#" + series_id);
  if (series_group.empty()) {
    series_group = TG_SERIES_CONTAINER.append("g").attr("id", series_id);
  }  
  
  // Path
  let path;
  if (value_axis.gi == 0) {
    path = series_group.select("path.tg_path");
    if (path.empty()) {
      path = series_group.append("path").attr("class", "tg_path");
    }
  }
  else if (value_axis.gi == 1){
    path = series_group.select("path.tg_path_1");
    if (path.empty()) {
      path = series_group.append("path").attr("class", "tg_path_1");
    }      
  }
  else {
    path = series_group.select("path.tg_path_2");
    if (path.empty()) {
      path = series_group.append("path").attr("class", "tg_path_2");
    }      
  }
  path.attr("visibility", hidden ? "hidden" : "visible");


  let legend_id = "legend_" + s.id;
  let legend_group = canvas.select("#" + legend_id);
  legend_group.remove();

  let legend_label_id = "legend_label_" + s.id;
  let legend_label_group = canvas.select("#" + legend_label_id);
  legend_label_group.remove();

  if (hidden) return;

    // Draw printable legend
  if (options.show_legend) {
    // console.log(s.name + " at " + yLegend);
    legend_group = canvas.append("g").attr("id", legend_id);
    let xLegend = options.width - options.paddingXRight + xLegendDistance + xLegendOffset;
    legend_group.append("circle")
                  .attr("cx", xLegend)
                  .attr("cy", yLegend)
                  .attr("r", 8)
                  .style("fill",s.color);
                  
/*                   var dragHandler = d3.drag()
                      .on("drag", function () {
                        d3.select(this)
                        .attr("cx", d3.event.x)
                        .attr("cy", d3.event.y);                        

                          console.log("X:"+d3.event.x+" Y:" + d3.event.y);

                      });
                  
                  dragHandler(canvas.selectAll("circle")); */
  
    legend_label_group = canvas.append("g").attr("id", legend_label_id);
    legend_label_group.append("text")
      .attr('class', 'legend_label')
      .attr("x", xLegend + 10)
      .attr("y", yLegend)
      .style("fill", s.color)
      .text(s.name)
      .attr("text-anchor", "left")
      .style("alignment-baseline", "middle");
  }

  if (value_axis.gi == 0) {
    canvas.append("clipPath")
    .attr("class","clipPath")
    .attr("id", "clip-0")  // <-- we need to use the ID of clipPath
    .append("rect")
    .attr("x", options.paddingXLeft)
    .attr("y", value_axis.rangeMax)
    .attr("width", options.width)
    .attr("height", value_axis.rangeMin - value_axis.rangeMax);
    path.attr("clip-path", "url(#clip-0)");
  }
  else if (value_axis.gi == 1) {
    canvas.append("clipPath")
    .attr("id", "clip-1")  // <-- we need to use the ID of clipPath
    .attr("class","clipPath")
    .append("rect")
    .attr("x", options.paddingXLeft)
    .attr("y", value_axis.rangeMax)
    .attr("width", options.width)
    .attr("height", value_axis.rangeMin - value_axis.rangeMax);
  
    path.attr("clip-path", "url(#clip-1)");    
  }
  else {
    canvas.append("clipPath")
    .attr("id", "clip-2")  // <-- we need to use the ID of clipPath
    .attr("class","clipPath")
    .append("rect")
    .attr("x", options.paddingXLeft)
    .attr("y", value_axis.rangeMax)
    .attr("width", options.width)
    .attr("height", value_axis.rangeMin - value_axis.rangeMax);
  
    path.attr("clip-path", "url(#clip-2)");     
  }

  const lineFunction = d3
    .line()
    .curve(d3.curveLinear)
    .x(xCoord)
    .y(value_axis.getYforPoint);

   let data = get_datum_array_from_series_data(
    s.data,
    options.thick,
    min_tstamp,
    max_tstamp
  ); 

  if (s.graph_type == Enum.graph_type.graph_type_line)
  {
    path
      .datum(data)
      .attr("fill", options.thick ? s.color : "none")
      .attr("stroke", s.color)
      .attr("d", lineFunction);
  }


  if (s.graph_type == Enum.graph_type.graph_type_bar)
  {  
    canvas
      .append('g')
      .attr('fill', s.color)
      .selectAll('rect')
      .data(data)
      .join('rect')
        .attr('x', xCoord)
        .attr('y', value_axis.getYforPoint)
        .attr('height', (d => value_axis.rangeMin - value_axis.yFunction(d.value))) 
        .attr('width', 10) //  / interval_scaling_factor ??
        .attr('class', 'barrect');
  }

  window.points_drawn += data.length;


  /*
  // Points
  let number_of_points_before_hide = options.width / options.maxPointDensity;
  let point_visibility = (hidden || options.thick || s.data.length >= number_of_points_before_hide) ? "hidden" : "visible";
  let _points = series_group.selectAll("circle.tg_point").data(data);

  _points.exit().remove();

  _points
    .enter()
    .append("circle")
    .attr("class", "tg_point")
    .attr("fill", s.color)
    .attr("r", 2)
    .attr("cx", xCoord)
    .attr("visibility", point_visibility)
    .attr("cy", value_axis.getYforPoint);

  if(point_visibility) {
    _points
      .attr("cx", xCoord)
      //.attr("visibility", point_visibility)
      .attr("cy", value_axis.getYforPoint);
  }
  */
}

function create_focus_circles_category(
  category_name,
  canvas,
  series,
  mouse_x_pos,
  y_func
) 
{
  let focus_circles_group = canvas.select(".tg_focus_circles");
  let matching_id = `focus_circle_${category_name}_${series.id}`;

  let matching_circles = focus_circles_group.select("#" + matching_id);

  if (matching_circles.empty()) {
    focus_circles_group
      .append("circle")
      .attr("r", "3")
      .attr("id", matching_id);

    matching_circles = canvas.select(matching_id);
  }

  matching_circles
    .attr("cx", mouse_x_pos)
    .attr("cy", y_func)
    .attr("display", series.hidden ? "none" : null)
    .attr("fill", series.color);
}

function new_value_axis(
  series,
  autoscale_axis,
  category,
  rightSide,
  offset,
  zoom_min,
  zoom_max,
  rangeMin,
  rangeMax,
  index,
  gi,
  yLegend,
  options
) 
{
  let axis = {};
  axis.name = category.unit;
  axis.rightSide = rightSide;
  axis.visible = category.visible;
  axis.offset = offset;
  axis.override_y_axis_scale = category.override_y_axis_scale;
  axis.override_y_axis_scale_min = category.override_y_axis_scale_min;
  axis.override_y_axis_scale_max = category.override_y_axis_scale_max;
  axis.grid = category.grid;
  axis.zero_line = category.zero_line;
  axis.draw_line_at = category.draw_line_at;
  axis.draw_line_at_value = category.draw_line_at_value;
  axis.yLabel = category.yLabel;
  axis.index = index;
  axis.gi = gi; // Stacked graph index
  axis.yLegend = yLegend;
  axis.rangeMin = rangeMin;
  axis.rangeMax = rangeMax;
  axis.unit = category.unit;
  axis.series = series.filter(
  s => (s.unit === category.unit) && (s.yPos == gi)
  );
  if (autoscale_axis) {
    axis.minY = get_min_value(axis.series);
    axis.maxY = get_max_value(axis.series);
  } else {
    axis.minY = zoom_min;
    axis.maxY = zoom_max;
  }

  axis.yFunction = d3
    .scaleLinear()
    .domain([axis.minY, axis.maxY])
    .rangeRound([rangeMin, rangeMax]);
  axis.getYforPoint = d => axis.yFunction(d.value);

  axis.x = 0;
  if (axis.rightSide) {
    offset = axis.offset;
    axis.x = options.width - options.paddingXRight + offset;
  } else {
    offset = axis.offset;
    axis.x = options.paddingXLeft - offset;
  }
 
/*  console.log("new_value_axis:");
  console.log("unit:"+axis.unit);
  console.log("x:"+axis.x);
  console.log("minY:"+axis.minY);
  console.log("maxY:"+axis.maxY);
  console.log("rangeMin:"+rangeMin);
  console.log("rangeMax:"+rangeMax); 
  console.log("visible:"+axis.visible); */
 
  return axis;
}

function get_datum_array_from_series_data(
  series_data,
  thick,
  min_date,
  max_date
) {
  let first_index =
    series_data.findIndex(s => s.minDate.getTime() >= min_date) - 1;

  if(first_index === -2) {
    // All data is before min_date, so we can safely return an empty array
    return [];
  }

  first_index = first_index <= 0 ? 0 : first_index;
  let last_index = series_data.findIndex(s => s.minDate.getTime() > max_date);
  last_index =
    last_index === -1
      ? series_data.length
      : last_index + (last_index === series_data.length ? 0 : 1);

  if (!thick) return series_data.slice(first_index, last_index);

  let samples_to_draw = last_index - first_index;
  if(samples_to_draw < 1) return [];

  let data = [];
  data.length = samples_to_draw * 2;
  for (let i = 0; i < samples_to_draw; i++) {
    let date = series_data[first_index + i].minDate;

    data[i] = {
      date,
      value: series_data[first_index + i].maxVal
    };

    data[data.length - i - 1] = {
      date,
      value: series_data[first_index + i].minVal
    };
  }
//  console.log("Samples to draw");
//  for (let i = 0; i < data.length; i++) {
//    console.log("i:"+i+" date:"+data[i].date+" value:"+data[i].value);
//  }

  return data;
}

function assert_order_of_data(data) {
  let last_date = 0;
  for (let i = 0; i < data.length; i++) {
    let start_date = data[i].date;
    if (start_date < last_date) {
      console.log(
        `Samples not in order! \nLast: ${last_date}, \n\nThis: ${start_date}`
      );
      window.bad_data = data;
      return false;
    }
    last_date = start_date;
  }
  return true;
}
