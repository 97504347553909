<template>
  <div
    class="topmenu_item"
    :class="{
      top_menu_button: route != null,
      top_menu_tab_selected: tab_selected
    }"
    @click="clicked()"
  >
    <div class="top_menu_item_content">
      <span v-if="icon != ''" class="inline_icon">
        <img
          v-if="this.route == null"
          src="favicon.ico"
          width="20px"
          style="padding-right: 4px"
        />
        <SmallIcon v-else :icon="icon" color="white" />
      </span>
      <span v-if="!$ui.tight_mode" :class="{ inline_item: icon != null }">{{
        label
      }}</span>
    </div>
  </div>
</template>
<script>
import router from '@/router';
export default {
  name: "TopMenuItem",
  data() {
    return {};
  },

  props: {
    label: String,
    icon: String,
    route: Object,
    tab_selected: Boolean
  },

  methods: {
    clicked() {
      if (this.route != null) {
        // eslint-disable-next-line
        router.push(this.route).catch(err => {});
      }
    }
  }
};
</script>
