import { WMBaseItem } from "./baseitem";

export class CustomerProject extends WMBaseItem {
  get schema() {
    return this.dto.schema;
  }

  get customerId() {
    return this.dto.customerId;
  }

  get alias() {
    return this.dto.alias;
  }

  upload_changes() {
    return this.Network.customer_project.update(this.id, this.changes());
  }

  static new_item_instance(dto, identification) {
    return new CustomerProject(dto, identification);
  }

  static find_parent(app, identification) {
    return app.$appm.get_customer(identification.customer_id).customer_projects;
  }

  static find(app, id, identification) {
    return app.$appm.get_customer_project(identification.customer_id, id);
  }
}
