<template>
  <div class="labeled-control">
    <p :class="{ wm_label_disabled: disabled }">{{ label }}</p>
    <input
      autocomplete="off"
      class="wm_input"
      :id="inputid"
      :placeholder="placeholder"
      :style="{ width: full_length }"
      :value="modelValue"
      v-on:input="$emit('update:modelValue', $event.target.value)"
      :disabled="disabled"
      :class="{
        wm_input_disabled: disabled,
        wm_input_tightness1: tightness == 1,
        wm_input_tightness2: tightness == 2,
        wm_username_input : username
      }"
      :type="type"
      :min="min"
      :max="max"
      :step="step"
      :maxlength="maxlength"
      :minlength="minlength"
      :hintText = "hintText"
      :title = "hint"
    />
  </div>
</template>

<script>
export default {
  name: "LabeledNumericInput",
  data() {
    return {};
  },

  props: {
    label: {
      type: String
    },
    placeholder: {
      type: String
    },
    modelValue: {
      type: Number,
      default: 0.0
    },
    type: {
      type: String,
      default: "number"
    },
    disabled: {
      type: Boolean
    },
    inputid: {
      default: null
    },
    width: {
      default: 150
    },
    tightness: {
      default: 0
    },
    step: {
      default: 1
    },
    min: {
      default: -100000
    },
    max: {
      default: 100000
    },
    minlength: {
      default: '0'
    },
    maxlength: {
      default: '524'
    },
    username: {
      default: false
    },
    hintText: {
      type: String,
      default: ""
    }
  },
  emits: ['update:modelValue'],
  computed: {
    hint() {
      return this.hintText;   
    },
    full_length: function() {
      return this.width.toString() + "px";
    }
  },

  methods: {},

  created: function() {}
};
</script>
