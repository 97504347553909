import { GrantAPI } from "./grant";
import Grant from "@/items/grant";

export class ProjectGrantAPI extends GrantAPI {
  list(
    project_id,
    custom_callback,
    refresh_interval = global.default_refresh_interval
  ) {
    return this.send({
      command: "projects/" + project_id + "/access/grants",
      method: "GET",
      identification: {
        project_id: project_id
      },
      on_receive: this.receive_grant_array,
      refresh_interval: refresh_interval,
      custom_callback: custom_callback
    });
  }

  fetch(
    project_id,
    user_id,
    refresh_interval = global.default_refresh_interval
  ) {
    return this.send({
      command: "projects/" + project_id + "/access/grants/" + user_id,
      method: "GET",
      identification: {
        project_id: project_id,
        user_id: user_id
      },
      refresh_interval: refresh_interval,
      on_receive: this.receive_grant
    });
  }

  del(project_id, user_id, refresh_interval = global.default_refresh_interval) {
    return this.send({
      command: "projects/" + project_id + "/access/grants/" + user_id,
      method: "DELETE",
      identification: {
        project_id: project_id,
        user_id: user_id
      },
      refresh_interval: refresh_interval,
      on_receive: this.receive_delete
    });
  }

  fetch_full(
    project_id,
    user_id,
    refresh_interval = global.default_refresh_interval
  ) {
    return this.send({
      command: "projects/" + project_id + "/access/grants/" + user_id + "/full",
      method: "GET",
      identification: {
        project_id: project_id
      },
      refresh_interval: refresh_interval,
      on_receive: this.receive_grant
    });
  }

  receive_delete(response, identification, myself) {
    if (response == true) {
      /*
      console.log(JSON.stringify(identification));
      console.log(JSON.stringify(myself.app.grants.length));
      */
      for (var i=0;i < myself.app.grants.length; i++)
      {
        var pid = myself.app.grants[i].projectId;
        var uid = myself.app.grants[i].userId;
        if ((pid === identification.project_id) 
              &&
           (uid === identification.user_id))
        {
          myself.app.grants.splice(i, 1);
           return;
        }
      }

/*        Grant.delete_clientside(
        myself.app,
        identification,
        identification.user_id
      ); 
 */
    }
  }

  update(project_id, user_id, grant_data) {
    // If creating a new user, this is null,
    // otherwise used to update existing users
    if (user_id != null) {
      grant_data.userId = user_id;
    }

    this.send({
      command: "projects/" + project_id + "/access/grants",
      method: "PUT",
      identification: {
        project_id: project_id,
        user_id: user_id
      },
      on_receive: this.receive_grant,
      post_json: grant_data
    });
  }
}
