import { APIBase } from "./apibase";
import { Customer } from "@/items/customer";

export class CustomerAPI extends APIBase {
  create(customer) {
    return this.send({
      command: "customers/",
      method: "POST",
      on_receive: this.receive_created,
      post_json: customer
    });
  }

  list(custom_callback, refresh_interval = global.default_refresh_interval) {
    return this.send({
      command: "customers",
      method: "GET",
      refresh_interval: refresh_interval,
      on_receive: this.receive_array,
      callback: custom_callback
    });
  }

  fetch(customer_id, refresh_interval = global.default_refresh_interval) {
    return this.send({
      command: "customers/" + customer_id,
      method: "GET",
      refresh_interval: refresh_interval,
      on_receive: this.receive
    });
  }

  update(customer_id, changes) {
    return this.send({
      command: "customers/" + customer_id,
      method: "PUT",
      on_receive: this.receive,
      post_json: changes
    });
  }

  delete(customer_id) {
    return this.send({
      command: "customers/" + customer_id,
      method: "DELETE",
      on_receive: this.receive_delete,
      identification: { customer_id: customer_id },
      post_json:  {
        tag: "tag1"
      }
    });
  }

  receive_array(response, identification, myself) {
    for (var i = 0; i < response.length; i++) {
      Customer.update_or_create_from_dto(
        response[i],
        myself.app,
        identification
      );
    }
    if (
      typeof myself.app.session.current_user.set_customer_privileges !=
      "function"
    ) {
      console.error(
        "Trying to apply privileges from customer before the 'session.current_user' is properly initialized!"
      );
    } else {
      myself.app.session.current_user.set_customer_privileges(response);
    }
  }

  receive(response, identification, myself) {
    Customer.update_or_create_from_dto(response, myself.app, identification);
  }

  receive_created(response, identification, myself) {
    myself.app.$appm.show_note("Customer successfully created");
    Customer.update_or_create_from_dto(response, myself.app, identification);
  }

  receive_delete(response, identification, myself) {
    Customer.delete_clientside(
      myself.app,
      identification,
      identification.customer_id
    );
  }
}
