export default class Note {
  constructor(text, icon) {
    this.text = text;
    this.id = Note.next_id();
    this.time = Date.now();
    this.closed = false;

    if (icon) {
      this.icon = icon;
    } else {
      this.icon = Note.default_icon;
    }
  }

  static next_id() {
    if (!Note.id_counter) {
      Note.id_counter = 0;
    }
    Note.id_counter += 1;
    return Note.id_counter;
  }

  static get default_icon() {
    return "Info";
  }
}
