import { APIBase } from "./apibase";
import { Zone } from "@/items/zone";
import RequestData from "./requestdata";

export class ZoneAPI extends APIBase {
  list(
    project_id,
    callback,
    refresh_interval = global.default_refresh_interval
  ) {
    return this.send({
      command: "projects/" + project_id + "/zones",
      method: "GET",
      on_receive: this.receive_array,
      refresh_interval: refresh_interval,
      identification: {
        project_id: project_id
      },
      custom_callback: callback
    });
  }

  fetch(
    project_id,
    zone_id,
    refresh_interval = global.default_refresh_interval
  ) {
    var cmd = "";
    cmd += "projects/" + project_id.toString();
    cmd += "/zones/" + zone_id.toString();
    return this.send({
      command: cmd,
      method: "GET",
      on_receive: this.receive,
      refresh_interval: refresh_interval,
      identification: {
        project_id: project_id,
        zone_id: zone_id
      }
    });
  }

  create(project_id, zone) {
    return this.send({
      command: "projects/" + project_id + "/zones",
      method: "POST",
      identification: {
        project_id: project_id
      },
      on_receive: this.receive,
      post_json: zone
    });
  }

  update(project_id, zone_id, changes, options) {
    let req = new RequestData(
      `projects/${project_id}/zones/${zone_id}`,
      options
    );
    req.method = "PUT";
    req.identification = { project_id, zone_id };
    req.on_receive = this.receive;
    req.post_json = changes;
    return this.send(req);
  }

  del(project_id, zone_id, options) {
    let req = new RequestData(
      `projects/${project_id}/zones/${zone_id}`,
      options
    );
    req.method = "DELETE";
    req.identification = { project_id, zone_id };
    req.on_receive = this.receive_delete;
    return this.send(req);
  }

  receive_array(response, identification, myself) {
    response.forEach(function(el) {
      Zone.update_or_create_from_dto(el, myself.app, identification);
    });
  }

  receive(response, identification, myself) {
    Zone.update_or_create_from_dto(response, myself.app, identification);
  }

  receive_delete(response, identification, myself) {
    Zone.delete_clientside(myself.app, identification, identification.zone_id);
  }
}
