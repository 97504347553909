<template>
  <div class="NotesAndErrorsPanel">
    <!-- ERRORS -->
    <div v-if="$ui.error_msgs.length > 0">
      <ErrorPanel
        @close="closeErr()"
        v-for="err in $ui.error_msgs"
        :key="err.time"
        :error="err"
        v-show="!err.closed"
      />
    </div>
    <!-- NOTES -->
    <div v-if="$ui.notes.length > 0">
      <NotePanel
        @close="closeNote()"
        v-for="note in $ui.notes"
        :key="note.time"
        :note="note"
        v-show="!note.closed"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: "NotesAndErrorsPanel",
  methods: {
    closeErr() {
      for (let err of this.$ui.error_msgs) {
        err.closed = true;
      }
    },
    closeNote() {
      for (let note of this.$ui.notes) {
        note.closed = true;
      }
    }
  }
}
</script>
