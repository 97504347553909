<template>
  <div class="wm_title_bar">
    <span v-if="prefix">{{ prefix }}{{ $txt.title_divider }}</span> {{ title }}
    <Btn
      v-if="enable_give_access_to_support"
      :title="$txt.give_access_to_support"
      @clicked="give_access_to_support()"
      style="float: right;margin-top: -3px"
    />
    <Btn
      :title="$txt.open_this_location_in_new_tab"
      :hintText="$txt.hint_open_this_location_in_new_tab"
      @clicked="open_in_new_tab()"
      style="float: right;margin-top: -3px"
    />
    <Btn
      :title="$txt.open_this_location_in_new_window"
      :hintText="$txt.hint_open_this_location_in_new_window"
      @clicked="open_in_new_window()"
      style="float: right;margin-top: -3px"
    />
  </div>
</template>

<script>
export default {
  name: "TitleBar",
  data() {
    return {};
  },

  props: {
    title: String,
    prefix: String,
    enable_give_access_to_support: Boolean
  },

  methods: {
    give_access_to_support() {
      this.$emit("give_access_to_support");
    },
    open_in_new_tab() {
      let url = window.location.href;
      window.open(url);
    },
    open_in_new_window() {
      let url = window.location.href;
      var w = window.innerWidth;
      var h = window.innerHeight;
      window.open(url, "", "width=w, height=h");
    },
  },

  created: function() {}
};
</script>
