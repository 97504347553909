<template>
  <a
    class="red_btn btn "
    :id="btnid"
    :disabled="disabled"
    :title="hintText"
    :class="{ wm_tight_dropdown: tight }"
    @click="click()"
  >
    {{ title }}
  </a>
</template>

<script>
export default {
  name: "BaseBtn",
  emits: ['clicked'],
  data() {
    return {};
  },

  props: {
    title: String,
    hintText: String,
    disabled:{
      type: Boolean,
      default: null
    },
    tight: {
      type: Boolean,
      default: false
    },
    btnid: {
      type: String,
      default: null
    }
  },

  methods: {
    click() {
      if (!this.disabled) {
        this.$emit("clicked");
      }
    }
  },

  created: function() {}
};
</script>
