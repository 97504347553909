<template>
  <ResponsiveTable :no_data="rows.length <= 0">
    <tr v-for="row in rows" :key="row[0].value">
      <td v-for="cell in row" :key="cell">{{ cell }}</td>
    </tr>
  </ResponsiveTable>
</template>

<script>
export default {
  name: "DataTable",
  data() {
    return {};
  },

  props: {
    rows: Array
  },

  methods: {},

  created: function() {}
};
</script>
