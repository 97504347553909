<template lang="html">
  <div>
    <NoDataLabel v-if="mode === 'can_not_setup_node'"
    :text="$txt.no_node_device_assigned" />

    <NoDataLabel v-if="mode === 'can_not_setup_cntrl'"
    :text="$txt.no_device_assigned" />

    <NoDataLabel v-if="mode === 'node_no_cntrl_assigned'"
    :text="$txt.no_device_is_assigned_cntrl_or_node" />

    <div class="none_label" v-if="mode === 'node_unlock_padlock'">
      <PadlockToggle v-model="$app.sltd.project.app_prop.edit_lock" />
      <span class="no_data_text"> {{ $txt.no_node_device_assigned }} </span>
      <p class="no_data_text">{{ $txt.press_the_padlock_to_assign_a_node }}</p>
    </div>

    <div class="none_label" v-if="mode === 'cntrl_unlock_padlock'">
      <PadlockToggle v-model="$app.sltd.project.app_prop.edit_lock" />
      <div v-if="available_cntrl_devices.length">
        <span class="no_data_text"> {{ $txt.no_device_assigned }}</span>
        <p class="no_data_text">{{ $txt.press_the_padlock_to_assign_a_controller_device }}</p>
      </div>
      <div v-else>
        <span class="no_data_text">{{ $txt.no_controller_assigned }}</span>
        <p class="no_data_text">{{ $txt.press_the_padlock_to_assign_a_controller_device }}</p>
      </div>
    </div>

    <div class="none_label" v-if="mode === 'cntrl_click_to_assign'">
      <PadlockToggle v-model="$app.sltd.project.app_prop.edit_lock" />
      <span class="no_data_text"> {{ $txt.no_controller_assigned }} </span>
      <br />
      <div v-if="available_cntrl_devices.length">
        <Btn
        :title="$txt.click_to_assign_controller_device"
        @clicked="show_cntrl_modal = true"
        v-if="!$app.sltd.project.edit_locked"
        style="margin-top: 10px"
        :id="btnid"
        />
      </div>
      <div v-else>
        <Btn
          :title="$txt.claim_new_controller"
          :disabled="show_claim_modal  ? true : null"
          @clicked="show_claim_modal = true"
        ></Btn>
      </div>
    </div>

    <div class="none_label" v-if="mode === 'node_click_to_assign'">
      <PadlockToggle v-model="$app.sltd.project.app_prop.edit_lock" />
      <span class="no_data_text">{{ $txt.no_node_device_assigned }}</span>
      <br />
      <Btn
        :title="$txt.click_to_assign_node_device"
        @clicked="show_node_modal = true"
        v-if="!$app.sltd.project.edit_locked"
        style="margin-top: 10px"
        :id="btnid"
      />
    </div>

    <Modal
      :title="$txt.click_device_to_assign"
      v-if="show_cntrl_modal"
      @close="show_cntrl_modal = false"
    >
      <div v-if="!available_cntrl_devices.length">
        <NoDataLabel text="No available controller devices"> </NoDataLabel>
      </div>
      <ControllerHardware
        class="wm_centered"
        v-else
        v-for="device in available_cntrl_devices"
        :clickmode="true"
        :key="device.dto.serialNumber"
        :controller="device"
        @click="
          item.assign_device(device);
          show_cntrl_modal = false;
        "
      />
    </Modal>

    <Modal
      :title="$txt.click_device_to_assign"
      v-if="show_node_modal"
      @close="show_node_modal = false"
    >
      <div v-if="!available_node_devices.length">
        <NoDataLabel text="No available node devices"> </NoDataLabel>
      </div>
      <DeviceRow
        class="wm_centered"
        v-else
        v-for="device in available_node_devices"
        :clickmode="true"
        :key="device.dto.serialNumber"
        :device="device"
        @click="
          item.assign_device(device.dto.serialNumber);
          show_node_modal = false;
        "
      />
    </Modal>

    <Modal
      :title="$txt.claim_new_controller"
      :width="250"
      v-if="show_claim_modal"
      @close="show_claim_modal = false"
    >
    <template v-slot:default>
      <LabeledInput v-model="claim_serial" :label="$txt.serial_no" />
       <br />
       <LabeledInput v-model="claim_checksum" :label="$txt.checksum" />
    </template>

    <template v-slot:footer>
  <div>
    <Btn
      :title="$txt.claim"
      @clicked="claim_cntrl()"
      :disabled="(!claim_serial.length || !claim_checksum.length) ? true : null"
    />
    <Btn
      :title="$txt.cancel"
      class="hol_btn"
      @clicked="show_claim_modal = false"
    />
  </div>
</template>
    </Modal>
  </div>
</template>

<script>
import ControllerHardware from "@/components/project/ControllerHardware";
import DeviceRow from "@/components/cntrl/DeviceRow";

export default {
  name: "NoDeviceLabel",

  components: {
    ControllerHardware,
    DeviceRow,
  },

  data() {
    return {
      show_cntrl_modal: false,
      show_node_modal: false,
      show_claim_modal: false,
      claim_checksum: "",
      claim_serial: "",
    };
  },
  props: {
    item: {
      type: Object,
    },
    kind: {
      type: String,
      default: "Controller",
    },
    btnid: {
      type: String,
      default: null,
    },
    assign_allowed: {
      type: Boolean,
      default: true,
    },
  },

  computed: {
    mode() {
      let padlock = this.$app.sltd.project.edit_locked;
      let can_setup =
        this.$app.sltd.project.dto.canSetup && this.assign_allowed;
      // NODE
      if (this.kind === "Node") {
        // - Allowed to setup?
        if (!can_setup) return "can_not_setup_node";
        // No controller?
        let node = this.$app.sltd.node;
        if (node.dto.controllerId === null || node.get_cntrl().device === null)
          return "node_no_cntrl_assigned";
        // - Padlock
        if (padlock) return "node_unlock_padlock";
        // - Default
        return "node_click_to_assign";
      }
      // Controller
      // - Allowed to setup?
      if (!can_setup) return "can_not_setup_cntrl";
      // - Padlock
      if (padlock) return "cntrl_unlock_padlock";
      // - Default
      return "cntrl_click_to_assign";
    },

    available_cntrl_devices() {
      return this.$app.sltd.project.cntrl_devices.filter(
        (device) => device.app_prop.cntrl == null
      );
    },

    available_node_devices() {
      if (this.$app.sltd.cntrl == null) return [];
      if (this.$app.sltd.cntrl.device == null) return [];
      return this.$app.sltd.cntrl.device.node_devices
        .filter(
          (device) =>
            device.capability == this.item.capability && device.dto.connected
        )
        .sort((a, b) => (a.serial > b.serial ? 1 : -1));
    },
  },
  methods: {
    claim_cntrl() {
      this.add_ctrl_mode = false;
      this.$appm.reset_errors_and_notes();

      if (!parseInt(this.claim_serial)) {
        this.$appm.show_note(this.$txt.serial_must_be_number);
        return;
      }
      if (this.claim_checksum.length < 1) {
        this.$appm.show_note(this.$txt.checksum_cant_be_empty);
        return;
      }

      var data = {
        projectId: this.$app.sltd.project.id,
        checksum: this.claim_checksum,
        serialNumber: this.claim_serial,
      };
      this.$net.cntrl.claim(data);
    },
  },
};
</script>
