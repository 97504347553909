import RequestHelper from "./requesthelper";

export default class NetworkQueue {
  constructor(net, queue_interval) {
    this.net = net;
    this.send_queue = [];
    this.arrive_queue = [];
    this.recently_sent = [];
    window.setInterval(this.send_queued_requests, queue_interval, this);
    window.setInterval(this.apply_queued_responses, queue_interval, this);
  }

  send_queued_requests(me) {
    while (me.send_queue.length > 0) {
      var request = me.send_queue.shift();
      me.send_request(request.data);
      request.on_send();
      me.recently_sent.push(request);
    }
    me.remove_nonrecent_from_recently_sent(me);
  }

  send_request(request) {
    request.xhr = this.net.send_request(request);
  }

  remove_nonrecent_from_recently_sent(me) {
    var n = me.recently_sent.filter(req => req.was_sent_recently());
    me.recently_sent = n;
  }

  apply_queued_responses(me) {
    while (me.arrive_queue.length > 0) {
      me.arrive_queue.shift();
    }
  }

  clear() {
    this.send_queue = [];
    this.arrive_queue = [];
    this.recently_sent = [];
  }

  push_to_send_queue(request) {
    var req = new RequestHelper(request);

    // Check if this call has been done
    // or is already in the queue

    if (!this.request_necessary(req)) {
      return "not_necessary";
    }

    // Find and push any prerequsites
    this.push_prerequisites(req);

    this.send_queue.push(req);
    return req;
  }

  push_prerequisites(req) {
    // If it's a delete call, we don't depend on
    // having anything in our data structure to
    // receive the answer
    if (req.data.method === "DELETE") return;

    if (req.depend_on_customer()) {
      this.net.customer.fetch(req.data.identification.customer_id);
    }

    if (req.depend_on_project()) {
      this.net.project.fetch(req.data.identification.project_id);
    }

    if (req.depend_on_zone()) {
      this.net.zone.fetch(
        req.data.identification.project_id,
        req.data.identification.zone_id
      );
    }

    if (req.depend_on_node()) {
      this.net.node.fetch(
        req.data.identification.project_id,
        req.data.identification.zone_id,
        req.data.identification.node_id
      );
    }
  }

  request_necessary(req) {
    if (req.data.method != "GET") return true;
    if (req.data.refresh_interval == 0) return true;

    // Already in sent queue?
    for (let i = 0; i < this.send_queue.length; i++) {
      if (req.equivialent_with(this.send_queue[i])) {
        return false;
      }
    }
    // Was recently sent?
    for (let i = 0; i < this.recently_sent.length; i++) {
      if (req.equivialent_with(this.recently_sent[i])) {
        return (
          this.recently_sent[i].time_since_send() > req.data.refresh_interval
        );
      }
    }

    return true;
  }
}
