<template>
  <div class="labeled-control-full-width">
    <p :class="{ wm_label_disabled: disabled }">{{ label }}</p>
    <textarea
      class="wm_textarea wm_input"
      :type="type"
      rows="4"
      v-bind:value="modelValue"
      :placeholder="placeholder"
      :disabled="disabled"
      :class="{ wm_input_disabled: disabled }"
      v-on:input="$emit('update:modelValue', $event.target.value)"
      autocomplete="off"
    >
    </textarea>
  </div>
</template>

<script>
export default {
  emits: ['update:modelValue'],
  name: "LabeledTextArea",
  data() {
    return {};
  },

  props: {
    label: {
      type: String
    },
    placeholder: {
      type: String,
      default: ""
    },
    modelValue: {},
    type: {
      type: String
    },
    disabled: {
      type: Boolean
    }
  },

  methods: {},

  created: function() {}
};
</script>
