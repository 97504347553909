/* The EventLogItem corresponds with one event in the EventLog (parent)
 *
 */
import { WMBaseItem } from "./baseitem";
export class EventLogItem extends WMBaseItem {
  get type() {
    return this.dto.type;
  }

  get code() {
    return this.dto.code;
  }

  get level() {
    return this.dto.level;
  }

  get message() {
    return this.dto.message;
  }

  get data() {
    return this.dto.data;
    // return {test:"explained!"};
  }

  get timestamp() {
    return this.dto.timestamp;
  }
  static new_item_instance(dto, identification) {
    return new EventLogItem(dto, identification);
  }
  // eslint-disable-next-line
    static find_parent(app, identification) {
    return app.event_log;
  }
}
