import { APIBase } from "./apibase";

export class ServerLogAPI extends APIBase {
  list(
    level,
    after,
    max_rows,
    custom_callback,
    refresh_interval = global.default_refresh_interval
  ) {
    return this.send({
      command:
        "serilog?level=" +
        level +
        "&start=" +
        encodeURIComponent(after) +
        "&maxRows=" +
        max_rows,
      method: "GET",
      identification: {},
      on_receive: this.receive_array,
      refresh_interval: refresh_interval,
      custom_callback: custom_callback
    });
  }

  receive_array(response, identification, myself) {
    myself.app.server_log = response.messages;
  }
}
