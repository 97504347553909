<template>
  <div class="DebugControls" v-show="$app.settings.developer_mode">
    <NotesAndErrorsPanel />

    <DropDown
      title=""
      v-model="debugaspect"
      :opts="[
        { id: 'websockets' },
        { id: 'encodeserials' },
        { id: 'api' },
        { id: 'cntrl' },
        { id: 'cntrl_settings' },
        { id: 'msgpack' },
        { id: 'resetmail' },
        { id: 'datestring' },
        { id: 'localstorage' },
        { id: 'drainnodes' },
        { id: 'cpdata' },
        { id: 'netqueue' },
        { id: 'node_inspect' },
        { id: 'misc' }
      ]"
    />
    <br />

    <Section title="Websockets" v-if="debugaspect === 'websockets'">
      <div v-if="$msg != null">
        <LabeledInput v-model="socket_type" />
        <LabeledInput v-model="socket_id" />
        <Btn title="Send to socket" @click="ws_send()" />
      </div>
      <Btn title="Open socket" @click="open_web_socket()" />
    </Section>

    <DebugNetQueueView v-if="debugaspect === 'netqueue'" />

    <Section title="misc" v-if="debugaspect === 'misc'">
      <Btn
        class="hol_btn"
        title="on_signature_expired"
        @click="this.$app.on_signature_expired"
      />

      <Btn
        class="hol_btn"
        title="delete selected zone"
        @click="$net.zone.del($app.sltd.prj_id, $app.sltd.zone_id)"
      />
    </Section>

    <Section title="cntrl_settings" v-if="debugaspect === 'cntrl_settings'">
      <NumericInput label="project id" v-model="cntrl_settings_project_id" />
      <NumericInput label="cntrl id" v-model="cntrl_Settings_cntrl_id" />
      <LabeledTextArea
        label="cntrl_settings_json"
        placeholder="{}"
        v-model="cntrl_settings_json"
      />
      <br />

      <Btn
        @click="
          $net.cntrl.update_schedule(
            cntrl_settings_project_id,
            cntrl_Settings_cntrl_id,
            JSON.parse(cntrl_settings_json)
          )
        "
        title="update_schedule"
      />
      <Btn
        @click="
          $net.cntrl.update_monitor_settings(
            cntrl_settings_project_id,
            cntrl_Settings_cntrl_id,
            JSON.parse(cntrl_settings_json)
          )
        "
        title="update_monitor_settings"
      />
      <Btn
        @click="
          $net.cntrl.update_lpr_settings(
            cntrl_settings_project_id,
            cntrl_Settings_cntrl_id,
            JSON.parse(cntrl_settings_json)
          )
        "
        title="update_lpr_settings"
      />
      <Btn
        @click="
          $net.cntrl.update_decay_settings(
            cntrl_settings_project_id,
            cntrl_Settings_cntrl_id,
            JSON.parse(cntrl_settings_json)
          )
        "
        title="update_decay_settings"
      />
    </Section>

    <Section title="cpdata" v-if="debugaspect === 'cpdata'">
      <NumericInput label="id" v-model="cp_data_id" />
      <NumericInput label="project id" v-model="cp_project_id" />
      <NumericInput label="off" v-model="cp_off" />
      <NumericInput label="lpr" v-model="cp_lpr" />
      <NumericInput label="area" v-model="cp_area" />

      <br />
      <Btn
        title="fetch_cpdata"
        @click="$net.cp_data.fetch(cp_project_id, cp_data_id)"
      />
      <RedBtn
        title="delete_cpdata"
        @click="$net.cp_data.del(cp_project_id, cp_data_id)"
      />
      <Btn
        title="post_cpdata"
        @click="
          $net.cp_data.post(cp_project_id, {
            preCommOff: cp_off,
            preCommLPR: cp_lpr,
            area: cp_area
          })
        "
      />
      <Btn
        title="update_cpdata"
        @click="
          $net.cp_data.update(cp_project_id, cp_data_id, {
            preCommOff: cp_off,
            preCommLPR: cp_lpr,
            area: cp_area
          })
        "
      />
    </Section>

    <Section title="encodeserials" v-if="debugaspect === 'encodeserials'">
      <LabeledInput label="es_serial_number" v-model="es_serial_number" />
      <LabeledInput label="es_bytes" v-model="es_bytes" />
      <LabeledInput label="es_base_64" v-model="es_base_64" />
      <br />
      <Btn
        title="serial to byte array"
        @click="es_bytes = $util.num_to_bytes(es_serial_number)"
      />
      <Btn
        title="byte array to base64"
        @click="es_base_64 = $util.bytes_to_base64(es_bytes)"
      />

      <Btn
        title="byte array to serial"
        @click="es_serial_number = $util.bytes_to_num(es_bytes)"
      />
      <Btn
        title="base64 to byte array"
        @click="es_bytes = $util.base64_to_bytes(es_base_64)"
      />
      <br />
      <Btn
        title="encode serial"
        @click="es_base_64 = $util.encode_serial_base64(es_serial_number)"
      />
      <Btn
        title="decode serial"
        @click="es_serial_number = $util.decode_serial_base64(es_base_64)"
      />
    </Section>

    <Section title="cntrl" v-if="debugaspect === 'cntrl'">
      <LabeledInput label="zc_serial_number" v-model="zc_serial_number" />
      <LabeledInput label="zc_checksum" v-model="zc_checksum" />
      <LabeledInput label="zc_online_status" v-model="zc_online_status" />
      <NumericInput label="zc_prj_id" v-model="zc_prj_id" />
      <Btn title="post_cntrl" @click="post_cntrl()" />
      <Btn title="claim_cntrl" @click="claim_cntrl()" />
      <Btn title="revoke_cntrl" @click="revoke_cntrl()" />
      <Btn title="list_cntrl" @click="list_cntrls()" />
      <Btn title="fetch_cntrl" @click="fetch_cntrl()" />
      <Btn title="add_fake_cntrl" @click="add_fake_cntrl()" />

      <ul>
        <li v-for="z in cntrls_of_prj" :key="z.serial">
          Serial: {{ z.serial }} Checksum: {{ z.checksum }}
        </li>
      </ul>
    </Section>

    <Section title="msgpack" v-if="debugaspect === 'msgpack'">
      <Btn title="encode_msgpack" @click="encode_msgpack()" />
      <Btn title="decode_msgpack" @click="decode_msgpack()" />
      <LabeledInput label="msgpack" v-model="msgpack_result" />
    </Section>

    <Section title="msgpack" v-if="debugaspect === 'msgpack'"> </Section>

    <Section title="resetmail" v-if="debugaspect === 'resetmail'">
      <LabeledInput label="mail" v-model="mail" />
      <Btn title="send_reset_mail" @click="send_verification()" />
    </Section>

    <Section title="datestring" v-if="debugaspect === 'datestring'">
      <LabeledDateInput label="t" v-model="date_string" />
      {{ date_string }}
      --- {{ $util.to_iso_datetime(date_string) }}
    </Section>

    <Section title="localstorage" v-if="debugaspect === 'localstorage'">
      <LabeledInput label="String to save" v-model="storage" />
      <Btn title="test_local_storage" @click="test_local_storage()" />
    </Section>

    <Section title="drain nodes" v-if="debugaspect === 'drainnodes'">
      <Btn title="test_drain_nodes" @click="test_drain_nodes(1, 1)" />
      <Btn title="503" @click="$app.server_temporarily_unavailable()" />
    </Section>

    <Section title="Inspect node" v-if="debugaspect === 'node_inspect'">
      <h3>Diff</h3>
      <pre class="code">
        {{ format_json($app.sltd.primary.changes()) }}
      </pre>
      <h3>DTO</h3>
      <pre class="code">
        {{ format_json($app.sltd.primary.dto) }}
      </pre>
      <h3>Locals</h3>
      <pre class="code">
        {{ format_json($app.sltd.primary.locals) }}
      </pre>
      <h3>App prop</h3>
      <pre class="code" v-if="$app.sltd.primary.id != $app.sltd.cntrl.id">
        {{ format_json($app.sltd.primary.app_prop) }}
      </pre>
    </Section>

    <Section title="API tests" v-if="debugaspect === 'api'">
      <Btn title="test_recording_info" @click="test_recording_info()" />
      <Btn title="test_formula" @click="test_formula()" />
      <Btn title="test_node" @click="test_node()" />
      <Btn title="test_zone" @click="test_zone()" />
      <Btn title="test_channels" @click="test_channels()" />

      <LabeledInput label="project_id" v-model="project_id" />
      <LabeledInput label="zone_id" v-model="zone_id" />
      <LabeledInput label="node_id" v-model="node_id" />
      <LabeledInput label="channel_id" v-model="channel_id" />

      <LabeledCheckbox label="create" v-model="create" />
      <LabeledCheckbox label="fetch" v-model="fetch" />
      <LabeledCheckbox label="update" v-model="update" />
      <LabeledCheckbox label="delete" v-model="delet" />
    </Section>
  </div>
</template>

<script>
import { encode, decode } from "msgpack-lite";

export default {
  name: "DebugControls",

  props: {
    starting_page: {
      default: "api"
    }
  },

  mounted() {
    this.debugaspect = this.starting_page;
  },
  //
  data() {
    return {
      debugaspect: "api",

      mail: "oskar.lundqvist@protector.no",
      date_string: "",
      substr: "ABC$1def$2",
      arr: ["Äpple", "Päron", "Piron", "Paron"],
      storage: "def",
      node_type: 0,
      project_id: 1,
      zone_id: 1,
      node_id: 24,
      channel_id: 0,
      msgpack_result: "none",

      es_serial_number: 394204,
      es_bytes: "",
      es_base_64: "",

      zc_serial_number: 394204,
      zc_checksum: "testchecksum",
      zc_online_status: 0,
      zc_prj_id: 1,

      cp_data_id: 1,
      cp_project_id: 1,
      cp_off: 1,
      cp_lpr: 1,
      cp_area: 1,

      cntrl_Settings_cntrl_id: 1,
      cntrl_settings_project_id: 1,
      cntrl_settings_json:
        '{  "next": "2019-10-17T12:10:39.114Z", "repeatEvery": 0,  "repeateUnit": 0 }',

      create: false,
      fetch: false,
      update: false,
      delet: false,

      socket: null,
      socket_type: 4,
      socket_id: 5
    };
  },

  methods: {
    open_web_socket() {
      this.$app.msg_manager.hub.subscribe("project", this.$app.sltd.prj_id);
    },

    ws_send(msg) {
      this.socket.send(msg);
    },

    ws_receive(event) {
      this.socket_receive = event.data + "2";
    },

    claim_cntrl() {
      var data = {
        projectId: this.zc_prj_id,
        checksum: this.zc_checksum,
        serialNumber: this.zc_serial_number
      };

      this.$net.cntrl.claim(data);
    },

    revoke_cntrl() {
      var data = {
        projectId: this.zc_prj_id,
        checksum: this.zc_checksum,
        serialNumber: Number(this.zc_serial_number)
      };

      this.$net.cntrl.revoke(data);
    },

    fetch_cntrl() {
      this.$net.cntrl.fetch(this.zc_serial_number, this.zc_checksum);
    },

    post_cntrl() {
      var data = {
        name: "Ny cntrl",
        project_id: this.zc_prj_id,
        onlineStatus: 0,
        serialNo: this.zc_serial_number
      };
      this.$net.cntrl.post(data);
    },

    list_cntrls() {
      this.$net.cntrl.list(this.zc_prj_id);
    },

    encode_msgpack() {
      this.msgpack_result = encode(JSON.parse(this.msgpack_result));
    },
    decode_msgpack() {
      this.msgpack_result = JSON.stringify(decode(this.msgpack_result));
    },

    test_drain_nodes(project_id, zone_id) {
      this.$app.drain_node_array(project_id, zone_id);
    },

    test_channels() {
      var input = {
        no: 1,
        cpDataId: 0,
        posX: 100,
        posY: 200,
        posZ: 300
      };

      // eslint-disable-next-line
      var changes = {
        posX: 15,
        posY: 30,
        posZ: 45
      };

      var output = {
        no: 2,
        cpDataId: 0,
        mode: 0,
        voltage: 0,
        current: 0,
        outputOn: true,
        remote: true,
        senseGuardEnabled: true,
        fallback: true
      };

      if (this.create) {
        this.$net.channel.input_create(
          this.project_id,
          this.zone_id,
          this.node_id,
          input
        );
        this.$net.channel.output_create(
          this.project_id,
          this.zone_id,
          this.node_id,
          output
        );
      }
      if (this.update) {
        // NOT IMPLEMENTED ON SERVER
      }
      if (this.fetch) {
        this.$net.channel.get(
          this.project_id,
          this.zone_id,
          this.node_id,
          this.channel_id
        );
      }
      if (this.delet) {
        this.$net.channel.delete(
          this.project_id,
          this.zone_id,
          this.node_id,
          this.channel_id
        );
      }

      this.$net.channel.list(this.project_id, this.zone_id, this.node_id);
    },

    test_node() {
      var node_data = {
        id: 0,
        zoneId: this.zone_id,
        serialNo: 655123,
        type: 14,
        versionMajor: 3,
        versionMinor: 3,
        active: true,
        connected: true,
        posX: 0,
        posY: 0,
        posZ: 0,
        sectionNo: 0,
        name: "P-Quick #1"
      };
      var changes = {
        name: "P-Quick #2",
        posX: 11
      };

      this.$net.node.list(this.project_id, this.zone_id);

      if (this.create) {
        this.$net.node.create(this.project_id, this.zone_id, node_data);
      }
      if (this.fetch) {
        this.$net.node.get(this.project_id, this.zone_id, this.node_id);
      }
      if (this.update) {
        this.$net.node.update(
          this.project_id,
          this.zone_id,
          this.node_id,
          changes
        );
      }
      if (this.delet) {
        this.$net.node.del(this.project_id, this.zone_id, 4);
      }
    },

    test_zone() {
      var zone_data = {
        id: 0
      };

      var changes = {
        name: "Zon @"
      };

      this.$net.zone.list(this.project_id, this.zone_id);

      if (this.create) {
        this.$net.zone.create(this.project_id, zone_data);
      }
      if (this.fetch) {
        this.$net.zone.get(this.project_id, this.node_id);
      }
      if (this.update) {
        this.$net.zone.update(this.project_id, this.zone_id, changes);
      }
      if (this.delet) {
        this.$net.zone.del(this.project_id, this.zone_id);
      }
    },

    format_json(obj) {
      let json = JSON.stringify(obj, undefined, 2).trim();
      return json.trim();
    },

    test_recording_info() {
      var project_id = 1;
      var zone_id = 1;
      var rec_id = 2;
      var rec_info = {
        start: "2019-01-15T09:33:26.272Z",
        stop: "2019-01-15T09:33:26.272Z",
        powerOffDelay: 0,
        iniDuration: 0,
        iniInterval: 0,
        duration: 0,
        interval: 0
      };
      var changes = {
        interval: 23
      };
      var create = false;
      var update = true;

      this.$net.recording_info.prj_list(project_id);
      this.$net.recording_info_decay.prj_list(project_id);
      this.$net.recording_info_monitor.prj_list(project_id);
      this.$net.recording_info_lpr.prj_list(project_id);

      this.$net.recording_info.list(project_id, zone_id);
      this.$net.recording_info_decay.list(project_id, zone_id);
      this.$net.recording_info_monitor.list(project_id, zone_id);
      this.$net.recording_info_lpr.list(project_id, zone_id);

      if (create) {
        this.$net.recording_info_decay.create(project_id, zone_id, rec_info);
        this.$net.recording_info_monitor.create(project_id, zone_id, rec_info);
        this.$net.recording_info_lpr.create(project_id, zone_id, rec_info);
      }

      this.$net.recording_info.fetch(project_id, zone_id, rec_id);
      this.$net.recording_info_decay.fetch(project_id, zone_id, 3);
      this.$net.recording_info_monitor.fetch(project_id, zone_id, 2);
      this.$net.recording_info_lpr.fetch(project_id, zone_id, 1);

      if (update) {
        this.$net.recording_info_decay.update(project_id, zone_id, 3, changes);
        this.$net.recording_info_monitor.update(
          project_id,
          zone_id,
          2,
          changes
        );
        this.$net.recording_info_lpr.update(project_id, zone_id, 1, changes);
      }
    },

    send_verification() {
      this.$net.user.send_verification_email(this.mail);
    },

    test_formula() {
      var project_id = 1;
      var formula_id = 11;
      // eslint-disable-next-line
      var data = {
        gain: 0,
        offset: 0,
        unit: "mV"
      };

      var changes = {
        unit: "V"
      };

      // Delete

      // List formulas
      this.$net.formula.list(project_id, function() {});
      // Create formula

      // Fetch by id
      this.$net.formula.fetch(project_id, formula_id, function() {});
      // Update
      this.$net.formula.update(project_id, formula_id, changes, function() {});
    },

    test_local_storage() {
      alert(localStorage.name);
      localStorage.name = this.storage;
      alert(localStorage.name);
    }
  },

  computed: {
    cntrls_of_prj() {
      var prj = this.$app.get_project(1);
      if (prj == null) return [];
      else return prj.cntrls;
    }
  }
};
</script>
<style media="screen">
.code {
  background-color: rgb(31, 45, 61);
  color: rgb(222, 100, 0);
  font-size: 11px;
  font-weight: lighter;
  font-family: monospace;
  padding: 10px;
}

.DebugControls {
  overflow-y: scroll;
  max-height: 700px;
}
</style>
