import { WMBaseItem } from "./baseitem";
import Network from "@/networking";

export class Customer extends WMBaseItem {
  get alias() {
    return this.dto.alias;
  }
  get access_collections() {
    return this.children.access_collections;
  }
  get customer_projects() {
    return this.children.customer_projects;
  }

  get user_groups() {
    return this.children.user_groups;
  }

  get item_type() {
    return "customer";
  }

  children_template() {
    return {
      access_collections: [],
      customer_projects: []
    };
  }

  upload_changes() {
    return Network.customer.update(this.id, this.changes());
  }

  get_grants(app) {
    return app.grants.filter(grant => grant.customerId == this.id);
  }

  get_grants_matching_template(app, template) {
    return this.get_grants(app).filter(grant =>
      grant.matches_template(template)
    );
  }

  get_grants_matching_role(app, collectionId) {
    let grants = this.get_grants(app);
    return grants.filter(grant => grant.collectionId == collectionId);
  }

  get_user_role_groups(app) {
    return [
      this.get_user_role_group(app, "Customer Administrator"),
      this.get_user_role_group(app, "Customer User"),
      this.get_user_role_group(app, "Customer Viewer")
    ];
  }

  get_role_access_collections(app, name) {
    return this.children.access_collections.filter(
      access_collection => access_collection.name == name
    );
  }

  get_user_role_group(app, name) {
    let group = new UserRoleGroup(name);
    let roleAccessCollections = this.get_role_access_collections(app, name);
    if (roleAccessCollections.length > 0) {
      group.collectionId = roleAccessCollections[0].id;
      group.canView = roleAccessCollections[0].canView;
      group.canReport = roleAccessCollections[0].canReport;
      group.canSetup = roleAccessCollections[0].canSetup;
      group.canDelete = roleAccessCollections[0].canDelete;
      group.canGrant = roleAccessCollections[0].canGrant;

      let grants = this.get_grants_matching_role(
        app,
        roleAccessCollections[0].id
      );
      group.from_grants_and_users(grants, app.users);
    }
//    console.log("User group:", name, " length:", group.users.length);
    return group;
  }

  static new_item_instance(dto, identification) {
    return new Customer(dto, identification);
  }

  // eslint-disable-next-line
  static find_parent(app, identification) {
    return app.customers;
  }

  // eslint-disable-next-line
  static find(app, id, identification) {
    return app.$appm.get_customer(id);
  }
}

class UserRoleGroup {
  constructor(roleName) {
    this.name = roleName;
    this.users = [];
    this.grants = [];
    this.collectionId = 0;
    this.canView = false;
    this.canReport = false;
    this.canSetup = false;
    this.canDelete = false;
    this.canGrant = false;
  }

  from_grants_and_users(grants, users) {
    let user_ids_wanted = [];
    for (let grant of grants) {
      user_ids_wanted.push(grant.userId);
      this.canView = grant.canView;
      this.canReport = grant.canReport;
      this.canSetup = grant.canSetup;
      this.canDelete = grant.canDelete;
      this.canGrant = grant.canGrant;
    }
    this.users = users.filter(user => user_ids_wanted.includes(user.id));
    this.grants = grants;
  }
}
