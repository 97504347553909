import { APIBase } from "./apibase";
import { Project } from "@/items/project";
import RequestData from "./requestdata";
import Network from "@/networking";

export class ProjectAPI extends APIBase {
  list() {
    let req = new RequestData("projects");
    req.on_receive = this.receive_array;
    return this.send(req);
  }
  diagnose(project_id) {
    let req = new RequestData(`projects/${project_id}/state/project_controller`);
    req.identification = { project_id: project_id };
    req.on_receive = this.receive_diagnostics;
    return this.send(req);
  }
  receive_array(response, identification, myself) {
    for (var i = 0; i < response.length; i++) {
      Project.update_or_create_from_dto(
        response[i],
        myself.app,
        identification
      );
      // Retrieve zones for each project
      // to evaluate alarm and alarmUnack
      Network.zone.list(response[i].id);
      // Retrieve controllers for each project
      // to evaluate lastSeen
      Network.cntrl.list(response[i].id);
    }
  }

  fetch(
    project_id,
    callback,
    refresh_interval = global.default_refresh_interval
  ) {
    let req = new RequestData(`projects/${project_id}`, callback);
    req.identification = { project_id: project_id };
    req.on_receive = this.receive;
    req.set_interval(refresh_interval);
    return this.send(req);
  }

  receive_diagnostics(response, identification, myself) {
  ;
  }


  receive(response, identification, myself) {
    Project.update_or_create_from_dto(response, myself.app, identification);
  }

  receive_created(response, identification, myself) {
    myself.app.$appm.show_note(`Project ${response.name} created`);
    Project.update_or_create_from_dto(response, myself.app, identification);
  }

  receive_delete(response, identification, myself) {
    Project.delete_clientside(
      myself.app,
      identification,
      identification.project_id
    );
  }

  receive_move(response, identification, myself) {
    Project.update_or_create_from_dto(response, myself.app, identification);8
  }

  create(customer_id, project, custom_callback) {
    let req = new RequestData(
      `customers/${customer_id}/projects`,
      custom_callback
    );
    req.identification = { customer_id: customer_id, name: project.name };
    req.on_receive = this.receive;
    req.method = "POST";
    req.post_json = project;
    req.custom_callback = custom_callback;
    return this.send(req);
  }

  update(customer_id, project_id, changes, custom_callback) {
    let req = new RequestData(`projects/${project_id}`, custom_callback);
    req.identification = { project_id: project_id };
    req.on_receive = this.receive;
    req.method = "PUT";
    req.post_json = changes;
    return this.send(req);
  }
  del(customer_id, project_id, options) {
    let req = new RequestData(
      `customers/${customer_id}/projects/${project_id}`,
      options
    );
    req.method = "DELETE";
    req.identification = { customer_id: customer_id, project_id: project_id };
    req.on_receive = this.receive_delete;
      // Request body is mandatory for this command
    req.post_json = {
      tag: "1"
    };
    return this.send(req);
  }
  move(customer_id, project_id, options)
  {
    let req = new RequestData(
      `customers/${customer_id}/projects/${project_id}/move`,
      options
    );
    req.method = "POST";
    req.identification = { customer_id: customer_id, project_id: project_id };
    req.on_receive = this.receive_move;
    req.post_json = options;
    return this.send(req);
  }
}
