<template>
  <div class="wm_compact_panel lock_panel note_panel">
    <TinyIcon :icon="$icon.locked" color="white" class="right_space" />
    <TinyIcon
      :icon="$icon.loading"
      color="white"
      class="right_space rotating"
    />
    <span v-if="label != ''">{{ label }}</span>
    <span v-else>{{ $txt.locked }}</span>
  </div>
</template>

<script>
export default {
  name: "LockPanel",
  props: {
    label: {
      type: String,
      default: ""
    }
  }
};
</script>

<style>
.lock_panel {
  color: white;
}

.right_space {
  margin-right: 8px;
}

@keyframes rotating {
  from {
    transform: rotate(360deg);
    -o-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
  }
  to {
    transform: rotate(0deg);
    -o-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
  }
}
@-webkit-keyframes rotating {
  from {
    transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
  }
  to {
    transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
  }
}
.rotating {
  -webkit-animation: rotating 1s linear infinite;
  -moz-animation: rotating 1s linear infinite;
  -ms-animation: rotating 1s linear infinite;
  -o-animation: rotating 1s linear infinite;
  animation: rotating 1s linear infinite;
}
</style>
