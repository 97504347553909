<template lang="html">
  <div
    v-if="false"
    :class="{
      drop_btn: dragging_something_accepted,
      invisible: dragging_something_else,
      project_tree_drop_area_hover: hovered && dragging_something_accepted
    }"
    @dragover="drag($event)"
    @dragleave="dragstop()"
    @drop="handle_drop($event)"
    class="ProjectTreeBtn"
  >
    <p class="project_tree_hidden_zone_text">
      <span v-if="add">+ </span>{{ title }}
    </p>
  </div>
</template>

<script>
export default {
  name: "ProjectTreeBtn",
  data() {
    return {
      hovered: false
    };
  },
  props: {
    title: {
      type: String,
      default: "Title"
    },
    add: {
      type: Boolean,
      default: false
    },
    accept_drop_type: {
      type: Array,
      default: () => []
    },

    dragged_type: {
      type: String,
      default: "none"
    },

    accept_type: {
      type: Number,
      default: null
    },

    cntrl_device_id: {
      type: Number,
      default: null
    }
  },

  methods: {
    drag(ev) {
      if (this.dragging_something_accepted) {
        ev.preventDefault();
      }
      this.hovered = true;
    },

    dragstop() {
      this.hovered = false;
    },

    handle_drop(ev) {
      if (this.dragging_something_accepted) {
        ev.preventDefault();
        this.hovered = false;
        this.$emit("drop_on_btn");
      }
      this.$app.dragged_item = null;
    }
  },

  computed: {
    dragging_something_accepted() {
      // TODO: Can not drop a node on a new zone of it is under another controller
      if (this.virtual_drag) {
        return this.accept_drop_type.includes(this.dragged_type);
      }

      if (this.accept_drop_type.includes("nodedevice")) {
        let same_cntrl_device =
          this.cntrl_device_id == this.dragged_controller_serial;
        if (!same_cntrl_device) return false;
      }
      return this.accept_drop_type.includes(this.dragged_type);
    },

    dragging_something_else() {
      return this.dragged_type != "none" && !this.dragging_something_accepted;
    },

    dragged_controller_serial() {
      if (this.$app.dragged_item == null) return null;
      return this.$app.dragged_item.dto.controllerSerialNumber;
    },

    virtual_drag() {
      return this.dragged_type.startsWith("virtual");
    }
  }
};
</script>

<style lang="css" scoped>
.ProjectTreeBtn{
  width: 100%;
  margin-top: 3px;
  padding: 2px;
  padding-left: 30px;
}

.drop_btn {
  color: black!important;
  font-weight: bold!important;
}

.f{
  border-top: 1px solid rgba(0, 0, 0, .1);
}
.ProjectTreeBtn:hover {
  background-color: rgba(0, 0, 0, .1);
  text-decoration: underline;
}
.ProjectTreeBtn:active {
  background-color: rgba(0, 0, 0, .15);
  text-decoration: underline;
}
</style>
