import { app } from "../main";
import { WMBaseItem } from "./baseitem";

export class Firmware extends WMBaseItem {
  get item_type() {
    return "firmware";
  }
  get version() {
         // Leading 0
    let versionMinor = String(this.dto.versionMinor).padStart(2, '0');
    return this.dto.versionMajor + "." + versionMinor;
  }


  static new_item_instance(dto, identification) {
    return new Firmware(dto, identification);
  }

  // eslint-disable-next-line
  static find_parent(app, identification) {
    return app.firmware;
  }

  // eslint-disable-next-line
  static find(app, id, identification) {
    return app.$appm.get_firmware(id);
  }

}
