<template>
  <div class="SuccessPage">
    <div class="centered-holder">
      <div class="centered-area content">
        <h2>{{ message }}</h2>
        <hr />
        <img width="50" src="@/assets/success.svg" />
        <div style="margin-bottom: 60px;"></div>
        <router-link :to="{ name: 'login' }">{{
          $txt.back_to_log_in
        }}</router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SuccessPage",

  computed: {
    matype() {
      return this.$route.params.type;
    },

    message() {
      if (this.matype == "passwordreset")
        return this.$txt.password_reset_succesful;
      if (this.matype == "verified") return this.$txt.verify_successful;
      if (this.matype == "forgotmailsent") return this.$txt.forgot_mail_sent;
      return "Success!";
    }
  }
};
</script>

<style scoped>
input {
  width: 100%;
}
.btn {
  width: 100%;
}
</style>
