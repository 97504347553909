<template>
  <a
    :id="tabid"
    class="tab_btn"
    :class="{ 'selected-tab': selected, 'tight-tab': tight }"
    @click="selectTabBtn()"
  >
    {{ title }}
  </a>
</template>

<script>
export default {
  name: "TabBtn",
  emits: ['click'],
  data() {
    return {};
  },

  props: {
    title: String,
    selected: Boolean,
    disable: Boolean,
    tight: Boolean,
    tabid: {
      type: String,
      default: null
    }
  },

  methods: {
    selectTabBtn() {
      console.log("selectTabBtn:"+this.tabid);
      this.$emit("click",this.tabid);
    }
  },

  created: function() {}
};
</script>
