<template>
  <div>
    <div v-if="!no_data" class="table-responsive">
      <table class="wm_table" :class="{ wm_log_scrollable: scrollable_body }">
        <thead :style="header_style">
          <slot name="head"></slot>
        </thead>

        <slot></slot>
      </table>
    </div>
    <NoDataLabel v-else />
  </div>
</template>

<script>
export default {
  name: "ResponsiveTable",
  props: {
    no_data: {
      type: Boolean,
      default: false
    },

    scrollable_body: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    header_style() {
      return `width: calc(100% - ${window.browser_scrollbar_width}px)`;
    }
  }
};
</script>

<style lang="css"></style>
