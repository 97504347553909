import { WMBaseItem } from "./baseitem";

export default class Formula extends WMBaseItem {
  get gain() {
    return this.dto.gain;
  }

  get offset() {
    return this.dto.offset;
  }

  get unit() {
    return this.dto.unit;
  }

  constructor(dto, identification) {
    super(dto);
    this.app_prop.project_id = identification.project_id;
  }

  props_template() {
    return {
      project_id: 0,
      decimals: 2
    };
  }

  upload_changes() {
    return this.Network.formula.update(
      this.app_prop.project_id,
      this.id,
      this.changes()
    );
  }

  static new_item_instance(dto, identification) {
    return new Formula(dto, identification);
  }

  static find_parent(app, identification) {
    return app.$appm.get_project(identification.project_id).formulas;
  }

  static find(app, id, identification) {
    return app.$appm.get_formula(identification.project_id, id);
  }
}
