export default {
  data() {
    return {
      filter_text: "",
      sort_prop: "name",
      reverse: false
    };
  },

  methods: {
    sort_by(by) {
      if (by == this.sort_prop) {
        this.reverse = !this.reverse;
      } else {
        this.reverse = false;
        this.sort_prop = by;
      }
    },

    sorted_items(items) {
      var sorted = this.$util.sort_table(items, this.sort_prop);
      if (this.reverse) sorted = sorted.reverse();
      return sorted;
    }
  }
};
