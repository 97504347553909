<template lang="html">
  <!--
  <img src="static/project_tree/sensor_simple.png"/>
-->
  <div class="png_icon_container">
    <img :src="src" />
    <img v-for="o in overlays" :key="o" :src="o" class="png_overlay_icon" />
  </div>
</template>

<script>
export default {
  name: "PNGIcon",

  props: {
    icon: String,
    alarm: Boolean,
    not_connected: Boolean,
    alarm_acknowledged: Boolean
  },

  computed: {
    src() {
      return this.path_to_icon(this.icon);
    },

    overlays() {
      let overlays = [];

      // Note: sort by priority,
      // the last overlay in the array will appear on top
      if (this.not_connected) {
        overlays.push(this.path_to_icon("overlay_not_connected"));
      }
      if (this.alarm && !this.alarm_acknowledged) {
        overlays.push(this.path_to_icon("overlay_alarm"));
      }
      if (this.alarm && this.alarm_acknowledged) {
        overlays.push(this.path_to_icon("overlay_acked_alarm"));
      }
      return overlays;
    }
  },

  methods: {
    path_to_icon(icon) {
      let subPath=`/static/project_tree/${icon}.png`;
      let path = subPath;
      if (process.env.NODE_ENV == 'production') {
        path = '/monitor' + subPath;
      } 
      return path;
    }
  }
};
</script>
