<template>
  <span class="YesNoIcon">
    <SmallIcon v-if="value" :icon="$icon.yes" color="green" />
    <SmallIcon v-else :icon="$icon.no" color="#ed330e" />
  </span>
</template>

<script>
export default {
  name: "YesNoIcon",
  data() {
    return {};
  },

  props: {
    value: Boolean
  }
};
</script>
