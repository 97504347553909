<template lang="html">
  <div class="">
    <div
      class="tooltipbox"
      v-if="tooltip_value != ''"
      :style="{ left: tool_tip_x + 'px', top: tool_tip_y + 'px' }"
    >
      {{ tooltip_value }}
    </div>

    <div
      style=" margin-left: -5px; margin-right: -5px; margin-top: 5px"
      @mousemove="mouse_move_parent($event)"
    >
      <!--
      <TrendChart
        @mouse-move="onMouseMove"
        class="trendchart trendchart-blue"
        :style="{ maxHeight: maxHeight, padding: '5px' }"
        :datasets="[
          {
            data: data,
            className: 'mystroke',
            smooth: true,
            showPoints: data.length < 40,
            fill: false
          }
        ]"
        :showPoints="true"
        padding="20"
        :interactive="true"
        :grid="{
          verticalLines: false,
          horizontalLines: true,
          horizontalLinesNumber: 5
        }"
      >
    </TrendChart> -->
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      currentInfo: null,
      tool_tip_x: 0,
      tool_tip_y: 0
    };
  },

  props: ["data", "height"],

  methods: {
    onMouseMove(params) {
      if (!params) {
        this.currentInfo = null;
        return;
      }
      this.currentInfo = {
        label: "Wednesday",
        value: params.data[0]
      };
    },

    mouse_move_parent(e) {
      this.tool_tip_x = e.clientX + 20;
      this.tool_tip_y = e.clientY;
    }
  },

  computed: {
    maxHeight() {
      if (this.height == null) {
        return "90px";
      } else {
        return this.height + "px";
      }
    },

    tooltip_value() {
      if (this.currentInfo != null) {
        return `${this.currentInfo.value.value} ${
          this.currentInfo.value.unit
        } (${this.$appg.langutil.print_time_sec(this.currentInfo.value.time)})`;
      } else {
        return "";
      }
    },

    show_horizontal_lines() {
      return this.height == null || this.height > 40;
    }
  }
};
</script>

<style lang="css" scoped>
.tooltipbox{
  background-color: white;
  opacity: 0.9;
  padding: 5px;
  border-radius: 4px;
  border: 1px solid gray;
  position: fixed;
}
</style>
