<template>
  <CompactPanel>
    <span class="filterbar_input" :title="hint">
      <!-- <IconBtn :icon="$icon.filter_search" /> --> 
      <input
        type="text"
        :title="$txt.hint_filter_for_name_or_type"
        :placeholder="placeholder"
        class="wm_search_input"
        :value="modelValue"
        @input="$emit('update:modelValue', $event.target.value)"
      />
      <IconBtn
        v-if="modelValue != '' && !tight_mode"
        @clicked="$emit('update:modelValue', '')"
        :icon="$icon.clear_search"
      />
    </span>
  </CompactPanel>
</template>

<script>
export default {
  props: {
    modelValue: String,
    placeholder: {
      type: String,
      default: "Filter"
    },
    value: {},
    tight_mode: {
      type: Boolean,
      default: false
    },
    hint: {
      type: String,
      default: ""
    }
  }
};
</script>

<style lang="css"></style>
