<template>
  <div
    class="project_tree_indicator"
    @mouseenter="display_arrow = true"
    @mouseleave="display_arrow = false"
  >
    <ProjectTreeIndicatorArrow v-show="display_arrow" />
  </div>
</template>

<script>
import ProjectTreeIndicatorArrow from "./ProjectTreeIndicatorArrow";

export default {
  name: "ProjectTreeIndicator",
  components: {
    ProjectTreeIndicatorArrow
  },
  data() {
    return {
      display_arrow: false
    };
  }
};
</script>
