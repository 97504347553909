<template>
  <div>
    <SmartProgressBar
      :tracked_request="$app.upload_req"
      @finished="$app.upload_req = null"
    />

    <NotesAndErrorsPanel />
    <div class="project_base_view">
      <SelectionBreadCrumb />

      <ProjectTreeIndicator
        v-if="$ui.tight_mode && !$ui.project_tree_keep_open"
      />
      <ProjectTree v-else />

      <div class="mainpage">
        <router-view :key="$route.fullPath"></router-view>
      </div>
    </div>
  </div>
</template>

<script>
import ProjectTree from "@/components/prjtree/ProjectTree";
import ProjectTreeIndicator from "@/components/prjtree/ProjectTreeIndicator";

export default {
  name: "ProjectBaseView",
   components: {
    ProjectTree,
    ProjectTreeIndicator
  },
  data() {
    return {
      show_tree: false
    };
  },

  mounted() {
    if (this.$app.sltd.project != null) {
      window.document.title = `${this.$app.sltd.project.name} | ${
        this.$txt.product_name
      }`;
    }
  }
};
</script>

<style>
.mainpage {
  overflow-y: scroll;
  grid-column-start: 2;
  grid-column-end: 3;
  grid-row-start: 2;
  grid-row-end: 3;
}

.wm_centered_icon {
  padding: 20px;
}
</style>
