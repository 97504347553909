<template>
  <div class="labeled-control">
    <p :class="{ wm_label_disabled: disabled }">{{ label }}</p>
    <input
      v-if="return_binary"
      class="wm_checkbox"
      :class="{ wm_input_disabled: disabled }"
      type="checkbox"
      placeholder=""
      :disabled="disabled"
      :title="hintText"
      v-bind:checked="modelValue ? 1 : 0"
      v-on:input="$emit('update:modelValue', $event.target.checked ? 1 : 0)"
      ref="labeledcheckbox"
    />
    <input
      v-else
      class="wm_checkbox"
      :class="{ wm_input_disabled: disabled }"
      type="checkbox"
      placeholder=""
      :disabled="disabled"
      :title="hintText"
      v-bind:checked="modelValue"
      v-on:input="$emit('update:modelValue', $event.target.checked)"
      ref="labeledcheckbox"
    />
  </div>
</template>

<script>
export default {
  emits: ['update:modelValue'],
  name: "LabeledCheckbox",
  data() {
    return {};
  },

  props: ["label", "modelValue", "return_binary", "disabled", "hintText", "indeterminate"],

  mounted() {
    this.setIndeterminate();
  },

  watch: {
    indeterminate() {
      this.setIndeterminate();
    }
  },

  methods: {
    setIndeterminate() {
      if (this.$refs.labeledcheckbox) {
        this.$refs.labeledcheckbox.indeterminate = this.indeterminate;
      }
    }
  },

  created: function() {}
};
</script>
