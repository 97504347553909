<template>
  <a
    :disabled="disabled"
    class="btn btn-primary dropdown-toggle"
    type="button"
    data-toggle="dropdown"
  >
    {{ title }}
    <span class="caret"></span>
  </a>
</template>

<script>
export default {
  name: "DropdownBtn",
  data() {
    return {};
  },

  props: {
    title: String,
    disabled: Boolean
  },

  methods: {},

  created: function() {}
};
</script>
