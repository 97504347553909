<template>
  <div class="none_label">
    <SmallIcon :icon="$icon.no_data" />
    <span class="no_data_text" v-if="text != ''">{{ text }}</span>
    <span v-else>{{ $txt.no_data }}</span>
    <slot />
  </div>
</template>

<script>
export default {
  props: {
    text: {
      type: String,
      default: ""
    }
  }
};
</script>

<style>
.none_label {
  text-align: center;
  padding: 20px;
  background-color: #efefef;
  border-radius: 4px;
}

.no_data_text {
  font-weight: bold;
  color: gray;
  text-transform: uppercase;
  padding-left: 7px;
}
</style>
