<template>
  <div class="labeled-control" :title="hint">
    <label class="wm_dropdown_label">{{ label }}
    <br />
    <input
      v-if="!disabled"
      class="wm_date_input"
      ref="datepicker"
      type="date"
      placeholder="YYYY-MM-DD"
      :min="minDate"
      :max="maxDate"
      :value="internal_date"
      :class="{wm_input_invalid: invalid_time}"
      @input="on_edit_date($event.target.value)"
      @change="on_edit_date($event.target.value)"
      autocomplete="off"
    />
    <input
      v-else
      class="wm_date_input"
      disabled="true"
      ref="datepicker"
      type="date"
      :class="{ wm_input_disabled: disabled }"
      :value="internal_date"
      autocomplete="off"
    />
    <input
      v-if="include_time"
      :disabled="disabled"
      :class="{ wm_input_disabled: disabled, wm_input_invalid: invalid_time }"
      type="time"
      style="margin-left: 3px"
      class="wm_time_input"
      :value="internal_time"
      @input="on_edit_time($event.target.value)"
      @change="on_edit_time($event.target.value)"
      autocomplete="off"
    />
    </label>
  </div>
</template>

<script>
export default {
  emits: ['update:modelValue'],
  name: "LabeledDateInput",
  data() {
    return {
      internal_date: "",
      internal_time: "",
      value_as_utc: "",
      invalid_time: false,
      minDate: "2021-01-01",
      maxDate: "2050-01-01",
    };
  },

  methods: {
    on_edit_date(new_date) {
      if(new_date !== "") {
        this.internal_date = new_date;
        this.emit_new_value();
      } else {
        this.invalid_time = true;
      }
    },

    on_edit_time(new_time) {
      if(new_time !== "") {
        this.internal_time = new_time;
        this.emit_new_value();
      } else {
        this.invalid_time = true;
      }
    },

    set_value_from_utc(utc_val) {
      let tz = this.$appg.langutil.get_working_tz();
      let as_local_time = this.$appg.langutil.convert_time_to_timezone(
        utc_val,
        tz
      );

      // Format date and time
      this.internal_date = this.format_date(as_local_time);
      this.internal_time = this.format_time(as_local_time);
      this.value_as_utc = this.$appg.langutil.convert_working_time_to_utc(
        as_local_time
      );
    },

    emit_new_value() {
      let combined_value = `${this.internal_date} ${this.internal_time}`;
      if(new Date(combined_value).toString().startsWith("Invalid")) {
        this.invalid_time = true;
        return;
      }
      this.invalid_time = false;

      this.value_as_utc = this.$appg.langutil.convert_working_time_to_utc(
        combined_value
      );
      // Always emit as UTC
      this.$emit("update:modelValue", this.value_as_utc);
    },

    format_time(val) {
      // From MSDN documentation:
      //   "The displayed date format will differ from the actual value —
      //    the displayed date is formatted based on the locale of the
      //    user's browser, but the parsed value is always formatted yyyy-mm-dd."
      // https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input/date
      return val.slice(11,16);
    },

    format_date(val) {
      // The value of the time input is always in 24-hour format that includes leading zeros: hh:mm
      // https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input/time
      return val.slice(0,10);
    }
  },

  computed: {
    hint() {
      let message = "";
      let timezone = "";
      let prj = null;

      let display_time = this.$app.settings.display_time;
      prj = this.$app.sltd.project;
      if (display_time == "prj" && prj == null) {
        display_time = "local";
      }

      switch (display_time) {
        case "prj":
          message = this.$txt.time_displayed_as_installation;
          timezone = prj.dto.info.timezone;
          break;
        case "utc":
          message = this.$txt.time_displayed_as_utc;
          timezone = "Universal Time Coordinated";
          break;
        default:
        case "local":
          message = this.$txt.time_displayed_as_local;
          timezone = this.$appg.langutil.get_local_timezone();
          break;
      }
      return `${message} (${timezone})`;
    }
  },

  mounted() {
    this.set_value_from_utc(this.modelValue);
  },

  props: {
    label: {
      type: String
    },

    // Input is always in utc
    modelValue: {
      type: String
    },

    disabled: {
      type: Boolean
    },

    include_time: {
      type: Boolean,
      default: false
    }
  },

  watch: {
    // Input is always in utc
    modelValue: function(new_value) {
      this.set_value_from_utc(new_value);
    }
  }
};
</script>
