// globalprops.js
import MessageManager from './message_manager'
import router from './router';
import Note from './utils/note'
import Err from './utils/error'
import MomentTimezone from 'moment-timezone';
import Version from './json/version.json'


const Selection = require('./selection').default;
const CamurUtils = require('@/utils/camurutils').default;
const Enum = require('@/utils/enums').default;
const Utils = require('@/utils').default;
const USERTOKEN_LOGGED_OUT = "loggedout";
const LogoutTimeout = 120.0 * 60 * 1000; // ms

export const GlobalProps = {
  install(app) {
    console.log("GlobalProps installed");
    app.config.globalProperties.$appg = {
      // Your global properties go here
      appName: 'MyApp',
      timezones: [],
      langutil: null,
      language_strings:null,
      eventformat: null
    };
    app.config.globalProperties.$appm = {
          /* INIT */
      init(Net, Store) {
        //global.app = this;
        this.net = Net;
        this.store = Store;
        this.currentRoute = null;
        this.waiting_for_initial_route = false;
        console.log("init()");
        this.store.current_version = Version;
        this.load_settings();

        this.load_language();
        
        this.init_timezones();
        this.init_selection();
        this.init_authentication();
        this.measure_browser_scrollbar_width();

        // Only do these steps if we are actually logged in
        //if(this.session.logged_in && this.session.current_user_id != null) {
        if (this.store.session.logged_in)  {
          console.log("Logged in at init()");
          this.load_user();
          this.load_initial_data();
          this.init_msg_manager();
        }

        this.update_decimal_point();
        this.handle_report_queries();
        //var currentRoute = router.currentRoute.value;
        if (this.currentRoute == null) {
          console.log("this.currentRoute is null");
          console.log("but url is:"+window.location.href);
          this.waiting_for_initial_route = true;
        } else {
          this.enter_route(this.currentRoute);
        }
          
      },
      load_initial_data() {
        this.net.customer.list();
        this.net.project.list();
      },
      load_user() {
        console.log("Load user");
        if(this.store.session.current_user_id == null)
        {
          this.store.session.current_user_id = parseInt(localStorage.current_user_id);
        }
        else
        {
          console.log("Load user:current_user_id:", this.store.session.current_user_id );
        }
        if(this.store.session.current_user_id && this.current_user_token_is_valid())
        {
          this.net.user.fetch(this.store.session.current_user_id);
          this.net.user.fetch_global_rights();
          console.log("Global rights fetched");
        } else {
            this.log_out();
        }
      },
      load_language() {
        if(localStorage.lang != "" && localStorage.lang != undefined && localStorage.lang != "undefined")
        {
          this.store.session.current_language = localStorage.lang;
          console.log("Load language:"+localStorage.lang);
        }
        else {
          console.log("Load language undefined. Use english");
          this.store.session.current_language = "en";
          this.store.session.current_language = this.store.settings.lang;
        }

        console.log("get_language");
        this.net.staticfiles.get_language(`${this.store.session.current_language}_${this.store.current_version.build}`);
        console.log("get_errors");
        this.net.staticfiles.get_errors(`${this.store.session.current_language}_errors-${this.store.current_version.build}`);
        console.log("get_eventformat");
        this.net.staticfiles.get_eventformat();
      },
      init_msg_manager() {
        if(!this.user_token_is_valid || !this.net.api_server) {
          throw "Cannot initialize message manager without usertoken and api_server"
        }
        // There is a chance of the "old" messagemanager trying to
        // reconnect with an outdated token, causing errors
        if(app.msg_manager != null && app.msg_manager.is_ready()) {
          app.msg_manager.disconnect();
        }
        console.log("init_msg_manager");
        app.msg_manager = new MessageManager(app, this.net.api_server, this.get_user_token());
      },
      init_selection() {
        console.log("init_selection");
        this.store.sltd = new Selection(app);
        this.store.sltd.on_project_changed = this.on_new_project_selected;
      },
      update_selections_from_route(route) {
        console.log("update_selections_from_route");
        this.store.sltd.from_params(route.params);
      },
      set_route(thisRoute){
        console.log("set_route:"+thisRoute.name);
        this.currentRoute = thisRoute;
        if ((this.currentRoute != null) && this.waiting_for_initial_route) {
          this.before_new_route(this.currentRoute, null, null);
          //this.enter_route(this.currentRoute);
          this.waiting_for_initial_route = false;
        }
      },
      enter_route(to) {
        
        console.log("enter_route:"+ to.name);
        this.reset_errors_and_notes();
        this.update_selections_from_route(to);
        this.request_any_selected_items();
        this.kick_log_out_timer();
      },
      on_new_project_selected(new_id, old_id) {
        if(old_id != null) app.msg_manager.unsubscribe_project(old_id);
        if(new_id != null) app.msg_manager.subscribe_project(new_id);
      },
      load_settings() {
        console.log("load_settings");
        if(localStorage.settings_blob != null && localStorage.settings_blob != undefined)
        {
          Object.assign(this.store.settings, JSON.parse(localStorage.settings_blob));
          console.log("settings assigned");
          console.log(this.store.settings.lang);
        }
      },
      change_language(language_strings) {
        app.config.globalProperties.$appg.language_strings = language_strings;
        //Vue.prototype.$txt = language_strings;
        app.config.globalProperties.$txt=language_strings;
        app.$txt = app.config.globalProperties.$txt;
        this.store.session.current_language = language_strings.code;
        localStorage.lang = this.store.session.current_language;
        this.store.session.loaded = true;
        console.log("Session loaded");
      }, 
      handle_report_queries() {
        console.log("handle_report_queries");
        this.store.settings.report.selected_recording = CamurUtils.all_recordings.id;
  
        //var query = this.$route.query;
         var currentRoute = router.currentRoute.value;
         var query = currentRoute.query;
        if(query.hasOwnProperty('recording')) {
          this.store.settings.report.selected_recording = parseInt(query.recording);
        }
        if(query.hasOwnProperty('from')) {
          this.store.settings.report.from_date = query.from
        }
        if(query.hasOwnProperty('to')) {
          this.store.settings.report.to_date = query.to
        }
        if(query.hasOwnProperty('type')) {
          this.store.settings.report.report_type = parseInt(query.type)
        }
        this.store.settings.report.interval = Enum.report_intervals.from_to;
      }, 
      save_settings() {
        var settings_json = JSON.stringify(this.store.settings);
        localStorage.settings_blob = settings_json;
        console.log("save_settings");
        console.log(this.store.settings.locale_string);
      },
          /* AUTH */
      init_authentication() {
        console.log("init_authentication");
        this.store.session.logged_in = this.current_user_token_is_valid();
        console.log("init_authentication 2");
        //var currentRoute = router.currentRoute.value;
        if (this.currentRoute != null) {
          console.log("init_authentication(), route:"+currentRoute.name);
          if(this.require_login(currentRoute) && !this.store.session.logged_in){
            localStorage.setItem("C3SavedRoute", JSON.stringify(currentRoute));
             console.log("C3SavedRoute");
            this.log_out();
          }  
        }
      },
          /* ACTIONS */
      require_login(route) {
        console.log("require_login:"+route.name);
        var login_required = false;

        if (route.meta.hasOwnProperty('no_login')) {
          if (route.meta.no_login === true) {
            login_required = false;
          } else {
            login_required = true;
          }
        } else {
          login_required = true;
        }

        console.log(" returns:");
        if (login_required) {
          console.log("true");
        } else {
          console.log("false");
        }
        return login_required;
      },
      before_new_route(to, from, next) {
        if(this.require_login(to) && !this.current_user_token_is_valid()){
          localStorage.setItem("C3SavedRoute", JSON.stringify(to));
          console.log("C3SavedRoute");
          this.log_out();
          return false;
        
        } else if (this.unsubmitted_changes_in_primary_selection()) {
          if(confirm(window.app.$txt.select_new_without_submitting) == false) {
            // Navigation cancelled by user
            return false;
          }
        } else if (this.store.ui.select_mode && from.name !== "config_multiple_nodes") {
          if(confirm(window.app.$txt.new_route_during_select_mode) == false) {
            // Navigation cancelled by user
            return false;
          } else {
            this.store.ui.select_mode = false;
          }
        }
        if (from && from.name == "config_multiple_nodes") {
          this.store.ui.select_mode = false;
        }
        this.enter_route(to);
        return true;
      },
      init_timezones() {
        console.log("init_timezones");
        if (app.config.globalProperties.$appg.timezones.length > 0) return;
        for(let tz of MomentTimezone.tz.names()) {
          app.config.globalProperties.$appg.timezones.push({ id: tz });
        }
      },
      measure_browser_scrollbar_width() {
        console.log("measure_browser_...");
        // Create the measurement node
        var scrollDiv = window.document.createElement("div");
        scrollDiv.style.width = "100px";
        scrollDiv.style.height = "100px";
        scrollDiv.style.overflow = "scroll";
        scrollDiv.style.position = "absolute";
        scrollDiv.style.top = "-9999px";
  
        window.document.body.appendChild(scrollDiv);
  
        // Get the scrollbar width
        window.browser_scrollbar_width = scrollDiv.offsetWidth - scrollDiv.clientWidth;
        window.document.body.removeChild(scrollDiv);
      },
  
      // pass in any date as parameter anyDateInMonth
      daysInMonth(anyDateInMonth) {
        return new Date(anyDateInMonth.getFullYear(),
                  anyDateInMonth.getMonth()+1,
                  0).getDate();
      },
      dates_for_interval(interval) {
        var now = new Date();
        var current_year = now.getFullYear(); // 4-digit 1-based
        var current_month = now.getMonth(); // 0-based!
        var current_day_of_month = now.getDate(); // 1-based!
  
        var result = { from_date: null, to_date: null };
  
        switch(interval) {
  
          case Enum.report_intervals.this_year: {
            result.from_date = Utils.make_date(current_year, 0, 1);
            result.to_date = Utils.make_date(current_year+1, 0, 1);
          } break;
  
          case Enum.report_intervals.this_month: {
            result.from_date = Utils.make_date(current_year, current_month, 1);
            var nextMonth = current_month +1;
            var nextYear = current_year;
            if (nextMonth > 11) // month is 0-based
            {
              nextYear += 1;
              nextMonth = 0;
            }
            result.to_date = Utils.make_date(nextYear, nextMonth, 1);
          } break;
  
          case Enum.report_intervals.this_week: {
            var dayInWeek = now.getDay();
            // getDay returns 0 for Sunday
            // as first day of the week
            // Adjust this to align with ISO 8601
            if (dayInWeek == 0) {
              // Sunday, change to last day in week
              dayInWeek = 6;
            } else {
              // Adjust Monday to Friday from
              // 1..6 -> 0..5
              dayInWeek--;
            }
            var fromYear = current_year;
            var fromMonth = current_month;
            var fromDay = current_day_of_month - dayInWeek;
  
            var toYear;
            var toMonth;
            var toDay;
  
            if (fromDay < 1)
            { // Week started previous month
              fromMonth = fromMonth - 1;
              if (fromMonth < 0)
              {
                fromYear = fromYear - 1;
                fromMonth = 11;
              }
              var anyDateInFromMonth = new Date(fromYear, fromMonth, 1);
              var daysInFromMonth = this.daysInMonth(anyDateInFromMonth);
              fromDay += daysInFromMonth;
  
              // toDay is in current month
              toYear = current_year;
              toMonth = current_month;
              toDay = fromDay + 7 - daysInFromMonth;             
            }
            else
            { // Week starts this month
              var anyDateInToMonth = new Date(current_year, current_month, 1);
              var daysInToMonth = this.daysInMonth(anyDateInToMonth);
              var toDay = fromDay + 7;
  
              toYear = current_year;
              toMonth = current_month;
              if (toDay > daysInToMonth)
              {
                toDay -= daysInToMonth;
                toMonth +=1;
                if (toMonth > 11) // Month is 0-based
                {
                  toMonth = 0;
                  toYear += 1;
                }
              }
            }  
            result.from_date = Utils.make_date(fromYear, fromMonth, fromDay);
            result.to_date = Utils.make_date(toYear, toMonth, toDay);
          }
          break;
          case Enum.report_intervals.today: {
            result.from_date = Utils.make_date(current_year, current_month, current_day_of_month);
            var toYear = current_year;
            var toMonth = current_month;
            var toDay = current_day_of_month + 1;
            var anyDateInToMonth = new Date(toYear, toMonth, 1);
            var daysInToMonth = this.daysInMonth(anyDateInToMonth);
            if (toDay > daysInToMonth)
            {
              toDay = 1;
              toMonth +=1;
              if (toMonth > 11) // Month is 0-based
              {
                toMonth = 0;
                toYear +=1;
              }
            }
            result.to_date = Utils.make_date(toYear, toMonth, toDay);
          } break;
          case Enum.report_intervals.all_intervals: {
            result.from_date = Utils.make_date(2020, 0, 1);
            result.to_date = Utils.make_date(current_year+1, 0, 1);
          } break;
  
          default: {
            return null;
          }
        }
        // Finally, convert the dates to UTC. When a user selects an
        // interval, we assume they want "this *" in their own timezone,
        // so we must account for that.
        result.from_date = window.app.langutil.convert_working_time_to_utc(result.from_date);
        result.to_date = window.app.langutil.convert_working_time_to_utc(result.to_date);
  
        return result;
      },
      select_report_interval(interval) {
        this.store.settings.report.interval = interval;
        let result = this.dates_for_interval(interval);
  
        if(result == null) return;
  
        this.store.settings.report.from_date = result.from_date;
        this.store.settings.report.to_date = result.to_date;
      },
  
      select_event_log_interval(interval) {
        this.store.settings.event_log.interval = interval;
        let result = this.dates_for_interval(interval);
  
        if(result == null) return;
  
        this.store.settings.event_log.from_date = result.from_date;
        this.store.settings.event_log.to_date = result.to_date;
      },
  
      get_icon_for_node_type(node_type) {
        if(CamurUtils.is_radio_link(node_type)) return "radio_link";
        if(CamurUtils.is_power_supply(node_type)) return "power_supply";
        if(CamurUtils.is_adv_node(node_type)) return "sensor_complex";
        else return "sensor_simple";
      },
      get_icon_for_zone(zone) {
        // TODO: change icon depending on zone status
        return "zone";
      },
      get_nodeList_for_map2D() {
        if(this.store.sltd.project == null) return [];
  
        let nodes = [];
        let zones = this.store.sltd.project.zones;
  
        for(let z of zones) {
          for(let n of z.nodes) {
            nodes.push({
              posY: n.posY,
              posX: n.posX,
              img: this.get_icon_for_node_type(n.capability),
              name: n.name,
              zone_color: z.locals.mapColor,
              hidden: !z.locals.mapVisible
            })
          }
        }
        return nodes;
      },
      set_title(title) {
        document.title = title + " | Camur Workspace";
      },
      
      receive_graph_data(project_id, data, metadata, graph_buffer) {
        // let prj = this.get_project(project_id);
        // if(prj != null) {
          graph_buffer.add_downloaded_block(data, metadata);
        // }
      },
      request_any_selected_items() {
        if(this.store.sltd.project_is_selected()) {
          if(this.store.sltd.zone_is_selected()) {
            if(this.store.sltd.node_is_selected()) {
              this.net.node.fetch(this.store.sltd.prj_id, this.store.sltd.zone_id, this.store.sltd.node_id);
            }
            else {
              this.net.zone.fetch(this.store.sltd.prj_id, this.store.sltd.zone_id);
            }
          }
          else {
            this.net.project.fetch(this.store.sltd.prj_id);
          }
        }
      },
      get_error(error_code) {
        try {
          return this.store.error_codes[error_code];
        } catch {
          console.error("Unknown errorcode: " + error_code);
        }
      },
      set_error_codes(error_codes) {
        this.store.error_codes = error_codes;
        //Vue.prototype.$errs = this.store.error_codes;
        app.config.globalProperties.$errs=this.store.error_codes;
      },
      reset_errors_and_notes() {
        // Reset any errors or notes
        let arrayLength = this.store.ui.error_msgs.length;
        if (arrayLength) {
          this.store.ui.error_msgs.splice(0, arrayLength);
        }

        arrayLength = this.store.ui.notes.length;
        if (arrayLength) {
          this.store.ui.notes.splice(0, arrayLength);
        }
      },
      set_eventformat(eventformat) {
        app.config.globalProperties.$appg.eventformat = eventformat;
      },
      get_eventmsg(event_code) {
        return app.config.globalProperties.$appg.eventformat[event_code];
      },
      get_project(project_id) {
        return Utils.array_element_by_id(project_id,
                                              this.store.projects);
      },
      get_cntrl(project_id, cntrl_id) {
        var project = this.get_project(project_id);
        if(project != null){
          return Utils.array_element_by_id(cntrl_id,
            project.cntrls);
        }
        return null;
      },
      get_cntrl_by_serial(project_id, cntrl_serial) {
        var project = this.get_project(project_id);
        if(project != null){
          let matching = project.cntrls.filter(c => c.dto.serialNumber == cntrl_serial);
          if(matching.length > 0) return matching[0];
          return null;
        }
        return null;
      },
      get_cntrl_device(project_id, cntrl_device_id) {
        var project = this.get_project(project_id);
        if(project != null) {
          return Utils.array_element_by_id(cntrl_device_id, project.cntrl_devices);
        }
        return null;
      },
      get_cntrl_device_by_serial(project_id, cntrl_serial) {
        var project = this.get_project(project_id);
        if(project != null) {
          for (let index = 0; index < project.cntrl_devices.length; index ++) {
            if (project.cntrl_devices[index].dto.serialNumber == cntrl_serial) {
              return project.cntrl_devices[index];
            }
          }
        }
        return null;
      },
      delete_cntrl_device(project_id, cntrl_serial) {
        var project = this.get_project(project_id);
        if(project != null) {
          for (let index = 0; index < project.cntrl_devices.length; index ++) {
            if (project.cntrl_devices[index].dto.serialNumber == cntrl_serial) {
              project.cntrl_devices.splice(index, 1);
              return true;
            }
          }
        }   
        return false;
      },    
      update_cntrl_device(project_id, dto) {
        var project = this.get_project(project_id);
        if(project != null) {
          let found = false;
          for (let index = 0; index < project.cntrl_devices.length; index ++) {
            if (project.cntrl_devices[index].dto.serialNumber == dto.serialNumber) {
              project.cntrl_devices[index].dto = dto;
              found = true;
            }
          }
          if (!found) {
            project.cntrl_devices.push(dto);
          }
        }   
      }, 
      get_recording_info(project_id, zone_id, recording_info_id) {
        var zone = this.get_zone(project_id, zone_id);
  
        if(zone != null){
          return Utils.array_element_by_id(recording_info_id,
            zone.recording_infos);
        }
        return null;
      },
      get_zone(project_id, zone_id) {
        var project = this.get_project(project_id);
        if(project != null){
          return Utils.array_element_by_id(zone_id,
            project.zones);
        }
        return null;
      },

      get_node(project_id, zone_id, node_id) {
        var zone = this.get_zone(project_id, zone_id);
  
        if(zone != null){
          return Utils.array_element_by_id(node_id,
            zone.nodes);
        }
        return null;
      },
      get_node_by_serial(project_id, node_serial) {
        let result = this.get_project(project_id).all_nodes.filter(n => n.serial == node_serial);
  
        if(result.length == 0) return null;
        return result[0];
      },
  
      get_node_by_id(project_id, node_id) {
        let result = this.get_project(project_id).all_nodes.filter(n => n.id == node_id);
  
        if(result.length == 0) return null;
        return result[0];
      },
      get_node_device(project_id, cntrl_device_id, node_device_id) {
        var project = this.get_project(project_id);
        if(project != null) {
          let cntrl_device = this.get_cntrl_device(project_id, cntrl_device_id);
  
          if(cntrl_device != null) {
            return Utils.array_element_by_id(node_device_id, cntrl_device.node_devices);
          }
        }
        return null;
      },
      get_channel(project_id, zone_id, node_id, channel_id) {
        var node = this.get_node(project_id, zone_id, node_id);
        if(node != null) {
          return Utils.array_element_by_id(channel_id,
            node.channels);
        }
        return null;
      },

      get_formula(project_id, formula_id) {
        var project = this.get_project(project_id);
        if(project != null){
          return Utils.array_element_by_id(formula_id,
            project.formulas);
        }
        return null;
      },
      get_cp_data(project_id, cp_id) {
        var project = this.get_project(project_id);
        if(project != null){
          return Utils.array_element_by_id(cp_id,
            project.cp_data);
        }
        return null;
      },
      get_node_type_name(node_type) {
        return app.config.globalProperties.$appg.langutil.get_enum_name(CamurUtils.node_type, node_type)
      },
      get_graph_prop(id) {
        if(this.store.graph_props != null){
          return Utils.array_element_by_id(id,
            this.store.graph_props);
        }
        return null;
      },
      get_customer(customer_id) {
        return Utils.array_element_by_id(customer_id,
                                              this.store.customers);
      },
      get_customer_project(customer_id, customer_project_id) {
        let customer = this.get_customer(customer_id);
        if(customer != null){
          return Utils.array_element_by_id(customer_id,
            customer.customer_projects);
        }
        return null;
      },
      get_customer_grant(customer_id, customer_grant_id) {
        let customer = this.get_customer(customer_id);
        if(customer != null){
          return Utils.array_element_by_id(customer_id,
            customer.user_grants);
        }
        return null;
      },
      get_user(user_id) {
        return Utils.array_element_by_id(user_id,
                                              this.store.users);
      },
      // If there is a User object where ID == current_user_id,
      // set the session.current_user pointer to it
      update_current_user() {
        let user_with_current_id = this.get_user(this.store.session.current_user_id);
        if(user_with_current_id) this.set_current_user(user_with_current_id);
      },
      current_user_initialized() {
        return (
          this.store.session.current_user != null &&
          "item_type" in this.store.session.current_user &&
          this.store.session.current_user.item_type == "user");
      },
      get_grant(grant_id) {
        return Utils.array_element_by_id(grant_id,
                                              this.store.grants);
      },
      get_access_collection(customer_id, collection_id) {
        return Utils.array_element_by_id(collection_id,
                                             this.get_customer(customer_id).access_collections);
      },
      get_firmware(firmware_id) {
        return Utils.array_element_by_id(firmware_id,
                                              this.store.firmware);
      },
      next_zone_name(proj, i) {
        return `${window.app.$txt.zone} ${proj.children.zones
          .length + 1 + i}`;
      },
      next_controller_name(proj, i) {
        return `${window.app.$txt.cntrl} ${proj.children.cntrls
          .length + 1 + i}`;
      },  
      can_move_node_to_zone(node, new_zone) {
        if(node == null) return false;
  
        if(node.locals.zoneId == new_zone.id) return false;
        let old_zone = this.get_zone(node.app_prop.project_id, node.dto.zoneId);
        if(old_zone.controllerId != new_zone.controllerId) return false;
  
        // TODO: Both old and new zone must be in standby mode
  
        return true;
      }, 
      can_see_customer_tab(){
        return this.store.session.current_user.can_see_customer_tab;
      },
      maintab () {
        return this.store.maintab;
      },
      can_drop_dragged_on_target (target) {
        let dragged_item = this.store.dragged_item;
        if(dragged_item == null ||
           target == null)
        {
          return false;
        }
  
        if(typeof dragged_item.virtual != "undefined") {
          // Handle virtual drop types
  
          // Virtual Controller on Project
          if(Utils.is_project(target) && dragged_item.type == "controller") {
            return true;
          }
  
          // Zone on Controller
          if(Utils.is_cntrl(target) && dragged_item.type == "zone") {
            return true;
          }
  
          // Node on zone
          if(Utils.is_zone(target) && dragged_item.type == "virtual_node") {
            console.log("node");
            return true;
          }
  
          return false;
        }
  
        let is_zone = Utils.is_zone(target);
  
        // Node on zone
        if(is_zone &&
           Utils.is_node(dragged_item))
        {
          return this.can_move_node_to_zone(dragged_item, target)
        }
  
        if(Utils.is_node_device(dragged_item) && is_zone) {
          // Can drop if the zone is on the same controller
          // AND device is supported
          let cntrl = this.get_cntrl(target.app_prop.project_id, target.controllerId);
          let supported = CamurUtils.node_function_is_supported(dragged_item.capability);
          return (supported && (dragged_item.app_prop.cntrl_device_id == cntrl.dto.serialNumber));
        }
  
        // Node Device -> Node (of same type)
        if(Utils.is_node_device(dragged_item) && Utils.is_node(target)) {
          if (dragged_item.type == target.type)
          {
            if (dragged_item.capability == target.capability) {
              if ((dragged_item.dto.controllerSerialNumber == this.store.sltd.cntrl.dto.serialNumber)
                  && (this.store.sltd.cntrl.dto.id == target.dto.controllerId)){
                // allow drop if target not already has assigned hardware
                if (target.dto.serialNumber == null) {
                  return true;
                }
                else {
                  return false;
                }
              }
              else
                return false;
            }
            else
              return false;
          }
          else
            return false;
        }
  
        // Controller device on controller
        if(Utils.is_cntrl(target) &&
           Utils.is_cntrl_device(dragged_item))
        {
          return this.can_assign_device_to_cntrl(dragged_item, target);
        }
  
        if(Utils.is_project(target) &&
           Utils.is_cntrl_device(dragged_item)) {
              return true;
        }
  
        return false;
      },
      can_assign_device_to_cntrl (hardware, virtual_controller) {
        // TODO: should you be able to drop hardware on any controller?
        return true;
      },
  
      drop_item(item, target, i) {
        if(item.virtual) {
  
          // Virtual controller on project
          if(item.type == "controller" && Utils.is_project(target)) {
            let ctrl_data = {
              name: this.next_controller_name(target, i)
            };
            this.net.cntrl.post(target.id, ctrl_data);
          }
  
          // Virtual zone on controller
          if(item.type == "zone" && Utils.is_cntrl(target)) {
            let zone_data = {
              name: this.next_zone_name(this.get_project(target.app_prop.project_id), i),
              controllerId: target.id
            };
  
            this.net.zone.create(target.app_prop.project_id, zone_data);
          }
  
          // Virtual node on zone
          if(item.type == "virtual_node" && Utils.is_zone(target)) {
            let node_data = {
              name: item.name,
              capability: item.capability,
              gridX : 0,
              gridY : 0
            };
            this.net.node.create(target.app_prop.project_id, target.id, node_data);
          }
          return;
        }
        // Moving node to new zone
        if(Utils.is_node(item) && Utils.is_zone(target)) {
          if(this.confirm_move_node(item, target)) {
          item.change_zone(target.id);
          }
          return;
        }

        // Moving hardware to controller
        if(Utils.is_cntrl_device(item) && Utils.is_cntrl(target)) {
          if(this.confirm_assign(item, target)) {
          target.assign_device(item);
          }
          return;
        }

        // Dropping controller device on project
        if(Utils.is_cntrl_device(item) && Utils.is_project(target)) {
          console.log(item);

          let ctrl_data = {
            name: this.next_controller_name(target, 0),
            serialNumber: item.dto.serialNumber
          };
          this.net.cntrl.post(target.id, ctrl_data);

          return;
        }

        // Moving hardware to node
        if(Utils.is_node_device(item) && Utils.is_node(target)) {
          if(this.confirm_assign(item, target)) {
            target.assign_device(item.dto.serialNumber);
          }
          return;
        }

        // Moving hardware to zone
        if(Utils.is_node_device(item) && Utils.is_zone(target)) {
          let node_data = {
            name: this.get_node_type_name(
              item.capability
            ),
            capability: item.capability,
            serialNumber: item.dto.serialNumber
          };
          this.net.node.create(target.app_prop.project_id, target.id, node_data);
        }

        console.log(`Invalid drop ${item} > ${target}`)
      },
      unsubmitted_changes_in_primary_selection() {
        return (this.store.sltd.primary != null && this.store.sltd.primary.any_change);
      },
      user_token_is_valid(token) {
        //console.log("user_token_is_valid");
        if(token) {
          return (token != "undefined") && (token != USERTOKEN_LOGGED_OUT);
        }
        return false;
      },
      current_user_token_is_valid() {
        //console.log("current_user_token_is_valid");
        return this.user_token_is_valid(this.get_user_token());
      },
      get_user_token () {
        return localStorage.usertoken;
      },
      set_user_token(token) {
        this.store.session.logged_in = this.user_token_is_valid(token);  // We must set a flag so the vue components has something to detect change on
        localStorage.usertoken = token;
      },
      set_current_user(user) {
        this.store.session.current_user = user;
        localStorage.current_user_id = user.id;
      },
      reset_current_user() {
        delete this.store.session.current_user;
        delete localStorage.current_user_id;
      },  
      logged_in() {
        return (this.store.session.logged_in && (this.store.session.current_user_id != null));
      },
      log_out() {
        console.log("Logging out");
        this.store.session.logged_in = false;
        this.store.ui.select_mode = false;
        this.set_user_token(USERTOKEN_LOGGED_OUT);
        this.reset_current_user();
        // localStorage.clear();
  
        // Note: it is important to clear the network queue,
        // otherwise failed requests (401s) will block new requests
        // after the re-login
        this.net.queue.send_queued_requests(this.net.queue);
        this.net.queue.clear();
  
        console.log("clearTimeout("+this.store.session.log_out_timeout_id + ")");
        clearTimeout(this.store.session.log_out_timeout_id);
  
        // eslint-disable-next-line
        router.push({name:"login"}).catch(err => {});
        // necessary to reload to clear rights and data after logout
        // window.location.reload();
  
      },
      auto_log_out() {
        this.log_out();
        this.show_note(window.app.$txt.inactive_user_signed_out);
        alert(window.app.$txt.inactive_user_signed_out);
      },
      kick_log_out_timer() {
        //console.log("Kick logout timeout");
        //console.log("clearTimeout("+this.store.session.log_out_timeout_id + ")");
        clearTimeout(this.store.session.log_out_timeout_id);
        if (this.store.session.logged_in)
          this.store.session.log_out_timeout_id = setTimeout(this.auto_log_out, LogoutTimeout);
          //console.log("setTimeout returns" + this.store.session.log_out_timeout_id);
      },
      confirm_move_node(node, zone) {
        if(!this.can_move_node_to_zone(node, zone)) return false;
        return confirm( `${window.app.$txt.move_node}\n ${node.name} → ${zone.name}`)
      },
      confirm_assign(device, target) {
        if(target.locals.serialNumber != null ) {
          return confirm(`${window.app.$txt.assign_device} ${device.serial} -> ${target.name}?`);
        }
        return true;
      },
      show_error(code, text, entries) {
        let error_type_text = "Unknown error:";
        console.log("show_error, code:"+code);
        if (code == null) {
          error_type_text = "";
        } else {
          if(code < 600) {
            error_type_text = "HTTP-Error";
          } else {
            let error_info = this.get_error(code);
            if(error_info == null) {
              error_type_text += code;
            }
            else {
              error_type_text = error_info.text;
            }
          }  
        }
  
        if(!text) {
          text = "";
        }
  
        var max_errors = 1;
        if(this.store.ui.error_msgs.length >= max_errors) {
          this.store.ui.error_msgs.shift();
        }
        let error = new Err (code, error_type_text + " " + text, null, entries)
        this.store.ui.error_msgs.push(error);
      },
      show_error_msg(msg) {
        this.show_error(null, msg);
      },
      on_cntrl_went_offline(cntrl) {
        this.show_error(1103, ": " + cntrl.name + "(" + cntrl.serial + ")")
      },
          //400 error code, removing controller
      unable_to_delete_controller(){
        this.show_error(1007);
      },
          //423 error
      unable_to_unassign_node(){
        this.show_error(1008);
      },

      on_failed_to_invoke_method_on_server(method) {
        this.show_error(1101, window.app.$txt.could_not_invoke_on_server);
      },
  
      on_internal_server_error(request_path) {
        this.show_error(1006, request_path);
      },
      on_request_conflict() {
        this.show_error(409, window.app.langutil.http_status_to_text(409));
      },

      on_default_server_error(http_status, server_message) {
        var error_msg = window.app.langutil.http_status_to_text(http_status);
        let msg_to_send = server_message;
        if(server_message == "") msg_to_send = error_msg;
        this.show_error(http_status, null, Object.entries(server_message));
      },
  
      on_get_live_values_failed() {
        this.show_error(1102)
      },
  
      on_fv_setting_failed() {
        this.show_error(1104)
      },
  
      on_request_time_out() {
        this.show_error(1005, "Can't connect to API-server! Please check your internet connection.")
      },
      on_verify_token_expired() {
        // TODO
      },
      on_receive_token(usertoken) {
        if(this.user_token_is_valid(usertoken)) {
          this.set_user_token(usertoken);
          this.load_initial_data();
        } else {
          throw "Received invalid token"
        }
      },
      on_cntrl_job_conflict(current_job_id, task_name, project_id, cntrl_id, request_name) {
        let cntrl = this.get_cntrl(project_id, cntrl_id);
        console.assert(cntrl != null, "Controller in job conflict was not found!");
        
        console.error(`Can not complete request ${request_name} on cntrl ${cntrl.name} because it is busy with job ${task_name}`)
  
        let job = cntrl.get_job_with_id(current_job_id);
        console.table(job);
      },
      show_note(text) {
        var max_notes = 1;
        if(this.store.ui.notes.length >= max_notes) {
          this.store.ui.notes.shift();
        }
        let note = new Note(text, null);
        console.log(`Note: ${text}`);
        this.store.ui.notes.push(note);
      },
      cannot_paste_settings_different_type(type_src, type_target) {
        this.show_note(window.app.$txt.cannot_paste_settings_different_type);
      },
      session_loaded() {
        console.log("session_loaded:"+this.store.session.loaded);
        return this.store.session.loaded;
      },
      on_login_failed(){
        this.show_note(window.app.$txt.wrong_username_or_password);
      },
      on_logged_in(usertoken, logged_in_user_id) {
        this.store.session.current_user_id = logged_in_user_id;
        this.on_receive_token(usertoken)
        this.load_user();
        this.init_msg_manager();
  
        console.log("on_logged_in");
  
        let savedRoute = localStorage.getItem("C3SavedRoute");
        if (savedRoute == null) {
          // eslint-disable-next-line
          console.log("->start route...");
          router.push({name:"start"}).catch(err => {});
        } else {
          //console.log("savedRoute:"+savedRoute);
          console.log("->Saved route...");
          router.push(JSON.parse(savedRoute)).catch(err => {});
          localStorage.removeItem("C3SavedRoute");
          console.log("Removed C3SavedRoute");
        }
        // Clear timeout in case timeout in progress
        //console.log("clearTimeout("+this.store.session.log_out_timeout_id + ")");
        clearTimeout(this.store.session.log_out_timeout_id); 
        this.store.session.log_out_timeout_id = setTimeout(this.auto_log_out, LogoutTimeout);
        //console.log("setTimeout returns" + this.store.session.log_out_timeout_id);
      },
      on_require_cntrl_device() {
        this.show_note(window.app.$txt.require_cntrl_device);
      },
      on_cntrl_went_online(cntrl) {
        this.show_note(window.app.$txt.controller_reconnected + ": " + cntrl.name + "(" + cntrl.serial + ")")
      },
      on_api_call_requires_token() {
        this.show_note("This resource requires login");
        this.log_out();
      },
      on_signature_expired() {
        this.show_note(window.app.$txt.user_not_authorized);
        this.log_out();
      },
      on_server_temporarily_unavailable() {
        // eslint-disable-next-line
        router.push({name:"unavailable"}).catch(err => {});
      },
      on_download_data_finished(filename) {
        this.show_note(`✓ ${window.app.$txt.export_finished}: ${filename}`);
      },
      on_password_change(success) {
        if(success) this.show_note(window.app.$txt.password_reset_succesful)
        else this.show_note(window.app.$txt.password_reset_fail)
      },
      on_user_verified(success) {
        if(success) {
          this.show_note(window.app.$txt.user_verification_success);
          router.push({
            name: "success",
            params: { type: "verified" }
          }).catch(err => {});
        }
        else {
          this.show_note(window.app.$txt.user_verification_fail);
        }
      },
      update_decimal_point() {
        var n = 1.1;
        this.store.settings.decimal_point = n.toLocaleString().substring(1, 2);
      },
      print_formula(f) {
        let decimal_example = "X";
  
        for(let i = 0; i < f.app_prop.decimals; i++) {
          if(decimal_example.length == 1) decimal_example += ".";
          decimal_example += "X";
        }
        return `VAL × ${f.dto.gain} + ${f.dto.offset} ${decimal_example} ${f.unit}`;
      },
    };
  },
};

//Vue.use(GlobalProps);    
    
 
