<template>
  <div class="wm_title_bar">
    {{ title }}
    <span class="">
      <slot>
        default body
      </slot>
    </span>
  </div>
</template>

<script>
export default {
  name: "FilterBar",
  data() {
    return {};
  },

  props: {
    title: String,
    disabled: Boolean
  },

  methods: {},

  created: function() {}
};
</script>
