const Utils = require("../utils").default;

export class WMBaseItem {
  get id() {
    return this.dto.id;
  }
  get name() {
    return this.dto.name;
  }
  get created() {
    return this.dto.created;
  }
  get updated() {
    return this.dto.updated;
  }
  get submit_progress() {
    return this.app_prop.update_progress;
  }

  get item_type() {
    return "base";
  }

  // eslint-disable-next-line
  constructor(dto, identification = null) {
    this.Network = require("../networking").default;

    if (Utils.is_null(dto)) throw "dto is null!";
    this.app_prop = this.props_template();
    this.app_prop.update_progress = null;
    this.children = this.children_template();
    this.update_from_dto(dto, true);
  }

  update_from_dto(dto, reset_local_changes) {
    let unsubmitted_changes = Utils.deep_clone(this.changes());

    // Update prop for prop, as to support DTOs with partial info
    if (!this.hasOwnProperty("dto")) this.dto = {};
    if (!this.hasOwnProperty("locals")) this.locals = {};

    for (let prop in dto) {
      this.dto[prop] = dto[prop];
    }
    let any_change = this.any_change;
    this.locals = Utils.deep_clone(this.dto);

    this.last_refresh = Date.now();
    this.on_data_updated(dto, window.app);

    if (reset_local_changes || !any_change) {
      return;
    }
    for (let prop in unsubmitted_changes) {
      this.locals[prop] = unsubmitted_changes[prop];
    }
  }

  // eslint-disable-next-line
  on_data_updated(dto, app) {}

  changes() {
    if (!this.dto || !this.changes) return {};
    return Utils.diff_objects(this.dto, this.locals);
  }

  changes_to_props(props) {
    let result = {};
    let changes = this.changes();

    for (let changed_prop in changes) {
      if (props.includes(changed_prop)) {
        result[changed_prop] = changes[changed_prop];
      }
    }
    return result;
  }

  diff_dto(dto) {
    return Object.keys(Utils.diff_objects(this.dto, dto)).length > 0;
  }

  get any_change() {
    return Object.keys(this.changes()).length > 0;
  }

  reset_changes() {
    this.locals = Utils.deep_clone(this.dto);
  }

  submit() {
    if (this.any_change) {
      this.app_prop.update_progress = this.upload_changes();
      if (global.app) global.app.upload_req = this.app_prop.update_progress;
      return this.app_prop.update_progress;
    }
    return null;
  }

  // eslint-disable-next-line
  upload_changes(c) {
    throw "No upload routine implemented!";
  }

  get_child_by_id(col, id) {
    return Utils.array_element_by_id(col, id);
  }

  get_time_since_refresh() {
    return (Date.now() - this.last_refresh) / 1000;
  }

  props_template() {
    return {};
  }
  children_template() {
    return {};
  }

  static update_or_create_from_dto(dto, app, identification) {
    let id = dto.id;
    if (typeof id == "undefined") id = dto.serialNumber;

    var item = this.find(app, id, identification);

    if (item != null) {
      item.update_from_dto(dto, false);
    } else {
      item = this.new_item_instance(dto, identification);
      this.find_parent(app, identification).push(item);
      item.on_data_updated(dto, app);
    }
    return item;
  }

  static delete_clientside(app, identification, id) {
    let parent = this.find_parent(app, identification);  
    if (parent == null) throw "parent of object to delete cannot be null";
    if (id == null) throw "id of object to delete cannot be null";
    Utils.array_remove_object_with_id(parent, id);
  }

  // eslint-disable-next-line
  static find(app, id, identification) {
    return null;
  }

  // eslint-disable-next-line
  static find_parent(app, identification) {
    return null;
  }

  static new_item_instance(dto, identification) {
    return new WMBaseItem(dto, identification);
  }
}
