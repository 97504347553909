<template lang="html">
  <PNGIcon
    :icon="icon"
    :title="hint"
    :alarm="alarm"
    :alarm_acknowledged="alarm_acked"
  />
</template>

<script>
export default {
  name: "SmartNodeIcon",

  props: {
    node: Object
  },

  computed: {
    icon() {
      return this.$appm.get_icon_for_node_type(this.node.capability);
    },

    alarm() {
      return this.node.dto.aggregateAlarmSignal;
    },

    alarm_acked() {
      return (
        this.node.dto.aggregateAlarmSignal && !this.node.dto.aggregateAlarmUnack
      ); // TODO fix the real prop
    },

    not_connected() {
      return !this.node.connected;
    },

    hint() {
      let hint = `${this.$appm.get_node_type_name(this.node.capability)} v${
        this.node.version_full
      }`;
      hint += `\n${this.$txt.serial_short}: ${this.node.serial}`;
      if (this.not_connected) {
        hint += `\n- ${this.$txt.not_connected}`;
      }
      if (this.alarm) {
        hint += `\n- ${this.$txt.alarm}`;
      }
      return hint;
    }
  }
};
</script>

<style lang="css" scoped></style>
