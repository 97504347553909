import { APIBase } from "./apibase";
import RequestData from "./requestdata";
import Network from "@/networking";
const Utils = require("@/utils").default;

export class GraphPropsAPI extends APIBase {
  create(project_id, data) {
    let req = new RequestData(`projects/${project_id}/graph/props`);
    req.post_json = data;
    req.identification = { project_id: project_id };
    req.method = "POST";
    req.on_receive = this.receive;
    req.refresh_interval = 0;
    return this.send(req);
  }

  update(project_id, id, data) {
    let req = new RequestData(`projects/${project_id}/graph/props/${id}`);
    req.post_json = data;
    req.identification = { project_id: project_id, id: id };
    req.method = "PUT";
    req.on_receive = this.receive;
    req.refresh_interval = 0;
    return this.send(req);
  }  

  delete(project_id, id) {
    let req = new RequestData(`projects/${project_id}/graph/props/${id}`);
    req.identification = { project_id: project_id, id: id };
    req.method = "DELETE";
    req.on_receive = this.receive_delete;
    req.refresh_interval = 0;
    return this.send(req);
  }  

  list(project_id, options) {
    let req = new RequestData(
      `projects/${project_id}/graph/props`,
      options
    );
    req.identification = { project_id: project_id };
    req.method = "GET";
    req.on_receive = this.receive_array;
    req.refresh_interval = 0;
    return this.send(req);
  }
  // eslint-disable-next-line
  receive(response, identification, myself) {
    Network.graph_props.list(identification.project_id);
  }

  receive_delete(response, identification, myself) {
    Network.graph_props.list(identification.project_id);
  }

  receive_array(response, identification, myself) {
    window.console.log("GraphProps received, length:" + response.length);
    myself.app.$appm.store.graph_props = response;
    for (var i = 0; i < response.length; i++) {
      myself.app.$appm.store.graph_props[i].title = response[i].name;
//      window.console.log(JSON.stringify(response[i]));
    }  
  }
}
