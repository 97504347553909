import { WMBaseItem } from "./baseitem";

export default class CPData extends WMBaseItem {
  get preCommOff() {
    return this.dto.preCommOff;
  }

  get preCommLPR() {
    return this.dto.preCommLPR;
  }

  get area() {
    return this.dto.area;
  }

  constructor(dto, identification) {
    super(dto);
    this.app_prop.project_id = identification.project_id;
  }

  props_template() {
    return {
      project_id: 0
    };
  }

  upload_changes() {
    return this.Network.cpdata.update(
      this.app_prop.project_id,
      this.id,
      this.changes()
    );
  }

  static new_item_instance(dto, identification) {
    return new CPData(dto, identification);
  }

  static find_parent(app, identification) {
    return app.$appm.get_project(identification.project_id).cpdata;
  }

  static find(app, id, identification) {
    return app.$appm.get_cp_data(identification.project_id, id);
  }
}
