<template>
  <div
    id="project_tree"
    class=" project_tree ProjectTree"
    :style="{ width: width_px }"
    :class="{ project_tree_tight: $ui.tight_mode }"
    @mouseenter="display_arrow = true"
    @mouseleave="display_arrow = false"
  >
    <Resizer v-model="width" :min="250" :max="400" />
    <ProjectTreeIndicatorArrow v-if="$ui.tight_mode && display_arrow" />
    <ProjectTreeFilter v-model="filter_text" :tight_mode="$ui.tight_mode" />
    <span v-if="hovered_area != null">{{ hovered_area.serial }}</span>

    <div
      id="project_tree_main"
      class="project_tree_main"
      v-if="$app.sltd.project"
    >
      <div class="nobreak">
        <TreeOpenCloseBtn v-model="$app.sltd.project.app_prop.tree_open" />
        <ProjectTreeItem
          :item="$app.sltd.project"
          route_to="project"
          :route_params="{ project_id: $app.sltd.project.id }"
          @include_click="include_item($app.sltd.project)"
        />
        <PadlockToggle
          style="float: right"
          v-if="$app.sltd.project.dto.canSetup"
          v-model="$app.sltd.project.app_prop.edit_lock"
          :title="padlock_hint"
          @lock="lockProject()"
        />
        <IconToggle
          style="float: right"
          @click="
            $app.sltd.project.toggle_all_tree_open(expand_or_collapse)
          "
          v-model="expand_or_collapse"
          icon_open="unfold_less"
          icon_closed="unfold_more"
          :hint_open="$txt.collapse_all"
          :hint_closed="$txt.expand_all"
        />
      </div>

      <ul class="project_tree_ul" v-show="$app.sltd.project.app_prop.tree_open">
        <transition-group name="fade">
          <li
            class="project_tree_li"
            v-for="controller in sorted_cntrls"
            :key="controller.id"
            :class="{
              project_tree_drop_area_hover:
                $appm.can_drop_dragged_on_target(controller) &&
                hovered_area == controller
            }"
          >
            <p class="project_tree_invisiblen_zone_text" v-if="filtered">
              {{ controller.name }}
            </p>

            <TreeOpenCloseBtn
              :modelValue="get_open(controller)"
              @update:modelValue="toggle_zone_open(controller, $event)"
            />

            <ProjectTreeItem
              :item="controller"
              route_to="controller"
              :route_params="{
                project_id: $app.sltd.project.id,
                controller_id: controller.id
              }"
              @include_click="include_item(controller)"
            />

            <ul class="project_tree_ul" v-show="controller.app_prop.tree_open">
              <transition-group name="fade">
                <li
                  class="project_tree_li"
                  v-for="zone in zones_for_controller(controller)"
                  :key="zone.id"
                >
                  <p class="project_tree_invisiblen_zone_text" v-if="filtered">
                    {{ zone.name }}
                  </p>

                  <span v-show="match_filter(zone)">
                    <TreeOpenCloseBtn
                      :modelValue="get_open(zone)"
                      v-if="!filtered"
                      @update:modelValue="toggle_zone_open(zone, $event)"
                    />
                    <div v-else class="project_tree_spacer">&nbsp;</div>

                    <ProjectTreeItem
                      :item="zone"
                      route_to="zone"
                      :route_params="{
                        project_id: $app.sltd.project.id,
                        zone_id: zone.id
                      }"
                      :parent_include="true"
                      :node_include="zone.include_select"
                      @include_click="include_item(zone)"
                    />
                  </span>

                  <ul
                    class="project_tree_ul"
                    v-show="zone.tree_open || (!filtered && filter_text != '')"
                  >
                    <li
                      class="project_tree_li"
                      v-for="node in sorted_nodes_for_zone(zone)"
                      :key="node.id"
                      @dragstart="drag_node_start($event, node)"
                    >
                      <span v-show="match_filter(node)">
                        <div class="project_tree_spacer">&nbsp;</div>
                        <ProjectTreeItem
                          :draggable="$app.sltd.project.edit_locked ? false : true"
                          :item="node"
                          route_to="node"
                          :route_params="{
                            project_id: $app.sltd.project.id,
                            zone_id: node.dto.zoneId,
                            node_id: node.id
                          }"
                          @include_click="include_item(node)"
                        />
                      </span>
                    </li>
                    <ProjectTreeBtn
                      v-if="!filtered && !padlocked"
                      :title="$txt.new_node"
                      :add="true"
                      @click="new_node(zone.id, $app.sltd.prj_id)"
                      @drop_on_btn="
                        new_node_from_drop(zone.id, $app.sltd.prj_id)
                      "
                      :dragged_type="dragged_type"
                      :accept_drop_type="['nodedevice', 'virtual_node']"
                      :cntrl_device_id="controller.serial"
                    />
                  </ul>
                  <hr v-if="!match_filter(zone)" class="tight-hr" />
                </li>
              </transition-group>
              <ProjectTreeBtn
                v-if="!filtered && !padlocked"
                :add="true"
                :title="$txt.new_zone"
                @drop_on_btn="
                  new_zone_from_drop($app.sltd.prj_id, controller.id)
                "
                @click="new_zone(controller)"
                :dragged_type="dragged_type"
                :accept_drop_type="['node', 'virtual_zone']"
              />
            </ul>
          </li>
        </transition-group>
      </ul>
      <ProjectTreeBtn
        v-if="!filtered && !padlocked"
        :add="true"
        :title="$txt.new_controller"
        @click="new_controller($app.sltd.project)"
        @drop_on_btn="new_controller_from_device($app.sltd.prj_id)"
        :dragged_type="dragged_type"
        :accept_drop_type="['cntrldevice', 'virtual_controller']"
      />
    </div>

    <Modal
      :title="$txt.new_zone"
      v-if="new_zone_modal"
      @close="new_zone_modal = false"
    >
      <LabeledInput :label="$txt.name" v-model="new_item_name" />
      <DropDown
        v-model="new_zc_id"
        :title="$txt.cntrl"
        :opts="available_cntrls"
      />

      <template v-slot:footer>
        <Btn
          :title="$txt.create"
          @clicked="
            create_zone();
            new_zone_modal = false;
          "
          :disabled="(new_item_name.length < 1 || new_zc_id == 0) ? true : null"
        />
        <Btn
          class="hol_btn"
          :title="$txt.cancel"
          @clicked="new_zone_modal = false"
        />
      </template>
    </Modal>

    <Modal
      :title="$txt.new_controller"
      v-if="new_ctrl_modal"
      @close="new_ctrl_modal = false"
    >
      <LabeledInput :label="$txt.name" v-model="new_item_name" />

      <template v-slot:footer>
        <Btn
          :title="$txt.create"
          @clicked="
            create_ctrl($app.sltd.prj_id);
            new_ctrl_modal = false;
          "
          :disabled="(new_item_name.length < 1) ? true : null"
        />
        <Btn
          class="hol_btn"
          :title="$txt.cancel"
          @clicked="new_ctrl_modal = false"
        />
      </template>
    </Modal>

    <Modal
      :title="$txt.new_node"
      v-if="new_node_modal"
      @close="new_node_modal = false"
    >
      <LabeledInput :label="$txt.name" v-model="new_item_name" />
      <EnumDropDown
        :title="$txt.type"
        v-model="new_node_type"
        :enum_to_choose_from="$camur.node_type"
      ></EnumDropDown>

      <template v-slot:footer>
        <Btn
          :title="$txt.create"
          @clicked="
            create_node();
            new_node_modal = false;
          "
          :disabled="(new_item_name.length < 1) ? true : null"
        />
        <Btn
          class="hol_btn"
          :title="$txt.cancel"
          @clicked="new_node_modal = false"
        />
      </template>
    </Modal>
  </div>
</template>

<script>
import ProjectTreeItem from "./ProjectTreeItem";
import ProjectTreeBtn from "./ProjectTreeBtn";
import ProjectTreeFilter from "./ProjectTreeFilter";
import ProjectTreeIndicatorArrow from "./ProjectTreeIndicatorArrow";
import TreeOpenCloseBtn from "./TreeOpenCloseBtn";

export default {
  name: "ProjectTree",
  components: {
    ProjectTreeItem,
    ProjectTreeFilter,
    ProjectTreeIndicatorArrow,
    ProjectTreeBtn,
    TreeOpenCloseBtn
  },
  data() {
    return {
      filter_open: true,
      filter_text: "",
      display_arrow: false,
      dragged_node: null,
      hovered_area: null,
      width: 275,

      new_zc_id: 0,
      new_zone_modal: false,

      new_ctrl_modal: false,
      new_node_modal: false,
      new_node_type: 14,
      new_node_prj_id: 0,
      new_node_zone_id: 0,
      new_item_name: "",

      expand_or_collapse: false,
      selectedCapability: null
    };
  },

  created() {
    this.fetch_required_data();
    setTimeout(this.fetch_required_data, 500); // Push to top of event queue

    // Is causing too much traffic and load for big projects
    //setInterval(this.fetch_required_data, 5000);
  },

  methods: {
    cntrl_of_node(node) {
      if (!node) {
        return;
      }
      if (!node.dto) {
        return;
      }
      for (let cntrl of this.$app.sltd.project.cntrls) {
        if (node.dto.controllerId === cntrl.id) {
          return cntrl;
        }
      }
    },
    lockProject() {
      // console.log("lockProject");
      this.$app.sltd.project.app_prop.edit_lock = !this.$app.sltd.project.app_prop.edit_lock;
      // console.log(this.$app.sltd.project.app_prop.edit_lock ? "true":"false");
    },
    include_item(item) {
      // console.log(this.$app.sltd.project)
//      console.log("ProjectTree.include_item");
//      console.log(item);
      if (
        this.$route.name === 'config_multiple_nodes' && 
        item.item_type === "node"
      ) {
        //check if p4 and p4i
        // Check if the selected capability is different from the item's capability
        if (!this.matchingCapability(item)) {
          // Deselect all nodes if the capabilities are different
          for (let node of this.$app.sltd.project.all_nodes || []) {
            if (
              node && 
              node.app_prop
            ) {
              node.app_prop.include_select = false;
            }
          }
          this.$app.sltd.project.app_prop.include_select = false;
          for (let cntrl of this.$app.sltd.project.cntrls || []) {
            if (
              cntrl && 
              cntrl.app_prop
            ) {
              cntrl.app_prop.include_select = false;
            }
            for (let zone of cntrl.zones || []) {
              if (
                zone && 
                zone.app_prop
              ) {
                zone.app_prop.include_select = false;
              }
            }
          }

          // Update the selected capability
          this.selectedCapability = item.capability;
        }
        // Select the current node
        // item.app_prop.include_select = !item.app_prop.include_select;
      }
      
      if (this.$route.name == 'config_multiple_nodes') {
        item.app_prop.include_select = !item.app_prop.include_select;
        let anySelected = false;
        
        for (let node of this.$app.sltd.project.all_nodes) {
          if (node.app_prop.include_select == true) {
            anySelected = true;
          }
        }
        if (anySelected == false) {
          this.selectedCapability = null;
        }
      }
      // console.log(this.selectedCapability)
      
      if (item.item_type == "zone") {
        let anySelectedNodesRecording = false;
        for (let node of item.nodes) {
          if (this.$route.name === 'config_multiple_nodes') {
            if (this.matchingCapability(node)) 
            {
              for (let node_ of this.$app.sltd.project.all_nodes) {
                if (node_.app_prop.include_select) {
                  if (this.cntrl_of_node(node_).dto.recordingMode > this.$camur.recording_type.rectype_monitor) {
                    anySelectedNodesRecording = true;
                    break;
                  }
                }
              }
              if ((this.cntrl_of_node(node).dto.recordingMode > this.$camur.recording_type.rectype_monitor) === anySelectedNodesRecording) {
                node.app_prop.include_select = item.app_prop.include_select;
              }
            }
          } else {
            // Require assigned for project live values
            if (
              (node.serial > 0) || 
              (this.$ui.select_mode_level == this.$camur.select_mode_level.controller)
            ) {
              node.app_prop.include_select = item.app_prop.include_select;
            }
          }

          //console.log(node);
        }
      }
      else if (item.item_type == "cntrl") {
        let zones = this.zones_for_controller(item)
        for (let zone of zones) {
          zone.app_prop.include_select = item.app_prop.include_select;
          if (this.$route.name === 'config_multiple_nodes') {
            for (let node of zone.nodes) {
              if (this.matchingCapability(node)) {
                zone.app_prop.include_select = item.app_prop.include_select;
              }
            }
          }
          //console.log(zone);
        }
        for (let node of item.nodes) {
          if (this.$route.name === 'config_multiple_nodes') {
            if (this.matchingCapability(node)) {
              let anySelectedNodesRecording = false;
              for (let node_ of this.$app.sltd.project.all_nodes) {
                if (node_.app_prop.include_select) {
                  if (this.cntrl_of_node(node_).dto.recordingMode > this.$camur.recording_type.rectype_monitor) {
                    anySelectedNodesRecording = true;
                    break;
                  }
                }
              }
              if ((this.cntrl_of_node(node).dto.recordingMode > this.$camur.recording_type.rectype_monitor) === anySelectedNodesRecording) {
                node.app_prop.include_select = item.app_prop.include_select;
              }
            }
          } else {          
              // Require assigned for project live values
            if (
              (node.serial > 0) || 
              (this.$ui.select_mode_level == this.$camur.select_mode_level.controller)
            ) {
              node.app_prop.include_select = item.app_prop.include_select;
            }
          }
          //console.log(node);
        }
      }
      else if (item.item_type == "project") {
        for (let controller of item.cntrls) {
          controller.app_prop.include_select = item.app_prop.include_select;

          for (let node of controller.nodes) {
            if (this.$route.name === 'config_multiple_nodes') {
              if (this.matchingCapability(node)) {
                controller.app_prop.include_select = item.app_prop.include_select;
              }
            }
          }
        }
        for (let zone of item.zones) {
          zone.app_prop.include_select = item.app_prop.include_select;
          for (let node of zone.nodes) {
            if (this.$route.name === 'config_multiple_nodes') {
              if (this.matchingCapability(node)) {
                let anySelectedNodesRecording = false;
                for (let node_ of this.$app.sltd.project.all_nodes) {
                  if (node_.app_prop.include_select) {
                    if (this.cntrl_of_node(node_).dto.recordingMode > this.$camur.recording_type.rectype_monitor) {
                      anySelectedNodesRecording = true;
                      break;
                    }
                  }
                }
                if ((this.cntrl_of_node(node).dto.recordingMode > this.$camur.recording_type.rectype_monitor) === anySelectedNodesRecording) {
                  node.app_prop.include_select = item.app_prop.include_select;
                }
              }
            } else {
              // Require assigned for project live values
              if (
                (node.serial > 0) || 
                (this.$ui.select_mode_level == this.$camur.select_mode_level.controller)
              ) {
                node.app_prop.include_select = item.app_prop.include_select;
              }
            }
            //console.log(node);
          }
        }
      }
      let anyNodeSelected = false;
      let allNodesWithSameCapabilityInProjectSelected = true;
      let allNodesWithSameCapabilityInCntrlSelected = true;
      let allNodesWithSameCapabilityInZoneSelected = true;
      let hasNodesWithSelectedCapability = false;
      let anySelectedNodesRecording = false;
      for (let node_ of this.$app.sltd.project.all_nodes) {
        if (node_.app_prop.include_select) {
          if (this.cntrl_of_node(node_).dto.recordingMode > this.$camur.recording_type.rectype_monitor) {
            anySelectedNodesRecording = true;
            break;
          }
        }
      }

      if (this.$route.name === 'config_multiple_nodes') {
        // Check if any node in the project is selected
        for (let node of this.$app.sltd.project.all_nodes) {
          if (node.app_prop.include_select) {
            anyNodeSelected = true;
            break;
          }
        }

        // Check if all nodes with the same capability in the project are selected
        for (let node of this.$app.sltd.project.all_nodes) {
          if (this.matchingCapability(node)) {
            hasNodesWithSelectedCapability = true;
            if (
              !node.app_prop.include_select && 
              (this.cntrl_of_node(node).dto.recordingMode > this.$camur.recording_type.rectype_monitor) === anySelectedNodesRecording
            ) {
              allNodesWithSameCapabilityInProjectSelected = false;
            }
          }
        }

        if (
          !anyNodeSelected || 
          !allNodesWithSameCapabilityInProjectSelected
        ) {
          this.$app.sltd.project.app_prop.include_select = false;
        } else {
          this.$app.sltd.project.app_prop.include_select = true;
        }

        // Check if all nodes with the same capability in each controller are selected
        for (let cntrl of this.$app.sltd.project.cntrls) {
          allNodesWithSameCapabilityInCntrlSelected = true;
          hasNodesWithSelectedCapability = false;
          for (let node of cntrl.nodes || []) {
            if (this.matchingCapability(node)) {
              hasNodesWithSelectedCapability = true;
              if (!node.app_prop.include_select) {
                allNodesWithSameCapabilityInCntrlSelected = false;
              }
            }
          }
          if (!hasNodesWithSelectedCapability) {
            allNodesWithSameCapabilityInCntrlSelected = false;
          }
          cntrl.app_prop.include_select = allNodesWithSameCapabilityInCntrlSelected;
        }

        // Check if all nodes with the same capability in each zone are selected
        for (let zone of this.$app.sltd.project.zones) {
          allNodesWithSameCapabilityInZoneSelected = true;
          hasNodesWithSelectedCapability = false;
          for (let node of zone.nodes || []) {
            if (this.matchingCapability(node)) {
              hasNodesWithSelectedCapability = true;
              if (!node.app_prop.include_select) {
                allNodesWithSameCapabilityInZoneSelected = false;
              }
            }
          }
          if (!hasNodesWithSelectedCapability) {
            allNodesWithSameCapabilityInZoneSelected = false;
          }
          zone.app_prop.include_select = allNodesWithSameCapabilityInZoneSelected;
        }
      }
    },
    toggle_zone_open(zone, open) {
      zone.app_prop.tree_open = open;
    },

    new_zone(ctrl) {
      this.new_zc_id = ctrl.id;
      this.new_zone_modal = true;
    },

    new_node(zone_id, prj_id) {
      this.new_node_zone_id = zone_id;
      this.new_node_prj_id = prj_id;
      this.new_node_modal = true;
    },

    new_node_from_drop(zone_id, prj_id) {
      let node_data = {};

      if (this.$app.dragged_item.virtual) {
        node_data = {
          name: this.$app.dragged_item.name,
          capability: this.$app.dragged_item.type,
          gridX : 0,
          gridY : 0
          // serialNumber: 0
        };
      } else {
        if (!this.$util.is_node_device(this.$app.dragged_item)) {
          return;
        }

        let name = `${this.$appm.get_node_type_name(
          this.$app.dragged_item.dto.capability
        )}`;

        node_data = {
          name: name,
          capability: this.$app.dragged_item.dto.capability,
          serialNumber: this.$app.dragged_item.dto.serialNumber,
          gridX : gp.x,
          gridY : gp.y
        };
      }

      this.$net.node.create(prj_id, zone_id, node_data);
    },

    new_zone_from_drop(prj_id, cntrl_id) {
      let data = {
        name: this.$app.dragged_item.name,
        controllerId: cntrl_id
      };
      this.$net.zone.create(prj_id, data);
    },

    new_controller() {
      this.new_ctrl_modal = true;
      this.new_item_name = this.next_controller_name();
    },

    next_controller_name() {
      return `${this.$txt.cntrl} ${this.$app.sltd.project.children.cntrls
        .length + 1}`;
    },

    new_controller_from_device(prj_id) {
      let data = { name: this.next_controller_name() };

      if (this.$app.dragged_item.virtual) {
        data.serialNumber = 0;
      } else {
        if (!this.$util.is_cntrl_device(this.$app.dragged_item)) {
          return;
        }
        data.serialNumber = this.$app.dragged_item.serial;
      }
      this.$net.cntrl.post(prj_id, data);
    },

    create_zone: function() {
      let zone_data = {
        name: this.new_item_name,
        controllerId: parseInt(this.new_zc_id)
      };
      this.$net.zone.create(this.$app.sltd.project.id, zone_data);
    },

    create_ctrl: function(prj_id) {
      let ctrl_data = {
        name: this.new_item_name
      };
      this.$net.cntrl.post(prj_id, ctrl_data);
    },

    create_node() {
      let node_data = {
        name: this.new_item_name,
        capability: this.new_node_type,
        gridX : 0,
        gridY : 0
      };

      this.$net.node.create(
        this.new_node_prj_id,
        this.new_node_zone_id,
        node_data
      );
    },

    drag_node_start(ev, node) {
      if (ev) {
        ev.dataTransfer.setData("text", "drag_type_node");
        this.$app.dragged_item = node;
      }
    },

    get_open(zone) {
      return zone.app_prop.tree_open;
    },

    fetch_required_data() {
      if (this.$app.sltd.prj_id) {
        this.$net.project.fetch(this.$app.sltd.prj_id);
        this.$net.cntrl.list(this.$app.sltd.prj_id);
        this.$net.cntrl_device.list(this.$app.sltd.prj_id);
        this.$net.zone.list(this.$app.sltd.prj_id);
        this.$net.graph_props.list(this.$app.sltd.prj_id);
        //this.$net.alarm_subscribers.list(this.$app.sltd.prj_id);

        if (this.$app.sltd.project) {
          for (let zone of this.$app.sltd.project.zones) {
            this.$net.node.list(this.$app.sltd.prj_id, zone.id);
          }
        }
      }
    },

    zone_has_nodes(z) {
      return z != null && z.nodes.length > 0;
    },

    zones_for_controller(controller) {
      let zones = this.$appm.get_project(this.$app.sltd.prj_id).zones;
      zones = zones.filter(zone => zone.controllerId == controller.id);
      return this.$util.sort_table(zones, "name");
    },

    match_filter: function(item) {
      var name_match = this.$util.search_matches(item.name, this.filter_text);

      var type_match = false;
      if (item.type != "zone") {
        var type_name = this.$appm.get_node_type_name(item.type);
        type_match = this.$util.search_matches(type_name, this.filter_text);
      }

      var serial_match = false;
      if (item.type != "node") {
        serial_match = this.$util.search_matches(
          String(item.serial),
          this.filter_text
        );
      }

      var status_match = false;
      if (this.$util.search_matches(item.status, this.filter_text)) {
        status_match = true;
      }

      return name_match || type_match || serial_match || status_match;
    },

    is_selected: function(item) {
      if (
        this.$util.is_node(item) && 
        this.$app.sltd.node != null
      ) {
        return item.id == this.$app.sltd.node.id;
      } else if (
        this.$util.is_zone(item) && 
        this.$app.sltd.zone != null
      ) {
        return item.id == this.$app.sltd.zone.id;
      } else if (
        this.$util.is_project(item) &&
        this.$app.sltd.project != null
      ) {
        return item.id == this.$app.sltd.project.id;
      } else if (
        this.$util.is_controller(item) &&
        this.$app.sltd.project != null
      ) {
        return item.id == this.$app.sltd.controller_id;
      } else return false;
    },

    is_secondary_selected(item) {
      if (this.$util.is_zone(item)) {
        return this.is_selected(item) && this.$app.sltd.node != null;
      } else if (this.$util.is_project(item)) {
        return this.is_selected(item) && this.$app.sltd.controller_id != null;
      } else if (this.$util.is_controller(item)) {
        return this.is_selected(item) && this.$app.sltd.zone != null;
      } else {
        return false;
      }
    },

    sorted_nodes_for_zone(zone) {
      let name_sorted = this.$util.sort_table(zone.nodes_unsubmitted, "name");

      return this.$util.sort_table(name_sorted, "type");
    },

    matchingCapability(node) {
        if (
          node.capability === this.selectedCapability || 
          node.capability === this.$camur.node_type.ntype_p4 && 
          this.selectedCapability === this.$camur.node_type.ntype_p4i || 
          node.capability === this.$camur.node_type.ntype_p4i && 
          this.selectedCapability === this.$camur.node_type.ntype_p4
        ) {
          return true;
      } else {
          return false;
      }
    }
  },

  computed: {
    filtered() {
      return this.filter_text != "";
    },

    drop_allowed() {
      return this.$appm.can_drop_dragged_on_target(this.hovered_area);
    },

    sorted_cntrls() {
      return this.$util.sort_table(this.$app.sltd.project.cntrls, "name");
    },

    select_mode() {
      return this.$ui.select_mode;
    },

    available_cntrls() {
      return this.$util.list_cntrls_for_project(
        this.$app.sltd.project,
        this.$txt.none_single
      );
    },

    padlocked() {
      return this.$app.sltd.project.edit_locked;
    },

    padlock_hint() {
      if (this.padlocked) return this.$txt.enable_prj_edits;
      else return this.$txt.disable_prj_edits;
    },

    dragging() {
      return this.$app.dragged_item != null;
    },

    dragged_type() {
      if (!this.dragging) return "none";
      if (this.$app.dragged_item.virtual)
        return this.$app.dragged_item.drag_type;
      if (this.$util.is_node(this.$app.dragged_item)) return "node";
      if (this.$util.is_node_device(this.$app.dragged_item))
        return "nodedevice";
      if (this.$util.is_zone(this.$app.dragged_item)) return "zone";
      if (this.$util.is_project(this.$app.dragged_item)) return "project";
      if (this.$util.is_cntrl(this.$app.dragged_item)) return "cntrl";
      if (this.$util.is_cntrl_device(this.$app.dragged_item))
        return "cntrldevice";
      else return "unknown";
    },

    width_px() {
      return this.width + "px";
    }
  }
};
</script>

<style>
.fade-move {
  transition: transform 0.1s;
}
</style>
