export default {
  show_hide_sidebar: "menu",
  app_settings: "settings",
  account: "account_circle",
  admin: "security",
  customers: "account_balance",
  power_supply: "offline_bolt",
  projects: "folder",
  project: "folder_open",
  users: "group",
  user: "person",
  create_new_project: "create_new_folder",
  create_new_customer: "account_balance",
  create_new_user: "group_add",
  sidebar_settings: "build",
  filter_search: "search",
  zoom_area: "search",
  clear_search: "clear",
  clear_zoom_and_offset: "clear",
  zone: "router",
  node: "scanner",
  adv_node: "ballot",
  online: "cloud",
  arrow_right: "chevron_right",
  arrow_left: "chevron_left",
  admin_users: "security",
  company_users: "account_balance",
  external_users: "group_add",
  user_group_default: "group",
  support_users: "settings",
  graph_settings: "settings",
  download_graph_img: "download",
  delete: "delete",
  yes: "done",
  no: "clear",
  visible: "visibility",
  not_visible: "visibility_off",
  no_data: "grid_off",
  open_tree: "format_indent_increase",
  close_tree: "format_indent_decrease",
  drag_handle: "drag_indicator",
  loading: "cached",
  locked: "lock",
  padlock_open: "lock_open-black",
  padlock_locked: "lock-black"
};
