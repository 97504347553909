/***** Import components *****/
import router from './router';
import {DataStore} from './datastore';
import Network from "@/networking";


/***** Import all global Vue-components *****/
const global_components = require.context(
  './components/global',
  // Whether or not to look in subfolders
  true,
  // The regular expression used to match base component filenames
  /[A-Z]\w+\.(vue|js)$/
);

/* global_components.keys().forEach(filename => {
  const comp = global_components(filename); // Import the file
  const name = filename.replace(/^(\.\/\w+|\.)\/(.*)\.\w+$/, '$2'); // Remove file extension from filename
  Vue.component(name, comp.default || comp); // Add component
}); */

/***** Install plugins *****/

/***** Setup app *****/
const Enum = require('@/utils/enums').default;
const CamurUtils = require('@/utils/camurutils').default;
const Utils = require('@/utils').default;
const Icon = require('@/utils/icons').default;

import { createApp} from 'vue'
import SvgIcon from "vue3-icon";
import App from './App.vue';
import { GlobalProps } from './globalprops';
import LangUtils from '@/utils/langutils';


console.log("createApp");
const app = createApp(App);

app.use(router);
//app.use(DataStore);
app.use(GlobalProps);
//app.use(Network);
global_components.keys().forEach(filename => {
  const comp = global_components(filename); // Import the file
  const name = filename.replace(/^(\.\/\w+|\.)\/(.*)\.\w+$/, '$2'); // Remove file extension from filename
  app.component(name, comp.default || comp); // Add component
});
app.component("svg-icon",SvgIcon);
console.log("mount");
app.mount("#appmain");


app.$appm = app.config.globalProperties.$appm;
app.$appg = app.config.globalProperties.$appg;

console.log("LangUtils");
app.$appg.langutil = new LangUtils(app);

app.config.globalProperties.$icon = Icon;
app.config.globalProperties.$ui = DataStore.ui;
app.config.globalProperties.$app = DataStore;
app.config.globalProperties.$ds = DataStore;
app.config.globalProperties.$net = Network;
app.config.globalProperties.$util = Utils;
app.config.globalProperties.$camur = CamurUtils;
app.config.globalProperties.$enum = Enum;
app.settings = DataStore.settings;
app.users = DataStore.users;
app.session = DataStore.session;
app.customers = DataStore.customers;
app.projects = DataStore.projects;
app.sltd = DataStore.sltd;
app.langutil = app.$appg.langutil;
app.event_log = DataStore.event_log;
app.service_log = DataStore.service_log;
app.firmware = DataStore.firmware;
app.ui = DataStore.ui;
app.grants = DataStore.grants;

console.log("read_settings");
let api_settings = await read_settings();
console.log("Settings read");

global.app = app;
window.app = app;
window.net = app.$net;
window.appm = app.$appm;
global.default_refresh_interval = 60;

Network.init(app);
console.log("Network initialized");
Network.api_server = api_settings.api_server;  
console.log("Api server assigned");

console.log("Calling init()");
app.$appm.init(Network, DataStore);
console.log("Global props initialized");

/***** Global scope functions *****/
const TIGHT_MODE_THRESHOLD = 650;
function set_tight_mode(mode) {
  if(mode == DataStore.ui.tight_mode) return;
  DataStore.ui.tight_mode = mode;
  DataStore.ui.project_tree_keep_open = false;
};
function resize()
{
  // Set width flag
  if(window.innerWidth < TIGHT_MODE_THRESHOLD) {
    set_tight_mode(true)
  }
  else {
    set_tight_mode(false)
  }
};
function set_mousedown(down) {
  DataStore.ui.mousedown = down;
};

window.onresize = resize;
window.onDomChange = resize;
window.onload = resize;

document.body.onmousedown = function() {
  set_mousedown(true);
}
document.body.onmouseup = function() {
  set_mousedown(false);
}

async function read_settings() {
  let path = '/static/api_settings.json';
  if (process.env.NODE_ENV == 'production') {
    path = '/monitor/static/api_settings.json'
  } 
  let response = await fetch(path);
  let api_settings = await response.json();
  return api_settings;
}


