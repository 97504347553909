<template lang="html">
  <PNGIcon
    :icon="icon"
    :alarm="alarm"
    :title="hint"
    :alarm_acknowledged="alarm_acked"
  />
</template>

<script>
export default {
  name: "SmartCntrlIcon",

  props: {
    cntrl: Object
  },

  computed: {
    icon() {
      return "controller";
    },

    alarm() {
      return this.cntrl.alarm;
    },

    alarm_acked() {
      return this.cntrl.alarm && !this.cntrl.dto.aggregateAlarmUnack;
    },

    not_connected() {
      return !this.cntrl.connected;
    },

    hint() {
      let serial = "(Unassigned)";
      if (this.cntrl.device != null) serial = this.cntrl.device.serial;
      let hint = `Controller ${serial}`;
      if (this.not_connected) {
        hint += `\n- ${this.$txt.not_connected}`;
      }
      if (this.alarm) {
        hint += `\n- ${this.$txt.alarm}`;
      }
      return hint;
    }
  }
};
</script>

<style lang="css" scoped></style>
