<template>
  <div>
    <div
      :style="{ height: height_px }"
      class="progress"
      id="this_progress_bar"
      :class="{ _hide: !this.show }"
    >
      <div
        class="progress-bar"
        role="progressbar"
        :aria-valuenow="now"
        :aria-valuemin="min"
        :aria-valuemax="max"
        style="border-radius: 0px"
        :class="{ _done: max == now }"
        :style="{ width: percentage }"
      ></div>
    </div>
  </div>
</template>

<script>
export default {
  // eslint-disable-next-line
  name: "Progressbar",
  data() {
    return {};
  },

  props: {
    height: {
      type: Number,
      default: 2
    },
    min: {
      type: Number,
      default: 0
    },
    max: Number,
    now: Number,
    show: {
      type: Boolean,
      default: true
    }
  },

  computed: {
    percentage: function() {
      return ((this.now - this.min) / (this.max - this.min)) * 100 + "%";
    },

    height_px() {
      return this.height + "px";
    }
  },

  created: function() {}
};
</script>

<style lang="css">
#this_progress_bar {
  border-radius: 2px;
  margin-bottom: -4px;
}
._hide {
  visibility: hidden;
}
._done {
  background-color:green;
}
</style>
