<template lang="html">
  <div class="Picker wm_picker">
    <div class="ab_bgr" style="width: 100%">
      <a
        v-for="opt in options"
        :key="opt.val"
        class="btn_ab"
        @click="$emit('input', opt.val)"
        :class="{ btn_ab_sel: value != opt.val }"
      >
        {{ opt.title }}
      </a>
    </div>
  </div>
</template>

<script>
export default {
  // eslint-disable-next-line
  name : "Picker",
  props: {
    value: {
      default: "a"
    },
    options: {
      type: Array,
      default() {
        return [
          {
            title: "A",
            val: "a"
          },
          {
            title: "B",
            val: "b"
          }
        ];
      }
    }
  }
};
</script>

<style lang="css" scoped></style>
