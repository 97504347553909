<template>
  <div class="">
    <div v-if="is_connected">

      <TimeGraph
        :graph_buffer="this.graph_buffer"
        @fill_channels="fillChannels()"
        @clear_channels="clearChannels()"
        @select_nodes="selectNodes()"
        @done_select_nodes="doneSelectNodes()"
        :series="channel_series"
        :fixed_width="1200"
        :start_date="subscribe_time"
        :end_date="end_date"
        :fixed_height="500"
        :disable_zoom="true"
        :show_channel_controls="true"
        :autoshift="true"
        :unique_identifier="'g_2'"
        :firstYAxisLeft="false"
        :has_hold_values="true"
        :has_fill_channels="hasFillChannels"
        :has_select_nodes="hasSelectNodes"
        :zone="$app.sltd.zone"
        :nodes="nodes"
        :default_graph_description="defaultGraphDescription"
        :local_storage_id="localStorageId"
        :controller="controller"
        :select_mode="select_mode"
        @toggle-hidden-series="handleToggleHiddenSeries"
        @select-deselect-all-series="handleSelectDeselectAllSeries"
        @reset-graph-buffer="handleResetGraphBuffer"
        @set-hidden-series="handleSetHiddenSeries"
      />
    </div>
    <NoDataLabel v-else :text="disconnected_text" />
    <Section
      :title = "$txt.channels"
    >
      <ResponsiveTable>
        <template v-slot:head>
          <span>
            <tr class="wm_left_cell">
              <th>{{ $txt.name }}</th>
              <th>{{ $txt.timestamp }}</th>
              <th>{{ $txt.live_value }}</th>
              <th>{{ $txt.unit }}</th>
              <th>{{ $txt.timestamp }}</th>
              <th>{{ $txt.held_value }}</th>
            </tr>
          </span>
        </template>
        <tbody class="wm_left_cell">
          <tr v-for="c in channel_series" :key="c.no">
            <td>
              {{ c.name || '' }}
            </td>
            <td>
              <span v-if="c.data && c.data.length && c.data[c.data.length-1].minDate">
                <TimeLabel :time="c.data[c.data.length-1].minDate" :show_seconds="true" />
              </span>
              <span v-else></span>
            </td>
            <td>
              <span v-if="c.data && c.data.length && c.data[c.data.length-1].value !== undefined">
                {{ c.data[c.data.length-1].value }}
              </span>
              <span v-else></span>
            </td>
            <td>
              {{ c.unit || '' }}
            </td>
            <td>
              <span v-if="c.hold_value_timestamp">
                <TimeLabel :time="c.hold_value_timestamp" :show_seconds="true" />
              </span>
              <span v-else></span>
            </td>
            <td>
              <span v-if="c.hold_value !== undefined && c.hold_value !== null">
                {{ c.hold_value }}
              </span>
              <span v-else></span>
            </td>
          </tr>
        </tbody>
      </ResponsiveTable>

    </Section>
   
  </div>

  
</template>

<script>
import { GraphData } from "@/api/graphdata.js";
const GraphBuffer = require("@/recordings/graphbuffer").default;
const Camur = require("@/utils/camurutils").default;
export default {
  name: "LiveValuesView",

  data() {
    return {
      graph_data: null,
      graph_buffer: new GraphBuffer(this.$app.sltd.project, this.graph_data),
      subscribe_time: 0,
      initial_url: "",
      series:null
    };
  },

  props: {
    interval_s: {
      type: Number,
      default: 2
    },
    duration_s: {
      type: Number,
      default: 60
    },
    channels: {
      type: Array,
      default: null
    },
    live_vals: {
      type: Object,
      default: null
    },
    include_node_name: {
      type: Boolean,
      default: true
    },
    include_zone_name: {
      type: Boolean,
      default: false
    },
    held_vals: {
      type: Object,
      default: null
    },
    is_connected: {
      type: Boolean,
      default: true
    },
    disconnected_text: {
      type: String,
      default: "Not connected"
    },
    nodes: {
      type: Array,
      default: null
    },
    default_graph_description: {
      type: String,
      default: "Default graph description"
    },
    local_storage_id: {
      type: String,
      default: ""
    },
    controller: {
      type: Object,
      default: null      
    },
    select_mode: {
      type: Boolean,
      default: false
    }
  },

  mounted() {
    this.graph_data = new GraphData(this.$app, this.$appm.net);
    this.graph_data.graph_buffer = this.graph_buffer;
    this.initial_url = window.location.href;
    console.log("mounted");
    window.setTimeout(this.try_subscribe, 1500);
    window.setInterval(this.refresh, 5000);
    window.setTimeout(this.load_hold_values_from_localStorage, 1500);
  },

  methods: {
    handleToggleHiddenSeries(seriesId, hide) {
      if (hide) {
        if (!this.graph_buffer.hidden_series.includes(seriesId)) {
          this.graph_buffer.hidden_series.push(seriesId);
        }
      } else {
        this.graph_buffer.hidden_series = this.graph_buffer.hidden_series.filter(id => id !== seriesId);
      }
      this.graph_buffer.state++;
    },
    handleSelectDeselectAllSeries(showAll) {
      if (showAll) {
        this.graph_buffer.hidden_series = [];
      } else {
        this.graph_buffer.hidden_series = this.series.map(s => s.id);
      }
      this.graph_buffer.state++;
    },
    handleResetGraphBuffer() {
      this.graph_buffer.reset();
      this.graph_buffer.state++;
    },
    handleSetHiddenSeries(hiddenSeries) {
      this.graph_buffer.hidden_series = hiddenSeries;
      this.graph_buffer.state++;
    },
    load_hold_values_from_localStorage() {
      for (let node of this.$app.sltd.project.all_nodes) {
        if (
          !localStorage.hasOwnProperty("C3_Hold_"+node.cross_prj_identifier)
        ) {
          return;
        }

        let hold_val_json = localStorage["C3_Hold_"+node.cross_prj_identifier];
        node.app_prop.held_vals = JSON.parse(hold_val_json);
      }
    },
    refresh() {
      // this.series = null;
    },
    try_subscribe() {
      //console.log("try_subscribe");
      let time_since_subscribe = Date.now() - this.subscribe_time;

      if (
        window.app.msg_manager.is_ready() &&
        (time_since_subscribe >= this.duration_s * 1000 ||
          window.app.msg_manager.connection_time > this.subscribe_time)
      ) {
          this.subscribe_nodes();
      }

      // If we are still on the same page
      if (window.location.href == this.initial_url) {
        // Test if we need to resubscribe again
        window.setTimeout(this.try_subscribe, 1500);
      }
    },
    hold_values() {
      console.log("LiveValuesView.hold_values");
      this.$emit("hold_values");
    },

    subscribe_nodes() {
      console.log("LiveValuesView.subscribe_nodes");
      this.subscribe_time = Date.now();
      this.$emit("subscribe", this.interval_s, this.duration_s);
    },
    fillChannels() {
      console.log("LiveValuesView.fill_channels");
      this.$emit("fill_channels");
    },
    clearChannels() {
      console.log("LiveValuesView.clear_channels");
      this.$emit("clear_channels");
    },
    selectNodes() {
      console.log("LiveValuesView.selectNodes");
      this.$emit("select_nodes");
    },
    doneSelectNodes() {
      console.log("LiveValuesView.doneSelectNodes");
      this.$emit("done_select_nodes");
    },
    sort_string(a) {
      // sortStr will provide a unique string id
      // with a defined sort-order
      let sortStr=a.name + "-" + (10000 + a.id);
      return sortStr;
    }
  },

  computed: {
    end_date() {
      // twice as wide as the subscription duration
      return this.subscribe_time + this.duration_s * 2 * 1000;
    },

    local_held_vals() {
      //console.log("Fill held_vals");
      let result = {};
      for (let node of this.nodes) {
        for (let v in node.app_prop.held_vals ) {
           result[v] = node.app_prop.held_vals[v];
          //console.log("Held value:"+ result[v]); 
        }
      }
      return result;
    },

    defaultGraphDescription() {
      return this.default_graph_description;
    },   
    localStorageId() {
      return this.local_storage_id;
    },
    hasFillChannels() {
      // do not fill channels automatically if controller is selected
      return (this.controller != null);
    },
    hasSelectNodes() {
      // do not fill channels automatically if controller is selected
      return (this.controller == null) && (this.$app.sltd.zone == null);
    },
    channel_series() {
      console.log("channels.length:"+this.channels.length);
      let colors = this.$camur.get_series_color_scheme();
      let result = [];
      if (this.series != null) {
        result = this.series;
      }
      let color_index = 0;

      // Any series to be removed ?
      let removeCompleted = false;
      while (!removeCompleted) {
        removeCompleted = true;
        for (let serie of result) {
          let index = 0;
          let thisChannelObj = this.channels.find(ch => (ch.id == serie.id));
          if (thisChannelObj == null) {
            // Remove this serie
            result.splice(index);
            removeCompleted = false;
            console.log("Serie removed");
            break;
          }
        }
      }
  
      for (let channel of this.channels) {
        if (channel.io == Camur.io_type.io_type_output) continue;
       
        let channel_identifier = `${channel.nodeId}_${channel.no}`
        let hold_value = null;
        let hold_value_timestamp = null;

        if (this.local_held_vals[channel_identifier] != null) {
          hold_value = this.local_held_vals[channel_identifier].value;
          hold_value_timestamp = this.local_held_vals[channel_identifier].minDate;
          //console.log("Hold value:"+hold_value);
        }
        
        let display_name = channel.name;


        let node = this.$appm.get_node_by_id(
            this.$app.sltd.prj_id,
            channel.nodeId,
           );

        if (this.include_node_name) {
          if (this.include_zone_name) {
            let zone = this.$appm.get_zone(
              this.$app.sltd.prj_id,
              node.dto.zoneId,
            );
            display_name = `${zone.name}-${node.name}-${channel.name}`;
          } else {
            display_name = `${node.name}-${channel.name}`;
          }
        }

        let data = this.live_vals[channel_identifier];
        
        if (data == null) {
          data = [];
/*           color_index += 1;
          continue; */
        } 

/*         if (data.length == 0) {
          color_index += 1;
          continue;
        }  */
        
        let thisSerieObj = null;

        if (result != null) {
          thisSerieObj = result.find(s => (s.id == channel.id));
        }
        
        if (thisSerieObj !=null) {
          thisSerieObj.hold_value = hold_value; 
          thisSerieObj.hold_value_timestamp = hold_value_timestamp;  
        }
        else {
          result.push({
            name: display_name,
            data: data,
            unit: channel.formula.unit,
            graph_min: channel.formula.graphScaleMin,
            graph_max: channel.formula.graphScaleMax,
            decimals: channel.formula.decimals,
            color: colors[color_index % colors.length],
            line: "linear",
            id: channel.id,
            hidden: true,
            hold_value: hold_value,
            hold_value_timestamp : hold_value_timestamp,
            channel_no: channel.no
          });
        }
        color_index += 1;
        
      }

      result.sort((a, b) => (this.sort_string(a) > this.sort_string(b) ? 1 : -1));
      color_index =0;
        // re-assign colors after sort
      let rIndex=0;
      for (rIndex=0;rIndex < result.length ; rIndex++) {
        result[rIndex].color = colors[color_index % colors.length];
        color_index ++;
      }
      // eslint-disable-next-line
      this.series = result;
      //console.log(result)

      return result;
    }
  }
};
</script>
