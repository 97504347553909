<!-- eslint-disable -->

<template lang="html">
  <div class="map_holder">
    <div id="map_canvas" :style="{ height: height_px }"></div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      mapLoaded: false,
      home_coordinate: [57.44, 11.85],
      markers_layer: null,
      map: null
    };
  },

  methods: {
    on_projects_changed() {
      this.update_map_markers(false);
    },

    update_map_markers(auto_move) {
      if (this.projects == null) {
        window.setTimeout(this.update_map_markers, 10, true);
        return;
      }

      this.markers_layer.clearLayers();

      let positions = [];
      for (let project of this.projects) {
        if (project.latitude != null && project.longitude != null) {
          if (!isNaN(project.latitude) && !isNaN(project.longitude)) {
            let pos = this.make_marker_for_project(project);
            positions.push(pos);
          }
        }
      }

      this.map.addLayer(this.markers_layer);

      try {
        if (auto_move && positions.length > 0) {
          let bounds = new window.L.LatLngBounds(positions);
          this.map.fitBounds(bounds);
        }
      } catch (e) {
        // eslint-disable-next-line
        console.error(e);
      }
    },

    make_marker_for_project(project) {
      let latitude = 0;

      if (project.latitude == null) {
        latitude = 40 + Math.random() * 20;
      } else {
        latitude = project.latitude;
      }

      let longitude = 0;
      if (project.longitude == null) {
        longitude = -10 + project.id * 3;
      } else {
        longitude = project.longitude;
      }

      var marker = window.L.marker([latitude, longitude]).addTo(
        this.markers_layer
      );

      // TODO: Probably show any alarms here
      let popup = `
        <a href='#/prj/${project.id}'>
        <b>${project.name}</b>
        </a>`;

      marker.bindPopup(popup);
      marker.openPopup();
      return [latitude, longitude];
    }
  },

  computed: {
    height_px() {
      return this.height + "px";
    }
  },

  props: {
    height: {
      default: 400,
      type: Number
    },

    projects: {
      type: Array
    }
  },

  mounted() {
    this.map = window.L.map("map_canvas").setView(this.home_coordinate, 5);
    this.markers_layer = new window.L.LayerGroup();

    window.L.tileLayer(
      "https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token={accessToken}",
      {
        attribution:
          'Map data &copy; <a href="https://www.openstreetmap.org/">OpenStreetMap</a> contributors, <a href="https://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a>, Imagery © <a href="https://www.mapbox.com/">Mapbox</a>',
        maxZoom: 18,
        id: "mapbox/streets-v11",
        tileSize: 512,
        zoomOffset: -1,
        accessToken:
          "pk.eyJ1IjoiYmhlcm1hbnMiLCJhIjoiY2xmc2F1cHlqMDRqNTNxbWhkNmN1cDV2MSJ9.YsclgoAuFgFLg77nKrUp-g"
      }
    ).addTo(this.map);
    window.setTimeout(this.update_map_markers, 10, true);
  },

  watch: {
    projects() {
      this.on_projects_changed();
    }
  }
};
</script>

<style lang="css" scoped>
.map_holder {
  background-color: gray;
  width: 100%;
  color: white;
  display: flex;
   align-items: center;
}

#map_canvas {
  width: 100%;
}
</style>
