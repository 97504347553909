<template>
  <div class="FooterView" id="footer">
    <p class="footer-item" v-if="$txt != null">
      <em
        >Version:
        <span id="version">{{ $ds.current_version.version }}</span>
        Build: <span id="build">{{ $ds.current_version.build }}</span> (
        <span id="date">{{ $ds.current_version.date }}</span> )
      </em>
    </p>
    <p class="footer-item" v-if="$txt != null">
      <em
        >API: <span id="api_server">{{ $net.api_server }}</span></em
      >
    </p>
    <a
      class="footer-item"
      v-if="$ds.session.logged_in && $txt != null"
      @click="$appm.log_out()"
      >{{ $txt.sign_out }}</a
    >

    <a
      class="footer-item"
      v-if="$ds.settings.developer_mode"
      @click="show_debug_modal = true"
      >Debug window</a
    >
    <a
      class="footer-item"
      v-if="$ds.settings.developer_mode"
      id="dev_mode_btn"
    >
      <router-link :to="{ name: 'sketch', params: {} }">Sketch</router-link>
    </a>

    <Modal
      title="Debug window"
      v-if="show_debug_modal"
      :width="900"
      @close="show_debug_modal = false"
    >
      <DebugControls :starting_page="starting_debug_page" />
    </Modal>
  </div>
</template>

<script>
export default {
  name: "FooterView",

  mounted() {
    document.onkeydown = (event) => {
      if (event.which == 191) {
        this.starting_debug_page = "node_inspect";
        this.show_debug_modal = !this.show_debug_modal;
      }
    };
  },

  data() {
    return {
      show_debug_modal: false,
    };
  },
};
</script>
