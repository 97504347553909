<template>
  <input
    v-if="return_binary"
    class="wm_checkbox"
    type="checkbox"
    v-bind:checked="modelValue ? 1 : 0"
    v-on:input="$emit('update:modelValue', $event.target.checked ? 1 : 0)"
  />
  <input
    v-else
    class="wm_checkbox"
    type="checkbox"
    v-bind:checked="modelValue"
    v-on:input="$emit('update:modelValue', $event.target.checked)"
  />
</template>

<script>
export default {
  name: "CheckBox",
  data() {
    return {
      t: true
    };
  },

  props: {
    modelValue: {
      type: Boolean,
      default: false
    },
    return_binary: {
      type: Boolean,
      default: false
    }
  },

 // props: ["value", "return_binary", "disabled"],
  emits: ['update:modelValue'],
  methods: {},

  created: function() {}
};
</script>
