<template>
  <div class="labeled-control">
    <p :class="{ wm_label_disabled: disabled }" v-if="label">{{ label }}</p>
    <input
      class="wm_numeric_input"
      :placeholder="placeholder"
      v-bind:value="modelValue"
      v-on:input="
        $emit('update:modelValue', validate_min_max(parseFloat($event.target.value)))
      "
      :disabled="disabled"
      :class="{
        wm_input_disabled: disabled,
        wm_out_of_range: outOfRange,
        wm_input_tightness1: tightness == 1,
        wm_input_tightness2: tightness == 2,
      }"
      type="number"
      :step="step"
      :min="min"
      :max="max"
      :hintText = "hintText"
      :title = "hint"
    />      
  </div>
</template>

<script>
export default {
  emits: ['update:modelValue'],
  name: "NumericInput",
  data() {
    return {};
  },

  props: {
    label: {
      type: String
    },
    placeholder: {
      type: String,
      default: "Enter value"
    },
    modelValue: {},
    disabled: {
      type: Boolean
    },
    tightness: {
      default: 0
    },
    step: {
      default: 1
    },
    min: {
      default: -100000
    },
    max: {
      default: 100000
    },
    hintText: {
      type: String,
      default: ""
    }
  },
  computed: {
    hint() {
      let range = this.min +"..."+ this.max;
      if (this.hintText == "") {
        return range;
      } 
      else {
        return this.hintText + " ("+ range + ")";
      }
    },
    outOfRange() {
      if (this.modelValue < this.min) {
        return true;
      } else if (this.modelValue > this.max) {
        return true;
      } else return false;
    }
  },
  methods: {
    validate_min_max(value) {
      if (value < this.min) {
        return this.min;
      } else if (value > this.max) {
        return this.max;
      } else return value;
    }
  }
};
</script>
