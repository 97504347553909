import MessageHub from "./message_hub";
import { Project } from "@/items/project";
import { Cntrl } from "@/items/cntrl";
import { Zone } from "@/items/zone";
import { Node } from "@/items/node";
import Camur from "@/utils/camurutils";
import Network from "@/networking";

let self = null;

export default class MessageManager {
  constructor(app, apiurl, token) {
    this.forcefully_disconnected = false;
    this.app = app;
    this.attempts = 0;
    this.subscribe_time = 0;
    this.hub = new MessageHub(apiurl, token);
    this.hub.registerCallbacks(this);
    this.hub.on_start_fail = this.on_connection_fail;
    this.hub.on_done = this.on_connection_done;
    this.hub.on_invoke_fail = this.on_invoke_fail;
    this.hub.on_set_fv_setting_fail = this.on_set_fv_setting_fail;
    this.hub.on_live_values_failed = this.on_live_values_failed;
    this.hub.onreconnected = this.onreconnecting;
    this.hub.startConnection();
    this.connection_time = Date.now();

    window.setTimeout(this.reconnect_if_disconnected, 10000, this);

    this.current_subscriptions = [];
    self = this;
  }

  // Continually check if we accidentally disconnected and try to reconnect
  reconnect_if_disconnected(that) {
    if (!that.is_ready() && !that.forcefully_disconnected) {
      console.log("Force restart of Message hub");
      that.hub.startConnection();
    }
    window.setTimeout(that.reconnect_if_disconnected, 2000, that);
  }

  is_ready() {
     return (
      this.hub.connection.state == "Connected"
    ); 
  }

  /* Lifecycle Handlers */

  on_connection_fail(error) {
    window.console.error("Connection failed: " + error);
  }
  on_connection_done(that) {
    self.connection_time = Date.now();
    if (window.app.sltd.prj_id != null) {
      self.subscribe_project(window.app.sltd.prj_id);
    }
  }
  onreconnecting() {
    window.console.log("Reconnecting to server");
    window.appm.show_note("Reconnecting to API server...");
  }
  on_invoke_fail(error) {
    window.console.error(null, "Invoke failed: " + error);
    window.appm.on_failed_to_invoke_method_on_server(error);
  }
  on_live_values_failed(error) {
    window.console.error(null, "Request live values failed: " + error);
    window.appm.on_get_live_values_failed();
  }

  on_set_fv_setting_fail(error) {
    window.console.error(null, "Set FV setting failed: " + error);
    window.app.on_fv_setting_failed();
  }

  /* Actions */

  disconnect() {
    this.forcefully_disconnected = true;
    this.hub.stopConnection();
  }

  subscribe_project(project_id) {
    let subscription_interval = 50;
    let time_since_last_subscription = Date.now() - this.subscribe_time;

    // If we just tried to subscribe, we dont need to try again
    if (time_since_last_subscription < 1500) return;

    if (this.is_ready()) {
      window.console.log("Subscribe to project " + project_id);
      this.subscribe_time = Date.now();
      this.hub.subscribe("project", project_id);
    } else {
      window.setTimeout(
        self.subscribe_project.bind(this, project_id),
        subscription_interval
      );
    }
  }

  unsubscribe_project(project_id) {
    if (this.attempts < 10 && this.is_ready()) {
      window.console.log("Unsubscribe to project " + project_id);
      this.hub.unsubscribe("project", project_id);
      this.attempts = 0;
    } else {
      self.attempts += 1;
      window.setTimeout(self.unsubscribe_project.bind(this, project_id), 100);
    }
  }

  set_fv_setting(setting, prj_id, cntrl_id, node_serial, value) {
    switch (setting) {
      case "outputOn":
        this.hub.SetFixVoltOutputOnOff(prj_id, cntrl_id, node_serial, value);
        break;
      case "current":
        this.hub.SetFixVoltOutputI(prj_id, cntrl_id, node_serial, value);
        break;
      case "voltage":
        this.hub.SetFixVoltOutputV(prj_id, cntrl_id, node_serial, value);
        break;
      case "recovery":
        this.hub.SetFixVoltOutputRecovery(prj_id, cntrl_id, node_serial, value);
        break;
      default:
        // eslint-disable-next-line
        console.error("Undefined Fixvolt setting: " + setting);
    }
  }

  // Sends sample request to node
  // and sends them backed for fixed amount of time
  get_live_values_for_node(node, cntrl_serial, duration, interval) {
    this.hub.requestNodeSamples(
      node.app_prop.project_id,
      cntrl_serial,
      node.serial,
      duration,
      interval
    );
  }

  /* Callbacks */
  subscribed(type, id) {
    this.current_subscriptions.push({ type, id });
  }

  unsubscribed(type, id) {
    this.current_subscriptions = this.current_subscriptions.filter(
      sub => sub.type != type || sub.id != id
    );
  }

  controller_online_change(prj_id, cntrl_serial, timestamp, online_status) {
    let cntrl = window.appm.get_cntrl_by_serial(prj_id, cntrl_serial);
    if (cntrl != null) {
      cntrl.on_online_status_change(online_status);

      if (online_status) {
        window.appm.on_cntrl_went_online(cntrl);
      } else {
        window.appm.on_cntrl_went_offline(cntrl);
      }
    }
  }

  update_job(projectId, task, jobId, status, data, message) {
    let job_queue = null;

    switch (task) {
      case Camur.job_type.getControllerStatus:
      case Camur.job_type.getControllerLog:

      case Camur.job_type.recordingModeChange:
      case Camur.job_type.getControllerRecordingData:

      case Camur.job_type.setControllerRecordingSettings:

      case Camur.job_type.setControllerRecordingDecayParams:
      case Camur.job_type.setControllerRecordingLprParams:
      case Camur.job_type.setControllerRecordingZraParams:
      case Camur.job_type.setControllerRecordingResMesParams:
      case Camur.job_type.setControllerRecordingMonitorParams:
      case Camur.job_type.setControllerRecordingScheduleParams:
      case Camur.job_type.setControllerSwitchOffOnAlarm:

      case Camur.job_type.discoverControllerMonitorParams:
      case Camur.job_type.discoverControllerDecayParams:
      case Camur.job_type.discoverControllerLprParams:
      case Camur.job_type.discoverControllerZraParams:
      case Camur.job_type.discoverControllerResMesParams:
      case Camur.job_type.discoverControllerSwitchOffOnAlarm:

      case Camur.job_type.discoverControllerScheduleParams:
      case Camur.job_type.discoverControllerAckTimestamp:
      case Camur.job_type.discoverControllerNodes:
      case Camur.job_type.discoverControllerRecordingStatus:
      case Camur.job_type.discoverControllerTimestamp:
      case Camur.job_type.discoverControllerVersion:
      case Camur.job_type.upgradeControllerFirmware:
      case Camur.job_type.controllerReboot:
      case Camur.job_type.controllerPrepareBoot:
      case Camur.job_type.controllerCancelGetRecordingData:
      case Camur.job_type.controllerPingPeriodReset:
      case Camur.job_type.controllerResetCAN:

      case Camur.job_type.setNodePowerSupplyParams:
      case Camur.job_type.setFixVoltOutputMode:
      case Camur.job_type.setFixVoltOutputOnOff:
      case Camur.job_type.setFixVoltOutputRecovery:
      case Camur.job_type.setFixVoltOutputVoltage:
      case Camur.job_type.setFixVoltOutputCurrent:
      case Camur.job_type.setFixVoltSwitchOffOnAlarm:

      case Camur.job_type.discoverNodePowerSupplyParams:

      case Camur.job_type.setAlarmStatus:

      case Camur.job_type.setNodeCurrTransParams:
      case Camur.job_type.discoverNodeCurrTransParams:
      case Camur.job_type.setNodeChannelCount:
      case Camur.job_type.discoverNodeChannelCount:
      case Camur.job_type.setNodeTemperatureEnabled:
      case Camur.job_type.discoverNodeTemperatureEnabled:

      case Camur.job_type.discoverNodeAlarmLevels:
      case Camur.job_type.discoverNodeScaleValues:
      case Camur.job_type.discoverNodeResistanceParams:
      case Camur.job_type.setNodePreSampleInterval:
      case Camur.job_type.discoverNodePreSampleInterval:
      case Camur.job_type.setNodeOutputInverted:
      case Camur.job_type.discoverNodeOutputInverted:
      case Camur.job_type.setNodePowerInterface:
      case Camur.job_type.setChannelAlarmLow:
      case Camur.job_type.setChannelAlarmHigh:
      case Camur.job_type.setChannelAlarmEnabled:
      case Camur.job_type.resetChannelAlarm:
        job_queue = window.appm.get_cntrl(projectId, data.ControllerId).app_prop
          .job_queue;
        break;
      default:
        window.appm.show_error("Undefined task: " + task);
        return;
    }
    if (job_queue == null) return;

    let task_name = window.app.langutil.get_job_type_name(task);
    let job_desc = `Job ${task_name} (id: ${jobId})`;
    switch (status) {
      case Camur.job_status.queued:
        {
          job_queue.on_job_queued(jobId, data, task, message);
          window.console.log(job_desc + " queued");
        }
        break;
      case Camur.job_status.success:
        {
          window.console.log(job_desc + " successful");
          job_queue.on_job_successful(projectId, jobId, data, task, message);
        }
        break;
      case Camur.job_status.failure:
        {
          window.console.log(job_desc + " failed");
          job_queue.on_job_fail(jobId, data, task_name, message);
        }
        break;
      case Camur.job_status.inProgress:
        {
          // Temporary fix if job queued not received
          job_queue.on_job_queued(jobId, data, task_name, message);
          window.console.log(job_desc + " in progress");
        }
        break;
      default: {
        window.appm.show_error("Undefined Camur.job_status: " + status);
      }
    }
  }

  update_project(dto) {
    let proj = window.appm.get_project(dto.id);
    if (proj != null) this.update_item(proj, dto);
    else {
      Project.update_or_create_from_dto(dto, window.app, {
        project_id: dto.id
      });
    }
  }

  update_cntrl(project_id, dto) {
    window.console.log("update_cntrl");
    let cntrl = window.appm.get_cntrl(project_id, dto.id);
    if (cntrl != null) this.update_item(cntrl, dto);
    else {
      Project.update_or_create_from_dto(dto, window.app, {
        project_id: project_id,
        zone_id: dto.id
      });
    }
  }

  update_cntrl_device(project_id, dto) {
    window.console.log("update_cntrl_device");
    Network.cntrl_device.list(project_id);
    //window.appm.update_cntrl_device(project_id, dto);
  }

  update_zone(project_id, dto) {
    let proj = window.appm.get_project(project_id);
    let zone = proj.find_zone(dto.id);
    if (zone != null) this.update_item(zone, dto);
    else {
      Zone.update_or_create_from_dto(dto, window.app, {
        project_id,
        zone_id: dto.id
      });
    }
  }

  update_node(project_id, dto) {
    //window.console.log("MessageManager:update_node" + JSON.stringify(dto));
    if (dto.nodeType == 11) {
        // PowerInterface work-around
      window.console.log("MessageManager:fetch pi");
      Network.node.fetch(project_id, dto.zoneId, dto.id, null, 0);
    }
    else { 
    let proj = window.appm.get_project(project_id);
    let node = proj.find_node(dto.id);
    if (node != null) {
//      window.console.log("MessageManager:update_item");
      this.update_item(node, dto);
    }
    else {
//      window.console.log("MessageManager:update_or_create_from_dto");
      Node.update_or_create_from_dto(dto, window.app, {
        project_id,
        zone_id: dto.zoneId,
        node_id: dto.id
      });
    }
  }
  }
  // eslint-disable-next-line
  update_formula(projectId, cntrlId, zoneId, nodeId, channelId, formula) {
    //  item.update_from_dto(formula, false); TODO:
    //  Formula.update_or_create_from_dto ?
    window.console.log("MessageManager:update_formula");
    Network.node.fetch(projectId, zoneId, nodeId, null, 0);
  }

  update_nodechannel(project_id, zone_id, dto) {
    let proj = window.appm.get_project(project_id);
    let zone = proj.find_zone(zone_id);
    let channel = zone.find_channel_by_id(dto.id);
    if (channel != null) {
      window.console.log(
        "update_nodechannel,project_id:" +
          project_id +
          " zone_id:" +
          zone_id +
          " channel_id:" +
          channel.id
      );
      Network.node.fetch(project_id, zone_id, channel.nodeId, null, 0);
    }
  }

  alarm_channel(project_id, controller_id, node_id, channel_id) {
    window.console.log(
      "alarm_channel,project_id:" +
        project_id +
        " controller_id:" +
        controller_id +
        " node_id:" +
        node_id +
        " channel_id:" +
        channel_id
    );
  }

  update_last_value(project_id, channel_id, value, timestamp, raw) {
    let proj = window.appm.get_project(project_id);
    let channel = proj.find_channel(channel_id);
//    let node = proj.find_node(channel.nodeId);

    // We just add these samples from the recording as a live value
    // No, we stopped doing that, Bo H, 2021-03-30
    // node.add_live_value(value, timestamp, channel.no);
  }

  update_item(item, dto) {
    let otheruser = false;
    if (item != null && item.diff_dto(dto)) {
      // TODO: a way to know if it was another user who changed it
      if (otheruser) window.appm.show_note(item.name + " was changed");
      item.update_from_dto(dto, false);
    }
  }

  add_sample_value(
    projectId,
    controllerSerial,
    nodeSerial,
    channel_no,
    timestamp,
    value
  ) {
    let node = window.appm.get_node_by_serial(projectId, nodeSerial);
    if (node === null) {
      window.console.warn("Trying to add live value before node has loaded:"+nodeSerial);
      return;
    }
/*     window.console.log(
      "add_live_value:" +
        value +
      "nodeSerial:"+
        nodeSerial +
        " channel_no:" +
        channel_no);
 */
    node.add_live_value(value, timestamp, channel_no);
  }

  delete_cntrl(project_id, zone_id) {
    Cntrl.delete_clientside(window.app, { project_id, zone_id }, zone_id);
  }

  deleteZone(project_id, zone_id) {
    Zone.delete_clientside(window.app, { project_id, zone_id }, zone_id);
  }

  deleteNode(project_id, zone_id, node_id) {
    //   let node = window.app.get_node(project_id, zone_id, node_id);
    //  if (node != null) {
    //    window.app.show_error(`Node ${node.name} was deleted by another user!`);
    //  } // If we can't find it, we were probably the ones who changed it

    Node.delete_clientside(
      window.app,
      { project_id, zone_id, node_id },
      node_id
    );
  }

  fv_out_current_changed(
    projectId,
    controllerSerial,
    nodeSerial,
    timestamp,
    value
  ) {
    window.appm.get_node_by_serial(projectId, nodeSerial).update_from_dto(
      {
        psCurrentLimit: value / 1000
      },
      true
    );
    window.appm.show_note(`${nodeSerial}-I-Out changed: ${value}`);
  }

  fv_out_voltage_changed(
    projectId,
    controllerSerial,
    nodeSerial,
    timestamp,
    value
  ) {
    window.AudioParamMap.get_node_by_serial(projectId, nodeSerial).update_from_dto(
      {
        psFixedVoltage: value / 1000
      },
      true
    );
    window.appm.show_note(`${nodeSerial}-V-Out changed: ${value}`);
  }

  fv_out_recovery_changed(
    projectId,
    controllerSerial,
    nodeSerial,
    timestamp,
    recovery
  ) {
    // TODO
    window.appm.show_note(`${nodeSerial}-Recovery changed: ${recovery}`);
  }

  fv_out_ouput_changed(
    projectId,
    controllerSerial,
    nodeSerial,
    timestamp,
    isOn
  ) {
    // TODO
    window.appm.show_note(`${nodeSerial}-OutputOn changed: ${isOn}`);
  }

  deleteNodeChannel(project_id, zone_id, node_id, channel_id) {
    let identification = { project_id, zone_id, node_id, channel_id };
    Channel.delete_clientside(window.app, identification, channel_id);
  }

  nodeChannelPulse(project_id, zone_id, node_id, channel_id, value, time) {
    let identification = { project_id, zone_id, node_id, channel_id };
    let node = Node.find(window.app, node_id, identification);
    node.add_live_value(value, time);
  }

  receiveMessage(user, message) {
    window.appm.show_note("Unknown message received: " + message);
  }
}
