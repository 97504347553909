<template>
  <a @click="toggle" style="margin: 3px;" :title="hint">
    <TinyIcon v-if="modelValue" :icon="icon_open" />
    <TinyIcon v-else :icon="icon_closed" />
  </a>
</template>

<script>
export default {
  name: "IconToggle",
  emits: ['update:modelValue'],
  data() {
    return {};
  },

  props: {
    modelValue: {
      type: Boolean,
      default: false
    },

    icon_open: {
      type: String,
      default: "lock_open-black"
    },

    icon_closed: {
      type: String,
      default: "lock-black"
    },

    hint_open: {
      type: String
    },

    hint_closed: {
      type: String
    },

    editable: {
      type: Boolean,
      default: true
    }
  },

  methods: {
    toggle() {
      if (this.editable) this.$emit("update:modelValue", !this.modelValue);
    }
  },

  computed: {
    hint() {
      if (this.modelValue && this.hint_open != null) return this.hint_open;
      if (this.hint_closed != null) return this.hint_closed;
      return "";
    }
  }
};
</script>
