<template lang="html">
  <span :title="hint" style="" class="wm_time_label">
    {{ $appg.langutil.print_date_time(time, options) }}
  </span>
</template>

<script>
export default {
  name: "TimeLabel",
  props: {
    time: {
      default: null
    },

    show_time: {
      type: Boolean,
      default: true
    },

    show_seconds: {
      type: Boolean,
      default: true
    },

    show_date: {
      type: Boolean,
      default: true
    },

    use_iso_formatting: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    options() {
      return {
        show_date: this.show_date,
        show_time: this.show_time,
        show_seconds: this.show_seconds,
        use_iso_formatting: this.use_iso_formatting
      };
    },

    hint() {
      let message = "";
      let timezone = "";
      let prj = null;

      let display_time = this.$app.settings.display_time;
      prj = this.$app.sltd.project;
      if (display_time == "prj" && prj == null) {
        display_time = "local";
      }
      if (this.use_iso_formatting) {
        display_time = "utc";
      }

      switch (display_time) {
        case "prj":
          message = this.$txt.time_displayed_as_installation;
          timezone = prj.dto.info.timezone;
          break;
        case "utc":
          message = this.$txt.time_displayed_as_utc;
          timezone = "Universal Time Coordinated";
          break;
        default:
        case "local":
          message = this.$txt.time_displayed_as_local;
          timezone = this.$appg.langutil.get_local_timezone();
          break;
      }
      return `${message} (${timezone})`;
    }
  }
};
</script>
