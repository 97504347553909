<template>
  <div>
    <div class="labeled-control">
      <p>{{ dateLabel }}</p>
      <input
        v-if="!disabled"
        class="wm_date_input"
        ref="datepicker"
        type="date"
        :min="minDate"
        :value="date_in"
        v-on:input="date_change($event.target.value)"
      />
      <input
        v-else
        class="wm_time_input"
        disabled="true"
        type="text"
        :class="{ wm_input_disabled: disabled }"
        style="width:120px"
        v-bind:value="formatted_date()"
      />
    </div>

    <div class="labeled-control">
      <p>{{ timeLabel }}</p>
      <input
        v-if="!disabled"
        class="wm_time_input"
        ref="timepicker"
        type="time"
        :value="time_in"
        v-on:input="time_change($event.target.value)"
      />
      <input
        v-else
        class="wm_time_input"
        disabled="true"
        type="text"
        :class="{ wm_input_disabled: disabled }"
        style="width:120px"
        v-bind:value="formatted_time()"
      />
    </div>
  </div>
</template>

<script type="text/javascript">
export default {
  name: "ISOStringEditor",
  data() {
    return {
      time: "",
      date: "",
      minDate: "2021-01-01",
      out: ""
    };
  },
  props: {
    value: String,
    dateLabel: String,
    timeLabel: String,
    disabled: Boolean
  },

  computed: {
    date_in() {
      return this.local_time().split(" ")[0];
    },
    time_in() {
      let time_part = this.local_time().split(" ")[1];
      let hours = time_part.split(":")[0];
      let minutes = time_part.split(":")[1];

      return hours + ":" + minutes;
    }
  },

  methods: {
    local_time() {
        // lock to YYYY-MM-DD HH:MM as this is used internally
      return new Date(this.value).toLocaleString("sv-SE");
    },

    time_change(new_time) {
      this.time = new_time;
      this.emit_iso_string();
    },

    date_change(new_date) {
      if (new_date > this.minDate)
      {
        this.date = new_date;
        window.console.log(new_date);
        this.emit_iso_string();
      }
    },

    emit_iso_string() {
      if (this.date == "") this.date = this.date_in;
      if (this.time == "") this.time = this.time_in;

      let combined = this.date + "T" + this.time;
      let date = new Date(combined);
      this.out = date.toISOString(); 
      this.$emit("input", this.out);
    },
    formatted_time() {
      return this.$appg.langutil.print_time(this.value);
    },
    formatted_date() {
      return this.$appg.langutil.print_date(this.value);
    }
  }
};
</script>
