<template>
  <div class="loading_panel">
    <span>
      <div class="r rotating">
        <SmallIcon icon="cached" color="gray" />
      </div>
    </span>
    <div v-if="text != ''">{{ text }}...</div>
    <div v-else>{{ $txt.loading }}...</div>
  </div>
</template>

<script>
export default {
  props: {
    text: {
      type: String,
      default: ""
    }
  }
};
</script>

<style scoped>
.r {
  width: 20px;
  height: 20px;
  display: inline-block;
}

.loading_panel {
  text-transform: uppercase;
  text-align: center;
  padding: 20px;
  font-weight: bold;
  color: gray;
  border-radius: 4px;
  height: 100%;
}
@keyframes rotating {
  from {
    transform: rotate(360deg);
    -o-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
  }
  to {
    transform: rotate(0deg);
    -o-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
  }
}
@-webkit-keyframes rotating {
  from {
    transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
  }
  to {
    transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
  }
}
.rotating {
  -webkit-animation: rotating 1s linear infinite;
  -moz-animation: rotating 1s linear infinite;
  -ms-animation: rotating 1s linear infinite;
  -o-animation: rotating 1s linear infinite;
  animation: rotating 1s linear infinite;
}
</style>
