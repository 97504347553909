<template>
  <div class="dropdown labeled-control">
    <label
      class="wm_dropdown_label"
      for="main_select"
      :class="{ wm_label_disabled: disabled }"
    >
      {{ title }}
    </label>
    <br />
    <select
      class="btn btn-primary dropdown-toggle"
      :class="{ wm_disabled_btn: disabled }"
      :value="modelValue"
      @change="on_change($event.target)"
      :title="hintText"
    >
      <option
        class="wm_dropdown_option"
        v-for="drop in enum_to_choose_from"
        :disabled="option_disabled(drop)"
        :key="drop"
        :value="drop"
      >
        {{ $appg.langutil.get_enum_name(enum_to_choose_from, drop) }}
      </option>
    </select>
  </div>
</template>

<script>
const Camur = require("@/utils/camurutils").default;
export default {
  emits: ['update:modelValue'],
  data() {
    return {};
  },

  methods: {
    on_change(comp) {
      console.log("on_change");
      var index = this.get_enum_val(comp.selectedIndex);
      this.$emit("update:modelValue", index);      
    },

    get_enum_val(d) {
      var x = Object.values(this.enum_to_choose_from);
      var p = x[d];
      return p;
    },
    option_disabled(drop) {
      if (drop == Camur.report_type.reptype_separator) {
        return true;
      } else {
        return this.disabled ? true : false;
      }
    }
  },

  props: {
    hintText: String,
    title: {
      type: String,
      default: "title"
    },
    enum_to_choose_from: {
      type: Object,
      default() {
        0;
      }
    },
    modelValue: {
      type: Number,
      default: 0
    },
    disabled: {
      type: Boolean
    }
  }
};
</script>
