import { WMBaseItem } from "./baseitem";

export default class Grant extends WMBaseItem {
  get userId() {
    return this.dto.userId;
  }

  get collectionId() {
    return this.dto.collectionId;
  }

  get projectId() {
    return this.dto.projectId;
  }

  get customerId() {
    return this.dto.customerId;
  }

  get canView() {
    return this.dto.canView;
  }

  get canReport() {
    return this.dto.canReport;
  }

  get canSetup() {
    return this.dto.canSetup;
  }

  get canDelete() {
    return this.dto.canDelete;
  }

  get canGrant() {
    return this.dto.canGrant;
  }

  get item_type() {
    return "grant";
  }

  upload_changes() {
    return this.Network.project_grant.update(
      this.dto.projectId,
      this.dto.userId,
      this.changes()
    );
  }

  get_user(app) {
    return app.$appm.get_user(this.userId);
  }

  get_customer(app) {
    return app.$appm.get_customer(this.customerId);
  }

  get_collection(app) {
    return app.$appm.get_access_collection(this.customerId, this.userId);
  }

  matches_template(template) {
    let compared_props = [
      "canView",
      "canReport",
      "canSetup",
      "canDelete",
      "canGrant"
    ];

    compared_props = compared_props.filter(prop =>
      this.dto.hasOwnProperty(prop)
    );

    for (let prop of compared_props) {
      if (this.dto[prop] != template[prop]) return false;
    }
    return true;
  }

  static new_item_instance(dto, identification) {
    return new Grant(dto, identification);
  }

  // eslint-disable-next-line
  static find_parent(app, identification) {
    return app.$appm.store.grants;
  }

  // eslint-disable-next-line
  static find(app, id, identification) {
    return app.$appm.get_grant(id);
  }
}
