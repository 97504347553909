export default class RequestHelper {
  constructor(request_data) {
    request_data.supposed_to_exist = request_data.method != "DELETE";
    this.req = request_data;
  }

  get data() {
    return this.req;
  }

  get failed() {
    return this.req.error_code != null;
  }

  get finished() {
    return false;
  }

  get in_progress() {
    return !this.finished && !this.failed;
  }

  get status() {
    if (this.failed()) return "failed";
    if (this.finished()) return "finished";
    if (this.send_time == null) return "in_queue";
    return "unknown";
  }

  identification_items_include(required_length, thing_to_include) {
    if (this.req.identification == null) return false;
    if (!this.returns_single_endpoint()) required_length -= 1;

    var items = Object.keys(this.req.identification);
    return items.length > required_length && items.includes(thing_to_include);
  }

  returns_single_endpoint() {
    let r = this.req.command.match(/\d+$/);
    return r != null;
  }

  equivialent_with(other_request) {
    var result =
      this.req.command == other_request.data.command &&
      this.req.method == other_request.data.method;
    return result;
  }

  on_send() {
    this.req.send_time = Date.now();
  }

  depend_on_project() {
    return this.identification_items_include(1, "project_id");
  }

  depend_on_zone() {
    return this.identification_items_include(2, "zone_id");
  }

  depend_on_customer() {
    return this.identification_items_include(1, "customer_id");
  }

  depend_on_node() {
    return this.identification_items_include(3, "node_id");
  }

  depend_on_user_being_initalized() {
    // We should have a better way of figuring this out,
    // for now we hardcode it to certain commands
    let commands_which_depend_on_user = ["customers"];
    return commands_which_depend_on_user.includes(this.req.command);
  }

  time_since_send() {
    return (Date.now() - this.req.send_time) / 1000;
  }

  was_sent_recently() {
    return this.time_since_send() < this.req.refresh_interval;
  }
}
