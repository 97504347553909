import { APIBase } from "./apibase";
import { Report } from "@/items/report";
import RequestData from "./requestdata";
import Network from "@/networking";

export class ReportAPI extends APIBase {
//   create(project_id, report) {
//     let req = new RequestData(`projects/${project_id}/reports`);
//     req.post_json = report;
//     req.identification = { project_id: project_id };
//     req.method = "POST";
//     req.on_receive = this.receive;
//     req.refresh_interval = 0;
//     return this.send(req);
//   }

create(project_id, report) {
    return new Promise((resolve, reject) => {
      let req = new RequestData(`projects/${project_id}/reports`);
      req.post_json = report;
      req.identification = { project_id: project_id };
      req.method = "POST";
      req.on_receive = (response, identification, myself) => {
        this.receive(response, identification, myself);
        resolve(response); // Resolve with the created report
      };
      req.on_error = (error) => {
        reject(error); // Reject on error
      };
      req.refresh_interval = 0;
      this.send(req);
    });
  }
  

  update(project_id, id, changes) {
    let req = new RequestData(`projects/${project_id}/reports/${id}`);
    req.post_json = changes;
    req.identification = { project_id: project_id, id: id };
    req.method = "PUT";
    req.on_receive = this.receive;
    req.refresh_interval = 0;
    return this.send(req);
  }  

//   delete(project_id, id) {
//     let req = new RequestData(`projects/${project_id}/reports/${id}`);
//     req.identification = { project_id: project_id, id: id };
//     req.method = "DELETE";
//     req.on_receive = this.receive_delete;
//     req.refresh_interval = 0;
//     return this.send(req);
//   }  

delete(project_id, id) {
    return new Promise((resolve, reject) => {
      let req = new RequestData(`projects/${project_id}/reports/${id}`);
      req.identification = { project_id: project_id, id: id };
      req.method = "DELETE";
      req.on_receive = (response, identification, myself) => {
        this.receive_delete(response, identification, myself);
        resolve(); // Resolve the promise when deletion is successful
      };
      req.on_error = (error) => {
        reject(error); // Reject the promise on error
      };
      req.refresh_interval = 0;
      this.send(req);
    });
  }

//   list(project_id, options) {
//     let req = new RequestData(
//       `projects/${project_id}/reports`,
//       options
//     );
//     req.identification = { project_id: project_id };
//     req.method = "GET";
//     req.on_receive = this.receive_array;
//     req.refresh_interval = 0;
//     return this.send(req);
//   }

list(project_id, options) {
    return new Promise((resolve, reject) => {
      let req = new RequestData(`projects/${project_id}/reports`, options);
      req.identification = { project_id: project_id };
      req.method = "GET";
      req.on_receive = (response, identification, myself) => {
        this.receive_array(response, identification, myself);
        resolve(response);
      };
      req.on_error = (error) => {
        reject(error);
      };
      req.refresh_interval = 0;
      this.send(req);
    });
  }

  // eslint-disable-next-line
  receive(response, identification, myself) {
    Network.report.list(identification.project_id);
  }

  receive_delete(response, identification, myself) {
    Network.report.list(identification.project_id);
  }

  receive_array(response, identification, myself) {
    window.console.log("reports received, length:" + response.length);
    myself.app.$appm.store.report = response;
    for (var i = 0; i < response.length; i++) {
      myself.app.$appm.store.report[i].name = response[i].name;
//      window.console.log(JSON.stringify(response[i]));
    }  
  }
}
