<template>
  <span v-if="iconCached" :style="{color:activeColor}">
    <svg-icon size="24" viewbox="0 0 24 24" :path="cachedIcon" />
  </span>
  
  <span v-else-if="iconDragIndicator" :style="{color:activeColor}">
    <svg-icon size="13" viewbox="0 0 24 24" :path="dragIndicatorIcon" />
  </span> 

  <span v-else-if="iconDelete" :style="{color:activeColor}">
    <svg-icon size="20" viewbox="0 0 24 24" :path="deleteIcon" />
  </span> 

  <div v-else-if="iconFormatIndentIncrease" :style="{color:activeColor}">
    <svg-icon size="18" viewbox="0 0 24 24" :path="formatIndentIncreaseIcon" />
  </div>

  <div v-else-if="iconFormatIndentDecrease" :style="{color:activeColor}">
    <svg-icon size="18" viewbox="0 0 24 24" :path="formatIndentDecreaseIcon" />
  </div>

  <div v-else-if="iconFolder" :style="{color:activeColor}">
    <svg-icon size="24" viewbox="0 0 24 24" :path="folderIcon" />
  </div>

  <span v-else-if="iconSettings" :style="{color:activeColor}" >
    <svg-icon size="20" viewbox="0 0 24 24" :path="settingsIcon" />
  </span>

  <span v-else-if="iconDownload" :style="{color:activeColor}" >
    <svg-icon size="20" viewbox="0 0 24 24" :path="downloadIcon" />
  </span>

  <div v-else-if="iconAccountBalance" :style="{color:activeColor}">
    <svg-icon size="24" viewbox="0 0 24 24" :path="accountBalanceIcon" />
  </div>

  <div v-else-if="iconAccountCircle" :style="{color:activeColor}">
    <svg-icon size="24" viewbox="0 0 24 24" :path="accountCircleIcon" />
  </div>

  <span v-else-if="iconClear" :style="{color:activeColor}">
    <svg-icon size="20" viewbox="0 0 24 24" :path="clearIcon" />
  </span>

  <span v-else-if="iconSearch" :style="{color:activeColor}">
    <svg-icon size="20" viewbox="0 0 24 24" :path="searchIcon" />
  </span>

  <div v-else-if="iconGridOff" :style="{color:activeColor}">
    <svg-icon size="24" viewbox="0 0 24 24" :path="gridOffIcon" />
  </div>
  
  <div v-else-if="iconUnfoldLess" :style="{color:activeColor}">
    <svg-icon size="24" viewbox="0 0 24 24" :path="unfoldLessIcon" />
  </div>      
  <div v-else-if="iconUnfoldMore" :style="{color:activeColor}">
    <svg-icon size="24" viewbox="0 0 24 24" :path="unfoldMoreIcon" />
  </div>
  <div v-else-if="iconPerson" :style="{color:activeColor}">
    <svg-icon size="24" viewbox="0 0 24 24" :path="personIcon" />
  </div>  
  <div v-else-if="iconSecurity" :style="{color:activeColor}">
    <svg-icon size="16" viewbox="0 0 24 24" :path="securityIcon" />
  </div>   
  <div v-else-if="iconGroupAdd" :style="{color:activeColor}">
    <svg-icon size="16" viewbox="0 0 24 24" :path="groupAddIcon" />
  </div>
  <div v-else-if="iconChevronRight" :style="{color:activeColor}">
    <svg-icon size="15" viewbox="0 0 24 24" :path="chevronRightIcon" />
  </div> 
  <div v-else-if="iconDone" :style="{color:activeColor}">
    <svg-icon size="20" viewbox="0 0 24 24" :path="doneIcon" />
  </div>
  <span v-else-if="iconLockBlack" :style="{color:activeColor}">
    <svg-icon size="14" viewbox="0 0 24 24" :path="lockBlackIcon" />
  </span> 
  <span v-else-if="iconLockOpenBlack" :style="{color:activeColor}">
    <svg-icon size="14" viewbox="0 0 24 24" :path="lockOpenBlackIcon" />
  </span> 
  <div v-else>
    "icon"
  </div> 
    
</template>

<script>

const cachedIcon  ="M19 8l-4 4h3c0 3.31-2.69 6-6 6a5.87 5.87 0 01-2.8-.7l-1.46 1.46A7.93 7.93 0 0012 20c4.42 0 8-3.58 8-8h3l-4-4zM6 12c0-3.31 2.69-6 6-6 1.01 0 1.97.25 2.8.7l1.46-1.46A7.93 7.93 0 0012 4c-4.42 0-8 3.58-8 8H1l4 4 4-4H6z";
const dragIndicatorIcon="M11 18c0 1.1-.9 2-2 2s-2-.9-2-2 .9-2 2-2 2 .9 2 2zm-2-8c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm0-6c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm6 4c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2zm0 2c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm0 6c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2z";
const deleteIcon  ="M6 19c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V7H6v12zM19 4h-3.5l-1-1h-5l-1 1H5v2h14V4z";
const formatIndentIncreaseIcon ="M3 21h18v-2H3v2zM3 8v8l4-4-4-4zm8 9h10v-2H11v2zM3 3v2h18V3H3zm8 6h10V7H11v2zm0 4h10v-2H11v2z";
const formatIndentDecreaseIcon ="M11 17h10v-2H11v2zm-8-5l4 4V8l-4 4zm0 9h18v-2H3v2zM3 3v2h18V3H3zm8 6h10V7H11v2zm0 4h10v-2H11v2z";
const folderIcon="M10 4H4c-1.1 0-1.99.9-1.99 2L2 18c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V8c0-1.1-.9-2-2-2h-8l-2-2z";
const settingsIcon="M15.95 10.78c.03-.25.05-.51.05-.78s-.02-.53-.06-.78l1.69-1.32c.15-.12.19-.34.1-.51l-1.6-2.77c-.1-.18-.31-.24-.49-.18l-1.99.8c-.42-.32-.86-.58-1.35-.78L12 2.34a.4.4 0 00-.4-.34H8.4c-.2 0-.36.14-.39.34l-.3 2.12c-.49.2-.94.47-1.35.78l-1.99-.8c-.18-.07-.39 0-.49.18l-1.6 2.77c-.1.18-.06.39.1.51l1.69 1.32c-.04.25-.07.52-.07.78s.02.53.06.78L2.37 12.1c-.15.12-.19.34-.1.51l1.6 2.77c.1.18.31.24.49.18l1.99-.8c.42.32.86.58 1.35.78l.3 2.12c.04.2.2.34.4.34h3.2c.2 0 .37-.14.39-.34l.3-2.12c.49-.2.94-.47 1.35-.78l1.99.8c.18.07.39 0 .49-.18l1.6-2.77c.1-.18.06-.39-.1-.51l-1.67-1.32zM10 13c-1.65 0-3-1.35-3-3s1.35-3 3-3 3 1.35 3 3-1.35 3-3 3z";
const downloadIcon="M19 9h-4V3H9v6H5l7 7 7-7zM5 18v2h14v-2H5z";
const accountBalanceIcon ="M4 10v7h3v-7H4zm6 0v7h3v-7h-3zM2 22h19v-3H2v3zm14-12v7h3v-7h-3zm-4.5-9L2 6v2h19V6l-9.5-5z";
const accountCircleIcon ="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 3c1.66 0 3 1.34 3 3s-1.34 3-3 3-3-1.34-3-3 1.34-3 3-3zm0 14.2a7.2 7.2 0 01-6-3.22c.03-1.99 4-3.08 6-3.08 1.99 0 5.97 1.09 6 3.08a7.2 7.2 0 01-6 3.22z";
const clearIcon   ="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z";
const searchIcon   ="M15.5 14h-.79l-.28-.27A6.471 6.471 0 0016 9.5 6.5 6.5 0 109.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z";
const gridOffIcon ="M8 4v1.45l2 2V4h4v4h-3.45l2 2H14v1.45l2 2V10h4v4h-3.45l2 2H20v1.45l2 2V4c0-1.1-.9-2-2-2H4.55l2 2H8zm8 0h4v4h-4V4zM1.27 1.27L0 2.55l2 2V20c0 1.1.9 2 2 2h15.46l2 2 1.27-1.27L1.27 1.27zM10 12.55L11.45 14H10v-1.45zm-6-6L5.45 8H4V6.55zM8 20H4v-4h4v4zm0-6H4v-4h3.45l.55.55V14zm6 6h-4v-4h3.45l.55.54V20zm2 0v-1.46L17.46 20H16z";
const unfoldLessIcon ="M7.41 18.59L8.83 20 12 16.83 15.17 20l1.41-1.41L12 14l-4.59 4.59zm9.18-13.18L15.17 4 12 7.17 8.83 4 7.41 5.41 12 10l4.59-4.59z";
const unfoldMoreIcon ="M12 5.83L15.17 9l1.41-1.41L12 3 7.41 7.59 8.83 9 12 5.83zm0 12.34L8.83 15l-1.41 1.41L12 21l4.59-4.59L15.17 15 12 18.17z"
const personIcon  ="M12 12c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm0 2c-2.67 0-8 1.34-8 4v2h16v-2c0-2.66-5.33-4-8-4z";
const securityIcon  ="M12 1L3 5v6c0 5.55 3.84 10.74 9 12 5.16-1.26 9-6.45 9-12V5l-9-4zm0 10.99h7c-.53 4.12-3.28 7.79-7 8.94V12H5V6.3l7-3.11v8.8z";
const groupAddIcon  ="M8 10H5V7H3v3H0v2h3v3h2v-3h3v-2zm10 1c1.66 0 2.99-1.34 2.99-3S19.66 5 18 5c-.32 0-.63.05-.91.14.57.81.9 1.79.9 2.86s-.34 2.04-.9 2.86c.28.09.59.14.91.14zm-5 0c1.66 0 2.99-1.34 2.99-3S14.66 5 13 5c-1.66 0-3 1.34-3 3s1.34 3 3 3zm6.62 2.16c.83.73 1.38 1.66 1.38 2.84v2h3v-2c0-1.54-2.37-2.49-4.38-2.84zM13 13c-2 0-6 1-6 3v2h12v-2c0-2-4-3-6-3z";
const chevronRightIcon  ="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z";
const doneIcon  ="M9 16.2L4.8 12l-1.4 1.4L9 19 21 7l-1.4-1.4L9 16.2z";
const lockBlackIcon  ="M18 8h-1V6c0-2.76-2.24-5-5-5S7 3.24 7 6v2H6c-1.1 0-2 .9-2 2v10c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2V10c0-1.1-.9-2-2-2zm-6 9c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2zm3.1-9H8.9V6c0-1.71 1.39-3.1 3.1-3.1 1.71 0 3.1 1.39 3.1 3.1v2z";
const lockOpenBlackIcon  = "M12 17c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2zm6-9h-1V6c0-2.76-2.24-5-5-5S7 3.24 7 6h1.9c0-1.71 1.39-3.1 3.1-3.1 1.71 0 3.1 1.39 3.1 3.1v2H6c-1.1 0-2 .9-2 2v10c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2V10c0-1.1-.9-2-2-2zm0 12H6V10h12v10z";

export default {
  setup() {
		return {
        cachedIcon,
        dragIndicatorIcon,
        deleteIcon,
        formatIndentIncreaseIcon,
        formatIndentDecreaseIcon,
        folderIcon,
        settingsIcon,
        downloadIcon,
        accountBalanceIcon,
        accountCircleIcon,
        clearIcon,
        searchIcon,
        gridOffIcon,
        unfoldLessIcon,
        unfoldMoreIcon,
        personIcon,
        securityIcon,
        groupAddIcon,
        chevronRightIcon,
        doneIcon,
        lockBlackIcon,
        lockOpenBlackIcon 
	  	}
  	},
  name: "SmallIcon",
  data() {
    return {};
  },

  props: {
    icon: {
      type: String,
      default: "person"
    },
    color: {
      type: String,
      default: "black"
    },
    size: {
      type: String,
      default: "24"
    }
  },

  computed: {
    icon_name() {
      return "baseline-" + this.icon + "-24px";
    },

    iconCached() {
      if (this.icon == "cached")
        return true;
      else
        return false;   
    },
    iconDragIndicator() {
      if (this.icon == "drag_indicator")
        return true;
      else
        return false;   
    }, 
    iconDelete() {
      if (this.icon == "delete")
        return true;
      else
        return false;   
    },  
    iconFormatIndentIncrease() {
      if (this.icon == "format_indent_increase")
        return true;
      else
        return false
    },
    iconFormatIndentDecrease() {
      if (this.icon == "format_indent_decrease")
        return true;
      else
        return false
    },
    iconFolder() {
      if (this.icon == "folder")
        return true;
      else
        return false
    },
    iconSettings() {
      if (this.icon == "settings")
        return true;
      else
        return false
    },
    iconDownload() {
      if (this.icon == "download")
        return true;
      else
        return false
    },
    iconAccountBalance() {
      if (this.icon == "account_balance")
        return true;
      else
        return false
    },
    iconAccountCircle() {
      if (this.icon == "account_circle")
        return true;
      else
        return false
    },
    iconClear() {
      if (this.icon == "clear")
        return true;
      else
        return false;   
    }, 
    iconSearch() {
      if (this.icon == "search")
        return true;
      else
        return false;   
    },   
    iconGridOff() {
      if (this.icon == "grid_off")
        return true;
      else
        return false;   
    }, 
    iconUnfoldLess() {
      if (this.icon == "unfold_less")
        return true;
      else
        return false;   
    }, 
    iconUnfoldMore() {
      if (this.icon == "unfold_more")
        return true;
      else
        return false;   
    }, 
    iconPerson() {
      if (this.icon == "person")
        return true;
      else
        return false;   
    },
    iconSecurity() {
      if (this.icon == "security")
        return true;
      else
        return false;   
    },
    iconGroupAdd() {
      if (this.icon == "group_add")
        return true;
      else
        return false;   
    },
    iconChevronRight() {
      if (this.icon == "chevron_right")
        return true;
      else
        return false;   
    },
    iconDone() {
      if (this.icon == "done")
        return true;
      else
        return false;   
    },
    iconLockBlack() {
      if (this.icon == "lock-black")
        return true;
      else
        return false;   
    },
    iconLockOpenBlack() {
      if (this.icon == "lock_open-black")
        return true;
      else
        return false;   
    },
    activeColor() {
      return this.color;
    }
  },
  created: function() {}
};
</script>
