<template>
  <span class="wm_status_lamp">
    <svg height="8" width="8" v-if="tiny">
      <circle cx="3" cy="3" r="3" :fill="col" />
    </svg>
    <svg height="17" width="17" v-else>
      <circle
        cx="6"
        cy="10"
        r="5"
        :fill="col"
        style="border: solid 1px rgba(0,0,0,0.9);"
      />
    </svg>
  </span>
</template>

<script>
export default {
  name: "StatusLamp",
  data() {
    return {};
  },

  props: {
    status: String,
    color: {
      type: String,
      default: null
    },
    tiny: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    col() {
      if (this.color != null) {
        return this.color;
      }

      if (this.status == "green") return "#4ecc6b";
      if (this.status == "red") return "#ed330e";
      if (this.status == "yellow") return "#f7b10e";
      if (this.status == "blue") return "#4286f4";
      if (this.status == "gray") return "#afb4bc";
      if (this.status == "white") return "#ffffff ";
      return "#f7b10e";
    }
  }
};
</script>
