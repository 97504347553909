<template lang="html">
  <span class="wm_table_colored_box" :style="{ backgroundColor: color }">
    {{ text }}
  </span>
</template>

<script>
export default {
  name: "RecordingTypeLabel",

  props: {
    recording_type: {
      type: Number,
      default: null
    }
  },

  computed: {
    text() {
      return this.$camur.get_rectype_text(this.recording_type);
    },
    color() {
      return this.$camur.color_code_status_of_rectype(this.recording_type);
    }
  }
};
</script>
