export default class RequestData {
  constructor(command, options) {
    this.command = command;
    this.refresh_interval = 30;
    this.http_method = "GET";
    this.identification = {};
    this.on_receive_callbacks = [];
    this.xhr = null;
    this.send_time = null;
    this.finished = false;
    this.error_code = null;
    this.set_interval(global.default_refresh_interval);

    if (options != null) {
      if (options.callback != null) this.custom_callback = options.callback;
      if (options.interval != null) this.set_interval(options.interval);
    }
  }

  get method() {
    return this.http_method;
  }
  set method(val) {
    this.http_method = val;
    if (this.http_method != "GET") {
      this.set_interval(0);
    }
  }

  add_on_receive_callback(callback) {
    this.on_receive_callbacks.push(callback);
  }

  on_error(code) {
    this.error_code = code;
  }

  on_successful() {
    this.finished = true;
  }

  set_interval(interval) {
    if (interval != null) {
      this.refresh_interval = interval;
    }
  }
}
