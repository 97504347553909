import { APIBase } from "./apibase";
import User from "@/items/user";
import RequestData from "./requestdata";

export class UserAPI extends APIBase {
  fetch(id, options) {
    let req = new RequestData(`users/${id}`, options);
    req.on_receive = this.receive;
    return this.send(req);
  }

  login(username, password) {
    let req = new RequestData("users/authenticate");
    req.post_data = new FormData();
    req.post_data.append("email", username);
    req.post_data.append("password", password);
    req.method = "POST";
    req.use_token = false;
    req.on_receive = this.login_receive;
    req.set_interval(0);
    return this.send(req);
  }

  login_receive(response, identification, myself) {
    if (response.message == "Authentication failed.") {
      myself.app.$appm.show_note("Wrong email / password.");
    } else {
      myself.app.$appm.on_logged_in(response.token, response.id);
      User.update_or_create_from_dto(response, myself.app, identification);
    }
  }

  fetch_global_rights() {
    let req = new RequestData("users/authenticate/access");
    req.method = "GET";
    req.use_token = true;
    req.set_interval(0);
    req.on_receive = this.receive_global_rights;
    return this.send(req);
  }

  reset_password(id, password, verify_token) {
    let req = new RequestData("users/reset_password");
    req.method = "POST";
    req.on_receive = this.password_reset_receive;
    req.post_json = {
      id: id,
      password: password,
      verifyToken: verify_token
    };
    req.use_token = false;
    return this.send(req);
  }

  receive_password_reset(response, identification, myself) {
    var success = response != false;
    myself.app.$appm.on_password_change(success);
  }

  verify(id, password, verify_token) {
    let req = new RequestData("users/verify_user");
    req.method = "POST";
    req.on_receive = this.verify_receive;
    req.post_json = {
      id: id,
      password: password,
      verifyToken: verify_token
    };
    req.use_token = false;
    return this.send(req);
  }

  verify_receive(response, identification, myself) {
    var success = response != false;
    myself.app.$appm.on_user_verified(success);
  }

  create(data, custom_callback) {
    let req = new RequestData("users", custom_callback);
    req.method = "POST";
    req.on_receive = this.receive;
    req.post_json = data;
    return this.send(req);
  }

  send_verification_email(email, custom_callback) {
    let req = new RequestData("users/send_verification", custom_callback);
    req.method = "POST";
    req.on_receive = this.receive_send_verification_email;
    req.identification = { email: email };
    req.post_json = { email: email };
    req.use_token = false;
    return this.send(req);
  }

  verify_reset_token(id, token, refresh_interval = 0) {
    let req = new RequestData(`users/verify_token/${id}/${token}`);
    req.on_receive = this.verify_reset_token_receive;
    req.set_interval(refresh_interval);
    req.use_token = false;
    return this.send(req);
  }

  verify_reset_token_receive(response, identification, myself) {
    myself.app.$appm.store.received_token_verification = true;
    myself.app.$appm.store.received_token_valid = response;
    if (response != true) {
      myself.app.$appm.on_verify_token_expired();
    }
  }

  receive_send_verification_email(response, identification, myself) {
    myself.app.$appm.show_note(
      "A verification email was sent to " + identification.email
    );
  }

  receive_global_rights(response, identification, myself) {
    if (myself.app.session.current_user != null) {
      myself.app.session.current_user.global_rights = response;
    }
  }

  receive(response, identification, myself) {
    User.update_or_create_from_dto(response, myself.app, identification);
  }

  password_reset_receive(response, identification, myself) {
    myself.app.$appm.show_note("Your password was successfully changed.");
  }

  receive_delete(response, identification, myself) {
    User.delete_clientside(myself.app, identification, identification.user_id);
  }
}
