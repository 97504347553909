<template>
  <hr class="wm_section_end_hr" />
</template>

<script>
export default {
  name: "SectionEnd",
  data() {
    return {};
  },

  methods: {},

  created: function() {}
};
</script>
