<template>
  <div class="labeled-control">
    <p :class="{ wm_label_disabled: disabled }"> {{ label }}</p>
    <input
      v-if="!disabled"
      class="wm_time_input"
      ref="timepicker"
      type="time"
      step="1"
      v-bind:value="seconds_string"
      v-on:input="$emit('update:modelValue', timestring_to_secs($event.target.value))"
    />
    <input
      v-else
      class="wm_time_input"
      disabled="true"
      type="text"
      :class="{ wm_input_disabled: disabled }"
      style="width:120px"
      v-bind:value="seconds_string"
    />
  </div>
</template>

<script>
export default {
  emits: ['update:modelValue'],
  name: "LabeledTimeInput",
  data() {
    return {};
  },

  methods: {
    emit() {
      this.$emit("input", this.$refs.timepicker.value);
    },

    timestring_to_secs(timestring) {
      var a = timestring.split(":"); // split it at the colons
      var totalSeconds = 0;
      if (a[0] != null)
      {
        var hours = parseInt(a[0]);
        totalSeconds += hours * 60 * 60;
      }
      if (a[1] != null)
      {
        var minutes = parseInt(a[1]);
        totalSeconds += minutes * 60;
      }
      if (a[2] != null)
      {
        var seconds = parseInt(a[2]);
        totalSeconds += seconds;
      }
      return totalSeconds;
    }
  },

  computed: {
    seconds_string() {
      let hourString = ("00" + parseInt(this.modelValue / 3600)).slice(-2);
      let minuteString = ("00" + parseInt((this.modelValue % 3600) / 60)).slice(-2);
      let secString = ("00" + parseInt(this.modelValue % 60)).slice(-2);
      // console.log(`${hourString}:${minuteString}:${secString}`);
      return `${hourString}:${minuteString}:${secString}`;
    }
  },

  props: {
    label: {
      type: String
    },

    modelValue: {
      type: Number
    },

    disabled: {
      type: Boolean
    }
  }
};
</script>
