import { APIBase } from "./apibase";
import { CustomerProject } from "@/items/customerproject";

export class CustomerProjectAPI extends APIBase {
  list(customer_id, refresh_interval = global.default_refresh_interval) {
    var cmd = "customers/" + customer_id.toString() + "/projects";
    return this.send({
      command: cmd,
      method: "GET",
      identification: {
        customer_id: customer_id
      },
      refresh_interval: refresh_interval,
      on_receive: this.receive_array
    });
  }

  receive_array(response, identification, myself) {
    for (var i = 0; i < response.length; i++) {
      CustomerProject.delete_clientside(
        myself.app,
        identification,
        response[i].id
      );
      CustomerProject.update_or_create_from_dto(
        response[i],
        myself.app,
        identification
      );
    }
  }

  fetch(project_id, refresh_interval = global.default_refresh_interval) {
    var customer_id = 1;
    var cmd =
      "customers/" +
      customer_id.toString() +
      "/projects/" +
      project_id.toString();
    return this.send({
      command: cmd,
      method: "GET",
      refresh_interval: refresh_interval,
      on_receive: this.receive
    });
  }

  receive(response, identification, myself) {
    CustomerProject.update_or_create_from_dto(
      response,
      myself.app,
      identification
    );
  }

  receive_delete(response, identification, myself) {
    CustomerProject.delete_clientside(
      myself.app,
      identification,
      identification.customer_project_id
    );
  }
}
