<template>
  <div class="dropdown labeled-control">
    <label
      v-if="title.length > 0"
      class="wm_dropdown_label"
      for="main_select"
      :class="{ wm_label_disabled: disabled }"
    >
      {{ title }}
    </label>
    <br v-if="title.length > 0" />
    <select
      id="main_select"
      :value="modelValue"
      class="btn-primary dropdown-toggle btn"
      :class="{ btn_disabled: disabled, wm_tight_dropdown: tight }"
      :disabled="disabled"
      @change="on_change($event.target)"
      :title="hintText"
    >
      <option
        class="wm_dropdown_option"
        v-for="opt in opts"
        :key="opt.id"
        :value="opt.id"
      >
        <span v-if="opt.title">
          {{ opt.title }}
        </span>
        <span v-else>
          {{ opt.id }}
        </span>
      </option>
      <option
        class="wm_dropdown_option"
        v-for="opt in reportOpts"
        :key="opt.id"
        :value="opt.id"
      >
        <span v-if="opt.name">
          {{ opt.name }}
        </span>
        <span v-else>
          {{ opt.id }}
        </span>
      </option>
    </select>
  </div>
</template>

<script>
export default {
  name: "DropDown",
  data() {
    return {};
  },
  emits: ['update:modelValue','change'],
  methods: {
    on_change(comp) {
      var index = comp.value;
      this.$emit("update:modelValue", index);
      this.$emit("change", index);
    }
  },

  props: {
    hintText: String,
    title: {
      type: String,
      default: "title"
    },
    modelValue: {
      default: 0
    },
    opts: {
      type: Array
    },
    reportOpts: {
      type: Array
    },
    disabled: {
      type: Boolean
    },
    tight: {
      type: Boolean
    }
  }
};
</script>
