import { GrantAPI } from "./grant";

export class CustomerGrantAPI extends GrantAPI {
  list(
    customer_id,
    custom_callback,
    refresh_interval = global.default_refresh_interval
  ) {
    return this.send({
      command: "customers/" + customer_id + "/access/grants",
      method: "GET",
      identification: {
        customer_id: customer_id
      },
      on_receive: this.receive_grant_array,
      refresh_interval: refresh_interval,
      custom_callback: custom_callback
    });
  }

  fetch(
    customer_id,
    user_id,
    custom_callback,
    refresh_interval = global.default_refresh_interval
  ) {
    return this.send({
      command: "customers/" + customer_id + "/access/grants/" + user_id,
      method: "GET",
      identification: {
        customer_id: customer_id,
        user_id: user_id
      },
      refresh_interval: refresh_interval,
      on_receive: this.receive_grant,
      custom_callback: custom_callback
    });
  }

  del(
    customer_id,
    user_id,
    refresh_interval = global.default_refresh_interval,
    custom_callback
  ) {
    return this.send({
      command: "customers/" + customer_id + "/access/grants/" + user_id,
      method: "DELETE",
      identification: {
        customer_id: customer_id,
        user_id: user_id
      },
      refresh_interval: refresh_interval,
      on_receive: this.receive_delete,
      custom_callback: custom_callback
    });
  }

  update(customer_id, grant_data, custom_callback) {
    this.send({
      command: "customers/" + customer_id + "/access/grants",
      method: "PUT",
      identification: {
        customer_id: customer_id
      },
      on_receive: this.receive_grant,
      post_json: grant_data,
      custom_callback: custom_callback
    });
  }
}
