<template>
  <div class="tab_selector">
    <TabBtn
      v-for="tab in tabs"
      :tabid="tab.key"
      :key="tab.key"
      @click="select(tab.key)"
      :title="tab.name"
      :tight="tight"
      :selected="selected_tab === tab.key"
    />
  </div>
</template>

<script>
export default {
  name: "TabMenu",
  emits:['select-tab'],
  data() {
    return {};
  },

  props: {
    tabs: Array,
    selected_tab: String,
    tight: Boolean,
    tabid: {
      type: String,
      default: null
    }
  },

  methods: {
    select(key) {
      console.log("select:"+key);
      this.$emit('select-tab', key);
    }
  },

  created: function() {}
};
</script>
