import { APIBase } from "./apibase";
import { CntrlDevice } from "@/items/cntrldevice";
import { NodeDevice } from "@/items/nodedevice";

const Utils = require("@/utils").default;

export class CntrlDeviceAPI extends APIBase {
  fetch(
    project_id,
    cntrl_device_id,
    custom_callback,
    refresh_interval = global.default_refresh_interval
  ) {
    return this.send({
      command: `projects/${project_id}/devices/${cntrl_device_id}`,
      method: "GET",
      identification: {
        cntrl_device_id: cntrl_device_id
      },
      on_receive: this.receive,
      refresh_interval: refresh_interval,
      custom_callback: custom_callback
    });
  }

  register_serial(project_id, data, custom_callback) {
    return this.send({
      command: "controllers/serials",
      method: "POST",
      identification: {
        serialNumber: data.serialNumber
      },
      post_json: data,
      on_receive: this.receive,
      custom_callback: custom_callback
    });
  }

  list(
    project_id,
    custom_callback,
    refresh_interval = global.default_refresh_interval
  ) {
    return this.send({
      command: `projects/${project_id}/devices`,
      method: "GET",
      identification: {
        project_id
      },
      on_receive: this.receive_array,
      refresh_interval: 0, // always fetch immediately om demand and not periodically
      custom_callback: custom_callback
    });
  }

  receive(response, identification, myself) {
    CntrlDevice.update_or_create_from_dto(response, myself.app, identification);

    response.nodes.forEach(function(el) {
      NodeDevice.update_or_create_from_dto(el, myself.app, identification);
    });
  }

  receive_array(response, identification, myself) {
    response.forEach(function(el) {
      CntrlDevice.update_or_create_from_dto(el, myself.app, identification);

      el.nodes.forEach(function(no) {
        let ide = {
          project_id: identification.project_id,
          cntrl_device_id: el.serialNumber
        };
        NodeDevice.update_or_create_from_dto(no, myself.app, ide);
      });
    });
  }
}
