<template lang="html">
  <div class="SmallRangeIndicator" :style="{ width: width }">
    <div class="wm_sri_leftlabel">{{ leftlabel }}</div>
    <!--
    <div class="wm_sri_bar">
    <div class="wm_sri_bar_bg">
    <div
    :style="{ width: fraction_width, backgroundColor: color }"
    class="wm_sri_bar_bg wm_sri_bar_main"
    ></div>
  </div>
</div>
    -->
    <div style="text-align: center">
      ........
    </div>
    <div class="wm_sri_rightlabel">{{ rightlabel }}</div>
  </div>
</template>

<script>
export default {
  name: "SmallRangeIndicator",
  data() {
    return {
      status: "normal"
    };
  },

  props: {
    width: {
      type: String,
      default: "100%"
    },

    rightlabel: {
      type: String,
      default: ""
    },

    leftlabel: {
      type: String,
      default: ""
    },
    value: {
      type: Number,
      default: 33
    },
    max_value: {
      type: Number,
      default: 100
    },
    min_value: {
      type: Number,
      default: 0
    },
    too_high_level: {
      type: Number,
      default: 10000000000000
    },
    too_low_level: {
      type: Number,
      default: -10000000000000
    },

    colorGood: {
      type: String,
      default: "#3158b2"
    },
    colorHigh: {
      type: String,
      default: "#d13329"
    },
    colorLow: {
      type: String,
      default: "#d13329"
    }
  },

  computed: {
    percentage() {
      if (this.value < this.min_value) {
        return 0.0;
      }
      if (this.value > this.max_value) {
        return 100.0;
      }
      return (
        ((this.value - this.min_value) / (this.max_value - this.min_value)) *
        100
      );
    },

    fraction_width() {
      return this.percentage + "%";
    },

    color() {
      if (this.value <= this.too_low_level) {
        return this.colorLow;
      }
      if (this.value >= this.too_high_level) {
        return this.colorHigh;
      }
      return this.colorGood;
    }
  }
};
</script>

<style lang="css" scoped>
.SmallRangeIndicator {
	font-weight: bold;
	font-size: 9px;
	height: 12px;
	display: grid;
	grid-template-columns: 18px auto 50px;
	grid-template-rows: auto;
	white-space: nowrap;
}
.wm_sri_leftlabel {
	text-align: left;
}
.wm_sri_rightlabel {
	text-align: right;
}
.wm_sri_bar {
	padding: 0px;
	padding-top: 3px;
}

.wm_sri_bar_bg {
	background-color: #e0e0e0;
	height: 6px;
	width: 100%;
	border-radius: 3px;
	border: solid 1px rgba(0,0,0,0.1);
}
.wm_sri_bar_main {
	margin-top: -1px;
	margin-left: -1px;
	height: 6px;
	width: 50%;
	border-radius: 3px;
	position: relative;
}
</style>
