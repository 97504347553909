import { APIBase } from "../apibase";
import RecordingInfo from "@/items/recordinginfo";

export class RecordingInfoAPI extends APIBase {
  prj_list(
    project_id,
    callback,
    refresh_interval = global.default_refresh_interval
  ) {
    this.prj_list_base(project_id, callback, "", refresh_interval);
  }

  prj_list_base(
    project_id,
    callback,
    ending,
    refresh_interval = global.default_refresh_interval
  ) {
    return this.send({
      command: "projects/" + project_id + "/recordings" + ending,
      method: "GET",
      on_receive: this.receive_array,
      identification: {
        project_id: project_id
      },
      custom_callback: callback,
      refresh_interval: refresh_interval
    });
  }

  list_base(
    project_id,
    zone_id,
    start,
    stop,
    controller_id,
    max_vals,
    callback,
    refresh_interval = global.default_refresh_interval,
    ascending = 0
  ) {
    return this.send({
      command: `projects/${project_id}/recordings?Start=${encodeURIComponent(
        start.toISOString()
      )}&Stop=${encodeURIComponent(
        stop.toISOString()
      )}&ControllerIds=${controller_id}&Limit=${max_vals}&Ascending=${ascending}`,
      method: "GET",
      on_receive: this.receive_array,
      identification: {
        project_id: project_id,
        zone_id: zone_id
      },
      custom_callback: callback,
      refresh_interval: refresh_interval
    });
  }

  fetch_base(
    project_id,
    zone_id,
    recording_info_id,
    callback,
    ending,
    refresh_interval = global.default_refresh_interval
  ) {
    var cmd = "";
    cmd += "projects/" + project_id + "/zones/" + zone_id;
    cmd += "/recordings" + ending + "/" + recording_info_id.toString();
    return this.send({
      command: cmd,
      method: "GET",
      on_receive: this.receive,
      identification: {
        project_id: project_id,
        zone_id: zone_id,
        recording_id: recording_info_id
      },
      custom_callback: callback,
      refresh_interval: refresh_interval
    });
  }

  /*   update_base(
    project_id,
    zone_id,
    recording_info_id,
    changes,
    callback,
    ending
  ) {
    var cmd =
      "projects/" +
      project_id +
      "/zones/" +
      zone_id +
      "/recordings" +
      ending +
      "/" +
      recording_info_id;
    return this.send({
      command: cmd,
      method: "PUT",
      identification: {
        project_id: project_id,
        zone_id: zone_id,
        recording_id: recording_info_id
      },
      custom_callback: callback,
      on_receive: this.receive,
      post_json: changes
    });
  }

  create_base(project_id, zone_id, recording_info, callback, ending) {
    var cmd =
      "projects/" + project_id + "/zones/" + zone_id + "/recordings/" + ending;
    return this.send({
      command: cmd,
      method: "POST",
      identification: {
        project_id: project_id,
        zone_id: zone_id
      },
      custom_callback: callback,
      on_receive: this.receive,
      post_json: recording_info
    });
  }
 */
  list(
    project_id,
    zone_id,
    start,
    stop,
    controller_id,
    max_vals,
    refresh_interval = global.default_refresh_interval,
    ascending = 0,
    callback
  ) {
    this.list_base(
      project_id,
      zone_id,
      start,
      stop,
      controller_id,
      max_vals,
      callback,
      refresh_interval,
      ascending
    );
  }

  fetch(
    project_id,
    zone_id,
    recording_info_id,
    callback,
    refresh_interval = global.default_refresh_interval
  ) {
    this.fetch_base(
      project_id,
      zone_id,
      recording_info_id,
      callback,
      "",
      refresh_interval
    );
  }

  del(project_id, recording_id) {
    return this.send({
      command: "projects/" + project_id + "/recordings/" + recording_id,
      method: "DELETE",
      on_receive: this.receive_delete,
      identification: {
        project_id: project_id,
        recording_id : recording_id
      }
    });
  }

  receive(response, identification, myself) {
    RecordingInfo.update_or_create_from_dto(
      response,
      myself.app,
      identification
    );
  }

  receive_array(response, identification, myself) {
    response.forEach(function(el) {
      RecordingInfo.update_or_create_from_dto(el, myself.app, identification);
    });
  }

  // eslint-disable-next-line
  receive_delete(response, identification, myself) {
/*     RecordingInfo.delete_clientside(
      myself.app,
      identification.project_id,
      identification.recording_id
    ); */
  }
}
