import { WMBaseItem } from "./baseitem";

export class NodeDevice extends WMBaseItem {
  get serial() {
    return this.dto.serialNumber;
  }

  get id() {
    return this.dto.serialNumber;
  }

  get capability() {
    return this.dto.capability;
  }

  get versionMajor() {
    return this.dto.versionMajor;
  }
  get versionMinor() {
    return this.dto.versionMinor;
  }
  get version_full() {
     // Leading 0
    let versionMinor = String(this.dto.versionMinor).padStart(2, '0');
    return this.dto.versionMajor + "." + this.dto.versionMinor;
  }
  get item_type() {
    return "node_device";
  }

  constructor(dto, identification) {
    super(dto);
    this.app_prop.project_id = identification.project_id;
    this.app_prop.cntrl_device_id = identification.cntrl_device_id;
  }

  props_template() {
    return {
      project_id: 0,
      cntrl_device_id: 0
    };
  }

  children_template() {
    return {};
  }

  upload_changes() {
    let changes = this.handle_websocket_commands(this.changes());

    return this.Network.node_device.update(
      this.app_prop.project_id,
      this.app_prop.cntrl_device_id,
      this.id,
      changes
    );
  }

  static new_item_instance(dto, identification) {
    return new NodeDevice(dto, identification);
  }

  static find_parent(app, identification) {
    return app.$appm.get_cntrl_device(
      identification.project_id,
      identification.cntrl_device_id
    ).node_devices;
  }

  static find(app, id, identification) {
    var n = app.$appm.get_node_device(
      identification.project_id,
      identification.cntrl_device_id,
      id
    );
    return n;
  }
}
