import { WMBaseItem } from "./baseitem";

export default class AccessCollection extends WMBaseItem {
  get customerId() {
    return this.dto.customerId;
  }

  upload_changes() {
    return this.Network.access_collection.update(this.id, this.changes());
  }

  static new_item_instance(dto, identification) {
    return new AccessCollection(dto, identification);
  }

  static find_parent(app, identification) {
    return app.$appm.get_customer(identification.customer_id).access_collections;
  }

  static find(app, id, identification) {
    return app.$appm.get_access_collection(identification.customer_id, id);
  }
}
