<template lang="html">
  <div class="last_value">
    {{ $txt.last_measurement }}: <TimeLabel :time="date" />
  </div>
</template>

<script>
export default {
  props: ["date"]
};
</script>

<style lang="css" scoped>
.last_value {
  width: 100%;
  color: gray;
  font-size: 10px;
  text-align:center;
}
</style>
