import { APIBase } from "./apibase";
import RequestData from "./requestdata";
const static_server = (process.env.NODE_ENV == 'production') ? "/monitor/static" : "/static";

export class StaticFilesAPI extends APIBase {
  get_language(language, custom_callback) {
    let req = new RequestData(`lang/${language}.json`, custom_callback);
    req.on_receive = this.language_receive;
    req.server_domain = static_server;
    req.use_token = false;
    req.set_interval(0);
    return this.send(req, false);
  }

  language_receive(response, identification, myself) {
    myself.app.$appm.change_language(response);
    //myself.app.config.globalProperties.$appm.change_language(response);
  }

  get_errors(language, custom_callback) {
    let req = new RequestData(`lang/${language}.json`, custom_callback);
    req.on_receive = this.errors_receive;
    req.server_domain = static_server;
    req.use_token = false;
    req.set_interval(0);
    return this.send(req, false);
  }

  errors_receive(response, identification, myself) {
    myself.app.$appm.set_error_codes(response);
  }

  get_eventformat(custom_callback) {
    let req = new RequestData(`eventformat.json`, custom_callback);
    req.on_receive = this.eventformat_receive;
    req.server_domain = static_server;
    req.use_token = false;
    req.set_interval(0);
    return this.send(req, false);
  }

  eventformat_receive(response, identification, myself) {
    myself.app.$appm.set_eventformat(response);
  }

  version_receive(response, identification, myself) {
    myself.app.current_version = response;
  }

  get_settings() {
    let req = new RequestData("webmonitor_settings.json");
    req.on_receive = this.settings_receive;
    req.server_domain = static_server;
    req.set_interval(0);
    req.use_token = false;
    return this.send(req, false);
  }

  settings_receive(response, identification, myself) {
    myself.api_server = response.api_server;
    myself.app.init();
  }
}
