import { APIBase } from "./apibase";
import RequestData from "./requestdata";
import Network from "@/networking";
const Utils = require("@/utils").default;

export class AlarmSubscribersAPI extends APIBase {
  create(project_id, data) {
    let req = new RequestData(`projects/${project_id}/alarm_subscriber`);
    req.post_json = data;
    req.identification = { project_id: project_id };
    req.method = "POST";
    req.on_receive = this.receive;
    req.refresh_interval = 0;
    return this.send(req);
  }

  update(project_id, id, data) {
    let req = new RequestData(`projects/${project_id}/alarm_subscriber/${id}`);
    req.post_json = data;
    req.identification = { project_id: project_id, id: id };
    req.method = "PUT";
    req.on_receive = this.receive;
    req.refresh_interval = 0;
    return this.send(req);
  }  

  delete(project_id, id) {
    let req = new RequestData(`projects/${project_id}/alarm_subscriber/${id}`);
    req.identification = { project_id: project_id, id: id };
    req.method = "DELETE";
    req.on_receive = this.receive_delete;
    req.refresh_interval = 0;
    return this.send(req);
  }  

  list(project_id, options) {
    let req = new RequestData(
      `projects/${project_id}/alarm_subscribers`,
      options
    );
    req.identification = { project_id: project_id };
    req.method = "GET";
    req.on_receive = this.receive_array;
    req.refresh_interval = 0;
    return this.send(req);
  }
  // eslint-disable-next-line
  receive(response, identification, myself) {
    Network.alarm_subscribers.list(identification.project_id);
  }

  receive_delete(response, identification, myself) {
    Network.alarm_subscribers.list(identification.project_id);
  }

  receive_array(response, identification, myself) {    
    window.console.log("AlarmSubscribers received, length:" + response.length);
    // Do not assign entire object. Will disturb reactivity
    // Remove old content first
    if (myself.app.$appm.store.alarm_subscribers == null) {
      // first update
      myself.app.$appm.store.alarm_subscribers = response;
    } else {
      // delete old items from arry
      let arrayLength = myself.app.$appm.store.alarm_subscribers.length;
      if (arrayLength > 0) {
        myself.app.$appm.store.alarm_subscribers.splice(0, arrayLength);        
      }
      // push new items
      if (response.length > 0) {
        for (let index=0; index < response.length; index ++) {
          myself.app.$appm.store.alarm_subscribers.push(response[index]);    
        }
      }
    }
  }
}
