import { APIBase } from "./apibase";
const Utils = require("@/utils").default;
const Camur = require("@/utils/camurutils").default;

export class GraphData extends APIBase {
  fetch(
    start,
    stop,
    max_vals,
    project_id,
    zone_id,
    channel_ids,
    filters,
    cache_data,
    all_values,
    value_tag,
    callback = null
  ) {
    let data = {
      start: start.toISOString(),
      stop: stop.toISOString(),
      maxValues: max_vals
    };

    for (let prop in filters) {
      data[prop] = filters[prop];
    }

    let urlpath = `projects/${project_id}/data`;
    if (all_values)
      urlpath += "/all";
    let command = `${urlpath}?Start=${encodeURIComponent(
      start.toISOString()
    )}&Stop=${encodeURIComponent(
      stop.toISOString()
    )}&MaxValues=${max_vals}&ChannelIds=${channel_ids.join()}`;
    //    )}&MaxValues=${max_vals}&ZoneIds=${zone_id}`;

    if (data.recordingId > 0) {
      command += `&RecordingIds=${data.recordingId}`;
    }

    if (data.recordingType > 0) {
      command += `&RecordingTypes=${data.recordingType}`;
    }
    else {
      let types = [];
      // All recording types excluding the results
      types.push(Camur.recording_type.rectype_monitor);
      types.push(Camur.recording_type.rectype_lpr);
      types.push(Camur.recording_type.rectype_decay);
      types.push(Camur.recording_type.rectype_zra);
      types.push(Camur.recording_type.rectype_resmes);
      command += `&RecordingTypes=${types}`;
    }

    command += `&ValueTag=${value_tag}`;
    return this.send({
      command: command,
      method: "GET",
      on_receive: this.receive_data,
      identification: {
        project_id,
        cache_data,
        from: start,
        to: stop,
        filters: filters,
        zone_ids: [zone_id],
        max_values: max_vals,
        that: this
      },
      custom_callback: callback,
      refresh_interval: 0
    });
  }

  download_csv(
    project_id,
    start,
    stop,
    filename,
    zone_ids,
    channel_ids,
    recording_ids,
    recording_types,
    command,
    export_now,
    time_zone,
    time_format,
    callback = null
  ) {
    let data = {
      start: start.toISOString(),
      stop: stop.toISOString(),
      filename: filename,
      zoneIds: zone_ids,
      channelIds: channel_ids,
      recordingIds: recording_ids,
      recordingTypes: recording_types,
      csvDelimiter: ",",
      timeZone: time_zone,
      timeFormat: time_format
    };

    if (data.recordingIds == null) delete data.recordingIds;
    if (data.zoneIds == null) delete data.zoneIds;
    if (data.channelIds == null) delete data.channelIds;
    if (data.recordingTypes == null) delete data.recordingTypes;

    command.url=`projects/${project_id}/data/csv`;
    command.payload = JSON.stringify(data); 

    if (!export_now)
      return;

    return this.send({
      command: command.url,
      method: "POST",
      parse_response_as_json: false,
      on_receive: this.receive_csv,
      identification: {
        project_id,
        filename: filename,
        from: start,
        to: stop,
        zone_ids: zone_ids,
        channel_ids:channel_ids,
        rec_ids: recording_ids,
        rec_types : recording_types
      },
      post_json: data,
      custom_callback: callback,
      refresh_interval: 0
    });
  }

  download_xlsx(
    project_id,
    start,
    stop,
    filename,
    zone_ids,
    channel_ids,
    recording_ids,
    recording_types,
    command,
    export_now,
    time_zone,
    time_format,
    callback = null
  ) {
    let data = {
      start: start.toISOString(),
      stop: stop.toISOString(),
      filename: filename,
      zoneIds: zone_ids,
      channelIds: channel_ids,
      recordingIds: recording_ids,
      recordingTypes: recording_types,
      timeZone: time_zone,
      timeFormat: time_format
    };

    if (data.recordingIds == null) delete data.recordingIds;
    if (data.zoneIds == null) delete data.zoneIds;
    if (data.channelIds == null) delete data.channelIds;
    if (data.recordingTypes == null) delete data.recordingTypes;

    command.url=`projects/${project_id}/data/xlsx`;
    command.payload = JSON.stringify(data); 

    if (!export_now)
      return;

    return this.send({
      responseType: "blob",
      command: command.url,
      method: "POST",
      parse_response_as_json: false,
      on_receive: this.receive_xlsx,
      identification: {
        project_id,
        filename: filename,
        from: start,
        to: stop,
        zone_ids: zone_ids,
        channel_ids:channel_ids,
        rec_ids: recording_ids,
        rec_types : recording_types
      },
      post_json: data,
      custom_callback: callback,
      refresh_interval: 0
    });
  }


  fill_data(
    project_id,
    start,
    stop,
    interval,
    channelId,
    recordingId,
    formulaId,
    startValue,
    growth,
    wrapMin,
    wrapMax
  ) {
    let data = {
      start: start.toISOString(),
      stop: stop.toISOString(),
      interval,
      channelId,
      recordingId,
      formulaId,
      startValue,
      growth,
      wrapMin,
      wrapMax
    };

    return this.send({
      command: `projects/${project_id}/data/fill`,
      method: "POST",
      post_json: data,
      custom_callback: null
    });
  }

  receive_data(response, identification, myself) {
    console.log(JSON.stringify(identification.filters));
    myself.app.$appm.receive_graph_data(
      identification.project_id,
      response,
      identification,
      identification.that.graph_buffer
    );
  }

  // eslint-disable-next-line
  receive_csv(response, identification, myself) {
    window.appm.on_download_data_finished(identification.filename);
    Utils.download_blob_as_file(response, identification.filename, "text/csv;");
  }
  receive_xlsx(response, identification, myself) {
    window.appm.on_download_data_finished(identification.filename);
    Utils.download_xlsx_as_file(response, identification.filename);
  }

  // set_graph_buffer(graph_buffer) {
  //   this.graph_buffer = graph_buffer;
  // }
}
