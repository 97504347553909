<template>
  <a
    class="wm_icon_btn"
    :disabled="disabled"
    :title="tooltip"
    :class="{ wm_icon_btn_disabled: disabled }"
    @click="click()"
  >
    <SmallIcon :icon="icon" :color="color" :size="size" />
  </a>
</template>

<script>

export default {
  name: "IconBtn",
  emits: ['clicked','disabledclick'],
  data() {
    return {};
  },

  props: {
    icon: {
      type: String,
      default: "person"
    },
    color: {
      type: String,
      default: "gray"
    },
    tooltip: {
      type: String
    },
    disabled: {
      type: Boolean,
      default: null
    },
    size: {
      type: String,
      default: "16"
    }
  },
  methods: {
    click() {
      if (!this.disabled) {
        this.$emit("clicked");
      } else {
        this.$emit("disabledclick");
      }
    }
  },

  computed: {},

  created: function() {}
};
</script>
