import Camur from "@/utils/camurutils";
import Network from "@/networking";

export class Job {
  constructor(id) {
    this.id = id;
    this.posted = false;
    this.active = true;
    this.succesful = false;
    this.failed = false;
    this.type = 0;
    this.message = "";
    this.create_date = Date.now();
    this.finish_date = null;
    this.full_time = null;
  }

  finalize(succesful, type, message) {
    this.finish_date = Date.now();
    this.active = false;
    this.succesful = succesful;
    this.failed = !succesful;
    this.type = type;
    this.message = message;
    this.full_time = this.finish_date - this.create_date;
  }
}

export class JobQueue {
  constructor(parent) {
    this.active = [];
    this.finished = [];
    this.parent = parent;
  }

  get length() {
    return this.active.length;
  }

  on_job_with_id_posted(jobId) {
    window.console.log("Job posted with id:" + jobId);
    this.get_job_with_id(jobId).posted = true;
  }

  on_job_posted(response) {
    if (response.jobs.length > 0) {
      // Some settings may not need a job
      window.console.log("Job posted");
      this.get_job_with_id(response.jobs[0].jobId).posted = true;
    }
  }

  //eslint-disable-next-line
  on_job_queued(jobId, data, task) {
    let taskname = window.app.langutil.get_job_type_name(data.Task);
    window.console.log(taskname + " queued");
    let job = this.get_job_with_id(jobId);
    let txt = "";
    switch (data.Task) {
      case Camur.job_type.recordingModeChange:  
        txt = window.app.langutil.txt.recording_mode_change_in_progress;      
        let mode_txt = Camur.get_rectype_text(data.TargetMode);
        window.appm.show_note(`${txt} ${mode_txt}`);
        break;
      case Camur.job_type.upgradeControllerFirmware:  
        txt = window.app.langutil.txt.firmware_upgrade_in_progress;      
        window.appm.show_note(`${txt}`);
        break;
  
    }
  }

  refresh_node(projectId, nodeId) {
    let thisNode = window.appm.get_node_by_id(projectId, nodeId);
    if (thisNode != null) {
      Network.node.fetch(projectId, thisNode.dto.zoneId, nodeId, null, 0);
    }
  }

  on_job_successful(projectId, jobId, data, task, message) {
    let txt = "";
    switch (task) {
      case Camur.job_type.recordingModeChange:
        this.parent.update_from_dto({ recordingMode: data.TargetMode }, true);
        txt = window.app.langutil.txt.recording_mode_changed; 
        let mode_txt = Camur.get_rectype_text(data.TargetMode);
        window.appm.show_note(`${txt} ${mode_txt}`);
        break;
      case Camur.job_type.getControllerStatus:
      case Camur.job_type.getControllerLog:
      case Camur.job_type.getControllerRecordingData:
      case Camur.job_type.setControllerRecordingSettings:
      case Camur.job_type.setControllerRecordingDecayParams:
      case Camur.job_type.setControllerRecordingLprParams:
      case Camur.job_type.setControllerRecordingMonitorParams:
      case Camur.job_type.setControllerRecordingScheduleParams:
      case Camur.job_type.setControllerRecordingZraParams:
      case Camur.job_type.setControllerRecordingResMesParams:
      case Camur.job_type.setControllerSwitchOffOnAlarm:    
      case Camur.job_type.discoverControllerMonitorParams:
      case Camur.job_type.discoverControllerDecayParams:
      case Camur.job_type.discoverControllerLprParams:
      case Camur.job_type.discoverControllerZraParams:
      case Camur.job_type.discoverControllerResMesParams:
      case Camur.job_type.discoverControllerSwitchOffOnAlarm:
      case Camur.job_type.discoverControllerScheduleParams:
      case Camur.job_type.discoverControllerAckTimestamp:
      case Camur.job_type.discoverControllerNodes:
      case Camur.job_type.discoverControllerRecordingStatus:
      case Camur.job_type.discoverControllerTimestamp:
      case Camur.job_type.discoverControllerVersion:
      case Camur.job_type.setNodePowerSupplyParams:
        break;
      case Camur.job_type.upgradeControllerFirmware:
        txt = window.app.langutil.txt.firmware_upgrade_successful; 
        window.appm.show_note(`${txt}`);
        break;
      case Camur.job_type.setFixVoltOutputMode:
        window.appm.get_node_by_id(projectId, data.NodeId).update_from_dto(
          {
            psMode: data.TargetMode
          },
          true
        );
        window.appm.show_note(`Output mode: ${data.TargetMode}`);
        break;
      case Camur.job_type.setFixVoltOutputOnOff:
        window.appm.get_node_by_id(projectId, data.NodeId).update_from_dto(
          {
            psOutputOn: data.TargetOnOff
          },
          true
        );
        window.appm.show_note(`Output on: ${data.TargetOnOff}`);
        break;
      case Camur.job_type.setFixVoltOutputRecovery:
        window.appm.get_node_by_id(projectId, data.NodeId).update_from_dto(
          {
            psOutputRecovery: data.TargetRecovery
          },
          true
        );
        window.appm.show_note(`Output recovery changed: ${data.TargetRecovery}`);
        break;
      case Camur.job_type.setFixVoltOutputVoltage:
        window.appm.get_node_by_id(projectId, data.NodeId).update_from_dto(
          {
            psFixedVoltage: data.TargetValue
          },
          true
        );
        window.appm.show_note(`V-Out changed: ${data.TargetValue}`);
        break;
      case Camur.job_type.setFixVoltOutputCurrent:
        window.appm.get_node_by_id(projectId, data.NodeId).update_from_dto(
          {
            psCurrentLimit: data.TargetValue
          },
          true
        );
        window.appm.show_note(`Current limit changed: ${data.TargetValue}`);
        break;
      case Camur.job_type.setFixVoltSwitchOffOnAlarm:
        window.appm.get_node_by_id(projectId, data.NodeId).update_from_dto(
          {
            psSwitchOffOnAlarm: data.TargetSwitchOffOnAlarm
          },
          true
        );
        window.appm.show_note(`Switch off on alarm changed: ${data.TargetSwitchOffOnAlarm}`);
        break;        
      case Camur.job_type.setAlarmStatus:
      case Camur.job_type.discoverNodePowerSupplyParams:
      case Camur.job_type.setNodeCurrTransParams:
      case Camur.job_type.discoverNodeCurrTransParams:
      case Camur.job_type.setNodeChannelCount:
      case Camur.job_type.discoverNodeChannelCount:
      case Camur.job_type.setNodeTemperatureEnabled:
      case Camur.job_type.discoverNodeTemperatureEnabled:
      case Camur.job_type.discoverNodeAlarmLevels:
      case Camur.job_type.discoverNodeScaleValues:
      case Camur.job_type.discoverNodeResistanceParams:
      case Camur.job_type.setNodePreSampleInterval:
      case Camur.job_type.discoverNodePreSampleInterval:
      case Camur.job_type.setNodeOutputInverted:
      case Camur.job_type.discoverNodeOutputInverted:
      case Camur.job_type.setNodePowerInterface:
        break;    
      case Camur.job_type.setChannelAlarmLow:
      case Camur.job_type.setChannelAlarmHigh:
      case Camur.job_type.setChannelAlarmEnabled:
      case Camur.job_type.resetChannelAlarm:
        this.refresh_node(projectId, data.NodeId);
        break;
    }
    let finished_job = this.remove_job_with_id(jobId);
    if (finished_job != null)
      finished_job.finalize(true, task, message);
  }

  on_job_fail(jobId, data, task, message) {
    //console.log(JSON.stringify(data));

    let finished_job = this.remove_job_with_id(jobId);
    if (finished_job != null) {
      finished_job.finalize(false, task, message);
      console.error(
        `Job ${finished_job.type} (id: ${jobId}) failed: ${data.Status}, ${
          data.ErrorMessage
        }, ${finished_job.full_time}ms`
      );  
    }
    let nodeInfo = "";
    let thisNode = window.appm.get_node_by_id(data.ProjectId, data.NodeId);
    if (thisNode != null) {
      nodeInfo = " Node:" + thisNode.name + " with SerialNr:" + thisNode.dto.serialNumber;
    }

    switch (data.Task) {
      case Camur.job_type.upgradeControllerFirmware:  
        let txt = window.app.langutil.txt.firmware_upgrade_failed;      
        window.appm.show_note(`${txt}`);
        break;
      case Camur.job_type.discoverControllerTimestamp:
        if (this.controllerAssigned(data.ProjectId, data.ControllerId) && (finished_job != null) )
          // Only show message if still assigned
          window.appm.show_error(null, finished_job.message);          
        break;
      default:
        if (finished_job != null)
          window.appm.show_error(null, finished_job.message + nodeInfo);
    }
  }

  get_job_fail_msg(job) {
    let time = job.full_time;
    let msg = `${this.parent.name}: ${window.app.langutil.get_job_status(
      job
    )} (${time} ms)`;
    return msg;
  }

  // creates a new job if it doesn't exist
  get_job_with_id(id) {  
    // Find job in active queue
    var jobIndex = this.active.findIndex(job => job.id === id);
    if (jobIndex >= 0)
      return this.active[jobIndex];
    else
    { // Find job in finished queue
      jobIndex = this.finished.findIndex(job => job.id === id);
      if (jobIndex >= 0)
        return this.finished[jobIndex];
      else
      {
        // Create job 
        var newJob = new Job(id);
        this.active.push(newJob);
        window.console.log("Job with id:" + id + " created!");
        for (var j = 0; j < this.active.length; j++){
          window.console.log("Active jobs", this.active.length)
        }
        return newJob;
      }
    }
  }

  remove_job_with_id(id) { 
    // Find job in active queue
    var jobIndex = this.active.findIndex(job => job.id === id);
    if (jobIndex >= 0)
    {
      var deletedArray = this.active.splice(jobIndex);
      return deletedArray[0];
    }
    else
    {
      window.console.log(
        "Job id:" + id + " not found and could not be removed!");
    }
  }

  controllerAssigned(projectId, controllerId) {
    let projects = window.app.projects;
    for (let i=0; i < projects.length; i++ ) {        
      let proj = projects[i];
      if (proj.id == projectId) {
        for (let j=0; j <  proj.cntrls.length; j++) { 
          let cntrl = proj.cntrls[j];
          if (cntrl.id == controllerId) {
            if (cntrl.dto.serialNumber > 0)
              return true;
            else
              return false;
          }
        }
      }
    }
    return false;
  }
}
