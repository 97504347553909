<template>
  <div
    v-if="show_slots"
    class="wm_grid"
    ref="ggrid"
    :style="{
      height: total_height + 'px',
      width: 7 * (this.item_width + this.x_padding) + 10 + 'px',
    }"
    @mousemove="mouse_move($event)"
  >
    <div
      class="wm_grid_slot noselect"
      v-for="slot in slots"
      :key="slot.id"
      @mouseup="select_slot(slot)"
      :style="{
        height: item_height + 'px',
        width: item_width + 'px',
        position: 'absolute',
        left: get_x_pos(slot) + 'px',
        top: get_y_pos(slot) + 'px',
        margin: '0px',
      }"
    >
      +
    </div>
    <div
      class="wm_grid_item noselect"
      v-for="item in $app.sltd.zone.nodes"
      :key="item.id"
      :style="{
        height: item_height + 'px',
        width: item_width + 'px',
        left: get_x_pos(item) + 'px',
        top: get_y_pos(item) + 'px',
      }"
      :class="{ wm_item_dragged: selected_item == item }"
    >
      <NodeInfoPanel
        :node="item"
        @paneldragged="drag(item)"
        :style="{
          height: item_height + 'px',
          width: item_width + 'px',
        }"
      />
    </div>
  </div>
</template>

<script type="text/javascript">
import NodeInfoPanel from "@/components/zone/NodeInfoPanel";

export default {
  name: "WMGrid",
  components: {
    NodeInfoPanel,
  },
  data() {
    return {
      mouse: { x: 0, y: 0 },
      y_padding: 5,
      x_padding: 5,
      item_width: 125,
      item_height: 125,
      selected_item: null,
      slots: null,
      container: null,
      total_height: "1px",
      drag_offset_x: -0,
      drag_offset_y: -10,
    };
  },

  created() {
    this.slots = this.make_slots();
  },

  methods: {
    get_x_pos(item) {
      if (item == this.selected_item)
        return this.mouse.x + this.drag_offset_x + this.container.offsetLeft;
      return (
        item.gridX * this.x_padding +
        item.gridX * this.item_width +
        this.x_padding
      );
    },
    get_y_pos(item) {
      let result =
        item.gridY * this.y_padding +
        item.gridY * this.item_height +
        this.y_padding;
      if (item == this.selected_item) {
        result = this.mouse.y + this.container.offsetTop;
      }
      return result;
    },
    drag(item) {
      this.selected_item = item;
    },
    click_outside() {
      this.selected_item = null;
    },
    release() {
      this.selected_item = null;
    },
    select_slot(slot) {
      if (this.selected_item != null) {
        this.selected_item.save_grid_position(slot.gridX, slot.gridY);
        this.$net.node.update(
          this.$app.sltd.project.id,
          this.$app.sltd.zone.id,
          this.selected_item.id,
          this.selected_item.locals
        );
        this.selected_item = null;
      }
    },
    mouse_move(e) {
      this.mouse.x = e.clientX - this.container.offsetLeft;
      this.mouse.y = e.clientY - this.container.offsetTop;
    },
    make_slots() {
      var result = [];

      for (var row = 0; row < this.$app.settings.grid_rows; row++) {
        for (
          var column = 0;
          column < this.$app.settings.grid_columns;
          column++
        ) {
          result.push({
            gridX: column,
            gridY: row,
            id: row + "," + column,
          });
        }
      }
      return result;
    },

    calculate_height() {
      let document_height = window.document.documentElement.clientHeight;
      let top_height = this.container.getBoundingClientRect().top;
      let footer_height = 39;
      let margin = 0;
      return document_height - top_height - footer_height - margin;
    },

    resize() {
      if (this.container) {
        this.total_height = this.calculate_height();
      }
    },
  },

  computed: {
    show_slots() {
      return this.selected_item != null;
    },
  },

  mounted() {
    this.$nextTick(function () {
      this.container = this.$refs.ggrid;
      this.resize();
      window.addEventListener("resize", this.resize);
    });
  },

  unmounted() {
    window.removeEventListener("resize", this.resize);
  },

  watch: {
    "$app.ui.mousedown": function () {
      if (!this.$app.ui.mousedown) this.click_outside();
    },
  },
};
</script>

<style media="screen">
.wm_grid {
  width: 100%;
  overflow-y: auto;
  position: absolute;
}
.wm_grid_item {
  position: absolute;
  x: 100;
  background-color: white;
}
.wm_grid_slot {
  position: absolute;
  border: 4px dotted #dddddd;
  color: #ffffff;
  font-weight: bold;
  text-align: center;
  font-size: 50px;
  border-radius: 5px;
}
.wm_grid_slot:hover {
  color: #dddddd;
  border: 4px dotted #bbbbbb;
  color: #bbbbbb;
}
.wm_item_dragged {
  position: fixed;
  z-index: 999;
  -ms-transform: rotate(-5deg); /* IE 9 */
  -webkit-transform: rotate(-5deg); /* Safari 3-8 */
  transform: rotate(-5deg);
  -webkit-box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.21);
  -moz-box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.21);
  box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.21);
}
.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome and Opera */
}
</style>
