<template lang="">
  <transition name="noteanim">
    <div class="error_panel">
      <span id="error_message">{{ message }}</span>
      <span id="error_code" v-if="error.code"> - {{ error.code }} </span>

      <a v-if="error.code" :href="learn_more_link" target="_blank" class="learn_more_btn">
        {{ $txt.learn_more }}...
      </a>

      <span class="note_error_time_label">
        <TimeLabel :time="error.time" :show_date="false" :show_seconds="true" />
        <Btn
          style="margin-left: 4px; margin-top: -3px"
          class="white_hol_btn small_btn"
          @clicked="hide()"
          :title="$txt.close"
        />
      </span>

      <div v-for="entry in error.entries" :key="entry">
        <div class="error-text-row">
          <b>{{ entry[0] }}:</b>
          <ul class="error-text-row">
            <li class="error-text-row">
              {{ entry[1] }}
            </li>
          </ul>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  props: {
    error: {
      type: Object
    }
  },
  methods: {
    hide() {
      this.$emit("close");
    }
  },  
  computed: {
    message() {
      if (this.error.text != "") return this.error.text;
      else return this.$txt.unknown_error;
    },

    learn_more_link() {
      return "/#/errorlearnmore/" + this.error.code;
    }
  }
};
</script>

<style scoped>
.learn_more_btn {
  color: inherit;
  text-decoration: underline;
  margin-left: 12px;
}
</style>
