<template>
  <div>
    <a
      v-if="$ui.tight_mode && !$ui.project_tree_keep_open"
      title="Show tree view"
      class="project_indicator_arrow"
      @click="toggle_prj_tree()"
    >
      <SmallIcon size="16" :icon="$icon.arrow_right" />
    </a>

    <a
      v-else
      class="project_indicator_arrow project_indicator_arrow_close"
      title="Hide tree view"
      @click="toggle_prj_tree()"
    >
      <SmallIcon
        size="16"
        :icon="$icon.arrow_right"
        style="transform: rotate(180deg);"
      />
    </a>
  </div>
</template>

<script>
export default {
  name: "ProjectTreeIndicatorArrow",

  methods: {
    toggle_prj_tree() {
      this.$ui.project_tree_keep_open = !this.$ui.project_tree_keep_open;
    }
  }
};
</script>
