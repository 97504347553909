export default class Selection {
  constructor(app) {
    this.app = app;
    this.change_counter = 0;
    this.reset();
    this.on_project_changed = null;
  }

  get project() {
    return window.appm.get_project(this.prj_id);
  }
  get cntrl() {
    return window.appm.get_cntrl(this.prj_id, this.cntrl_id);
  }
  get zone() {
    return window.appm.get_zone(this.prj_id, this.zone_id);
  }
  get node() {
    return window.appm.get_node(this.prj_id, this.zone_id, this.node_id);
  }
  get customer() {
    return window.appm.get_customer(this.customer_id);
  }
  get user() {
    return window.appm.get_user(this.user_id);
  }

  get primary() {
    if (this.customer_is_selected()) return this.customer;
    if (this.user_is_selected()) return this.user;
    if (this.node_is_selected()) return this.node;
    if (this.zone_is_selected()) return this.zone;
    if (this.cntrl_is_selected()) return this.cntrl;
    if (this.project_is_selected()) return this.project;

    return null;
  }

  reset() {
    this.reset_project(null);
    this.reset_cntrl(null);
    this.reset_zone(null);
    this.reset_node(null);
    this.reset_user(null);
    this.reset_customer(null);
    this.change();
  }

  change() {
    this.change_counter += 1;
  }

  from_params(params) {
    var prj_id = this.to_id(params.project_id);
    var zone_id = this.to_id(params.zone_id);
    var cntrl_id = this.to_id(params.controller_id);
    var node_id = this.to_id(params.node_id);

    if (this.is_valid_id(prj_id)) {
      if (this.is_valid_id(zone_id)) {
        if (this.is_valid_id(node_id)) {
          this.set_node_primary(prj_id, zone_id, node_id);
        } else {
          this.set_zone_primary(prj_id, zone_id);
        }
      } else if (this.is_valid_id(cntrl_id)) {
        this.set_cntrl_primary(prj_id, cntrl_id);
      } else {
        this.set_project_primary(prj_id);
      }
    } else {
      this.reset();
    }

    var user_id = this.to_id(params.user_id);
    if (this.is_valid_id(user_id)) {
      this.set_user(user_id);
    }

    var customer_id = this.to_id(params.customer_id);
    if (this.is_valid_id(customer_id)) {
      this.set_customer(customer_id);
    }
  }

  to_id(id) {
    return parseInt(id);
  }

  is_valid_id(id) {
    return !Number.isNaN(id) && Number.isInteger(id) && id >= 0;
  }

  throw_error_on_invalid_id(id) {
    if (!this.is_valid_id(id) && id != null) {
      throw `${id} is an invalid id!`;
    }
  }

  call_callback_on_change(_callback, id, old_id) {
    if (id != old_id && _callback != null) {
      _callback(id, old_id);
    }
  }

  // Project
  set_project(id) {
    if (id === this.prj_id) return;
    let old_id = this.prj_id;
    this.throw_error_on_invalid_id(id);
    this.prj_id = id;
    this.change();
    this.call_callback_on_change(this.on_project_changed, id, old_id);
  }

  set_project_primary(id) {
    this.reset_zone();
    this.reset_cntrl();
    this.reset_node();
    this.set_project(id);
  }

  reset_project() {
    let old_id = this.prj_id;
    this.prj_id = null;
    this.call_callback_on_change(this.on_project_changed, this.prj_id, old_id);
  }
  project_is_selected() {
    return this.prj_id != null && this.project != null;
  }

  // Controller
  set_cntrl(id) {
    this.throw_error_on_invalid_id(id);
    this.cntrl_id = id;
    this.change();
  }

  set_cntrl_primary(project_id, id) {
    this.reset_zone();
    this.reset_node();

    this.set_project(project_id);
    this.set_cntrl(id);
  }

  reset_cntrl() {
    this.cntrl_id = null;
  }
  cntrl_is_selected() {
    return this.cntrl_id != null;
  }

  // Zone
  set_zone(id) {
    this.throw_error_on_invalid_id(id);
    this.zone_id = id;
    let z = this.zone;
    if (z != null && z.controllerId != null) {
      this.set_cntrl(z.controllerId);
    }
    this.change();
  }

  set_zone_primary(project_id, id) {
    this.reset_node();
    this.set_project(project_id);
    this.set_zone(id);
  }

  reset_zone() {
    this.zone_id = null;
  }
  zone_is_selected() {
    return this.zone_id != null;
  }

  // Node
  set_node(id) {
    this.throw_error_on_invalid_id(id);
    this.node_id = id;
    this.change();
  }

  set_node_primary(project_id, zone_id, id) {
    this.set_project(project_id);
    this.set_zone(zone_id);
    this.set_node(id);
  }

  node_is_selected() {
    return this.node_id != null;
  }
  reset_node() {
    this.node_id = null;
  }

  // Customer
  set_customer(id) {
    this.throw_error_on_invalid_id(id);
    this.customer_id = id;
    this.change();
  }
  reset_customer() {
    this.customer_id = null;
  }
  customer_is_selected() {
    return this.customer_id != null;
  }

  // User
  set_user(id) {
    this.throw_error_on_invalid_id(id);
    this.user_id = id;
    this.change();
  }
  reset_user() {
    this.user_id = null;
  }
  user_is_selected() {
    return this.user_id != null;
  }
}
