<template lang="html">
  <PNGIcon
    :icon="icon"
    :alarm="alarm"
    :title="hint"
    :alarm_acknowledged="alarm_acked"
  />
</template>

<script>
export default {
  name: "SmartZoneIcon",

  props: {
    zone: Object
  },

  computed: {
    icon() {
      return this.$appm.get_icon_for_zone(this.zone);
    },

    alarm() {
      return this.zone.dto.aggregateAlarmSignal;
    },

    alarm_acked() {
      return (
        this.zone.dto.aggregateAlarmSignal && !this.zone.dto.aggregateAlarmUnack
      );
    },


    hint() {
      let hint = `${this.$txt.zone} "${this.zone.name}"\n- ${this.$txt.status}: ${
        this.zone.status_text
      }`;
      if (this.alarm) {
        hint += `\n- ${this.$txt.alarm}`;
      }
      return hint;
    }
  }
};
</script>

<style lang="css" scoped></style>
