<template>
  <div class="wm_selection_bread_crumb">
    <slot />
    <router-link
      class="sel_breadcrumb_item"
      :title="$txt.hint_this_is_the_sltd_prj"
      v-if="$app.sltd.project"
      :to="{ name: 'project', params: { project_id: $app.sltd.project.id } }"
    >
      <SmartProjectIcon :project="$app.sltd.project" />
      {{ $app.sltd.project.name }}
    </router-link>

    <span style="margin-top:45px">
      <span v-if="$app.sltd.cntrl" class="sel_breadcrumb_arrow">
        <svg-icon size="15" viewbox="0 0 24 24" :path="chevronRightIcon" />
      </span>
    </span>
    <router-link
      class="sel_breadcrumb_item"
      :title="$txt.hint_this_is_the_sltd_cntrl"
      v-if="$app.sltd.cntrl"
      :to="{
        name: 'controller',
        params: {
          project_id: $app.sltd.project.id,
          controller_id: $app.sltd.cntrl_id
        }
      }"
    >
      <span v-if="$app.sltd.cntrl">
        <SmartCntrlIcon :cntrl="$app.sltd.cntrl" />
        {{ $app.sltd.cntrl.name }}
      </span>
    </router-link>

    <span v-if="$app.sltd.zone" class="sel_breadcrumb_arrow">
      <svg-icon size="15" viewbox="0 0 24 24" :path="chevronRightIcon" />
    </span>
    <router-link
      class="sel_breadcrumb_item"
      :title="$txt.hint_this_is_the_sltd_zone"
      v-if="$app.sltd.zone"
      :to="{
        name: 'zone',
        params: { project_id: $app.sltd.project.id, zone_id: $app.sltd.zone.id }
      }"
    >
      <span v-if="$app.sltd.zone">
        <SmartZoneIcon :zone="$app.sltd.zone" />
        {{ $app.sltd.zone.name }}
      </span>  
    </router-link>

    <span v-if="$app.sltd.node" class="sel_breadcrumb_arrow">
      <svg-icon size="15" viewbox="0 0 24 24" :path="chevronRightIcon" />
    </span>
    <router-link
      class="sel_breadcrumb_item"
      :title="$txt.hint_this_is_the_sltd_node"
      v-if="$app.sltd.node"
      :to="{
        name: 'node',
        params: {
          project_id: $app.sltd.project.id,
          zone_id: $app.sltd.zone.id,
          node_id: $app.sltd.node.id
        }
      }"
    >
      <span v-if="$app.sltd.node">
        <SmartNodeIcon :node="$app.sltd.node" />
        {{ $app.sltd.node.name }}
      </span>
    </router-link>

    <span style="float: right"
      >{{ $txt.project_time }}: {{ project_time }}</span
    >
  </div>
</template>

<script>
const chevronRightIcon  ="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z";
export default {
  setup() {
    return {
      chevronRightIcon
    }
  },
  name: "SelectionBreadCrumb",
  data() {
    return {
      project_time: ""
    };
  },

  props: {
    title: String
  },

  methods: {
    update_project_time() {
      if (this.$app.sltd.prj_id == null) {
        this.project_time = this.$txt.loading;
        return;
      }
      this.project_time = this.$app.sltd.project.get_project_time_str();
    }
  },
  computed: {},

  created: function() {
    this.project_time = this.$txt.loading;
    window.setInterval(this.update_project_time, 1000, this);
  }
};
</script>
