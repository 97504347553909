<template>
  <span
    class="ProjectTreeItem project_tree_item"
    transition="item"
    dropzone="move"
    @dragover="drag($event)"
    @dragleave="dragstop()"
    @drop="handle_drop($event)"
  >
    <router-link
      :to="{ name: route_to, params: route_params }"
      class="project_tree_node"
      :class="{
        project_tree_node_selected: selected,
        project_tree_node_unsubmitted_changes: item.any_change,
        project_tree_node_sec_selected: secondary_selected,
        project_tree_droppable_target: droppable_target,
        project_tree_undroppable_target: undroppable_target,
        project_tree_drop_area_hover: hovered && droppable_target
      }"
    >
      <Modal
        v-if="modal_is_open"
        :title="add_multiple_text"
        @close="modal_is_open = false"
      >
        <NumericInput
          :label="
            $txt.amount_of_function_to_add.replace(
              '*',
              this.item_that_was_dragged.name
            )
          "
          v-model="amount_to_add"
          :min="1"
          :max="10"
        />
        <template v-slot:footer>
          <Btn
            v-if="amount_to_add >= 1"
            :title="$txt.add + ' ' + amount_to_add"
            :disabled="(amount_to_add < 1 || amount_to_add > 10) ? true : null"
            @clicked="
              drop_multiple_nodes();
              modal_is_open = false;
            "

          />
          <Btn v-else
            :title="$txt.add"
            :disabled="true"
            @clicked="
              drop_multiple_nodes();
              modal_is_open = false;">
            </Btn>

          <Btn :title="$txt.cancel" @clicked="modal_is_open = false" />
        </template>
      </Modal>

      <DeviceAssignedIndicator
        v-if="$util.is_cntrl(item) || $util.is_node(item)"
        :item="item"
      />
      <SmartProjectIcon v-if="$util.is_project(item)" :project="item" />
      <SmartCntrlIcon v-if="$util.is_cntrl(item)" :cntrl="item" />
      <SmartZoneIcon v-if="$util.is_zone(item)" :zone="item" />
      <SmartNodeIcon
        v-if="$util.is_node(item)"
        :node="item"
      />

      {{ item.name }}
      <span v-if="item.any_change"> *</span>
      <span v-if="$util.is_cntrl(item) && item.in_a_busy_state"  style=" margin-left: 20px; margin-right: 20px;">
          <svg-icon size="16" viewbox="4 4 18 18" :path="cachedIcon" class="r rotating" />
      </span>
    </router-link>
    <span v-if="show_included">
      <a @click="include_item(item);$emit('include_click')" style="float:right">
        <!-- <TinyIcon :icon="$icon.yes" :color="include_color" /> -->
        <CheckBox v-model="included" style="float:right"/>
      </a>
    </span>
    <span v-if="show_not_yet_included">
      <a @click="include_item(item);$emit('include_click')" style="float:right">
        <!-- <TinyIcon :icon="$icon.arrow_right" :color="include_color" /> -->
        <CheckBox v-model="included" style="float:right"/>
      </a>
    </span>
  </span>
</template>

<script>
const cachedIcon  ="M19 8l-4 4h3c0 3.31-2.69 6-6 6a5.87 5.87 0 01-2.8-.7l-1.46 1.46A7.93 7.93 0 0012 20c4.42 0 8-3.58 8-8h3l-4-4zM6 12c0-3.31 2.69-6 6-6 1.01 0 1.97.25 2.8.7l1.46-1.46A7.93 7.93 0 0012 4c-4.42 0-8 3.58-8 8H1l4 4 4-4H6z";
export default {  
  setup() {
		return {
        cachedIcon
	  	}
  	},
  name: "ProjectTreeItem",
  data() {
    return {
      hovered: false,
      modal_is_open: false,
      amount_to_add: 2,
      item_that_was_dragged: null,
      included:false,
      selectedCapability: null,
      nodeIsRecording: false,
    };
  },

  props: {
    item: Object,
    route_to: String,
    route_params: Object
  },

  methods: {
    selectable() {
      // console.log("selectable? "+this.item.name);
      if (this.$ui.select_mode_level == this.$camur.select_mode_level.project) {
        return true;
      } else {
        // selected controller only
        let cntrlId = this.$app.sltd.cntrl.id;
        // check if this node belongs to selected controller
        if (this.$util.is_cntrl(this.item)) {
          if (this.item.id == cntrlId)
            return true;
          else
            return false;

        } else if (this.$util.is_zone(this.item)) {
          if (this.item.dto.controllerId == cntrlId)
            return true;
          else
            return false;

        } else if (this.$util.is_node(this.item)) {
          if (this.item.dto.controllerId == cntrlId)
            return true;
          else
            return false;
          
        } else if (this.$util.is_project(this.item)) 
          // project node selected
          return false;
        else 
          return false;

      }
    },
    include_item() {
//      console.log("include_item name"+this.item.name );
//      console.log("include_select="+ this.item.app_prop.include_select );
      // eslint-disable-next-line
      if (this.$route.name !== 'config_multiple_nodes') {
        // eslint-disable-next-line
        this.item.app_prop.include_select = !this.item.app_prop.include_select;
      }

      // console.log("include_select="+ this.item.app_prop.include_select);
    },
    drag(ev) {
      if (this.droppable_target) {
        ev.preventDefault();
      }
      this.hovered = true;
    },

    dragstop() {
      this.hovered = false;
    },

    handle_drop(ev) {
      if (this.droppable_target) {
        ev.preventDefault();
        this.hovered = false;

        if (this.$app.settings.drop_multiple_functions
            && ((this.$app.dragged_item.type == "virtual_node")
            || (this.$app.dragged_item.type == "zone")
            || (this.$app.dragged_item.type == "controller"))) {
          this.item_that_was_dragged = this.$app.dragged_item;
          this.modal_is_open = true;
        } else {
          this.$appm.drop_item(this.$app.dragged_item, this.item, 0);
        }
      }
      this.$app.dragged_item = null;
    },

    drop_multiple_nodes() {
      for (var i = 0; i < this.amount_to_add; i++) {
        let dragged_item = {
          ...this.item_that_was_dragged,
          name: this.item_that_was_dragged.name + " " + (i + 1)
        };
        this.$appm.drop_item(dragged_item, this.item, i);
      }
    },

    selected_capability() {
      this.selectedCapability = null;
      for (let node of this.$app.sltd.project.all_nodes) {
        if (node.app_prop.include_select == true) {
          this.selectedCapability = node.capability;
          break;
        }
      }
    },

    cntrl_of_node(node) {
      if (!node) {
        return;
      }
      if (!node.dto) {
        return;
      }
      for (let cntrl of this.$app.sltd.project.cntrls) {
        if (node.dto.controllerId === cntrl.id) {
          return cntrl;
        }
      }
    },
    matchingCapability(node, selectedCapability) {
        if (
          node.capability === selectedCapability || 
          node.capability === this.$camur.node_type.ntype_p4 && 
          selectedCapability === this.$camur.node_type.ntype_p4i || 
          node.capability === this.$camur.node_type.ntype_p4i && 
          selectedCapability === this.$camur.node_type.ntype_p4
        ) {
          return true;
      } else {
          return false;
      }
    },

    checkNodeCapabilityAndRecordingMode(node, selectedCapability) {
/*       return (
        (selectedCapability === node.capability && 
        (anySelectedNodeRecording === (this.cntrl_of_node(node).dto.recordingMode > anySelectedNodeRecording))) || 
        (anySelectedNodeRecording === (this.cntrl_of_node(node).dto.recordingMode > anySelectedNodeRecording) && 
        selectedCapability === this.$camur.node_type.ntype_p4i && 
        node.capability === this.$camur.node_type.ntype_p4) || 
        (anySelectedNodeRecording === (this.cntrl_of_node(node).dto.recordingMode > anySelectedNodeRecording) && 
        selectedCapability === this.$camur.node_type.ntype_p4 && 
        node.capability === this.$camur.node_type.ntype_p4i)
      ); */

      if (this.matchingCapability(node, selectedCapability)) {
        // check if recording mode of this controller is other than StandBy or Monitor
        if (this.cntrl_of_node(node).dto.recordingMode > this.$camur.recording_type.rectype_monitor) {
          return false;
        } else {
          return true;
        }
      } else {
        return false;
      }
      


    }
  },

  computed: {
    add_multiple_text() {
      if (this.item_that_was_dragged) {
        return `${this.$txt.add_multiple_nodes} '${
          this.item_that_was_dragged.name
        }' → ${this.item.name}`.toUpperCase();
      }
      return "";
    },
    show_included() {
      if (this.select_mode && this.item.app_prop.include_select) {
        if (this.selectable()) {
          if (this.item.item_type == "node") {
            if (this.$route.name === 'config_multiple_nodes') {
              // eslint-disable-next-line
              this.included = true;
            } else {
              // show assigned nodes for "project live values" or all nodes for "controller recorded values"
              if (
                (this.item.serial > 0) || 
                (this.$ui.select_mode_level == this.$camur.select_mode_level.controller)
              ) {
                // eslint-disable-next-line
                this.included = true;
              }
              else {
                // eslint-disable-next-line
                this.included = false;
              }
            }
          }
          else {
            // eslint-disable-next-line
            this.included = true;
          }
        }
        else
          // eslint-disable-next-line 
          this.included = false;
      }
      else 
        // eslint-disable-next-line 
        this.included = false;    
      return this.included;
    },
    show_not_yet_included() {
      let anyNodeSelected = false;
      if (this.$route.name === 'config_multiple_nodes') {
        this.selected_capability();
        for (let node of this.$app.sltd.project.all_nodes) {
          if (node.app_prop.include_select) {
            anyNodeSelected = true;
            break;
          }
        }
        if (!anyNodeSelected) {
          for (let cntrl of this.$app.sltd.project.cntrls) {
            cntrl.app_prop.include_select = false;
          }
          for (let zone of this.$app.sltd.project.zones) {
            zone.app_prop.include_select = false;
          }
        }
      }
      if (
        this.select_mode && 
        !this.item.app_prop.include_select
      ) {
        if (this.selectable()) {
          if (this.item.item_type == "node") {
            if (this.$route.name === 'config_multiple_nodes') {
              // Add capability in the array for supported node types in config multiple nodes
              let supportedInConfigMultipleNodes = [14, 24, 30, 31, 32, 33, 34];
              if (this.selectedCapability == null) {
                // Do not show checkbox when in recording mode decay etc..
                if (this.cntrl_of_node(this.item).dto.recordingMode > this.$camur.recording_type.rectype_monitor) {
                  return false;
                } else {
                  return supportedInConfigMultipleNodes.includes(this.item.capability);
                }
              } else if (this.checkNodeCapabilityAndRecordingMode(this.item, this.selectedCapability)) {
                  return supportedInConfigMultipleNodes.includes(this.item.capability);
              } else {
                return false;
              }
            } else {
              // show assigned nodes for "project live values" or all nodes for "controller recorded values"
              if (
                (this.item.serial > 0) || 
                (this.$ui.select_mode_level == this.$camur.select_mode_level.controller)
              ) {
                return true;
              }
              else {
                return false;
              }
            }
          } else if (
            this.item.item_type === "cntrl" && 
            this.$route.name === 'config_multiple_nodes' && 
            anyNodeSelected
          ) {
            let cntrlContainsSupportedNodes = false;
            for (let node of this.item.nodes) {
              if (this.checkNodeCapabilityAndRecordingMode(node, this.selectedCapability)) {
                cntrlContainsSupportedNodes = true;
                  break;
                }
              }
              return cntrlContainsSupportedNodes;
          } else if (
            this.item.item_type === "zone" && 
            this.$route.name === 'config_multiple_nodes' && 
            anyNodeSelected
          ) {
            let zoneContainsSupportedNodes = false;
            for (let node of this.item.nodes) {
              if (this.checkNodeCapabilityAndRecordingMode(node, this.selectedCapability)) {
                zoneContainsSupportedNodes = true;
                  break;
                }
              }
              return zoneContainsSupportedNodes;
          } else {
            if (
              this.$route.name === 'config_multiple_nodes' && 
              !anyNodeSelected
            ) {
              return false
            } else {
              return true;
            }
          }
        }
        else
          return false;
      }
      else 
        return false;
    },
    droppable_target() {
      let x =
        this.$appm.can_drop_dragged_on_target(this.item) &&
        this.$app.dragged_item != null;
      return x;
    },

    undroppable_target() {
      return !this.droppable_target && this.$app.dragged_item != null;
    },

    icon_color() {
      if (this.item.selected) return "white";
      else return "gray";
    },
    include_icon() {
      if (this.item.app_prop.include_select) return "visibility";
      else return "visibility_off";
    },
    include_color(item) {
/*       if (this.item.include_report) return "#4286f4";
      else return "#dfe2e2"; */
      if (this.item.app_prop.include_select) return "green";
      else return "gray";
      
    },

    select_mode() {
      return this.$ui.select_mode;
    },

    selected() {
      if (this.$util.is_project(this.item)) {
        return this.item.id == this.$app.sltd.prj_id;
      }
      if (this.$util.is_cntrl(this.item)) {
        return this.item.id == this.$app.sltd.cntrl_id;
      }
      if (this.$util.is_zone(this.item)) {
        return this.item.id == this.$app.sltd.zone_id;
      }
      if (this.$util.is_node(this.item)) {
        return this.item.id == this.$app.sltd.node_id;
      }
      return false;
    },

    secondary_selected() {
      if (this.$util.is_zone(this.item)) {
        return this.selected && this.$app.sltd.node != null;
      } else if (this.$util.is_cntrl(this.item)) {
        return this.selected && this.$app.sltd.zone != null;
      } else if (this.$util.is_project(this.item)) {
        return (
          this.selected &&
          (this.$app.sltd.zone_id != null || this.$app.sltd.cntrl_id != null)
        );
      } else {
        return false;
      }
    }
  }
};
</script>

<style>
.item-move {
  transition: 1s;
}
.item-leave,
.item-enter {
  transition: 1s;
}
</style>
