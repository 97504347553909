<template>
  <div class="table-responsive">
    <table class="table info-table">
      <tr v-for="row in rows" :key="row.key">
        <td class="info-table-headline">{{ row.key }}</td>
        <td v-if="row.is_time">
          <TimeLabel :time="row.value" :show_seconds="row.show_seconds" />
        </td>
        <td v-else>{{ row.value }}</td>
      </tr>
    </table>
  </div>
</template>

<script>
export default {
  name: "InfoTable",
  data() {
    return {};
  },

  props: {
    rows: Array
  },

  methods: {},

  created: function() {}
};
</script>
