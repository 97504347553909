import { WMBaseItem } from "./baseitem";
const Utils = require("@/utils").default;
const Camur = require("@/utils/camurutils").default;
import Network from "@/networking";

export class Zone extends WMBaseItem {
  get controllerId() {
    return this.dto.controllerId;
  }

  get aggregateAlarmSignal() {
    return this.dto.aggregateAlarmSignal;
  }

  get anode() {
    return this.dto.anode;
  }

  get anodeArea() {
    return this.dto.anodeArea;
  }

  get cathodeArea() {
    return this.dto.cathodeArea;
  }

  get location() {
    return this.dto.location;
  }

  get building() {
    return this.dto.building;
  }

  get tree_open() {
    return this.app_prop.tree_open;
  }
  set tree_open(val) {
    this.app_prop.tree_open = val;
  }

  get status() {
    return this.dto.status;
  }

  get gridX() {
    return this.locals.gridX;
  }
  set gridX(val) {
    return (this.locals.gridX = val);
  }
  get gridY() {
    return this.locals.gridY;
  }

  get power_on() {
    return this.nodes.filter(n => n.dto.psOutputOn).length > 0;
  }

  set gridY(val) {
    return (this.locals.gridY = val);
  }

  get nodes() {
    return this.children.nodes;
  }

  get item_type() {
    return "zone";
  }

  get nodes_unsubmitted() {
      return window.app.$appm.store.sltd.project.all_nodes.filter(
        node => node.locals.zoneId == this.id
    );
  }

  get recording_infos() {
    return this.children.recording_infos;
  }

  get node_amount() {
    return this.children.nodes.length;
  }
  get connected_node_amount() {
    return this.children.nodes.filter(node => node.connected).length;
  }

  get power_supply_connected() {
    for (let node of this.children.nodes) {
      if (node.is_power_supply) return true;
    }
    return false;
  }

  get channels() {
    let result = [];
    for (let node of this.nodes) {
      for (let channel in node.channels) {
        result.push(node.channels[channel]);
      }
    }
    return result;
  }

  get status_text() {
    return Camur.get_rectype_text(this.recording_mode);
  }

  get recording_mode() {
    var controllers = window.app.$appm.store.sltd.project.cntrls.filter(
      controller => controller.id === this.dto.controllerId
    );
    let recording_mode = Camur.recording_type.rectype_standby;
    if (controllers.length > 0) {
      // window.console.log(" found, mode is:"+controllers[0].dto.recordingMode);
      recording_mode = controllers[0].dto.recordingMode;
    }
    return recording_mode;
  }

  get schedule_enabled() {
    var controllers = window.app.$appm.store.sltd.project.cntrls.filter(
      controller => controller.id === this.dto.controllerId
    );
    let enabled = false;
    if (controllers.length > 0) {
      // window.console.log(" found, mode is:"+controllers[0].dto.recordingMode);
      enabled = controllers[0].schedule.active;
    }
    return enabled;
  }


  find_node_by_id(id) {
    return Utils.array_element_by_id(id, this.children.nodes);
  }

  find_channel_by_id(id) {
    return Utils.array_element_by_id(id, this.channels);
  }

  delete_zone() {
    Network.zone.del(this.app_prop.project_id, this.dto.id);
  }

  constructor(dto, identification) {
    super(dto);
    this.app_prop.project_id = identification.project_id;
  }

  props_template() {
    return {
      include_select: false,
      tree_open: true,
      project_id: 0,
      color: "#ff00dd",
      show_map: true
    };
  }

  children_template() {
    return {
      nodes: [],
      recording_infos: []
    };
  }

  upload_changes() {
    return Network.zone.update(
      this.app_prop.project_id,
      this.id,
      this.changes()
    );
  }

  submit_map_changes() {
    return Network.zone.update(
      this.app_prop.project_id,
      this.id,
      this.changes_to_props(["mapColor", "mapVisible"])
    );
  }

  static new_item_instance(dto, identification) {
    return new Zone(dto, identification);
  }

  static find_parent(app, identification) {
    var parent = window.app.$appm.get_project(identification.project_id).zones;
    return parent;
  }

  static find(app, id, identification) {
    return window.app.$appm.get_zone(identification.project_id, id);
  }
}
