import { APIBase } from "./apibase";
import CPData from "@/items/cpdata";

export class CPDataAPI extends APIBase {
  fetch(
    project_id,
    cp_id,
    custom_callback,
    refresh_interval = global.default_refresh_interval
  ) {
    return this.send({
      command: "projects/" + project_id + "/cpdata/" + cp_id,
      method: "GET",
      identification: {
        project_id: project_id,
        cp_id: cp_id
      },
      on_receive: this.receive,
      refresh_interval: refresh_interval,
      custom_callback: custom_callback
    });
  }

  post(project_id, data, custom_callback) {
    return this.send({
      command: "projects/" + project_id + "/cpdata",
      method: "POST",
      identification: {
        project_id: project_id
      },
      post_json: data,
      on_receive: this.receive,
      custom_callback: custom_callback
    });
  }

  update(project_id, cp_id, data, custom_callback) {
    return this.send({
      command: "projects/" + project_id + "/cpdata/" + cp_id,
      method: "PUT",
      identification: {
        cp_id: cp_id,
        project_id: project_id
      },
      post_json: data,
      on_receive: this.receive,
      custom_callback: custom_callback
    });
  }

  del(project_id, cp_id, custom_callback) {
    return this.send({
      command: "projects/" + project_id + "/cpdata/" + cp_id,
      method: "DELETE",
      identification: {
        project_id: project_id,
        cp_id: cp_id
      },
      on_receive: this.receive_delete,
      custom_callback: custom_callback
    });
  }

  receive(response, identification, myself) {
    CPData.update_or_create_from_dto(response, myself.app, identification);
  }

  receive_delete(response, identification, myself) {
    CPData.delete_clientside(myself.app, identification, identification.cp_id);
  }
}
