import { WMBaseItem } from "./baseitem";
export class Report extends WMBaseItem {
  get reportType() {
    return this.dto.reportType;
  }

  get reportFrom() {
    return this.dto.reportFrom;
  }

  get reportTo() {
    return this.dto.reportTo;
  }
  get template() {
    return this.dto.template
  }
  get name() {
    return this.dto.name
  }
  get layout() {
    return this.dto.layout
  }
}
