<template>
  <div class="none_label">
    <PadlockToggle
      size="14"
      v-model="$app.sltd.project.app_prop.edit_lock"
      :title="padlock_hint"
      @lock="lockProject()"
    />
    <span class="no_data_text" v-if="$app.sltd.project.app_prop.edit_lock">{{
      $txt.enable_prj_edits
    }}</span>
    <span class="no_data_text" v-else>{{ $txt.disable_prj_edits }} </span>
    <slot />
  </div>
</template>

<script>
export default {
  name: "PadlockPanel",

  methods: {
    lockProject() {
      console.log("lockProject");
      this.$app.sltd.project.app_prop.edit_lock = !this.$app.sltd.project.app_prop.edit_lock;
      console.log(this.$app.sltd.project.app_prop.edit_lock ? "true":"false");
    },
  },
  computed: {
    padlocked() {
      return this.$app.sltd.project.edit_locked;
    },
    padlock_hint() {
      if (this.padlocked) return this.$txt.enable_prj_edits;
      else return this.$txt.disable_prj_edits;
    }
  }
};
</script>

<style>
.none_label {
  text-align: center;
  padding: 20px;
  background-color: #efefef;
  border-radius: 4px;
}

.no_data_text {
  font-weight: bold;
  color: gray;
  text-transform: uppercase;
  padding-left: 7px;
}
</style>
