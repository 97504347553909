import { APIBase } from "./apibase";
import RequestData from "./requestdata";
import { EventLogItem } from "@/items/eventlogitem";

export class EventLogAPI extends APIBase {
  list(project_id, filter, options) {
    let req = new RequestData(
      `projects/${project_id}/log/events` + filter,
      options
    );
    req.identification = { project_id: project_id };
    req.method = "GET";
    req.on_receive = this.receive_array;
    return this.send(req);
  }
  delete(project_id, filter, options) {
    let req = new RequestData(
      `projects/${project_id}/log/events` + filter,
      options
    );
    req.identification = { project_id: project_id };
    req.method = "DELETE";
    req.on_receive = this.receive_delete;
    return this.send(req);
  }
  // eslint-disable-next-line
  receive(response, identification, myself) {}

  receive_delete(response, identification, myself) {
    window.console.log("Eventlog purged");
  }

  receive_array(response, identification, myself) {
    window.console.log("EventLog received, length:" + response.length);
    for (var i = 0; i < response.length; i++) {
      EventLogItem.update_or_create_from_dto(
        response[i],
        myself.app,
        identification
      );
    }
  }
}
