<template>
  <div style="height:100%">
    <div id="appmain" v-if="ready_to_run">
      <TopMenuView />
      <router-view id="main"></router-view>
      <FooterView />
    </div>
    <div v-else>
      <div v-if="load_timeout" style="padding: 20px">
        <h3>Loading failed</h3>
        <table>
          <tr>
            <td class="fail">timeout:</td>
            <td class="fail">{{ load_timeout }}</td>
          </tr>
        </table>
      </div>
      <LoadingPanel v-else text="Loading..." />
    </div>
  </div>
</template>

<script>
import TopMenuView from "@/components/TopMenuView";
import FooterView from "@/components/FooterView";
import {DataStore} from './datastore';


export default {
  name: "App",
  components: {
    TopMenuView,
    FooterView
  },

  data() {
    return {
      load_timeout: false,
      ready_to_run: false,
      oldRoutePath: ""
    };
  },

  created: function() {
    this.timeout = setInterval(() => this.on_timeout(), 20000);
    setInterval(() => this.check_ready(), 500);
  },

  computed: {
    session_data_loaded() {
      return this.ready_to_run;
    },
  },

  methods: {

    on_timeout() {
      this.load_timeout = true;
    },
    check_ready() {
      this.ready_to_run = DataStore.session.loaded;
    },
    set_route() {
      if (this.$route.path != this.oldRoutePath) {
        this.oldRoutePath = this.$route.path;
        this.$appm.set_route(this.$route);
      }
    }
  },
  watch: {
      $route: function() {
        this.set_route();
    }
  }
};
</script>

<style>
@import "styles/plugins/css/bootstrap.min.css";
@import "styles/plugins/fonts/font-roboto.css";
</style>

<style lang="less">
@import "styles/main.less";

td.fail {
  padding: 5px;
}
</style>
