import { APIBase } from "../apibase";
import AccessCollection from "@/items/accesscollection";
import User from "@/items/user";
import RequestData from "../requestdata";

export class AccessCollectionAPI extends APIBase {
  delete(customer_id, collection_id, options) {
    let req = new RequestData(
      `customers/${customer_id}/access/collections/${collection_id}`,
      options
    );
    req.method = "DELETE";
    req.identification = {
      customer_id,
      collection_id
    };
    req.on_receive = this.receive_delete;
    return this.send(req);
  }

  create(customer_id, data, options) {
    let req = new RequestData(
      `customers/${customer_id}/access/collections`,
      options
    );
    req.post_json = data;
    req.method = "POST";
    req.on_receive = this.receive;
    return this.send(req);
  }

  list(customer_id, options) {
    let req = new RequestData(
      `customers/${customer_id}/access/collections`,
      options
    );
    req.identification = {
      customer_id: customer_id
    };

    req.on_receive = this.receive_array;
    return this.send(req);
  }

  get(customer_id, collection_id, options) {
    let req = new RequestData(
      `customers/${customer_id}/access/collections/${collection_id}`,
      options
    );
    req.on_receive = this.receive;
    return this.send(req);
  }

  update(customer_id, collection_id, options) {
    let req = new RequestData(
      `customers/${customer_id}/access/collections/${collection_id}`,
      options
    );
    req.on_receive = this.receive;
    req.method = "PUT";
    return this.send(req);
  }

  list_users(customer_id, collection_id, options) {
    let req = new RequestData( // eslint-disable-next-line
      `/customers​/${customer_id}​/access​/collections​/${collection_id}​/users`, options
    );
    req.identification = {
      customer_id,
      collection_id
    };
    req.on_receive = this.receive_user_array;
    return this.send(req);
  }

  add_email(customer_id, collection_id, email, name) {
    let req = new RequestData( // eslint-disable-next-line
      `customers/${customer_id}/access/collections/${collection_id}/users`
    );
    req.method = "POST";
    req.identification = {
      customer_id,
      collection_id,
      email
    };
    req.post_json = { email: email, name: name };
    req.on_receive = this.receive_user;
    return this.send(req);
  }

  add_id(customer_id, collection_id, user_id) {
    let req = new RequestData( // eslint-disable-next-line
      `/customers​/${customer_id}/access​/collections​/${collection_id}​/users`, options
    );
    req.method = "POST";
    req.identification = {
      customer_id,
      collection_id,
      user_id
    };
    req.post_json = { userId: user_id };
    req.on_receive = this.receive_user;
    return this.send(req);
  }

  remove_user(customer_id, collection_id, user_id) {
    var reqURL =
      "customers/" +
      customer_id +
      "/access/collections/" +
      collection_id +
      "/users";
    let req = new RequestData(reqURL);
    req.method = "DELETE";
    req.identification = {
      customer_id,
      collection_id,
      user_id
    };
    req.post_json = { userId: user_id };
    req.on_receive = this.receive_user_removed;
    return this.send(req);
  }

  receive(response, identification, myself) {
    AccessCollection.update_or_create_from_dto(
      response,
      myself.app,
      identification
    );
  }

  receive_array(response, identification, myself) {
    for (let access_collection of response) {
      AccessCollection.update_or_create_from_dto(
        access_collection,
        myself.app,
        identification
      );
    }
  }

  receive_user(response, identification, myself) {
    User.update_or_create_from_dto(response, myself.app, identification);
    location.reload();
  }

  receive_user_array(response, identification, myself) {
    response.forEach(function(el) {
      User.update_or_create_from_dto(el, myself.app, identification);
    });
  }

  // eslint-disable-next-line
  receive_delete(response, identification, myself) {
    // TODO:
    myself.receive_not_implemented(
      response,
      identification,
      myself,
      "receive_delete @" + myself.prototype.name
    );
  }

  receive_user_removed(response, identification, myself) {
    location.reload();
    /*     myself.app.remove_customer_access_collection_user(
      identification.customer_id,
      identification.collection_id,
      identification.user_id
    );
 */
  }
}
