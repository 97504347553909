/* The ServiceLogItem corresponds with one comment in the ServiceLog (parent)
 *
 */
import { WMBaseItem } from "./baseitem";
export class ServiceLogItem extends WMBaseItem {
  get message() {
    return this.dto.message;
  }

  get userName() {
    return this.dto.user.name;
  }

  get timestamp() {
    return this.dto.timestamp;
  }
  static new_item_instance(dto, identification) {
    return new ServiceLogItem(dto, identification);
  }
  // eslint-disable-next-line
    static find_parent(app, identification) {
    return app.service_log;
  }
}
