<template>
  <Progressbar :max="4" :now="request_progress" :show="show_progress" />
</template>

<script>
export default {
  emits: ['finished'],
  data() {
    return {
      request_progress: -1,
      show_progress: true,
      intervalId:0
    };
  },
  methods: {
    on_tick() {
      console.log("on_tick");
      this.update_request_progress();
    },
    request_progress_check() {
      if (this.tracked_request) {
        console.log("request_progress_check");
        this.intervalId = window.setInterval(this.on_tick, 1000, this);
      } else {
        this.clear_progress_check();
      }
    },
    clear_progress_check() {
      if (this.intervalId != 0) {
        console.log("clear_progress_check");
        window.clearInterval(this.intervalId); 
        this.intervalId = 0; 
      }
    },
    update_request_progress() {
      this.request_progress = 0;
      this.show_progress = false;

      if (this.tracked_request) {
        this.request_progress = 0.5;
        this.show_progress = true;

        if (this.tracked_request.req.xhr) {
          this.request_progress = this.tracked_request.req.xhr.readyState;
        }

        if (this.request_progress == 4) {
          var that = this;
          setTimeout(function() {
            that.show_progress = false;
            that.$emit("finished", null);
            that.clear_progress_check();
            that.request_progress = 0;
          }, 600);
        }
      }
    }
  },

  props: {
    tracked_request: {}
  },

  watch: {
    "$app.netstate": function() {
      this.update_request_progress();
    },
    "tracked_request": function() {
      this.request_progress_check();
    }
  }
};
</script>

<style lang="css" scoped></style>
