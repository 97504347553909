<template lang="html">
  <PNGIcon
    :icon="icon"
    :alarm="alarm"
    :title="hint"
    :alarm_acknowledged="alarm_acked"
  />
</template>

<script>
export default {
  name: "SmartProjectIcon",

  props: {
    project: Object
  },

  computed: {
    icon() {
      return "project";
    },

    alarm() {
      return this.project.alarm;
    },

    alarm_acked() {
      return this.project.alarm && !this.project.alarmUnack; // TODO fix the real prop
    },

    not_connected() {
      return false; // Perhaps should depend on if any node is disconnected?
    },
    hint() {
      return "Project";
    }
  }
};
</script>

<style lang="css" scoped></style>
