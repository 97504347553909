const block_size = {
  MONTH: "MONTH",
  DAY: "DAY",
  WEEK: "WEEK",
  HOUR: "HOUR"
};

// Returns the number of days between two Date-objects as a float
export function get_days_between(a, b) {
  let delta_ms = b.getTime() - a.getTime();
  let delta_days = delta_ms / (1000 * 60 * 60 * 24);
  return delta_days;
}

// Returns the resolution settings appropriate for a given time span
export function get_resolution_for_timespan(from_date, to_date) {
  let days_between = get_days_between(from_date, to_date);

  if (days_between >= 365) {
    return {
      name: "Year",
      block_size: block_size.MONTH,
      resolution_per_block: 32
    };
  }
  if (days_between >= 182) {
    return {
      name: "Half-year",
      block_size: block_size.MONTH,
      resolution_per_block: 96
    };
  }
  if (days_between >= 30) {
    return {
      name: "Month",
      block_size: block_size.MONTH,
      resolution_per_block: 96
    };
  }
  if (days_between >= 14) {
    return {
      name: "Forthnight",
      block_size: block_size.WEEK,
      resolution_per_block: 96
    };
  }
  if (days_between >= 7) {
    return {
      name: "Week",
      block_size: block_size.WEEK,
      resolution_per_block: 96
    };
  }
  if (days_between >= 1) {
    return {
      name: "Day",
      block_size: block_size.DAY,
      resolution_per_block: 96
    };
  }
  return {
    name: "Hours",
    block_size: block_size.HOUR,
    resolution_per_block: 3600
  };
}

export function round_down_to_closest_hour(time) {
  return new Date(
    Date.UTC(
      time.getUTCFullYear(),
      time.getUTCMonth(),
      time.getUTCDate(),
      time.getUTCHours(),
      0
    )
  );
}

export function round_down_to_closest_day(time) {
  return new Date(
    Date.UTC(time.getUTCFullYear(), time.getUTCMonth(), time.getUTCDate(), 0, 0)
  );
}

export function round_down_to_closest_month(time) {
  return new Date(Date.UTC(time.getUTCFullYear(), time.getUTCMonth()));
}

export function get_end_of_block(start_of_block, size_of_block) {
  switch (size_of_block) {
    case block_size.MONTH:
      return new Date(
        Date.UTC(
          start_of_block.getUTCFullYear(),
          start_of_block.getUTCMonth() + 1
        )
      );
    case block_size.WEEK:
      return new Date(
        Date.UTC(
          start_of_block.getUTCFullYear(),
          start_of_block.getUTCMonth(),
          start_of_block.getUTCDate() + 7
        )
      );
    case block_size.DAY:
      return new Date(
        Date.UTC(
          start_of_block.getUTCFullYear(),
          start_of_block.getUTCMonth(),
          start_of_block.getUTCDate() + 1
        )
      );
    default:
    case block_size.HOUR:
      return new Date(
        Date.UTC(
          start_of_block.getUTCFullYear(),
          start_of_block.getUTCMonth(),
          start_of_block.getUTCDate(),
          start_of_block.getUTCHours() + 1
        )
      );
  }
}

export function get_start_of_first_block(from, blocksize) {
  switch (blocksize) {
    case block_size.HOUR:
      return round_down_to_closest_hour(from);
    case block_size.DAY:
      return round_down_to_closest_day(from);
    case block_size.MONTH:
    default:
      return round_down_to_closest_month(from);
  }
}

export function get_recording_blocks_for_timespan(from_date,
                                                  to_date,
                                                  display_from,
                                                  display_to) {
  let resolution_level = get_resolution_for_timespan(display_from, display_to);
  let start_of_block = get_start_of_first_block(
    from_date,
    resolution_level.block_size
  );
  let end_of_block = 0;
  let blocks_to_get = [];

  do {
    end_of_block = get_end_of_block(
      start_of_block,
      resolution_level.block_size
    );

    let next_block = {
      from: start_of_block,
      to: end_of_block,
      max_values: resolution_level.resolution_per_block
    };

    next_block.hash = hash_from_block(next_block);
    blocks_to_get.push(next_block);
    start_of_block = end_of_block;
  } while (start_of_block < to_date);

  return blocks_to_get;
}

export function hash_from_block(block) {
  return (
    (block.from.getTime() * 17 +
      block.to.getTime() +
      block.max_values * block.max_values) %
    8503057
  );
}
