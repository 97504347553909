<template>
  <div
    class="wm_controller_hardware"
    :draggable="mode == 'grab'"
    :class="{
      wm_clickable: mode == 'click',
      wm_grabbable: mode == 'grab',
      wm_ungrabbable: mode == 'ungrab'
    }"
    @dragstart="drag_hwctrl_start($event, controller)"
    :title="hint"
  >
    <SmallIcon
      icon="drag_indicator"
      color="gray"
      size="13"
      :class="{
        invisible: mode != 'grab' || $app.sltd.project.edit_locked
      }"
    />

    <StatusLamp v-if="available_for_assignment" :status="computed_color" />
    <StatusLamp v-else status="blue" />
    {{ $txt.serial_no }} <b>{{ controller.serial }}</b> ({{
      controller.node_devices.length
    }})
    <span> → {{ status }} </span>
  </div>
</template>

<script>
export default {
  name: "ControllerHardware",

  data() {
    return {
      revokemodal: false,
      checksum: ""
    };
  },
  props: {
    controller: Object,
    clickmode: {
      type: Boolean,
      default: false
    }
  },

  methods: {
    revoke() {
      var data = {
        checksum: this.checksum,
        serialNumber: this.controller.serial,
        projectId: this.$app.sltd.project.id
      };
      this.$net.cntrl.revoke(data);
    },

    drag_hwctrl_start(ev, hwctrl) {
      if (ev) {
        ev.dataTransfer.setData("text", "drag_type_node");
        this.$app.dragged_item = hwctrl;
      }
    }
  },

  computed: {
    zone() {
      var zones = this.$app.sltd.project.zones.filter(
        zone => zone.controllerId === this.controller.id
      );
      if (zones.length > 0) return zones[0];
      return null;
    },

    hint() {
      if (this.$app.sltd.project.edit_locked) {
        return this.$txt.project_is_locked;
      }
      if (!this.available_for_assignment) {
        return this.$txt.device_already_assigned;
      }
/*        if (!this.is_online) {
        return this.$txt.device_is_offline;
      } */

      switch (this.mode) {
        case "grab":
          return this.$txt.drag_to_assign_cntrl_hint;

        case "click":
          return "";

        default:
          return this.$txt.cntrl_already_assigned_hint;
      }
    },

    mode() {
      if (
        this.available_for_assignment &&
//        this.is_online &&
        !this.$app.sltd.project.edit_locked
      ) {
        if (this.clickmode) return "click";
        return "grab";
      }
      return "ungrab";
    },

    status() {
      if (!this.is_online) {
        return this.$txt.offline;
      }
      if (!this.available_for_assignment) {
        return this.controller.app_prop.cntrl.name;
      }

      return this.$txt.available;
    },

    available_for_assignment() {
      return this.controller.app_prop.cntrl == null;
    },

    is_online() {
      //Check date of lastSeen in 24hour range
      //Give statuslamp white if lastSeen is over 24hours it's not online
      var currentDate = Date.now();
      var lastSeenDate = new Date(this.controller.locals.lastSeen);
      var differenceMilliSeconds = currentDate - lastSeenDate;
      var differenceHours = differenceMilliSeconds / 1000 / 60 / 60;

      if (differenceHours > 24) {
        return false;
      }
      return true;
    },
    computed_color() {
      if (this.is_online) {
        return "green";
      } else return "white";
    }
  }
};
</script>
