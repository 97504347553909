<template lang="html">
  <div class="work_in_progress">
    WORK IN PROGRESS
  </div>
</template>

<script>
export default {};
</script>

<style lang="css" scoped>
.work_in_progress {
  background-color: yellow;
  padding: 4px;
  font-weight: bold;
  border: 1px solid orange;
  text-align: center;
}
</style>
