<template>
  <div class="NodeInfoPanel info_panel_node" @click="clicked()">
    <span class="info_panel_title" style="width: 60px">{{ node.name }}</span>

    <span class="info_panel_drag_btn" @mousedown="$emit('paneldragged', '')">
      <SmallIcon :icon="$icon.drag_handle" color="gray" size="16" />
    </span>

    <span class="info_panel_icon">
      <DeviceAssignedIndicator
        :item="node"
        style="position: absolute; top: 8px; right: 24px"
      />
      <SmartNodeIcon
        :node="node"
        style="position: absolute; top: 10px; right: 5px"
      />
    </span>

    <hr class="info_panel_hr" />
    <!--
    <InfoPanelTrendChart
      v-if="!$camur.is_radio_link(node.capability)"
      :data="[0.13, 0.11, 0.14, 0.19, 0.1]"
      :height="40"
    />
  -->

    <div id="info_panel_bottom_part">
      <div v-if="node.capability == $camur.node_type.ntype_corrowatch">
        <SmallRangeIndicator
          :leftlabel="$txt.ct_mode"
          :rightlabel="$appg.langutil.get_enum_name($camur.ct_mode, node.dto.ctMode)"
        />

        <div v-for="channel in corrowatch_ladder_channels" :key="channel.id">
          <SmallRangeIndicator
            :leftlabel="channel.name"
            :colorGood="channel_color(channel)"
            :rightlabel="node.last_measured_for_channel(channel, 15)"
          />
        </div>
      </div>
      <div v-else-if="node.capability == $camur.node_type.ntype_mre">
        <div v-for="channel in mre_channels" :key="channel.id">
          <SmallRangeIndicator
            :leftlabel="channel.name"
            :colorGood="channel_color(channel)"
            :rightlabel="node.last_measured_for_channel(channel, 15)"
          />
        </div>
      </div>
      <div v-else-if="node.capability == $camur.node_type.ntype_ladder">
        <SmallRangeIndicator
          :leftlabel="$txt.ct_mode"
          :rightlabel="$appg.langutil.get_enum_name($camur.ct_mode, node.dto.ctMode)"
        /> 
        <div v-for="channel in corrowatch_ladder_channels" :key="channel.id">
          <SmallRangeIndicator
            :leftlabel="channel.name"
            :colorGood="channel_color(channel)"
            :rightlabel="node.last_measured_for_channel(channel, 15)"
          />
        </div>
      </div>
      <div v-else v-for="channel in sorted_channels" :key="channel.id">
        <SmallRangeIndicator
          :leftlabel="channel.name"
          :colorGood="channel_color(channel)"
          :rightlabel="node.last_measured_for_channel(channel, 15)"
        />
      </div>
    </div>
  </div>
</template>

<script>
import router from '@/router';
export default {
  name: "NodeInfoPanel",
  data() {
    return {};
  },
  props: {
    node: Object
  },

  methods: {
    clicked() {
      router.push({
        name: "node",
        params: {
          project_id: this.$app.sltd.project.id,
          zone_id: this.$app.sltd.zone.id,
          node_id: this.node.id
        }
      });
    },

    channel_color(channel) {
      return channel.alarm ? "red" : "green";
      // return !channel.isOutput ? "#1383c4" : "#0ace96";
    },

    alarm_color(alarm) {
      return this.$camur.ok_nok_color(alarm);
    },

    get_channels() {}
  },

  computed: {
    connected() {
      return this.yes_no_string(this.node.connected);
    },

    icon() {
      return this.$appm.get_icon_for_node_type(this.node.capability);
    },

    sorted_channels() {
      let result = [];

      for (let channel_name in this.node.channels) {
        let channel = this.node.channels[channel_name];
        result.push(channel);
      }
      var sort_criteria = function(a, b) {
        // Sort in channel no order but inputs before outputs
        // io indicates if input or output
        if (a.io * 100 + a.no > b.io * 100 + b.no) {
          return 1;
        } else return -1;
      };
      result.sort(sort_criteria);
      return result;
    },

    corrowatch_ladder_channels() {
      let result = [];
      var i;
      var count = 0;
      switch (this.node.dto.ctMode)
      {
        case this.$camur.ct_mode.ct_mode_r:
          // R-channels one less than channelCount
          for (i=0;(i < this.sorted_channels.length) && (count < (this.node.dto.channelCount - 1));i++)
          {
            if (this.sorted_channels[i].code == "R1")
            {
              result.push(this.sorted_channels[i]);
              count ++;
            }
            else if (count > 0)
            {
              result.push(this.sorted_channels[i]);
              count ++;
            }
          }
          return result;
        case this.$camur.ct_mode.ct_mode_zra:
          for (i=0;(i < this.sorted_channels.length) && (count < this.node.dto.channelCount);i++)
          {
            if (this.sorted_channels[i].code == "I1")
            {
              result.push(this.sorted_channels[i]);
              count ++;
            }
            else if (count > 0)
            {
              result.push(this.sorted_channels[i]);
              count ++;
            }
          }
          return result;
        case this.$camur.ct_mode.ct_mode_p:
        default:
          for (i=0;(i < this.sorted_channels.length) && (count < this.node.dto.channelCount);i++)
          {
            if (this.sorted_channels[i].code == "U1")
            {
              result.push(this.sorted_channels[i]);
              count ++;
            }
            else if (count > 0)
            {
              result.push(this.sorted_channels[i]);
              count ++;
            }
          }
          return result;
      }

    },
    mre_channels() {
      let result = [];

      for (let channel_name in this.node.channels) {
        let channel = this.node.channels[channel_name];
        // Only R-channels and T-channel
        if (((channel.no >= 15) && (channel.no <= 19)) || ((channel.no == 22)))
          result.push(channel);
      }
      var sort_criteria = function(a, b) {
        // Sort in channel no order but inputs before outputs
        // io indicates if input or output
        if (a.io * 100 + a.no > b.io * 100 + b.no) {
          return 1;
        } else return -1;
      };
      result.sort(sort_criteria);
      return result;
    },

    connection_status() {
      return this.$camur.connected_color(this.node.connected);
    }
  },

  created: function() {
    this.get_channels();
  },

  watch: {
    "$route.params": function() {
      this.get_channels();
    }
  }
};
</script>
