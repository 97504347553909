<template lang="html">
  <Modal :title="$txt.create_new_project" :width="260">
    <LabeledInput
      v-model="new_project_name"
      :label="$txt.name"
      :width="220"
      :placeholder="$txt.new_project_name"
    />

    <div class="dropdown labeled-control">
      <DropDown
        :title="$txt.customer"
        :opts="available_customers_opts"
        v-model="new_project_customer_id"
      />
    </div>

    <template v-slot:footer>
      <Btn :title="$txt.cancel" class="hol_btn" @clicked="$emit('close')" />
      <Btn
        :title="$txt.create"
        :disabled="create_disabled ? true : null"
        @clicked="create_new_project()"
      />
    </template>
  </Modal>
</template>

<script>
export default {
  data() {
    return {
      new_project_name: "",
      new_project_customer_id: null,
      create_req: null,
      prj_mode: 0
    };
  },

  methods: {
    create_new_project() {
      let proj = {
        customerId: this.new_project_customer_id,
        name: this.new_project_name,
        alias: this.$util.alias_from_name(this.new_project_name)
      };
      window.console.log(proj.alias);
      this.create_req = this.$net.project.create(
        this.new_project_customer_id,
        proj,
        this.on_prj_done
      );
    },

    on_prj_done() {
      location.reload();
    }
  },

  computed: {
    dropdown_customer_title() {
      if (this.new_project_customer_id != null)
        return this.$appm.get_customer(this.new_project_customer_id).dto.name;
      else return "dsd";
    },

    create_disabled() {
      if (this.new_project_name == "" || this.new_project_customer_id == null)
        return true;
      else return false;
    },

    available_customers_opts() {
      let opts = [];

      for (let cust of this.$app.customers) {
        opts.push({
          title: cust.dto.name,
          id: cust.dto.id
        });
      }
      return opts;
    }
  }
};
</script>

<style lang="css" scoped></style>
