import { RecordingInfoAPI } from "./recinfo";

export class RecordingInfoMonitorAPI extends RecordingInfoAPI {
  monitor_prj_list(
    project_id,
    callback,
    refresh_interval = global.default_refresh_interval
  ) {
    this.prj_list_base(project_id, callback, "/monitor", refresh_interval);
  }

  // monitor recording info
  list(
    project_id,
    zone_id,
    callback,
    refresh_interval = global.default_refresh_interval
  ) {
    this.list_base(project_id, zone_id, callback, "/monitor", refresh_interval);
  }

  fetch(
    project_id,
    zone_id,
    recording_info_id,
    callback,
    refresh_interval = global.default_refresh_interval
  ) {
    this.fetch_base(
      project_id,
      zone_id,
      recording_info_id,
      callback,
      "/monitor",
      refresh_interval
    );
  }

  update(project_id, zone_id, recording_info_id, changes, callback) {
    this.update_base(
      project_id,
      zone_id,
      recording_info_id,
      changes,
      callback,
      "/monitor"
    );
  }

  create(project_id, zone_id, rec_info, callback) {
    this.create_base(project_id, zone_id, rec_info, callback, "/monitor");
  }
}
