import { WMBaseItem } from "./baseitem";
import { JobQueue } from "./subsystems/job_queue";
import Network from "@/networking";
const Utils = require("../utils").default;
import Camur from "@/utils/camurutils";

export class Cntrl extends WMBaseItem {
  get ip() {
    return this.dto.ip;
  }

  get lastseen() {
    return this.dto.lastSeen;
  }

  get_last_seen_string() {
    return window.app.langutil.print_date_time_sec(this.lastseen);
  }

  get connected() {
    return this.dto.connected;
  }

  get device() {
    return window.appm.get_cntrl_device(
      this.app_prop.project_id,
      this.dto.serialNumber
    );
  }

  get name() {
    return this.dto.name;
  }

  get port() {
    return this.dto.port;
  }

  get alarm() {
    return this.dto.alarmSignal || this.dto.aggregateAlarmSignal;
  }

  get serial() {
    return this.dto.serialNumber;
  }

  get locked() {
    return this.has_jobs /* || this.dto.recordingMode != 0 */;
  }

  get in_a_busy_state() {
    if (this.has_jobs)
      return true;

    if(this.dto.state === Camur.controller_state.state_listening_to_commands ||
       this.dto.state === Camur.controller_state.state_failure ||
       this.dto.state === Camur.controller_state.state_not_connected ||
       this.dto.state === Camur.controller_state.state_disconnected ||
       this.dto.state === Camur.controller_state.state_no_assigned_device)
    {
      return false;
    }
    return true;
  }

  get has_jobs() {
    let hasJobs = this.app_prop.job_queue.active.length > 0;
    return hasJobs;
  }

  get active_task() {
    for (let key in this.app_prop.job_queue.active) {
      return this.app_prop.job_queue.active[key];
    }
    return null;
  }

  get active_task_name() {
    if (this.active_task == null) return null;
    return window.app.langutil.get_job_status(this.active_task);
  }

  get last_task() {
    let e = Object.entries(this.app_prop.job_queue.finished).sort(
      (a, b) => a.finish_date > b.finish_date
    );
    if (e.length > 0) return e[0][1];
    return null;
  }

  get last_task_name() {
    if (this.last_task == null) return null;
    return window.app.langutil.get_job_status(this.last_task);
  }

  get schedule_changed() {
      // calling items_are_different() also serves the purpose 
      // to check that local_schedule is accessible
    var changed = items_are_different(
      this.local_schedule,
      this.schedule);
    if (changed)
    {   // Check again because need to check 
        // only year, month, day, hour, minute
      var nextTimeStamp = new Date(this.schedule.next);
      var localNextTimeStamp = new Date(this.local_schedule.next);
      if ((this.local_schedule.active != this.schedule.active) ||
        (this.local_schedule.repeatEvery != this.schedule.repeatEvery) ||
        (this.local_schedule.repeatUnit != this.schedule.repeatUnit) ||
        (localNextTimeStamp.getFullYear() != nextTimeStamp.getFullYear()) ||
        (localNextTimeStamp.getMonth() != nextTimeStamp.getMonth()) ||
        (localNextTimeStamp.getDate() != nextTimeStamp.getDate()) ||
        (localNextTimeStamp.getHours() != nextTimeStamp.getHours()) ||
        (localNextTimeStamp.getMinutes() != nextTimeStamp.getMinutes()))
      {
        changed = true;
      }
      else
      {
        changed = false;
      }    
    }
    return changed;
  }

  get monitor_settings_changed() {
    return items_are_different(
      this.local_monitor_settings,
      this.monitor_settings
    );
  }

  get decay_settings_changed() {
    return items_are_different(this.local_decay_settings, this.decay_settings);
  }

  get lpr_settings_changed() {
    return items_are_different(this.local_lpr_settings, this.lpr_settings);
  }

  get zra_settings_changed() {
    return items_are_different(this.local_zra_settings, this.zra_settings);
  }

  get resmes_settings_changed() {
    return items_are_different(
      this.local_resmes_settings,
      this.resmes_settings
    );
  }

  get ready_to_stop_recordings() {
    if(!this.active_task) return true;

    switch (this.active_task.type) {
      case Camur.job_type.recordingModeChange:
        return false;
      default:
        return true;
    }
  }

  get item_type() {
    return "cntrl";
  }

  get nodes() {
    let project = window.appm.get_project(this.app_prop.project_id);
    let zones = project.zones;
    let result = [];

//    for(let z of this.zones) {
    for(let z of zones) {
      for(let n of z.nodes) {
        //console.log("cntrl.node:"+n.id);
        if (n.dto.controllerId == this.id) {
          //console.log(" pushed");
          result.push(n);
        }
      }
    }
    return result;
  }

  get zones() {

    let result = [];
    let project = window.appm.get_project(this.app_prop.project_id);
    let zones = project.zones;

    for(let z of zones) {
      if (z.controllerId == this.id) { 
        result.push(z);
      }
    }
    return result;
  }

  get zoneIds() {

    let result = [];
    let project = window.appm.get_project(this.app_prop.project_id);
    let zones = project.zones;

    for(let z of zones) {
      if (z.controllerId == this.id) { 
        result.push(z.id);
      }
    }
    return result;
  }

  constructor(dto, identification) {
    let settings = settings_from_dto(dto);
    dto = remove_settings_from_dto(dto);
    super(dto);
    this.save_settings(settings);

    this.app_prop.project_id = identification.project_id;
  }

  update_from_dto(dto, reset_locals) {
    if (
      dto.schedule &&
      dto.lpr &&
      dto.monitor &&
      dto.decay &&
      dto.zra &&
      dto.resMes
    ) {
      // We should only update the settings
      // if the dto actually contains new settings.
      // This is because the dto can be set via WS-events
      let settings = settings_from_dto(dto);
      dto = remove_settings_from_dto(dto);
      this.save_settings(settings);
    }
    super.update_from_dto(dto, reset_locals);
    // this.save_settings(settings);
  }

  save_settings(settings) {
//    this.monitor_settings = Utils.deep_clone(settings.monitor);
// Check for any local changes before over-writing with updated settings
    if (!this.monitor_settings_changed)
      this.local_monitor_settings = Utils.deep_clone(settings.monitor);
    this.monitor_settings = Utils.deep_clone(settings.monitor);

//    this.decay_settings = Utils.deep_clone(settings.decay);
    if (!this.decay_settings_changed)
      this.local_decay_settings = Utils.deep_clone(settings.decay);
    this.decay_settings = Utils.deep_clone(settings.decay);

//    this.lpr_settings = Utils.deep_clone(settings.lpr);
    if (!this.lpr_settings_changed)
      this.local_lpr_settings = Utils.deep_clone(settings.lpr);
    this.lpr_settings = Utils.deep_clone(settings.lpr);

//    this.zra_settings = Utils.deep_clone(settings.zra);
    if (!this.zra_settings_changed)
      this.local_zra_settings = Utils.deep_clone(settings.zra);
    this.zra_settings = Utils.deep_clone(settings.zra);

//    this.resmes_settings = Utils.deep_clone(settings.resmes);
    if (!this.resmes_settings_changed)
      this.local_resmes_settings = Utils.deep_clone(settings.resmes);
    this.resmes_settings = Utils.deep_clone(settings.resmes);

//    this.schedule = Utils.deep_clone(settings.schedule);
    if (!this.schedule_changed)
      this.local_schedule = Utils.deep_clone(settings.schedule);
    this.schedule = Utils.deep_clone(settings.schedule);
  }

  reset_settings() {
    this.local_monitor_settings = Utils.deep_clone(this.monitor_settings);
    this.local_decay_settings = Utils.deep_clone(this.decay_settings);
    this.local_lpr_settings = Utils.deep_clone(this.lpr_settings);
    this.local_zra_settings = Utils.deep_clone(this.zra_settings);
    this.local_resmes_settings = Utils.deep_clone(this.resmes_settings);
    this.local_schedule = Utils.deep_clone(this.schedule);
  }

  get_job_with_id(id) {
      // Find job in active queue
      var jobIndex = this.app_prop.job_queue.active.findIndex(job => job.id === id);
      if (jobIndex >= 0)
        return this.active[jobIndex];
      else
      { // Find job in finished queue
        jobIndex = this.finished.findIndex(job => job.id === id);
        if (jobIndex >= 0)
          return this.finished[jobIndex];
        else
        {
          return null;
        }
      }
  }

  props_template() {
    return {
      include_select: false,
      project_id: 0,
      tree_open: true,
      job_queue: new JobQueue(this)
    };
  }

  upload_changes() {
    let chngs = this.changes();
    chngs.lastUpdate = this.dto.updated;
    return Network.cntrl.update(this.app_prop.project_id, this.id, chngs);
  }

  assign_device(device) {
    let serial = 0;
    if (this.device != null) {
      this.device.set_cntrl(null);
    }

    if (device != null) {
      device.set_cntrl(this);
      serial = device.serial;
    }

    this.submit_device_change(serial);
  }

  submit_device_change(new_serial) {
    // TODO: internationalize
    let msg = new_serial ? `Device with serial ${new_serial} assigned to ${this.name}` : `Unassign device on ${this.name}`;

    return Network.cntrl.update(
      this.app_prop.project_id,
      this.id,
      {
        serialNumber: new_serial,
        lastUpdate: this.dto.updated
      },
      () => window.appm.show_note(msg)
    );
  }

  // eslint-disable-next-line
  on_data_updated(dto, app) {
    if (this.device != null) {
      this.device.set_cntrl(this);
    }
  }

  on_online_status_change(new_status) {
    if (new_status != this.connected) {
      // Reload data
      Network.cntrl.fetch(this.app_prop.project_id, this.dto.id, null, 0);
      Network.cntrl_device.list(this.app_prop.project_id, null, 0);
    }
  }

  static new_item_instance(dto, identification) {
    return new Cntrl(dto, identification);
  }

  static find_parent(app, identification) {
    return app.$appm.get_project(identification.project_id).cntrls;
  }

  static find(app, id, identification) {
    return app.$appm.get_cntrl(identification.project_id, id);
  }
}

function settings_from_dto(dto) {
  return {
    monitor: dto.monitor,
    lpr: dto.lpr,
    decay: dto.decay,
    zra: dto.zra,
    resmes: dto.resMes,
    schedule: dto.schedule
  };
}

function remove_settings_from_dto(dto) {
  delete dto.monitor;
  delete dto.lpr;
  delete dto.decay;
  delete dto.zra;
  delete dto.resMes;
  delete dto.schedule;
  return dto;
}

function items_are_different(a, b) {
  return Object.keys(Utils.diff_objects(a, b)).length > 0;
}
