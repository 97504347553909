<template lang="html">
  <div
    class="wm_resizer"
    @mousedown="is_dragging = true"
    @mouseup="is_dragging = false"
  ></div>
</template>

<script>
export default {
  emits: ['update:modelValue'],
  // eslint-disable-next-line
  name: "Resizer",
  data() {
    return {
      is_dragging: false
    };
  },

  props: {
    modelValue: {
      type: Number,
      default: 100
    },

    min: {
      type: Number,
      default: 100
    },

    max: {
      type: Number,
      default: 200
    }
  },

  methods: {
    on_dragging(event) {
      if (this.is_dragging) {
        if (this.$app.ui.mousedown) {
          let width = event.clientX;
          if (width > this.max) width = this.max;
          if (width < this.min) width = this.min;
          this.$emit("update:modelValue", width);
        } else {
          this.is_dragging = false;
        }
      }
    }
  },

  created() {
    window.addEventListener("mousemove", this.on_dragging);
  },

  unmounted() {
    window.removeEventListener("mousemove", this.on_dragging);
  },

  watch: {
    "$app.ui.mousedown": function() {
      if (!this.$app.ui.mousedown) this.is_dragging = false;
    }
  }
};
</script>

<style lang="css" scoped></style>
