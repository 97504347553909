<!-- From https://vuejs.org/v2/examples/modal.html -->
<template lang="html">
  <transition name="modalanim">
    <div class="modal-mask">
      <div class="modal-wrapper">
        <div class="modal-container" :style="{ width: full_length }">
          <div class="modal-header">
            <h4 v-if="title != null" class="section_start">{{ title }}</h4>
          </div>

          <div class="modal-body">
            <slot>
              default body
            </slot>
          </div>

          <div class="modal-footer" v-show="!no_footer">
            <slot name="footer">
              <Btn
                :title="$txt.cancel"
                class="modal-default-button hol_btn"
                @clicked="$emit('close')"
              >
              </Btn>
            </slot>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  // eslint-disable-next-line
  name: "Modal",
  props: {
    width: {
      default: 500,
    },
    title: {
      default: null,
    },
    no_footer: {
      default: false,
    },
  },

  computed: {
    full_length: function () {
      return this.width.toString() + "px";
    },
  },
};
</script>

<style lang="css" scoped>
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.1s ease;
}

.modal-wrapper {
  display: inherit;
  margin: auto;
  vertical-align: top;
  margin-top: 100px;
}

.modal-container {
  width: 300px;
  margin: 0px auto;
  padding: 10px 10px;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.1s ease;
}

.modal-header h3 {
  padding: 0px;
  margin-top: 0;
  color: #42b983;
}

.modal-header {
  padding: 0px;
}

.modal-body {
  padding: 5px;
  margin: 1px 0;
  max-height: 400px;
  overflow-y: auto;
}

.modal-footer {
  padding: 0px;
  padding-top: 5px;
}

.modal-default-button {
  float: right;
}

/*
 * The following styles are auto-applied to elements with
 * transition="modal" when their visibility is toggled
 * by Vue.js.
 *
 * You can easily play with the modal transition by editing
 * these styles.
 */

.modalanim-enter-active {
  transition: all 0.1s;
}

.modalanim-leave-active {
  transition: all 0.1s ease;
}

.modalanim-enter {
  opacity: 0 !important;
}

.modalanim-enter-to {
  opacity: 1 !important;
}

.modalanim-leave-to {
  transform: translateY(-20px);
  opacity: 0;
}
</style>
