<template lang="html">
  <transition name="noteanim">
    <div class="note_panel">
      <span>
        <span>
          {{ note.text }}
        </span>

        <span class="note_error_time_label">
          <TimeLabel
            :time="note.time"
            :show_date="false"
            :show_seconds="true"
          />
          <Btn
            style="margin-left: 4px; margin-top: -3px"
            class="white_hol_btn small_btn"
            @clicked="hide()"
            :title="$txt.close"
          />
        </span>

        <span class="note_error_time_label"> </span>
      </span>
    </div>
  </transition>
</template>

<script>
export default {
  props: {
    note: {
      type: Object
    }
  },
  methods: {
    hide() {
      this.$emit("close");
    }
  },  
};
</script>
