import { WMBaseItem } from "./baseitem";

export class CntrlDevice extends WMBaseItem {
  get serial() {
    return this.dto.serialNumber;
  }

  get id() {
    return this.dto.serialNumber;
  }

  get node_devices() {
    return this.children.node_devices;
  }

  get versionMajor() {
    return this.dto.versionMajor;
  }
  get versionMinor() {
    return this.dto.versionMinor;
  }
  get version_full() {
     // Leading 0
    let versionMinor = String(this.dto.versionMinor).padStart(2, '0');
    return this.dto.versionMajor + "." + versionMinor;
  }

  get item_type() {
    return "cntrl_device";
  }

  constructor(dto, identification) {
    super(dto);
    this.app_prop.project_id = identification.project_id;
    this.find_ctrl();
  }

  props_template() {
    return {
      project_id: 0,
      cntrl: null
    };
  }

  children_template() {
    return {
      node_devices: []
    };
  }

  upload_changes() {
    let changes = this.handle_websocket_commands(this.changes());

    return this.Network.node_device.update(
      this.app_prop.project_id,
      this.id,
      changes
    );
  }

  find_ctrl() {
    let prj = window.appm.get_project(this.app_prop.project_id);

    if (prj == null) return;

    for (let cntrl of prj.cntrls) {
      if (cntrl.serial == this.serial) {
        this.set_cntrl(cntrl);
        return;
      }
    }
  }

  set_cntrl(cntrl) {
    this.app_prop.cntrl = cntrl;
  }
  // eslint-disable-next-line
  on_data_updated(dto, app) {
    this.find_ctrl();
  }

  static new_item_instance(dto, identification) {
    return new CntrlDevice(dto, identification);
  }

  static find_parent(app, identification) {
    return app.$appm.get_project(identification.project_id).cntrl_devices;
  }

  static find(app, id, identification) {
    var n = app.$appm.get_cntrl_device(identification.project_id, id);
    return n;
  }
}
