import { APIBase } from "./apibase";
import { Firmware } from "@/items/firmware";

export class FirmwareAPI extends APIBase {
  list(custom_callback, refresh_interval = global.default_refresh_interval) {
    return this.send({
      command: "firmware",
      method: "GET",
      refresh_interval: refresh_interval,
      on_receive: this.receive_array,
      callback: custom_callback
    });
  }

  receive_array(response, identification, myself) {
    for (var i = 0; i < response.length; i++) {
      Firmware.update_or_create_from_dto(
        response[i],
        myself.app,
        identification
      );
    }
  }
}
