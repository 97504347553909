<template lang="html">
  <div class="LoginView">
    <NotesAndErrorsPanel />
    <div class="login_main">
      <div class="login_main_content">
        <div class="login_welcome_area">
          <div class="login_welcome_content">
            <h1 class="login_welcome_heading">
              Welcome to the new Camur Workspace
            </h1>
            <p class="login_welcome_text">
              Contact your local Camur Workspace Account Administrator to get access if your organization already has an account. 
              <br/>
              <br/>
              Contact 
              <a :href="'mailto:' + this.admin_email" class="login_welcome_link"
                target="_blank" rel="noopener noreferrer"> 
                workspace-admin@protector-technology.com
              </a>
               to create a new account for your organization

              <br/>
              <br/>
              <a
                class="login_welcome_link"
                :href="'https://www.protector.no/en/support/support.html'"
                target="_blank"
              >
                Protector Support information
              </a>
              <!-- <router-link to="about" class="login_welcome_link">
                here.
              </router-link> -->
            </p>
          </div>
        </div>

        <div class="login_signin_area">
          <div class="login_signin_box">
            <LoadingPanel
              v-if="sending_login_request || sending_forgot_request"
            />
            <form v-else>
              <transition name="loginanim" mode="out-in">
                <div v-if="mode == 'forgot'" key="forgot">
                  <h4 class="login_signin_title">{{ $txt.reset_password }}</h4>
                  <p class="login_signin_text">{{ $txt.enter_reset_email }}</p>
                  <input
                    class="wm_date_input login_username_input"
                    @keyup.enter="forgot(username)"
                    type="text"
                    placeholder="E-mail"
                    autocomplete="email"
                    v-model="username"
                    @input="v => { username = toLower(username)}"
                  />
                  <br />
                  <Btn
                    class="login_button"
                    :title="this.$txt.send_email"
                    @clicked="forgot(username)"
                    :disabled="$util.verify_email(username) ? null : true"
                    type="submit"
                  />
                  <br />
                  <Btn
                    class="login_button hol_btn"
                    @clicked="set_mode('login')"
                    :title="'← ' + $txt.back_to_log_in"
                  />
                </div>

                <div v-else key="login">
                  <h4 class="login_signin_title">{{ $txt.log_in }}</h4>
                  <input
                    id="username_input"
                    class="login_username_input wm_date_input "
                    type="text"
                    :placeholder="this.$txt.username"
                    autocomplete="username"
                    v-model="username"
                    @input="v => { username = toLower(username)}"
                  />
                  <input
                    id="password_input"
                    class="wm_date_input login_input"
                    @keyup.enter="sign_in(username, password)"
                    type="password"
                    :placeholder="this.$txt.password"
                    autocomplete="current-password"
                    v-model="password"
                  />

                  <Btn
                    class="login_button"
                    :title="$txt.sign_in"
                    @clicked="sign_in(username, password)"
                    @disabledclick="check_invalid_login_fields()"
                    type="submit"
                  />
                  <!-- :disabled="invalid_login_fields" -->
                  <Btn
                    class="login_button hol_btn"
                    id="forgot_password_btn"
                    @clicked="set_mode('forgot')"
                    :title="$txt.forgot_password"
                  />
                </div>
              </transition>
            </form>
          </div>
        </div>
      </div>
    </div>

    <div class="login_below">
      <div class="login_below_content">
        <h1 class="login_below_heading"></h1>
        <p class="login_welcome_text"></p>
        <p class="login_welcome_text"></p>
      </div>
    </div>

    <div class="login_lowest">
      <img width="200" src="../assets/protector.png" />
    </div>
  </div>
</template>

<script>
import router from '@/router';
export default {
  name: "LoginView",
  data() {
    return {
      username: "",
      password: "",
      mode: "login",
      login_request: null,
      forgot_password_request: null,
      admin_email: "workspace-admin@protector-technology.com",
    };
  },

  computed: {
    invalid_login_fields() {
      return this.username == "" || this.password == "";
    },

    sending_login_request() {
      if (this.login_request == null) {
        return false;
      }
      let in_progress = !this.login_request.finished && (this.login_request.err_code == 0);
      return in_progress;
    },

    sending_forgot_request() {
      if (this.forgot_password_request == null) {
        return false;
      }
      return !this.forgot_password_request.req.finished;
    },
  },

  methods: {
    toLower(v) {
      return v.toLowerCase();
    },
    forgot(email) {
      if (this.$util.verify_email(email)) {
        this.forgot_password_request =
          this.$net.user.send_verification_email(email);
          router.push({
          name: "success",
          params: { type: "forgotmailsent" }});
      } else {
        this.$appm.show_error_msg("Invalid email address");
      }
    },

    sign_in() {
      if (!this.check_invalid_login_fields()) {
        this.$appm.reset_errors_and_notes();
        this.login_request = this.$net.user.login(this.username, this.password);
      }
    },

    set_mode(new_mode) {
      this.$appm.reset_errors_and_notes();
      this.mode = new_mode;
    },

    check_invalid_login_fields() {
      if (this.invalid_login_fields) {
        this.$appm.show_note(this.$txt.please_fill_in);
        return true;
      }
      return false;
    },
  },

  created: function () {
//    this.$app.reset_errors_and_notes();
    this.$appm.set_title(this.$txt.log_in);
  }
};
</script>
