export default {
  parameters_to_string(parameters) {
    var str = "?";
    while (parameters.length > 0) {
      var p = parameters.shift();
      str += p[0] + "=" + p[1];
      if (parameters.length > 0) str += "&";
    }
    return encodeURI(str);
  },

  get_xhr_response(xhr) {
    if (xhr != null && xhr.readyState == 4) {
      try {
        return JSON.parse(xhr.response);
      } catch (e) {
        if (e instanceof SyntaxError) {
          throw "Error parsing response: " + e;
        }
      }
    }
    return null;
  },

  svg_element_to_img_download(el, filename) {
    var svgString = new XMLSerializer().serializeToString(el);
    var canvas = document.createElement("canvas");
    var ctx = canvas.getContext("2d");

    ctx.canvas.height = el.clientHeight;
    ctx.canvas.width = el.clientWidth;

    var DOMURL = self.URL || self.webkitURL || self;
    var img = new Image();
    var svg = new Blob([svgString], { type: "image/svg+xml;charset=utf-8" });
    var url = DOMURL.createObjectURL(svg);

    let _this = this;

    img.onload = function() {
        // Skip first y-row because of a garbage dotted line !!?
      ctx.drawImage(img, 0, -1);
      var png = canvas.toDataURL("image/png");
      _this.download_data_as_file(png, filename + ".png");

      DOMURL.revokeObjectURL(png);
      canvas.remove();
    };
    img.src = url;
  },

  svg_element_download_as_file(el, filename) {
    var svgData = el.outerHTML;

    //add name spaces.
    if (!svgData.match(/^<svg[^>]+xmlns="http:\/\/www\.w3\.org\/2000\/svg"/)) {
      svgData = svgData.replace(
        /^<svg/,
        '<svg xmlns="http://www.w3.org/2000/svg"'
      );
    }
    if (!svgData.match(/^<svg[^>]+"http:\/\/www\.w3\.org\/1999\/xlink"/)) {
      svgData = svgData.replace(
        /^<svg/,
        '<svg xmlns:xlink="http://www.w3.org/1999/xlink"'
      );
    }

    this.download_blob_as_file(
      svgData,
      filename + ".svg",
      "image/svg+xml;charset=utf-8"
    );
  },

  download_data_as_file(data, filename) {
    var download = document.createElement("a");
    download.href = data;
    download.download = filename;
    download.click();
    download.remove();
  },

  download_blob_as_file(data, filename, type) {
    var blob = new Blob([data], { type: type });
    var url = URL.createObjectURL(blob);
    var downloadLink = document.createElement("a");
    downloadLink.href = url;
    downloadLink.download = filename;
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  },
  download_xlsx_as_file(data, filename) {
    var blob = new Blob([data]);
    var url = URL.createObjectURL(blob);
    var downloadLink = document.createElement("a");
    downloadLink.href = url;
    downloadLink.download = filename;
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  },

  //A-Za-z0-9 3-8 letters, cannot start with number
  alias_from_name(name) {
    name = name.toLowerCase();

    let legal_characters = "abcdefghijklmnopqrstuvwxyz0123456789";

    // Remove all illegal characters
    var alias = "";
    for (let i = 0; i < name.length; i++) {
      if (legal_characters.includes(name[i])) alias += name[i];
    }

    // If name is empty give it a timebased number
    if (alias.length == 0) {
      alias += Date.now();
    }

    // It cannot start with a number!
    if ("0123456789".includes(alias[0])) {
      alias = "a" + alias;
    }

    while (alias.length < 3 && alias.length > 0) {
      alias += alias;
    }
    alias = alias.substring(0, 8);

    return alias;
  },

  list_cntrls_for_project(proj, none_str) {
    var controllers = proj.cntrls;

    var result = [];
    for (var i = 0; i < controllers.length; i++) {
      result.push({
        id: controllers[i].id,
        title: controllers[i].name
      });
    }
    result.push({
      id: 0,
      title: none_str
    });

    return result;
  },

  is_array(value) {
    var res = value && typeof value === "object" && value.constructor === Array;
    return res;
  },

  is_object(value) {
    return value && typeof value === "object" && value.constructor === Object;
  },

  is_null(value) {
    return value === null;
  },

  is_node(item) {
    return item.item_type == "node";
  },

  is_zone(item) {
    return item.item_type == "zone";
  },

  is_cntrl(item) {
    return item.item_type == "cntrl";
  },

  is_project(item) {
    return item.item_type == "project";
  },

  is_cntrl_device(item) {
    return item.item_type == "cntrl_device";
  },

  is_node_device(item) {
    return item.item_type == "node_device";
  },

  deep_clone(src) {
    return this.iteration_copy(src);
  },

  iteration_copy(src) {
    if (this.is_array(src)) {
      let target = [];
      for (var i = 0; i < src.length; i++) {
        target.push(this.iteration_copy(src[i]));
      }
      return target;
    } else if (this.is_object(src)) {
      let target = {};
      for (let prop in src) {
        if (src.hasOwnProperty(prop)) {
          target[prop] = this.iteration_copy(src[prop]);
        }
      }
      return target;
    } else {
      return src;
    }
  },

  assign_template(obj, template) {
    for (var prop in template) {
      if (!obj.hasOwnProperty(prop)) {
        if (this.is_object(template[prop]) || this.is_array(template[prop])) {
          obj[prop] = this.iteration_copy(template[prop]);
        } else {
          obj[prop] = template[prop];
        }
      }
    }
  },

  diff_objects(old_object, changed_object) {
    var changes = {};
    for (var prop in old_object) {
      if (changed_object.hasOwnProperty(prop)) {
        if (
          this.is_object(old_object[prop]) ||
          this.is_array(old_object[prop])
        ) {
          var c = this.diff_objects(old_object[prop], changed_object[prop]);
          if (Object.keys(c).length > 0) changes[prop] = c;
        } else if (changed_object[prop] != old_object[prop]) {
          if (prop == "updated" || prop == "created") continue;
          changes[prop] = changed_object[prop];
        }
      }
    }
    return changes;
  },

  array_element_by_id(id, elements) {
    let existing_elements = elements.filter(el => {
      return el.id === id;
    });

    if (existing_elements.length == 1) {
      return existing_elements[0];
    } else if (existing_elements.length == 0) {
      return null;
    }
    throw "Multiple items in array had id " + id;
  },

  array_elements_mathing_prop(elements, prop_name, val) {
    return elements.filter(el => {
      return el.hasOwnProperty(prop_name) && el[prop_name] === val;
    });
  },

  update_object_in_array(obj_data, array_name, parent_object, template) {
    var array = parent_object[array_name];
    this.update_object(obj_data, array, template);
  },

  update_object(obj_data, array, template = null) {
    var object_to_update = this.array_element_by_id(obj_data.id, array);
    if (object_to_update != null) {
      object_to_update = Object.assign(object_to_update, obj_data);
    } else {
      if (template != null) this.assign_template(obj_data, template);
      array.push(obj_data);
    }
  },

  array_remove_object_with_id(array, id) {
    for (var i = 0; i < array.length; i++) {
      var uid = array[i].id;
      if (uid === id) {
        array.splice(i, 1);
        return;
      }
    }
  },

  array_remove_object_matching_prop(array, prop_name, prop_value) {
    for (var i = 0; i < array.length; i++) {
      if (array[i].hasOwnProperty(prop_name)) {
        var uid = array[i][prop_name];
        if (uid === prop_value) {
          array.splice(i, 1);
          return;
        }
      }
    }
  },

  remove_elements_not_present_in_new_array(old_array, new_array) {
    var ids_to_delete = [];

    for (var i = 0; i < old_array.length; i++) {
      if (this.array_element_by_id(old_array[i].id, new_array) === null) {
        ids_to_delete.push(old_array[i].id);
      }
    }

    for (var j = 0; j < ids_to_delete.length; j++) {
      this.array_remove_object_with_id(old_array, ids_to_delete[j]);
    }
  },

  //This function can find results when entering parts of the string.
  search_matches(name, pattern) {
    if (name == null || pattern == null) return false;
  
    name = name.trim().toLowerCase();
    pattern = pattern.trim().toLowerCase();
  
    // Return true if the name contains the pattern
    return name.includes(pattern);
  },

  // search_matches(name, pattern, contain_search = false) {
  //   if (name == null) return false;

  //   name = name.trim().toLowerCase();
  //   pattern = pattern.trim().toLowerCase();

  //   if (name.startsWith(pattern)) return true;

  //   let parts = name.split(" ");
  //   for (let part of parts) {
  //     if (contain_search) {
  //       if (part.includes(pattern)) return true;
  //     } else {
  //       if (part.startsWith(pattern)) return true;
  //     }
  //   }
  //   return false;
  // },

  //Makes the webpage laggy and crash from longer inputs
  // verify_email(email) {
  //   return /^\w+([.-]?[\w|+]+)*@\w+([.-]?\w+)*(\.\w{2,6})+$/.test(email);
  // },

  //Alot faster than above and doesnt make the email input lag
  verify_email(email) {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  },

  to_iso_datetime(str) {
    if (!str) return false;
    var date = new Date(str);
    if (this.is_valid_date(date)) {
      return date.toISOString();
    }
    return false;
  },

  is_valid_date(d) {
    return d instanceof Date && !isNaN(d);
  },

  recording_is_inside_period(rec, period_start, period_end) {
    var period_start_time = new Date(period_start).getTime();
    var period_end_time = new Date(period_end).getTime();
    var rec_start_time = new Date(rec.start).getTime();
    var rec_end_date = new Date(Date.now());
    if (rec.stop != null) rec_end_date = new Date(rec.stop);

    var rec_end_time = rec_end_date.getTime();

    return (
      rec_end_time >= period_start_time && rec_start_time <= period_end_time
    );
  },

  make_date(year, month, day) {
    return new Date(year, month, day).toLocaleDateString("sv");
  },

  get_columns(pts) {
    var cols = [];
    for (var i = 0; i < pts.length; i++) {
      var keys = Object.keys(pts[i]);
      for (var j = 0; j < keys.length; j++) {
        if (!cols.includes(keys[j])) cols.push(keys[j]);
      }
    }
    return cols;
  },

  ordered_table(pts) {
    var rows = [];
    var columns = this.get_columns(pts);
    var last_dt = "";

    var tmplt_row = {
      Date: ""
    };
    for (let i = 1; i < columns.length; i++) {
      tmplt_row[columns[i]] = null;
    }

    for (let i = 0; i < pts.length; i++) {
      var date_key = "d" + new Date(pts[i].date).getTime();
      if (date_key != last_dt) {
        var row = this.deep_clone(tmplt_row);
        row.Date = pts[i].date;
        last_dt = date_key;
        rows.push(row);
      }
      for (var j = 0; j < columns.length; j++) {
        var key = columns[j];
        if (pts[i].hasOwnProperty(columns[j]) && key != "date") {
          rows[rows.length - 1][key] = pts[i][key];
        }
      }
    }
    return rows;
  },

  obj_array_to_array_array(obj_array) {
    return obj_array.map(function(obj) {
      return Object.keys(obj)
        .sort()
        .map(function(key) {
          return obj[key];
        });
    });
  },

  num_to_bytes(num) {
    num = parseInt(num);
    var byteArray = [0, 0, 0, 0];
    for (var index = 0; index < byteArray.length; index++) {
      var byte = num & 0xff;
      byteArray[index] = byte;
      num = (num - byte) / 256;
    }
    return String.fromCharCode(
      byteArray[0],
      byteArray[1],
      byteArray[2],
      byteArray[3]
    );
  },

  bytes_to_num(bytes_str) {
    var bytes = [];
    for (var i = 0; i < 4; i++) {
      bytes[i] = bytes_str.charCodeAt(i);
    }
    var result = bytes[0];
    result += bytes[1] << 8;
    result += bytes[2] << 16;
    result += bytes[3] << 24;
    return result;
  },

  bytes_to_base64(bytes) {
    return btoa(bytes);
  },
  base64_to_bytes(bytes) {
    return atob(bytes);
  },

  encode_serial_base64(serial) {
    return this.bytes_to_base64(this.num_to_bytes(serial));
  },

  decode_serial_base64(serial) {
    return this.bytes_to_num(this.base64_to_bytes(serial));
  },

  // eslint-disable-next-line
  download_xls(rows, filename) {
    alert("Not yet supported");
  },

  download_json(rows, filename) {
    let json_content =
      "data:text/json;charset=utf-8," +
      encodeURIComponent(JSON.stringify(this.obj_array_to_array_array(rows)));
    this.download_file(json_content, filename + ".json");
  },

  download_csv(rows, filename) {
    let csv_content =
      "data:text/csv;charset=utf-8," +
      this.obj_array_to_array_array(rows)
        .map(e => e.join(","))
        .join("\n");
    this.download_file(encodeURI(csv_content), filename + ".csv");
  },

  download_file(content, filename) {
    var link = document.createElement("a");
    link.setAttribute("href", content);
    link.setAttribute("download", filename);
    document.body.appendChild(link); // Required for FF
    link.click();
  },

  filter_table(table, filter_string, filter_prop, contain_search = false) {
    if (filter_string == "") return table;
    return table.filter(e =>
      this.search_matches(e[filter_prop], filter_string, contain_search)
    );
  },

  is_string(val) {
    return typeof val === "string" || val instanceof String;
  },

  chance(percentage) {
    var d = Math.random() * 100.0 > percentage;
    return d;
  },

  rough_lowres_of_table(table, max_size) {
    let keep_ratio = max_size / table.length;
    let keep = [];
    for (var i = 0; i < table.length; i++) {
      if (keep.length / i < keep_ratio) {
        keep.push(table[i]);
      }
    }
    return keep;
  },

  sort_table(table, sort_prop) {
    if (table.length == 0) return [];
    var sorted = this.deep_clone(table);
    if (
      sort_prop == "updated" ||
      sort_prop == "created" ||
      this.is_string(sorted[0][sort_prop])
    ) {
      return sorted.sort(function(a, b) {
        var _a = a[sort_prop];
        if (_a == null) _a = "";
        var _b = b[sort_prop];
        if (_b == null) _b = "";
        return _a.toUpperCase() < _b.toUpperCase() ? -1 : 1;
      });
    } else {
      return sorted.sort(function(a, b) {
        var _a = a[sort_prop];
        if (_a == null) _a = 0;
        var _b = b[sort_prop];
        if (_b == null) _b = 0;

        if (_a < _b) {
          return -1;
        }
        return 1;
      });
    }
  }
};
