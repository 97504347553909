import { WMBaseItem } from "./baseitem";

export default class RecordingInfo extends WMBaseItem {
  get zoneId() {
    return this.dto.zoneId;
  }

  get start() {
    return this.dto.start;
  }

  get stop() {
    return this.dto.stop;
  }

  constructor(dto, identification) {
    super(dto);
    this.app_prop.project_id = identification.project_id;
    this.app_prop.zone_id = identification.zone_id;
  }

  props_template() {
    return {
      project_id: 0,
      zone_id: 0
    };
  }

  upload_changes() {
    return this.Network.recording_info.update(
      this.app_prop.project_id,
      this.app_prop.zone_id,
      this.id,
      this.changes()
    );
  }

  static new_item_instance(dto, identification) {
    return new RecordingInfo(dto, identification);
  }

  static find_parent(app, identification) {
    return app.$appm.get_zone(identification.project_id, identification.zone_id)
      .recording_infos;
  }

  static find(app, id, identification) {
    return app.$appm.get_recording_info(
      identification.project_id,
      identification.zone_id,
      id
    );
  }
}
