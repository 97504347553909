<template>
  <div class="TopMenuView top-menu" id="topmenu">
    <!--     <div class="topmenu_left">
      <a href="/"><TopMenuItem label="WebMonitor"/></a>
    </div>
 -->
    <!-- <div class="topmenu_center" v-if="ready_to_render"> -->
    <div
      class="topmenu_left"
      v-if="ready_to_render"
      v-show="current_route.name != 'login'"
    >
      <TopMenuItem
        :label="$txt.customers"
        :icon="$icon.customers"
        :route="{ name: 'customers' }"
        v-show="$appm.can_see_customer_tab"
        :tab_selected="$appm.maintab == 'customers'"
      />
      <TopMenuItem
        :label="$txt.projects"
        :icon="$icon.projects"
        :route="{ name: 'project_manager' }"
        :tab_selected="$appm.maintab == 'projects'"
      />
      <!--       <TopMenuItem
        :label="$txt.users"
        :icon="$icon.user"
        :route="{ name: 'users' }"
        v-show="$app.session.current_user.can_see_users_tab"
        :tab_selected="$app.maintab == 'users'"
      />
 -->
      <TopMenuItem
        :label="$txt.settings"
        :icon="$icon.app_settings"
        :route="{ name: 'settings' }"
        :tab_selected="$appm.maintab == 'settings'"
      />
    </div>

    <div class="topmenu_right" v-show="this.$route.name != 'login'">
      <div class="top_menu_item, top_menu_button">
        <a
          class="user_manual"
          :href="'https://www.protector.no/en/support/support.html'"
          target="_blank"
        >
          {{ $txt.user_manual }}
        </a>
      </div>

      <TopMenuItem :icon="$icon.account" :route="{ name: 'account' }" />
    </div>
  </div>
</template>

<script>
import TopMenuItem from "@/components/topmenu/TopMenuItem";
import router from '@/router';

export default {
  components: { TopMenuItem },
  name: "TopMenuView",

  computed: {
    ready_to_render() {
      return this.$appm.logged_in();
    },
    current_route() {
      return router.currentRoute.value;
    }
  },
};
</script>
