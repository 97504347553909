import { reactive } from 'vue'
export const DataStore = reactive ({
  projects: [],
  users: [],
  customers: [],
  grants: [],

  firmware: [],

  netstate: 0,

  sltd: {},

  maintab: "projects",

  session: {
    loaded: false,
    current_user_id: null,
    current_language: "sv",
    usertoken: "undefined",
    logged_in: false,
    current_user: null, // This should be initalized as a User class
    log_out_timeout_id:0
  },

  upload_req: null,
  dragged_item: null,

 // report_table: [],
  //received_data: true,
  received_token_verification: false,
  received_token_valid: false,
  server_log: [{ name: "A" }, { name: "B" }],
  service_log: [],
  event_log: [],
  error_codes: null,
  graph_props: null,
  alarm_subscribers: null,

  settings: {
    grid_rows: 7,
    grid_columns: 7,
    display_time: "prj",
    show_map_prj_manager: false,
    drop_multiple_functions: false,
    display_internal_id : false,

    api_version: "0",
    lang: "en",
    locale_string: "sv-se",
    developer_mode: false,
    number_locale: "def",
    time_locale: "def",
    decimal_point: '.',

    last_tab: {
      report: "table",
      zone: "zone_overview",
      project: "project_overview",
      node: "node_overview",
      controller: "controller_overview"
    },

    map2d: {
      unit: "m"
    },

    report: {
      interval: 2,
      report_type: 0,
      from_date: "",
      to_date: "",
      selected_recording: ""
    },

    event_log: {
      interval: 2,
      from_date: "",
      to_date: "",
      event_type: 0,
      event_level: 0,
      max_rows: 100
    },

    copied_node_settings: null,
    copied_formula: null,

    chart: {
      custom_size: false,
      custom_width: 900,
      custom_height: 500
    },
  
    project_overview_display_aspect: "grid"
  },

  ui: {
    error_msgs: [],
    notes: [],
    project_tree_keep_open: false,
    mousedown: false,
    tight_mode: false,
    select_mode: false,
    select_mode_level:0
  },

  current_version: {
    build: "unknown build",
    builder: "unknown builder",
    date: "unknown date",
    version: "unknown version"
  }
});
