<template>
  <a
    class="btn"
    :id="btnid"
    :disabled="disabled"
    :title="hintText"
    :class="{ wm_tight_dropdown: tight }"
    @click="click()"
  >
    {{ title }}
  </a>
</template>

<script>
export default {
  // eslint-disable-next-line
  name: "Btn",
  emits: ['clicked'],
  data() {
    return {};
  },

  props: {
    title: String,
    hintText: String,
    disabled: null,
    tight: {
      type: Boolean,
      default: false
    },
    btnid: {
      type: String,
      default: null
    }
  },

  methods: {
    click() {
      let disable_this = false;
      if (this.disabled != null) {
        disable_this = this.disabled;
      }
      if (!disable_this) {
        //console.log("btn click");
        this.$emit("clicked");
      }
    }
  },

  created: function() {}
};
</script>
