import { APIBase } from "../apibase";
import Grant from "@/items/grant";
import User from "@/items/user";
import AccessCollection from "@/items/accesscollection";

export class GrantAPI extends APIBase {
  list(refresh_interval = global.default_refresh_interval) {
    return this.send({
      command: "users/access/grants",
      method: "GET",
      identification: {},
      refresh_interval: refresh_interval,
      on_receive: this.receive_grant_array
    });
  }

  update(grant_data, custom_callback) {
    this.send({
      command: "users/access/grants",
      method: "POST",
      identification: {},
      on_receive: this.receive_grant,
      post_json: grant_data,
      custom_callback: custom_callback
    });
  }

  del(user_id) {
    this.send({
      command: "users/access/grants/" + user_id,
      method: "DELETE",
      identification: {
        user_id: user_id
      },
      on_receive: this.receive_delete
    });
  }

  receive_grant_array(response, identification, myself) {
    for (let grant of response.grants) {
      Grant.update_or_create_from_dto(grant, myself.app, identification);
    }
    // We get a bunch of users and ACs in the response
    // Which we need to deal with
    if (response.users != null) {
      for (let user_id in response.users) {
        User.update_or_create_from_dto(
          response.users[user_id],
          myself.app,
          identification
        );
      }
    }

    if (response.collections != null) {
      for (let ac_id in response.collections) {
        AccessCollection.update_or_create_from_dto(
          response.collections[ac_id],
          myself.app,
          identification
        );
      }
    }
  }

  receive_grant(response, identification, myself) {
    Grant.update_or_create_from_dto(response, myself.app, identification);
  }

  receive_delete(response, identification, myself) {
    if (response == true) {
      Grant.delete_clientside(
        myself.app,
        identification,
        identification.grant_id
      );
    }
  }
}
