<template>
  <div
    class="wm_section_begin noselect"
    @click="on_collapse(!modelValue)"
    :title="$txt.section_hint"
  >
    <SectionOpenCloseBtn v-if="collapsable" :modelValue="modelValue" />
    <span class="section_start">
      {{ title }}
    </span>
  </div>
</template>

<script>
export default {
  name: "SectionBegin",
  data() {
    return {};
  },

  props: {
    title: String,
    modelValue: Boolean,
    collapsable: {
      type: Boolean,
      default: true
    }
  },
  emits: ['update:modelValue'],
  methods: {
    on_collapse(event) {
      if (this.collapsable) this.$emit("update:modelValue", event);
    }
  },

  created: function() {}
};
</script>

<style media="screen"></style>
