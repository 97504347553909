import { GrantAPI } from "./grant";

export class AccessCollectionGrantAPI extends GrantAPI {
  list(
    customer_id,
    collection_id,
    custom_callback,
    refresh_interval = global.default_refresh_interval
  ) {
    return this.send({
      command:
        "customers/" +
        customer_id +
        "/access/collections/" +
        collection_id +
        "/grants",
      method: "GET",
      identification: {
        customer_id: customer_id,
        collection_id: collection_id
      },
      refresh_interval: refresh_interval,
      on_receive: this.receive_grant_array,
      custom_callback: custom_callback
    });
  }

  update(customer_id, collection_id, grant, custom_callback) {
    return this.send({
      command:
        "customers/" +
        customer_id +
        "/access/collections/" +
        collection_id +
        "/grants",
      method: "PUT",
      identification: {
        customer_id: customer_id,
        collection_id: collection_id
      },
      on_receive: this.receive_grant,
      post_json: grant,
      custom_callback: custom_callback
    });
  }
}
