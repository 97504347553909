<template>
  <div class="wm_section">
    <SectionEnd />
    <SectionBegin
      class="wm_section_head"
      :title="this.title"
      v-model="collapsed"
    />
    <SectionEnd />
    <div v-if="!collapsed" :class="{ wm_section_content: content_padding }">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  // eslint-disable-next-line
  name: "Section",
  data() {
    return {
      collapsed: false
    };
  },

  created() {
    this.collapsed = this.collapsedFromStart;
  },

  props: {
    title: {
      type: String,
      default: "Unnamed section"
    },
    content_padding: {
      type: Boolean,
      default: true
    },
    collapsedFromStart: {
      type: Boolean,
      default: false
    }
  },

  methods: {
    on_collapse(event) {
      this.collapsed = event;
    }
  }
};
</script>
<style lang="less">
.open-enter-active {
  transition: all 0.05s ease;
}

.open-enter,
.open-leave-to {
  transform: translateY(-10px);
  opacity: 0;
}
</style>
