<template>
  <SmallIcon
    :icon="icon"
    :color="color"
    :size="size"
  ></SmallIcon>
</template>

<script>

export default {
  name: "TinyIcon",
  data() {
    return {};
  },

  props: {
    icon: {
      type: String,
      default: "person"
    },
    color: {
      type: String,
      default: "gray"
    },
    size: {
      type: String,
      default: "14"
    }
  },

  computed: {
    icon_name() {
      return "baseline-" + this.icon + "-24px";
    }
  },

  created: function() {}
};
</script>
