import { WMBaseItem } from "./baseitem";

export default class User extends WMBaseItem {
  get privileges() {
    return this.children.privileges;
  }
  get email() {
    return this.dto.email;
  }

  children_template() {
    return {
      customers_they_can_grant: {},
      customers_they_can_setup: {},
      customers_they_can_view: {}
    };
  }

  upload_changes() {
    return this.Network.user.update(this.id, this.changes());
  }

  on_data_updated(dto, app) {
    app.$appm.update_current_user();
  }

  set_customer_privileges(all_customers) {
    this.children.customers_they_can_grant = all_customers.filter(
      c => c.canGrant
    );
    this.children.customers_they_can_setup = all_customers.filter(
      c => c.canSetup
    );
    this.children.customers_they_can_view = all_customers.filter(
      c => c.canView
    );
  }

  get can_create_customer() {
    return this.app_prop.global_rights.canSetup;
  }

  get can_purge_events() {
    return this.app_prop.global_rights.canSetup;
  }

  get can_diagnose() {
    return this.app_prop.global_rights.canSetup;
  }

  get can_remove_recordings() {
    return this.app_prop.global_rights.canDelete;
  }

  get can_global_admin() {
    return this.app_prop.global_rights.canSetup;
  }

  get can_see_accounting() {
    return this.app_prop.global_rights.canView;
  }

  set global_rights(val) {
    this.app_prop.global_rights = val;
  }

  get can_create_projects() {
    // Any user with project grant (inherited from Customer),
    // canSetup
    return this.children.customers_they_can_setup.length > 0;
  }

  get can_see_customer_tab() {
    return this.children.customers_they_can_view.length > 0;
  }
  get can_see_users_tab() {
    return this.children.customers_they_can_grant.length > 0;
  }

  get item_type() {
    return "user";
  }

  static new_item_instance(dto, identification) {
    return new User(dto, identification);
  }

  // eslint-disable-next-line
  static find_parent(app, identification) {
    return app.users;
  }

  // eslint-disable-next-line
  static find(app, id, identification) {
    return app.$appm.get_user(id);
  }

  props_template() {
    return {
      global_rights: {}
    };
  }
}
